import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";

import { displayChartNoGrid } from "./options/chart-grid";
import { checkColor } from "../../utils/chart/color";
import {
  convertBigInts,
  findMinMaxForCoinChart,
  findOnlyNumbers,
  formatDataToCoinsChart,
} from "../../utils/chart/coins-chart";

type CoinsChartProps = {
  data: (null | number | BigInt)[];
};

export default function CoinsChart({ data }: CoinsChartProps): JSX.Element {
  const amounts = convertBigInts(data);
  const onlyNumbers = findOnlyNumbers(amounts);

  const checkChartColor = () => checkColor(onlyNumbers, true);

  return (
    <div className="chart chart--coins">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={formatDataToCoinsChart(amounts)}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          {displayChartNoGrid()}
          <XAxis dataKey="timestamp" hide={true} />
          <YAxis
            dataKey="amount"
            hide={true}
            domain={findMinMaxForCoinChart(onlyNumbers)}
          />
          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="amount"
            stroke={checkChartColor()}
            strokeWidth={1}
            fillOpacity={0.08}
            fill={checkChartColor()}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
