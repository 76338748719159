import Xarrow from "react-xarrows";
import { StepsLineColors } from "../const";

export const displayLine = (start: string, end: string, isDone: boolean) => {
  return (
    <Xarrow
      start={start}
      end={end}
      startAnchor={{ position: "bottom", offset: { y: 4 } }}
      endAnchor={{ position: "top", offset: { y: -4 } }}
      lineColor={isDone ? StepsLineColors.DONE : StepsLineColors.NORMAL}
      showHead={false}
      dashness={{ strokeLen: 6, nonStrokeLen: 6 }}
      strokeWidth={2.5}
      SVGcanvasProps={{
        strokeLinecap: "round",
      }}
    />
  );
};
