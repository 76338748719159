import { SyntheticEvent, forwardRef, memo, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

import Hint from "../../hint/hint";
import ChainIcon from "../../icons/nav/chain-icon";
import AffiliateIcon from "../../icons/nav/affiliate-icon";
import NavReferralsSkeleton from "../../skeletons/nav-referrals-skeleton";
import AnimatedNavNotifications from "./animated-nav-notifications";
import { setNavAccountsOpened } from "../../../store/actions";
import { useAppDispatch } from "../../../hooks";
import { AuthStatus, AppRoute, HINTS_TEXT } from "../../../const";
import { MessagesType } from "../../../types/general";

type NavReferralsItemProps = {
  messages: MessagesType;
  authStatus: string;
  isAffiliate: boolean;
  isUserProfileLoading: boolean;
};

export function NavReferralsItem(
  {
    messages,
    authStatus,
    isAffiliate,
    isUserProfileLoading,
  }: NavReferralsItemProps,
  ref: any
): JSX.Element {
  const path = useLocation();
  const dispatch = useAppDispatch();

  const isAuth = authStatus === AuthStatus.Auth;
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const handleReferralsClick = (evt: SyntheticEvent) => {
    if (ref?.current?.contains(evt.target as Element)) {
      evt.preventDefault();
    }
  };

  useEffect(() => {
    if (path.pathname.includes(AppRoute.Accounts)) {
      dispatch(setNavAccountsOpened(true));
    }
  }, [dispatch, path.pathname]);

  return (
    <li className="nav__item">
      <NavLink
        className="nav__link"
        to={isAffiliate ? AppRoute.Affiliates : AppRoute.Referrals}
        onClick={handleReferralsClick}
      >
        {isUserProfileLoading ? (
          <NavReferralsSkeleton />
        ) : isAffiliate ? (
          <>
            <div className="nav__icon-wrapper">
              <span className="nav__icon nav__icon--affiliate">
                <AffiliateIcon />
              </span>
            </div>
            <span className="nav__link-text">Affiliates</span>
          </>
        ) : (
          <>
            <div className="nav__icon-wrapper">
              <span className="nav__icon nav__icon--referral">
                <ChainIcon />
              </span>
            </div>
            <span className="nav__link-text">Referral program</span>
            {isNoAuth && (
              <Hint
                type="nav__referrals-hint"
                id="nav/referrals"
                hintText={HINTS_TEXT["nav/referrals"]}
                ref={ref}
              />
            )}
          </>
        )}
        {isAuth && messages && (
          <AnimatedNavNotifications messagesNumber={messages.referrals} />
        )}
      </NavLink>
    </li>
  );
}

export default memo(
  forwardRef<HTMLDivElement | null, NavReferralsItemProps>(NavReferralsItem)
);
