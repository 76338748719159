import CoinToggle from "./coin-toggle";
import PeriodToggle from "./period-toggle";

type TogglesBaseProps = {
  type: string;
  isBitcoinMode: boolean;
  isAllDays: boolean;
  setBitcoinMode: (isBitcoinMode: boolean) => void;
  setAllDays: (isAllDays: boolean) => void;
  hint?: JSX.Element | null;
};

export default function TogglesBase({
  type,
  isBitcoinMode,
  setBitcoinMode,
  isAllDays,
  setAllDays,
  hint,
}: TogglesBaseProps): JSX.Element {
  return (
    <div className="toggles-base">
      <PeriodToggle type={type} isAllDays={isAllDays} setAllDays={setAllDays} />
      <div className="toggles-base__item-wrapper">
        <CoinToggle
          type={type}
          isBitcoinMode={isBitcoinMode}
          setBitcoinMode={setBitcoinMode}
        />
        {hint && <div className="toggles-base__hint">{hint}</div>}
      </div>
    </div>
  );
}
