export default function FiveStarsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="142" height="25" fill="none">
      <path
        fill="currentColor"
        d="M12 2.8a1 1 0 0 1 2 0L15.6 8c.1.4.5.6 1 .6h5.5a1 1 0 0 1 .6 1.9l-4.5 3a1 1 0 0 0-.3 1.2l1.7 5A1 1 0 0 1 18 21l-4.5-3.2a1 1 0 0 0-1.2 0L8 20.9a1 1 0 0 1-1.6-1.2l1.7-5a1 1 0 0 0-.3-1.2l-4.5-3a1 1 0 0 1 .6-1.9h5.6a1 1 0 0 0 1-.6L12 2.8ZM41 2.8a1 1 0 0 1 2 0L44.6 8c.1.4.5.6 1 .6h5.5a1 1 0 0 1 .6 1.9l-4.5 3a1 1 0 0 0-.3 1.2l1.7 5A1 1 0 0 1 47 21l-4.5-3.2a1 1 0 0 0-1.2 0L37 20.9a1 1 0 0 1-1.6-1.2l1.7-5a1 1 0 0 0-.3-1.2l-4.5-3a1 1 0 0 1 .6-1.9h5.6a1 1 0 0 0 1-.6L41 2.8ZM70 2.8a1 1 0 0 1 2 0L73.6 8c.1.4.5.6 1 .6h5.5a1 1 0 0 1 .6 1.9l-4.5 3a1 1 0 0 0-.3 1.2l1.7 5A1 1 0 0 1 76 21l-4.5-3.2a1 1 0 0 0-1.2 0L66 20.9a1 1 0 0 1-1.6-1.2l1.7-5a1 1 0 0 0-.3-1.2l-4.5-3a1 1 0 0 1 .6-1.9h5.6a1 1 0 0 0 1-.6L70 2.8ZM99 2.8a1 1 0 0 1 2 0l1.7 5.2c.1.4.5.6 1 .6h5.5a1 1 0 0 1 .6 1.9l-4.5 3a1 1 0 0 0-.3 1.2l1.7 5A1 1 0 0 1 105 21l-4.5-3.2a1 1 0 0 0-1.2 0L95 20.9a1 1 0 0 1-1.6-1.2l1.7-5a1 1 0 0 0-.3-1.2l-4.5-3a1 1 0 0 1 .6-1.9h5.6a1 1 0 0 0 1-.6L99 2.8ZM128 2.8a1 1 0 0 1 2 0l1.7 5.2c.1.4.5.6 1 .6h5.5a1 1 0 0 1 .6 1.9l-4.5 3a1 1 0 0 0-.3 1.2l1.7 5A1 1 0 0 1 134 21l-4.5-3.2a1 1 0 0 0-1.2 0l-4.5 3.2a1 1 0 0 1-1.6-1.2l1.7-5a1 1 0 0 0-.3-1.2l-4.5-3a1 1 0 0 1 .6-1.9h5.6a1 1 0 0 0 1-.6l1.7-5.2Z"
      />
    </svg>
  );
}
