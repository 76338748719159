import { Outlet } from "react-router-dom";

import ShortHeader from "../header/short-header/short-header";
import Footer from "../footer/footer";

export default function LandingLayout(): JSX.Element {
  return (
    <div className="page page--landing">
      <div className="page__cointainer page__cointainer--landing">
        <ShortHeader />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
