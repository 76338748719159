export default function SmardIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill="#EFF3FB" />
      <path
        d="M6.18882 11.0828L5.98505 11.4355L5.37147 12.5H5.37033L3.5 9.25H5.13355L6.18882 11.0828Z"
        fill="#F93332"
      />
      <path
        d="M10.0998 4L9.50799 5.08351L9.31071 5.44545H6.88344L5.27388 8.39154L5.07714 8.75H3.5L6.09432 4H10.0998Z"
        fill="#1A1A1A"
      />
      <path
        d="M14.4995 9.05062L11.6885 14H7.65527L8.25112 12.9505L8.45029 12.6012H10.894L12.9112 9.05062L11.6885 6.89876L11.8865 6.54893L12.4824 5.5L14.4995 9.05062Z"
        fill="#1A1A1A"
      />
      <path
        d="M12.0556 4.70629L11.268 6.11944L7.66666 12.5869L7.2726 13.2931L6.87964 14H6.09316L5.7002 13.2931L6.48612 11.8823L6.48722 11.88L10.0886 5.41258L10.8761 4H11.6621L12.0556 4.70629Z"
        fill="#32E532"
      />
    </svg>
  );
}
