import { useState } from "react";

import Hint from "../hint/hint";
import { CoinType } from "../../types/coins";
import { checkIfDesktop } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { HINTS_TEXT, RERENDER_DELAY } from "../../const";
import {
  displayArrow,
  checkPercentClass,
  displayPercent,
  displayMinus,
  displayAmount,
  displayPrice,
} from "../../utils/component/coin";

type CoinBoardProps = {
  coin: CoinType;
};

export default function CoinBoard({ coin }: CoinBoardProps): JSX.Element {
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());
  const isPrice = coin.price !== null;

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  const currentPrice = (
    <div className="coin-board__item coin-board__item--cp">
      <p className="coin-board__amount">
        {displayMinus(coin.price as number)}
        {displayPrice(coin.price as number)}
      </p>
      {coin.change_24h !== null && (
        <p
          className={`coin-board__percent ${checkPercentClass(
            coin.change_24h
          )}`}
        >
          {displayArrow(coin.change_24h)}
          {`${displayPercent(coin.change_24h)} (24h)`}
        </p>
      )}
      <p className="coin-board__title">Current Price</p>
    </div>
  );
  return (
    <section className={`coin-board ${isPrice ? "" : "coin-board--no-price"}`}>
      {isDesktop && isPrice && (
        <div className="coin__item coin-board__item--cp coin-board__block-price">
          {currentPrice}
        </div>
      )}

      <div className="coin__item coin-board__block">
        {!isDesktop && isPrice && currentPrice}

        <div className="coin-board__item coin-board__item--mc">
          <p className="coin-board__amount">
            {displayMinus(coin.market_cap)}
            {displayAmount(coin.market_cap)}
          </p>
          <p className="coin-board__title">Market Cap</p>
        </div>

        <div className="coin-board__item coin-board__item--v24">
          <p className="coin-board__amount">
            {displayMinus(coin.volume_24h)}
            {displayAmount(coin.volume_24h)}
          </p>
          <p
            className={`coin-board__percent ${checkPercentClass(
              coin.volume_24h_change
            )}`}
          >
            {displayArrow(coin.volume_24h_change)}
            {displayPercent(coin.volume_24h_change)}
          </p>
          <div className="coin-board__title">
            <span>Volume (24h)</span>
            <Hint
              type="coin-board__hint coin-board__hint--volume24"
              id="coin-board/volume24h"
              hintText={HINTS_TEXT["coin/volume24h"]}
            />
          </div>
        </div>
        <div className="coin-board__item  coin-board__item--cs">
          <p className="coin-board__amount">
            {displayMinus(coin.circulating_supply)}
            {displayAmount(coin.circulating_supply)}
          </p>
          <div className="coin-board__title">
            <span>Circulating Supply</span>
            <Hint
              type="coin-board__hint coin-board__hint--circulating-supply"
              id="coin-board/circulating-supply"
              hintText={HINTS_TEXT["coin/circulating-supply"]}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
