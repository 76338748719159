import { SEARCH_ROBOTS } from "../const";

export const addShadowIfLongInstrument = (string: string) => {
  if (string.split("").length > 8) {
    return "shadow shadow--instrument";
  }
  return "";
};

export const checkTrendingTableClass = (amount: number) => {
  if (amount > 0) {
    return "up";
  }

  if (amount < 0) {
    return "down";
  }
  return "";
};

export const checkNotificationsAmount = (amount: number) => {
  if (amount >= 100) {
    return "99+";
  }
  return amount;
};

export const deleteExtraDescription = () => {
  const description = document.getElementById("mainDescription");

  if (description) {
    description.remove();
  }
};

export const deleteExtraCanonicalLink = () => {
  const canonicalLink = document.getElementById("mainCanonicalLink");

  if (canonicalLink) {
    canonicalLink.remove();
  }
};

export const deleteExtraLinkedData = () => {
  const linkedData1 = document.getElementById("mainLinkedData1");
  const linkedData2 = document.getElementById("mainLinkedData2");

  if (linkedData1) {
    linkedData1.remove();
  }

  if (linkedData2) {
    linkedData2.remove();
  }
};

export const deleteSeoData = () => {
  deleteExtraDescription();
  deleteExtraCanonicalLink();
  deleteExtraLinkedData();
};

export const isSearchRobot = () => {
  const userAgent = navigator.userAgent;
  return SEARCH_ROBOTS.some((item) => userAgent.includes(item));
};

export const redirectToExternalLink = (link: string) =>
  window.location.replace(link);