export default function StepOkxIcon(): JSX.Element {
  return (
    <svg
    width="100%"
    height="100%"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0908 3.66606H3.91359C3.77864 3.66606 3.66863 3.77607 3.66863 3.91103V7.08826C3.66863 7.22321 3.77864 7.33323 3.91359 7.33323H7.0908C7.22575 7.33323 7.33576 7.22321 7.33576 7.08826V3.91103C7.33429 3.77607 7.22575 3.66606 7.0908 3.66606Z"
        fill="currentColor"
      />
      <path
        d="M3.42217 0H0.244966C0.110015 0 0 0.110015 0 0.244967V3.4222C0 3.55716 0.110015 3.66717 0.244966 3.66717H3.42217C3.55712 3.66717 3.66714 3.55716 3.66714 3.4222V0.2435C3.66714 0.108548 3.55712 0 3.42217 0Z"
        fill="currentColor"
      />
      <path
        d="M10.755 0H7.57783C7.44288 0 7.33286 0.110015 7.33286 0.244967V3.4222C7.33286 3.55716 7.44288 3.66717 7.57783 3.66717H10.755C10.89 3.66717 11 3.55716 11 3.4222V0.2435C11 0.108548 10.89 0 10.755 0Z"
        fill="currentColor"
      />
      <path
        d="M3.42217 7.33283H0.244966C0.110015 7.33283 0 7.44284 0 7.5778V10.755C0 10.89 0.110015 11 0.244966 11H3.42217C3.55712 11 3.66714 10.89 3.66714 10.755V7.5778C3.66714 7.44138 3.55712 7.33283 3.42217 7.33283Z"
        fill="currentColor"
      />
      <path
        d="M10.755 7.33283H7.57783C7.44288 7.33283 7.33286 7.44284 7.33286 7.5778V10.755C7.33286 10.89 7.44288 11 7.57783 11H10.755C10.89 11 11 10.89 11 10.755V7.5778C11 7.44138 10.89 7.33283 10.755 7.33283Z"
        fill="currentColor"
      />
    </svg>
  );
}
