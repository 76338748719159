export default function EthereumCoinIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle cx="10" cy="10" r="10" fill="#D9DBEE" />
      <path fill="#7880E7" d="M4 10.03 10 .5l6 9.53-6 3.47-6-3.47Z" />
      <path fill="#5C64C7" d="m10 .5 6 9.53-6 3.47V.5Z" />
      <path fill="#7880E7" d="m4 11.5 6 3.37 6-3.37-6 8-6-8Z" />
      <path
        fill="#5C64C7"
        d="m10 14.79 6-3.43-6 8.14v-4.71Zm-6-4.72 6-2.57 6 2.57-6 3.43-6-3.43Z"
      />
      <path fill="#2A3192" d="m10 7.5 6 2.57-6 3.43v-6Z" />
    </svg>
  );
}
