import { ErrorType } from "../../const";
import { ResponseStatusType } from "../../types/details";
import { minNotional } from "../../utils/env";
import Message from "../message/message";
import AmountRangeNote from "../steps-to-connect/notes/amount-rage-note";
import MinNotionalError from "../steps-to-connect/notes/min-notional-error";

type BinanceFuturesChangeAmountErrorMessageProps = {
  availableAmount: number;
  loadAmountToStartStatus: null | ResponseStatusType;
  sendAmountToStartStatus: null | ResponseStatusType;
};

export default function BinanceFuturesChangeAmountErrorMessage({
  sendAmountToStartStatus,
  loadAmountToStartStatus,
  availableAmount,
}: BinanceFuturesChangeAmountErrorMessageProps): JSX.Element {
  const isStartStatusFail = sendAmountToStartStatus?.isSuccess === false;
  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const startStatus = sendAmountToStartStatus;

  return isStartStatusFail && startStatus?.message ? (
    <Message status="error" message={startStatus?.message} />
  ) : isLoadAmountFail ? (
    <Message status="error" message={ErrorType.General} />
  ) : availableAmount >= minNotional ? (
    <Message
      status="neutral"
      message={<AmountRangeNote availableAmount={availableAmount} />}
    />
  ) : (
    <Message status="error" message={<MinNotionalError />} />
  );
}
