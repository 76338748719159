import { useState } from "react";
import { motion } from "framer-motion";

import RenewKeysWrapper from "./renew-keys-wrapper";

type AnimatedRenewKeysProps = {
  accountName: string;
};

export default function AnimatedRenewKeys({
  accountName,
}: AnimatedRenewKeysProps): JSX.Element {
  const [isAnimationEnd, setAnimationEnd] = useState<boolean>(false);

  return (
    <motion.div
    // несмотря на то, что у блока в стилях уже заданы margin-bottom : - 15px через details__renew-keys, здесь тоже пишем, чтобы блок не прыгал при исчезновении в анимации
      initial={{
        height: 0,
        marginBottom: "-13px",
        opacity: 0,
      }}
      animate={{
        height: "auto",
        marginBottom: 0,
        opacity: 1,
      }}
      exit={{
        height: 0,
        marginBottom: "-13px",
        opacity: 0,
      }}
      onAnimationComplete={() => {
        setAnimationEnd(true);
      }}
      style={{ overflow: "hidden" }}
      transition={{ duration: 0.4 }}
      className="details__renew-keys"
    >
      <RenewKeysWrapper
        accountName={accountName}
        isAnimationEnd={isAnimationEnd}
        clearAnimationEnd={() => {
          setAnimationEnd(false);
        }}
      />
    </motion.div>
  );
}
