import { useState } from "react";
import ReactSlider from "react-slider";

import { localAmount } from "../../utils/amount";

export default function PartnershipIncome(): JSX.Element {
  const DEFAULT_AMOUNT = 1000000;

  const [amount, setAmount] = useState<number>(DEFAULT_AMOUNT);

  const handleAmountChange = (valueNow: number) => {
    setAmount(valueNow);
  };

  const displaySliderAmount = () => {
    const calculated = amount * 0.6 * 0.1 * 0.5;
    const formatted = Math.round(calculated / 100) * 100;
    return `${localAmount(formatted)} USDT`;
  };

  return (
    <div className="partnership-income">
      <h3 className="partnership-income__heading">
        Your average annual income:
      </h3>
      <p className="partnership-income__amount">{displaySliderAmount()}</p>
      <div className="partnership-income__toggle">
        <span className="partnership-income__label">
          Total Affiliate Capital Invested
        </span>
        <ReactSlider
          className="partnership-income__slider"
          ariaLabel="Audience investment"
          thumbClassName="partnership-income__thumb"
          trackClassName="partnership-income__track"
          min={100000}
          max={5000000}
          step={10000}
          defaultValue={amount}
          onChange={handleAmountChange}
          renderThumb={(props, state) => (
            <div {...props}>
              <span className="partnership-income__mark">$</span>
              <span className="partnership-income__value">{`$${localAmount(
                state.valueNow
              )}`}</span>
            </div>
          )}
        />
      </div>
      <p className="partnership-income__note">
        *Based on average returns of SMARD strategies
      </p>
    </div>
  );
}
