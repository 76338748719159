import { createAction } from "@reduxjs/toolkit";

import { StateAction } from "../action-types";
import { AppRoute } from "../../const";
import {
  AccountStatusesType,
  UserAuthAccountsType,
  UserProfileType,
} from "../../types/general";

export const redirectToRoute = createAction<AppRoute | string>(
  StateAction.User.RedirectToRoute
);

export const setAccountStatuses = createAction<AccountStatusesType>(
  StateAction.User.SetAccountStatuses
);
export const setIsAccountStatusesLoading = createAction<boolean>(
  StateAction.User.setIsAccountStatusesLoading
);
export const requireAuthorization = createAction<string>(
  StateAction.User.RequireAuth
);
export const loadUserAuthAccountsStatus = createAction<UserAuthAccountsType>(
  StateAction.User.LoadUserAuthAccountsStatus
);
export const loadUserProfile = createAction<UserProfileType>(
  StateAction.User.LoadUserProfile
);
export const setUserProfileLoading = createAction<boolean>(
  StateAction.User.SetUserProfileLoading
);

export const setOkxConnected = createAction<boolean>(
  StateAction.User.SetOkxConnected
);
export const setBybitConnected = createAction<boolean>(
  StateAction.User.SetBybitConnected
);
export const setBinanceSpotConnected = createAction<boolean>(
  StateAction.User.SetBinanceSpotConnected
);
export const setBinanceFuturesConnected = createAction<boolean>(
  StateAction.User.SetBinanceFuturesConnected
);
export const setOkxRedirectFromApple = createAction<boolean>(
  StateAction.User.SetOkxRedirectFromApple
);
export const setBitgetConnected = createAction<boolean>(
  StateAction.User.SetBitgetConnected
);
export const setBitgetRedirectFromApple = createAction<boolean>(
  StateAction.User.SetBitgetRedirectFromApple
);
