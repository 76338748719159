export default function LogoMobileIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 40 37"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <g clipPath="url(#a)">
        <path
          fill="#F93232"
          d="m9 26-.68 1.3-2.06 3.93-6.26-12h5.47L9 25.99Z"
        />
        <path
          fill="#1A1A1A"
          d="M24 .23 21.85 4.1l-.72 1.3H12.3L6.45 15.94l-.71 1.29H0l9.43-17H24ZM40 18.18 29.73 36.23H15l2.18-3.83.72-1.27h8.93l7.37-12.95-4.47-7.85.73-1.28 2.17-3.82L40 18.18Z"
        />
        <path
          fill="#32E532"
          d="m31 2.77-2.85 5.09-13.03 23.28-1.43 2.54-1.42 2.55H9.42L8 33.68l2.84-5.08L23.89 5.3 26.73.23h2.85L31 2.77Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .23h40v36H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
