import { YAxis } from "recharts";

export function displayChartBenchYAxis(
  isFewData: boolean,
  formatTicks: (tick: number) => string,
  domain: number[],
  ticks: number[]
): JSX.Element {
  return isFewData ? (
    <YAxis
      yAxisId="right"
      orientation="right"
      axisLine={false}
      tickLine={false}
      dataKey="benchmarkAmount"
      width={50}
      allowDecimals={false}
      interval={0}
      domain={domain}
      tickFormatter={(tick: number) => formatTicks(tick)}
    />
  ) : (
    <YAxis
      yAxisId="right"
      orientation="right"
      axisLine={false}
      tickLine={false}
      dataKey="benchmarkAmount"
      width={50}
      allowDecimals={false}
      interval={0}
      domain={domain}
      tickFormatter={(tick: number) => formatTicks(tick)}
      ticks={ticks}
    />
  );
}

export function displayCoinChartSecondYAxis(
  isFewData: boolean,
  formatTicks: (tick: number) => string,
  domain: number[],
  ticks: number[]
): JSX.Element {
  return isFewData ? (
    <YAxis
      yAxisId="right"
      orientation="right"
      axisLine={false}
      tickLine={false}
      dataKey="amount"
      width={50}
      allowDecimals={false}
      interval={0}
      domain={domain}
      tickFormatter={(tick: number) => formatTicks(tick)}
    />
  ) : (
    <YAxis
      yAxisId="right"
      orientation="right"
      axisLine={false}
      tickLine={false}
      dataKey="amount"
      width={50}
      allowDecimals={false}
      interval={0}
      domain={domain}
      tickFormatter={(tick: number) => formatTicks(tick)}
      ticks={ticks}
    />
  );
}
