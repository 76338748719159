import { useEffect } from "react";

import TelegramLogin from "../telegram-login/telegram-login";
import TelegramIcon from "../icons/socials/telegram-icon";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch } from "../../hooks";
import { AUTH_TELEGRAM_BOT_NAME, AUTH_TELEGRAM_URL } from "../../const";

type LoginTelegramProps = {
  isAuthInShortHeader: boolean;
  isAuthModalOpened: boolean;
  getAuthUrl: (link: string) => string;
};

export default function LoginTelegram({
  isAuthInShortHeader,
  isAuthModalOpened,
  getAuthUrl,
}: LoginTelegramProps): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.focus();
    window.addEventListener("blur", () => {
      if (
        document.activeElement === document.querySelector("iframe") &&
        document.querySelector("iframe")?.id.includes("telegram-login")
      ) {
        trackEvent("TelegramAuthButton", "Clicked");
        trackEvent("AuthButton", "Clicked");
      }
    });
  }, [dispatch]);

  return (
    <div className="login login--telegram">
      {/* Когда открывается auth модалка - перестает работать кнопка в шапке, а только в модалке, тк id бота должен быть один на странице*/}
      {(isAuthInShortHeader && isAuthModalOpened) || (
        <TelegramLogin
          dataAuthUrl={getAuthUrl(AUTH_TELEGRAM_URL)}
          botName={AUTH_TELEGRAM_BOT_NAME}
          radius="0"
          typeClass="login__telegram-frame"
        />
      )}
      <span className="login__icon login__icon--telegram">
        <TelegramIcon />
      </span>
      <span className="visually-hidden">Login with telegram</span>
    </div>
  );
}
