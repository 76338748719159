import Button from "../button/button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { FailedTransactionsType } from "../../types/payment";
import { localAmount } from "../../utils/amount";
import { showFullDate, showTime } from "../../utils/datetime";
import { tryAgainFailedTransactions } from "../../store/api-actions";
import { setCardFormChosen, setCryptoFormChosen } from "../../store/actions";

type failedTransactionsProps = {
  failedTransactions: FailedTransactionsType[];
};

export default function PaymentsFailed({
  failedTransactions,
}: failedTransactionsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const { isFailedTransactionsAgainLoading } = useAppSelector((state) => state);

  const addSign = (amount: number) => {
    const rounded = Math.round(amount);
    if (rounded >= 0) {
      return "";
    }
    return "payments-failed__absolute--minus";
  };

  const displayAmount = (amount: number) =>
    `$${localAmount(Math.abs(Math.round(amount)))}`;

  const handleTryAgain = () => {
    dispatch(tryAgainFailedTransactions());
  };

  const handleAddDeposit = () => {
    dispatch(setCryptoFormChosen(true));
  };

  const handleAddCard = () => {
    dispatch(setCardFormChosen(true));
  };

  return (
    <section className="block payments__item payments__failed payments-failed">
      <h2 className="block__heading block__heading--alert">
        Failed transactions
      </h2>
      <p className="payments-failed__intro">
        Your trading activity has been paused due to an outstanding
        payment&nbsp;issue. <br />
        Please make a payment in any way that is convenient to&nbsp;you!
      </p>
      <ul className="payments-failed__table">
        <li className="payments-failed__row">
          <p className="payments-failed__row-heading payments-failed__row-heading--date">
            Date
          </p>
          <p className="payments-failed__row-heading payments-failed__row-heading--reason">
            Reason
          </p>
          <p className="payments-failed__row-heading payments-failed__row-heading--amount">
            Amount
          </p>
        </li>

        {failedTransactions.map((item, i) => (
          <li className="payments-failed__row" key={i}>
            <div className="payments-failed__date">
              <p className="payments-failed__date-full">
                {showFullDate(item.timestamp)}
              </p>
              <p className="payments-failed__date-time">
                {showTime(item.timestamp)}
              </p>
            </div>

            <div className="payments-failed__reason">
              <p className="payments-failed__reason-text">{item.reason}</p>
            </div>

            <div className="payments-failed__amount">
              <p
                className={`payments-failed__absolute ${addSign(item.amount)}`}
              >
                {displayAmount(item.amount)}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <div className="payments-failed__btns-wrapper">
        <Button
          classElement="payments-failed__btn"
          pattern="green-white"
          text="Add crypto"
          icon="plus-in-circle"
          type="button"
          handleClick={handleAddDeposit}
        ></Button>
        <Button
          classElement="payments-failed__btn"
          pattern="green-white"
          text="Add card"
          icon="credit-card"
          type="button"
          handleClick={handleAddCard}
        />
        <Button
          classElement={`payments-failed__btn payments-failed__btn--try-again ${
            isFailedTransactionsAgainLoading ? "btn--disabled" : ""
          }`}
          pattern="white-blue"
          text="Try again"
          icon="arrow-right"
          type="button"
          handleClick={handleTryAgain}
        />
      </div>
    </section>
  );
}
