export default function WatchStopIcon(): JSX.Element {
  return (
<svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0819 5.87891L17.5528 8.31766C17.2324 8.62666 17.2324 9.12722 17.5528 9.43621L19.8943 11.6531C20.0546 11.8076 20.2646 11.8849 20.4743 11.8849C20.6841 11.8849 20.8941 11.8076 21.0543 11.6531L23.5618 9.23537L20.0819 5.87891Z" fill="#60A5FA" />
<path d="M7.40213 8.35363L4.88031 5.92188L1.40039 9.27751L3.9158 11.7031C4.07602 11.8576 4.28601 11.9348 4.49579 11.9348C4.70557 11.9348 4.91556 11.8576 5.07578 11.7031L7.40213 9.47218C7.72256 9.16319 7.72256 8.66262 7.40213 8.35363Z" fill="#2563EB" />
<path d="M10.8633 3.95312H14.1445V7.96094H10.8633V3.95312Z" fill="#2563EB" />
<path d="M12.5039 3.95312H14.1445V7.96094H12.5039V3.95312Z" fill="#60A5FA" />
<path d="M14.1445 4.74609H10.8633C9.50635 4.74609 8.40234 3.68152 8.40234 2.37305C8.40234 1.06458 9.50635 0 10.8633 0H14.1445C15.5015 0 16.6055 1.06458 16.6055 2.37305C16.6055 3.68152 15.5015 4.74609 14.1445 4.74609ZM10.8633 1.58203C10.4108 1.58203 10.043 1.93696 10.043 2.37305C10.043 2.80914 10.4108 3.16406 10.8633 3.16406H14.1445C14.5968 3.16406 14.9648 2.80914 14.9648 2.37305C14.9648 1.93696 14.5968 1.58203 14.1445 1.58203H10.8633Z" fill="#60A5FA" />
<path d="M14.1445 0H12.5039V1.58203H14.1445C14.5968 1.58203 14.9648 1.93696 14.9648 2.37305C14.9648 2.80914 14.5968 3.16406 14.1445 3.16406H12.5039V4.74609H14.1445C15.5015 4.74609 16.6055 3.68152 16.6055 2.37305C16.6055 1.06458 15.5015 0 14.1445 0Z" fill="#2563EB" />
<path d="M12.5039 6.37891C6.63034 6.37891 1.83984 10.9983 1.83984 16.6621C1.83984 22.3259 6.63034 26.998 12.5039 26.998C18.3773 26.998 23.168 22.3259 23.168 16.6621C23.168 10.9983 18.3773 6.37891 12.5039 6.37891Z" fill="#60A5FA" />
<path d="M23.168 16.6621C23.168 22.3259 18.3773 26.998 12.5039 26.998V6.37891C18.3773 6.37891 23.168 10.9983 23.168 16.6621Z" fill="#2563EB" />
<path d="M23.5619 10.3526L18.922 5.87763C18.6015 5.56864 18.6015 5.06808 18.922 4.75909C19.2424 4.4501 19.7615 4.4501 20.0819 4.75909L24.7218 9.23409C25.0423 9.54308 25.0423 10.0436 24.7218 10.3526C24.4014 10.6616 23.8823 10.6616 23.5619 10.3526Z" fill="#2563EB" />
<path d="M0.240326 10.3948C-0.0801086 10.0858 -0.0801086 9.58523 0.240326 9.27624L4.88022 4.80206C5.20065 4.49306 5.71976 4.49306 6.04019 4.80206C6.36063 5.11105 6.36063 5.61161 6.04019 5.9206L1.4003 10.3948C1.07986 10.7038 0.56076 10.7038 0.240326 10.3948Z" fill="#60A5FA" />
<path d="M19.8867 16.666C19.8867 20.5894 16.5726 23.7852 12.5039 23.7852C8.43524 23.7852 5.12109 20.5894 5.12109 16.666C5.12109 12.7427 8.43524 9.54688 12.5039 9.54688L19.8867 16.666Z" fill="#A0CBFF" />
<path d="M19.8867 16.666C19.8867 20.5894 16.5726 23.7852 12.5039 23.7852V9.54688L19.8867 16.666Z" fill="#60A5FA" />
<path d="M12.5039 9.54688V16.666H19.8867C19.8867 12.7404 16.5749 9.54688 12.5039 9.54688Z" fill="#DCE7FA" />
</svg>

  );
}
