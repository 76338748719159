export default function ServerErrorScreen(): JSX.Element {
  return (
    <main className="main server-error">
      <div className="server-error__wrapper">
        <h1 className="server-error__heading">Internal Server Error</h1>
        <p className="server-error__message">
          Something went wrong. <br />
          We are working on a fix already.
        </p>
      </div>
      <img
        className="server-error__img"
        src={`${process.env.PUBLIC_URL}/img/500-error.svg`}
        alt="500 error"
        width="320"
        height="145"
      />
    </main>
  );
}
