import { forwardRef } from "react";

import OverviewBtn from "../elements/overview-btn";
import { StrategyType } from "../../../types/strategy";

type OverviewComingSoonProps = {
  overview: StrategyType;
  index: number;
  isNavigateAble: boolean;
};

function OverviewComingSoon(
  { overview, index, isNavigateAble }: OverviewComingSoonProps,
  ref: any
): JSX.Element {
  return (
    <div className="overview-coming-soon">
      <div className="overview-coming-soon__wrapper">
        <OverviewBtn
          overview={overview}
          index={index}
          ref={ref}
          isNavigateAble={isNavigateAble}
        />
      </div>
    </div>
  );
}

export default forwardRef<
  {
    buttonRef: React.RefObject<HTMLButtonElement> | null;
    hintButtonRef: React.RefObject<HTMLDivElement> | null;
  },
  OverviewComingSoonProps
>(OverviewComingSoon);
