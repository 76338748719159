type PositionDiscrepancySkeletonProps = {
  rows: number;
};

export default function PositionDiscrepancySkeleton({
  rows,
}: PositionDiscrepancySkeletonProps): JSX.Element {
  return (
    <div className="sk-position-discrepancy animate-pulse">
      {Array(rows)
        .fill("")
        .map((_, i) => (
          <div className="sk-position-discrepancy__row" key={i}>
            <div className="sk-position-discrepancy__text"></div>
            <div className="sk-position-discrepancy__text"></div>
            <div className="sk-position-discrepancy__text"></div>
            <div className="sk-position-discrepancy__text"></div>
          </div>
        ))}
    </div>
  );
}
