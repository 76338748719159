export default function StopIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 10 11"
    >
      <circle cx="5" cy="5.2" r="4.5" stroke="currentColor" />
      <rect
        width="3"
        height="3"
        x="3.5"
        y="3.7"
        stroke="currentColor"
        rx=".5"
      />
    </svg>
  );
}
