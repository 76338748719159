export default function GoogleIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 17 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6227 6.4998C16.7247 7.03535 16.7803 7.59529 16.7803 8.1796C16.7803 12.7501 13.6587 16 8.94406 16C7.87189 16.0003 6.81015 15.7936 5.81953 15.3917C4.8289 14.9898 3.92881 14.4005 3.17066 13.6576C2.41252 12.9146 1.81119 12.0326 1.40104 11.0618C0.990886 10.0911 0.779947 9.05066 0.780274 8C0.779947 6.94933 0.990886 5.9089 1.40104 4.93815C1.81119 3.9674 2.41252 3.08536 3.17066 2.34243C3.92881 1.5995 4.8289 1.01024 5.81953 0.608312C6.81015 0.206387 7.87189 -0.000319969 8.94406 3.71761e-07C11.1484 3.71761e-07 12.9903 0.794799 14.4035 2.08533L12.1021 4.34051V4.33482C11.2454 3.53515 10.1582 3.12475 8.94406 3.12475C6.25044 3.12475 4.06105 5.35473 4.06105 7.99512C4.06105 10.6347 6.25044 12.8696 8.94406 12.8696C11.3881 12.8696 13.0517 11.5002 13.3933 9.61967H8.94406V6.4998H16.6227Z"
        fill="currentColor"
      />
    </svg>
  );
}
