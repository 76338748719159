import Hint from "../../hint/hint";
import AlertStatusIcon from "../../icons/alert/alert-status-icon";
import { HINTS_TEXT, StrategyStatus } from "../../../const";
import { StrategyOverviewType } from "../../../types/details";

type DetailsAlertProps = {
  overview: StrategyOverviewType;
  strategyStatus: string;
};

export default function DetailsAlert({
  overview,
  strategyStatus,
}: DetailsAlertProps): JSX.Element {
  return overview.alert ? (
    <>
      <p className={`details-board__alert alert alert--${overview.alert.code}`}>
        <span className="alert__icon">
          <AlertStatusIcon status={overview.alert.code} />
        </span>
        {overview.alert.title}
      </p>
      {strategyStatus === StrategyStatus.Demo && (
        <Hint
          type="details-board__hint-alert"
          id="details-strategy/alert"
          hintText={HINTS_TEXT["overview/strategy/alert"]}
        />
      )}
    </>
  ) : (
    <></>
  );
}
