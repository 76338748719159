import TriangleDownIcon from "../icons/triangle/triangle-down-icon";
import TriangleUpIcon from "../icons/triangle/triangle-up-icon";
import { checkTrendingTableClass } from "../../utils/general";
import {
  deleteMinusIfNegative,
  formatTrending,
  localAmount,
} from "../../utils/amount";

type TableTrendingProps = {
  amount: number;
};

export default function TableTrending({
  amount,
}: TableTrendingProps): JSX.Element {
  const checkedAmount = formatTrending(amount).amount;
  const checkedShort = formatTrending(amount).short;
  const localedAmount = localAmount(deleteMinusIfNegative(checkedAmount));
  const result = `${localedAmount}${checkedShort}%`;

  const classModifier = `table-trending--${checkTrendingTableClass(
    checkedAmount
  )}`;

  return (
    <p className={`table-trending ${checkedAmount === 0 ? "" : classModifier}`}>
      {checkedAmount === 0 || (
        <span className="table-trending__icon">
          {checkedAmount > 0 ? <TriangleUpIcon /> : <TriangleDownIcon />}
        </span>
      )}

      {result}
    </p>
  );
}
