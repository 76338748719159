import FaqItem from "./faq-item";
import { useAppSelector } from "../../hooks";
import { StrategyResultsType } from "../../types/details";
import {
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";

export default function BinanceFaq(): JSX.Element {
  const { binanceSpotDemo } = useAppSelector((state) => state);
  const results = binanceSpotDemo?.strategy_results as StrategyResultsType;

  const statistics =
    results &&
    results?.sharpe_ratio !== null &&
    results?.annualized_return !== null &&
    results?.avg_monthly_return !== null &&
    ` For example, since 01.01.2021,
    SMARD strategies on Binance Spot have shown the following
    performance: Sharpe ratio is ${displayResultsAmount(
      results.sharpe_ratio
    )}, annualized return is ${displayResultsPercent(
      results.annualized_return
    )}, and
average monthly return is ${displayResultsPercent(
      results.avg_monthly_return
    )}.`;

  return (
    <div className="landing__item faq faq--landing">
      <h2 className="landing__title landing__title--faq">
        Frequently Asked Questions
      </h2>
      <ul className="faq__inner-list">
        <FaqItem
          question="What country is Binance originally from?"
          answer={
            <p className="faq-item__paragraph">
              Binance was founded in 2017 by Changpeng Zhao and initially was
              based in China, then moved to Japan shortly before the Chinese
              government restricted cryptocurrency companies. For up-to-date
              information on current terms of use, check out the{" "}
              <a
                className="link"
                href="https://www.binance.com/en/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Binance
              </a>{" "}
              website.
            </p>
          }
        />
        <FaqItem
          question="Is AI trading profitable?"
          answer={
            <p className="faq-item__paragraph">
              The profitability of an AI trading bot depends on various factors,
              including its underlying strategy, the quality of the data used,
              and current market conditions.{statistics}
            </p>
          }
        />

        <FaqItem
          question="Is Binance safe?"
          answer={
            <p className="faq-item__paragraph">
              Binance uses what is called proof of reserves. This means that
              they prove in a verifiable way via the blockchain that all user
              and exchange funds are accounted for and are actually backed
              one-to-one. For up-to-date reserve data, visit the official{" "}
              <a
                className="link"
                href="https://www.binance.com/en/proof-of-reserves"
                target="_blank"
                rel="noopener noreferrer"
              >
                Binance
              </a>{" "}
              website. Consider you own risks anyway.
            </p>
          }
        />
        <FaqItem
          question="Should I trust trading bots?"
          answer={
            <p className="faq-item__paragraph">
              When evaluating the reliability of trading bots, factors such as:
              Reputation, how strategies work, historical results, and how risk
              is controlled are important. It is also important how your assets
              are managed and under whose control they are held. SMARD does not
              take users' money for itself, and the connection to the platform
              is done through API keys without access to withdrawals. In this
              way, all assets remain under the control of the user.
            </p>
          }
        />
        <FaqItem
          question="Can I withdraw with Binance API?"
          answer={
            <>
              <p className="faq-item__paragraph">
                Yes, you can withdraw funds using the Binance API. Binance
                provides functionality to programmatically initiate withdrawal
                requests through its API, allowing users to manage their
                accounts and perform withdrawals in an automated fashion. When
                connecting to SMARD via API keys, withdrawal access is not
                required.
              </p>
              <p className="faq-item__paragraph">
                Yes, you can continue to use your account to invest or trade;
                just do not touch the position that the bot has formed. If, at
                the time of trading, SMARD does not see the expected position,
                then it will pause and not continue until you recover the
                expected position in the assets.
              </p>
            </>
          }
        />
        <FaqItem
          question="What are my risks?"
          answer={
            <>
              <p className="faq-item__paragraph">
                SMARD does not take uncalculated risks: operates without
                leverage, trades highly liquid instruments only and uses stop
                losses, trailing stops and a combination of both to control the
                risk.
              </p>
              <p className="faq-item__paragraph">
                SMARD uses both its own tested algorithms and a set of trading
                strategies that are described in scientific research. For
                example, SMARD uses a momentum effect that has been investigated
                and confirmed by{" "}
                <a
                  className="link"
                  href="https://research.cbs.dk/en/publications/time-series-momentum"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  fundamental literature
                </a>
                .
              </p>
            </>
          }
        />
        <FaqItem
          question="Why is the algorithm SMARD so efficient?"
          answer={
            <p className="faq-item__paragraph">
              The algorithm is based on the momentum effect, which has been
              repeatedly confirmed in scientific literature. This principle
              allows SMARD to identify future "winners" and "losers" in the
              market. In combination with other methods of improving trading
              efficiency, SMARD achieves high rates of return over a long
              distance at a relatively low-risk level.
            </p>
          }
        />
      </ul>
    </div>
  );
}
