import { createAction } from "@reduxjs/toolkit";
import { StateAction } from "../action-types";
import { OverviewSummaryType, StrategyType } from "../../types/strategy";
import { ResponseStatusType } from "../../types/details";

export const loadOverviewSummary = createAction<null | OverviewSummaryType>(
  StateAction.Overview.LoadOverviewSummary
);
export const setOverviewSummaryLoading = createAction<boolean>(
  StateAction.Overview.SetOverviewSummaryLoading
);
export const setOverviewSummaryStatus = createAction<null | ResponseStatusType>(
  StateAction.Overview.SetOverviewSummaryStatus
);

export const loadStrategies = createAction<StrategyType[]>(
  StateAction.Overview.LoadStrategies
);
export const setStrategiesLoading = createAction<boolean>(
  StateAction.Overview.SetStrategiesLoading
);

export const addSubscribedAccount = createAction<string>(
  StateAction.Overview.AddSubscribedAccount
);
