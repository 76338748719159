import TelegramIcon from "../icons/socials/telegram-icon";
import TwitterNewIcon from "../icons/socials/twitter-new-icon";
import MailIcon from "../icons/socials/mail-icon";
import LinkedinIcon from "../icons/socials/linkedin-icon";
import { trackEvent } from "../../utils/tracking";

export default function Contacts(): JSX.Element {
  const handleEmailClick = () => {
    trackEvent("Email", "Clicked");
  };

  return (
    <ul className="contacts">
      <li className="contacts__item">
        <a
          className="contacts__link"
          href="https://t.me/smard_club"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="contacts__icon contacts__icon--telegram">
            <TelegramIcon />
          </span>
          <span className="visually-hidden">Go to our telegram channel</span>
        </a>
      </li>
      <li className="contacts__item">
        <a
          className="contacts__link"
          href="https://twitter.com/smard_club"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="contacts__icon contacts__icon--twitter">
            <TwitterNewIcon />
          </span>
          <span className="visually-hidden">Follow our twitter</span>
        </a>
      </li>
      <li className="contacts__item">
        <a
          className="contacts__link"
          href="mailto:info@smard.club"
          onClick={handleEmailClick}
        >
          <span className="contacts__icon contacts__icon--email">
            <MailIcon />
          </span>
          <span className="visually-hidden">Send us email</span>
        </a>
      </li>

      <li className="contacts__item">
        <a
          className="contacts__link"
          href="https://www.linkedin.com/company/smardclub/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="contacts__icon contacts__icon--linkedin">
            <LinkedinIcon />
          </span>
          <span className="visually-hidden">Follow our linkedin</span>
        </a>
      </li>
    </ul>
  );
}
