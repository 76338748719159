export default function LinkWithDollarIcon(): JSX.Element {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.7453 5H15.7213V5.9595C15.2694 5.99895 14.8638 6.09831 14.5071 6.25913C14.1234 6.43401 13.8183 6.67451 13.5969 6.98166C13.3768 7.28888 13.2692 7.65455 13.2692 8.07269C13.2692 8.47533 13.3573 8.82906 13.5383 9.12745C13.7191 9.42127 14.0001 9.67368 14.3717 9.88708C14.726 10.0883 15.1765 10.2802 15.7213 10.4637V12.4349C15.3478 12.4125 14.9625 12.3517 14.565 12.2523C14.1193 12.1407 13.7482 12.0166 13.45 11.8807L13.2752 11.801V13.314L13.3499 13.3457C13.6627 13.4781 14.0301 13.5852 14.4509 13.6681L14.4522 13.6683C14.844 13.7411 15.2671 13.7835 15.7213 13.7961V15H16.7453V13.7521C17.5279 13.6672 18.1448 13.4431 18.5812 13.0687C19.0388 12.6721 19.2692 12.1662 19.2692 11.5599C19.2692 11.1045 19.1543 10.7235 18.9134 10.4285C18.6841 10.1389 18.3687 9.90285 17.9726 9.71777C17.6106 9.54683 17.2013 9.38699 16.7453 9.23801V7.32715C17.0004 7.35566 17.263 7.40076 17.5332 7.46257C17.8504 7.53512 18.1556 7.6286 18.4488 7.74295L18.5631 7.78753L19.0893 6.4802L18.9798 6.43303C18.6295 6.28209 18.2612 6.16505 17.8751 6.08178C17.5257 6.00212 17.1491 5.95408 16.7453 5.93717V5ZM15.3084 7.46689C15.4223 7.41695 15.5594 7.37883 15.7213 7.35394V8.86939C15.5489 8.8069 15.4055 8.74309 15.2899 8.67837C15.1482 8.59675 15.0523 8.50543 14.9939 8.40734C14.9355 8.30935 14.9033 8.186 14.9033 8.03187C14.9033 7.89799 14.9372 7.78905 15.0006 7.69941C15.0646 7.60895 15.1643 7.53014 15.3084 7.46689ZM16.7453 12.3754V10.8254C16.937 10.892 17.0928 10.9588 17.2143 11.0252L17.2154 11.0258C17.3716 11.1092 17.475 11.1983 17.536 11.2892L17.5377 11.2916C17.6004 11.3803 17.6351 11.4927 17.6351 11.6357C17.6351 11.8402 17.558 12.0016 17.3998 12.1298C17.2588 12.2407 17.0443 12.3254 16.7453 12.3754Z"
        fill="#828282"
      />
      <path
        d="M2.54092 6C2.54092 4.11061 4.03569 2.57895 5.87958 2.57895H8.38358C8.80909 2.57895 9.15404 2.22549 9.15404 1.78947C9.15404 1.35346 8.80909 1 8.38358 1H5.87958C3.18466 1 1 3.23858 1 6C1 8.76142 3.18466 11 5.87958 11H8.38358C8.80909 11 9.15404 10.6465 9.15404 10.2105C9.15404 9.77451 8.80909 9.42105 8.38358 9.42105H5.87958C4.03569 9.42105 2.54092 7.8894 2.54092 6Z"
        fill="#828282"
      />
      <path
        d="M12.6211 1C12.1956 1 11.8507 1.35346 11.8507 1.78947C11.8507 2.22549 12.1956 2.57895 12.6211 2.57895H15.1251C16.6793 2.57895 17.9854 3.66712 18.3575 5.14048C18.6964 5.23255 19.0253 5.34859 19.3435 5.4891L20 5.77891C19.8871 3.12007 17.7477 1 15.1251 1H12.6211Z"
        fill="#828282"
      />
      <path
        d="M14.1564 5.21278C14.0766 5.24383 13.9978 5.27726 13.9201 5.31314L13.9163 5.31492C13.3821 5.56441 12.9193 5.92682 12.5739 6.41788L12.5722 6.42024C12.4889 6.5394 12.4162 6.6627 12.3536 6.78947H6.90686C6.48135 6.78947 6.1364 6.43601 6.1364 6C6.1364 5.56399 6.48135 5.21053 6.90686 5.21053H14.0978C14.1175 5.21053 14.1371 5.21129 14.1564 5.21278Z"
        fill="#828282"
      />
    </svg>
  );
}
