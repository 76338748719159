export default function BlueShieldIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.1111 6.61698H24.5556C22.4078 6.61698 20.6667 4.8944 20.6667 2.7695V2H19.8889H14H8.11111H7.33333V2.7695C7.33333 4.8944 5.59222 6.61698 3.44444 6.61698H2.88889H2V7.49641V8.62902C2 15.9361 6.00418 22.6688 12.4604 26.2174L12.9647 26.4946C13.2867 26.6715 13.6433 26.76 14 26.76C14.3567 26.76 14.7133 26.6715 15.0353 26.4946L15.5396 26.2174C21.9958 22.6688 26 15.9361 26 8.62902V7.49641V6.61698H25.1111Z"
        stroke="#60A5FA"
        strokeWidth={1.5}
      />
      <path
        d="M14 2H19.8889H20.6667V2.7695C20.6667 4.8944 22.4078 6.61698 24.5556 6.61698H25.1111H26V7.49641V8.62902C26 15.9361 21.9958 22.6688 15.5396 26.2174L15.0353 26.4946C14.7133 26.6715 14.3567 26.76 14 26.76"
        stroke="#2563EB"
        strokeWidth={1.5}
      />
      <path
        d="M22.3333 8.49512H21.9167C20.3058 8.49512 19 7.18928 19 5.57845V4.99512H18.4167H14V23.765C14.2675 23.765 14.535 23.6979 14.7764 23.5638L15.1547 23.3536C19.9969 20.6635 23 15.5597 23 10.0204V9.16178V8.49512H22.3333Z"
        fill="#2563EB"
      />
      <path
        d="M12.8453 23.3536L13.2236 23.5638C13.465 23.6979 13.7325 23.765 14 23.765V4.99512H9.58333H9V5.57845C9 7.18928 7.69416 8.49512 6.08333 8.49512H5.66667H5V9.16178V10.0204C5 15.5597 8.00313 20.6635 12.8453 23.3536Z"
        fill="#60A5FA"
      />
      <path
        d="M10.8909 15.2728L12.5969 16.6622C13.0098 16.9985 13.6138 16.9522 13.9706 16.557L18.5037 11.5366"
        stroke="#60A5FA"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <mask
        id="mask0_896_1150"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="10"
        width="10"
        height="8"
      >
        <path
          d="M10.8909 15.2728L12.5969 16.6622C13.0098 16.9985 13.6138 16.9522 13.9706 16.557L18.5037 11.5366"
          stroke="#A0CBFF"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
      </mask>
      <g mask="url(#mask0_896_1150)">
        <rect
          x="8.7605"
          y="9.63867"
          width="5.23804"
          height="9.35498"
          fill="#A0CBFF"
        />
      </g>
    </svg>
  );
}
