import { ChartType, ChartWithBenchmarkType } from "../../types/chart";
import { collectAmounts } from "./chart";

export const findTimeTicks = (array: number[]) => {
  const secondsIn30Days = 2592000;
  let current = array[0];
  const last = Math.max(array[array.length - 1], current + secondsIn30Days);

  const increment = Math.round(last - current) / 6;
  const timeTicks = [];

  while (current <= last) {
    timeTicks.push(current);
    current = current + increment;
  }

  return timeTicks;
};

export const findCorrelativeMinMax = (
  strategyData: ChartType[],
  benchData: ChartType[]
) => {
  // STRATEGY
  const allStrategyAmounts = collectAmounts(strategyData);
  const strategyStartPoint = allStrategyAmounts[0];
  const strategyMin = Math.min(...allStrategyAmounts);
  const strategyMax = Math.max(...allStrategyAmounts);

  const strategyDelta = Math.max(
    Math.abs(strategyStartPoint - strategyMin),
    Math.abs(strategyStartPoint - strategyMax)
  );
  const strategyDeltaK =
    (strategyStartPoint + strategyDelta) / strategyStartPoint;

  // BENCH
  const allBenchAmounts = collectAmounts(benchData);
  const benchStartPoint = allBenchAmounts[0];
  const benchMin = Math.min(...allBenchAmounts);
  const benchMax = Math.max(...allBenchAmounts);

  const benchmarkDelta = Math.max(
    Math.abs(benchStartPoint - benchMin),
    Math.abs(benchStartPoint - benchMax)
  );
  const benchDeltaK = (benchStartPoint + benchmarkDelta) / benchStartPoint;

  // RESULT
  const maxDeltaK = Math.max(strategyDeltaK, benchDeltaK);

  const strategyRange = [
    strategyStartPoint * (2 - maxDeltaK) * 0.9,
    strategyStartPoint * maxDeltaK * 1.1,
  ];
  const benchRange = [
    benchStartPoint * (2 - maxDeltaK) * 0.9,
    benchStartPoint * maxDeltaK * 1.1,
  ];

  return { strategyRange, benchRange };
};

export const findRelativeCorrelativeMinMax = (
  strategyData1: ChartType[],
  strategyData2: ChartType[],
  strategyData3: ChartType[],
  strategyData4: ChartType[],
  strategyData5: ChartType[],
  benchData: ChartType[]
) => {
  // STRATEGY
  const allStrategyAmounts = collectAmounts([
    ...strategyData1,
    ...strategyData2,
    ...strategyData3,
    ...strategyData4,
    ...strategyData5,
  ]);
  const allStrategyAmounts1 = collectAmounts(strategyData1);
  const allStrategyAmounts2 = collectAmounts(strategyData2);
  const allStrategyAmounts3 = collectAmounts(strategyData3);
  const allStrategyAmounts4 = collectAmounts(strategyData4);
  const allStrategyAmounts5 = collectAmounts(strategyData5);

  const strategyStartPoint1 = allStrategyAmounts1[0];
  const strategyStartPoint2 = allStrategyAmounts2[0];
  const strategyStartPoint3 = allStrategyAmounts3[0];
  const strategyStartPoint4 = allStrategyAmounts4[0];
  const strategyStartPoint5 = allStrategyAmounts5[0];

  const sortedToUpStartPoints = [
    strategyStartPoint1,
    strategyStartPoint2,
    strategyStartPoint3,
    strategyStartPoint4,
    strategyStartPoint5,
  ]
    .slice()
    .sort((a: number, b: number) => a - b);

  const leastStartPoint = sortedToUpStartPoints[0];
  const biggestStartPoint = sortedToUpStartPoints[2];
  const mediumStartPoint =
    (biggestStartPoint - leastStartPoint) / 2 + leastStartPoint;

  const strategyStartPoint = mediumStartPoint;
  const strategyMin = Math.min(...allStrategyAmounts);
  const strategyMax = Math.max(...allStrategyAmounts);

  const strategyDelta = Math.max(
    Math.abs(strategyStartPoint - strategyMin),
    Math.abs(strategyStartPoint - strategyMax)
  );
  const strategyDeltaK =
    (strategyStartPoint + strategyDelta) / strategyStartPoint;

  // BENCH
  const allBenchAmounts = collectAmounts(benchData);
  const benchStartPoint = allBenchAmounts[0];
  const benchMin = Math.min(...allBenchAmounts);
  const benchMax = Math.max(...allBenchAmounts);

  const benchmarkDelta = Math.max(
    Math.abs(benchStartPoint - benchMin),
    Math.abs(benchStartPoint - benchMax)
  );
  const benchDeltaK = (benchStartPoint + benchmarkDelta) / benchStartPoint;

  // RESULT
  const maxDeltaK = Math.max(strategyDeltaK, benchDeltaK);

  const strategyRange = [
    strategyStartPoint * (2 - maxDeltaK) * 0.9,
    strategyStartPoint * maxDeltaK * 1.1,
  ];
  const benchRange = [
    benchStartPoint * (2 - maxDeltaK) * 0.9,
    benchStartPoint * maxDeltaK * 1.1,
  ];

  return { strategyRange, benchRange };
};

export const formatDetails2ChartsData = (
  mainData: ChartType[],
  benchmark: ChartType[]
) => {
  const formattedData: ChartWithBenchmarkType[] = [];
  const mainDataFormatted: ChartType[] = [];
  if (mainData) {
    mainData.forEach(({ timestamp, amount }) => {
      const obj = {
        timestamp: timestamp,
        amount: amount,
      };
      mainDataFormatted.push(obj);
    });
  }

  if (benchmark) {
    benchmark.forEach(({ amount }, index) => {
      formattedData.push({
        ...mainDataFormatted[index],
        benchmarkAmount: amount,
      });
    });
  }

  return formattedData;
};
