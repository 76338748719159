export default function HintIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 26 26"
    >
      <circle cx="12.94" cy="13.27" r="12.5" fill="#DBEAFE" />
      <circle cx="12.94" cy="13.27" r="8.5" fill="#3B82F6" />
      <path
        fill="#fff"
        d="M11.96 14.92v-.37c0-.29.03-.54.1-.76.07-.21.18-.41.33-.6.16-.2.37-.4.64-.6.27-.21.49-.4.65-.55.16-.16.28-.32.35-.47.07-.16.11-.35.11-.56 0-.33-.11-.58-.34-.76a1.49 1.49 0 0 0-.94-.26c-.36 0-.7.05-1.02.15-.31.1-.63.23-.93.38l-.5-1.03a5.5 5.5 0 0 1 2.54-.65c.8 0 1.41.2 1.85.58.43.39.65.9.65 1.53 0 .35-.05.65-.16.9-.11.24-.27.47-.49.68-.2.21-.46.43-.77.66-.24.2-.43.36-.56.5-.13.15-.22.3-.27.44a1.7 1.7 0 0 0-.07.52v.27h-1.17Zm-.22 1.88c0-.32.08-.54.24-.67.17-.13.38-.2.62-.2.23 0 .43.07.6.2.17.13.25.35.25.67 0 .32-.08.54-.25.68a.93.93 0 0 1-.6.2.94.94 0 0 1-.62-.2c-.16-.14-.24-.36-.24-.68Z"
      />
    </svg>
  );
}
