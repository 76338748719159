import BoardSign from "../../board-sign/board-sign";
import { StrategyOverviewType } from "../../../types/details";
import { formatStrategy } from "../../../utils/amount";

type DetailsSignsProps = {
  overview: StrategyOverviewType;
};

export default function DetailsSigns({
  overview,
}: DetailsSignsProps): JSX.Element {
  const amount = overview.profit_from_start;

  return amount !== null ? (
    <>
      <div className="details-board__sign details-board__sign--calc">
        {formatStrategy(amount).amount >= 0 ? (
          <BoardSign sign="plus" isColored />
        ) : (
          <BoardSign sign="minus" isColored />
        )}
      </div>

      <div className="details-board__sign details-board__sign--equal">
        <BoardSign sign="equal" />
      </div>
    </>
  ) : (
    <></>
  );
}
