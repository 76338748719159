export default function ExitIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70472 12.3133C8.05138 12.66 8.61138 12.66 8.95805 12.3133L12.1492 9.12222C12.4958 8.77556 12.4958 8.21556 12.1492 7.86889L8.95805 4.67778C8.61138 4.33111 8.05138 4.33111 7.70472 4.67778C7.35805 5.02444 7.35805 5.58444 7.70472 5.93111L9.37583 7.61111H1.66916C1.18027 7.61111 0.780273 8.01111 0.780273 8.5C0.780273 8.98889 1.18027 9.38889 1.66916 9.38889H9.37583L7.70472 11.06C7.35805 11.4067 7.36694 11.9756 7.70472 12.3133ZM15.0025 0.5H2.55805C1.57138 0.5 0.780273 1.3 0.780273 2.27778V4.94444C0.780273 5.43333 1.18027 5.83333 1.66916 5.83333C2.15805 5.83333 2.55805 5.43333 2.55805 4.94444V3.16667C2.55805 2.67778 2.95805 2.27778 3.44694 2.27778H14.1136C14.6025 2.27778 15.0025 2.67778 15.0025 3.16667V13.8333C15.0025 14.3222 14.6025 14.7222 14.1136 14.7222H3.44694C2.95805 14.7222 2.55805 14.3222 2.55805 13.8333V12.0556C2.55805 11.5667 2.15805 11.1667 1.66916 11.1667C1.18027 11.1667 0.780273 11.5667 0.780273 12.0556V14.7222C0.780273 15.7 1.58027 16.5 2.55805 16.5H15.0025C15.9803 16.5 16.7803 15.7 16.7803 14.7222V2.27778C16.7803 1.3 15.9803 0.5 15.0025 0.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
