import { useState } from "react";
import SkeletonChartIcon from "../icons/skeleton-chart-icon";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";

export default function RelativeBlockSkeleton(): JSX.Element {
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const slider = (
    <div className="sk-relative-block__slider">
      <div className="sk-relative-block__logo"></div>
      <div className="sk-relative-block__heading"></div>
      <div className="sk-relative-block__wrapper">
        <div className="sk-relative-block__chart">
          <SkeletonChartIcon />
        </div>
        <div className="sk-relative-block__bottom">
          <div className="sk-relative-block__toggle"></div>
          <div className="sk-relative-block__extra">
            <div className="sk-relative-block__list">
              <div className="sk-relative-block__item">
                <div className="sk-relative-block__row"></div>
              </div>
              <div className="sk-relative-block__item">
                <div className="sk-relative-block__row"></div>
              </div>
            </div>
            <div className="sk-relative-block__btns">
              <div className="sk-relative-block__btn"></div>
              <div className="sk-relative-block__btn"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="sk-relative-block animate-pulse">
      {isTabletAndMore ? (
        <>
          {slider}
          {slider}
          {slider}
        </>
      ) : (
        slider
      )}
    </div>
  );
}
