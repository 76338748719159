import { useLayoutEffect, useRef } from "react";

export type TelegramLoginProps = {
  dataAuthUrl: string;
  botName: string;
  radius: string;
  typeClass: string;
};

export default function TelegramLogin({
  dataAuthUrl,
  botName,
  radius,
  typeClass,
}: TelegramLoginProps): JSX.Element {
  const el = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?10";
    script.async = true;

    script.setAttribute("data-telegram-login", botName);
    script.setAttribute("data-auth-url", dataAuthUrl);
    script.setAttribute("data-radius", radius);
    script.setAttribute("data-request-access", "write");

    if (el.current) {
      el.current.appendChild(script);
    }
    // eslint-disable-next-line
  }, []);

  return <div className={typeClass} ref={el}></div>;
}
