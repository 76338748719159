import AlertIcon from "./alert-icon";
import BanIcon from "./ban-icon";
import PauseIcon from "./pause-icon";
import SandglassIcon from "./sandglass-icon";
import StopIcon from "./stop-icon";
import { AlertStatus } from "../../../const";

type AlertStatusIconProps = {
  status: string;
};

export default function AlertStatusIcon({
  status,
}: AlertStatusIconProps): JSX.Element | null {
  switch (status) {
    case AlertStatus.Alert:
      return <AlertIcon />;
    case AlertStatus.ComingSoon:
      return <SandglassIcon />;
    case AlertStatus.NotConnected:
      return <BanIcon />;
    case AlertStatus.Paused:
      return <PauseIcon />;
    case AlertStatus.Stopping:
      return <StopIcon />;
    default:
      return null;
  }
}
