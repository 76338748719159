import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import PartnerLogos from "../grid/partner-logos";
import { RERENDER_DELAY } from "../../const";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "lodash";

export default function Affiliates(): JSX.Element {
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const affiliate1 = (
    <a
      className="affiliate__link"
      href="https://youtu.be/XBBVbVoy8fU"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="affiliate__head">
        <picture>
          <source
            media="(min-width: 1080px)"
            srcSet={`${process.env.PUBLIC_URL}/img/affiliate/affiliate1-desktop.png`}
            width="70"
            height="70"
          />
          <img
            className="affiliate__avatar"
            src={`${process.env.PUBLIC_URL}/img/affiliate/affiliate1-mobile.png`}
            alt="Avatar"
            width="55"
            height="55"
          />
        </picture>
        <span className="affiliate__name">Crypto Jax</span>
        <span className="affiliate__subscribers">141K subscribers</span>
      </div>
      <p className="affiliate__title">
        Smard - Automated Trading | Review of Results for 3 months
      </p>
      <picture>
        <source
          media="(min-width: 1080px)"
          srcSet={`${process.env.PUBLIC_URL}/img/affiliate/video1-desktop.jpg`}
          width="296"
          height="162"
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${process.env.PUBLIC_URL}/img/affiliate/video1-tablet.jpg`}
          width="186"
          height="102"
        />
        <img
          className="affiliate__video-img"
          src={`${process.env.PUBLIC_URL}/img/affiliate/video1-mobile.jpg`}
          alt="Avatar"
          width="250"
          height="138"
        />
      </picture>
    </a>
  );

  const affiliate2 = (
    <a
      className="affiliate__link"
      href="https://youtu.be/ea_NFmHbLgk"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="affiliate__head">
        <picture>
          <source
            media="(min-width: 1080px)"
            srcSet={`${process.env.PUBLIC_URL}/img/affiliate/affiliate2-desktop.png`}
            width="70"
            height="70"
          />
          <img
            className="affiliate__avatar"
            src={`${process.env.PUBLIC_URL}/img/affiliate/affiliate2-mobile.png`}
            alt="Avatar"
            width="55"
            height="55"
          />
        </picture>
        <span className="affiliate__name">Top Crypto</span>
        <span className="affiliate__subscribers">122K subscribers</span>
      </div>
      <p className="affiliate__title">
        Trading Bot 🔥 Crypto Trading Bots: How Made Passive Income
      </p>
      <picture>
        <source
          media="(min-width: 1080px)"
          srcSet={`${process.env.PUBLIC_URL}/img/affiliate/video2-desktop.jpg`}
          width="296"
          height="162"
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${process.env.PUBLIC_URL}/img/affiliate/video2-tablet.jpg`}
          width="186"
          height="102"
        />
        <img
          className="affiliate__video-img"
          src={`${process.env.PUBLIC_URL}/img/affiliate/video2-mobile.jpg`}
          alt="Avatar"
          width="250"
          height="138"
        />
      </picture>
    </a>
  );

  const affiliate3 = (
    <a
      className="affiliate__link"
      href="https://youtu.be/8Atyuwcbqg4"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="affiliate__head">
        <picture>
          <source
            media="(min-width: 1080px)"
            srcSet={`${process.env.PUBLIC_URL}/img/affiliate/affiliate3-desktop.png`}
            width="70"
            height="70"
          />
          <img
            className="affiliate__avatar"
            src={`${process.env.PUBLIC_URL}/img/affiliate/affiliate3-mobile.png`}
            alt="Avatar"
            width="55"
            height="55"
          />
        </picture>
        <span className="affiliate__name">Crypto Lord</span>
        <span className="affiliate__subscribers">75.5K subscribers</span>
      </div>
      <p className="affiliate__title">
        SMARD Automated Trading Software | 9038$ Passive Income
      </p>
      <picture>
        <source
          media="(min-width: 1080px)"
          srcSet={`${process.env.PUBLIC_URL}/img/affiliate/video3-desktop.jpg`}
          width="296"
          height="162"
        />
        <source
          media="(min-width: 768px)"
          srcSet={`${process.env.PUBLIC_URL}/img/affiliate/video3-tablet.jpg`}
          width="186"
          height="102"
        />
        <img
          className="affiliate__video-img"
          src={`${process.env.PUBLIC_URL}/img/affiliate/video3-mobile.jpg`}
          alt="Avatar"
          width="250"
          height="138"
        />
      </picture>
    </a>
  );

  return (
    <section className="landing__item affiliates">
      <h2 className="landing__title">
        Become part of our affiliate team and start earning
      </h2>
      {isTabletAndMore ? (
        <ul className="affiliates__list">
          <li>{affiliate1}</li>
          <li>{affiliate2}</li>
          <li>{affiliate3}</li>
        </ul>
      ) : (
        <Swiper
          grabCursor={true}
          slidesPerView={"auto"}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="affiliates__slide">{affiliate1}</div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="affiliates__slide">{affiliate2}</div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="affiliates__slide">{affiliate3}</div>
          </SwiperSlide>
        </Swiper>
      )}
      <div className="affiliates__logos">
        <PartnerLogos />
      </div>
    </section>
  );
}
