export default function BlueCalendarIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 22"
    >
      <path
        fill="#60A5FA"
        d="M18.8 21V1c0-.6-.4-1-1-1h-15A2.7 2.7 0 0 0 0 2.7V21h18.8Z"
      />
      <path fill="#2563EB" d="M18.8 1c0-.6-.4-1-1-1H9.5v21h9.4V1Z" />
      <path fill="#A0CBFF" d="M2.5 3.8h3.8v3.8H2.5V3.8Z" />
      <path
        fill="#A0CBFF"
        d="M2.5 3.8h3.8v3.8H2.5V3.8Zm0 4.8h3.8v3.8H2.5V8.6Z"
      />
      <path
        fill="#A0CBFF"
        d="M2.5 8.6h3.8v3.8H2.5V8.6Zm0 4.9h3.8v3.8H2.5v-3.8Z"
      />
      <path
        fill="#A0CBFF"
        d="M2.5 13.5h3.8v3.8H2.5v-3.8Zm5-9.7h3.8v3.8H7.5V3.8Z"
      />
      <path
        fill="#A0CBFF"
        d="M7.5 3.8h3.8v3.8H7.5V3.8Zm0 4.8h3.8v3.8H7.5V8.6Z"
      />
      <path
        fill="#A0CBFF"
        d="M7.5 8.6h3.8v3.8H7.5V8.6Zm0 4.9h3.8v3.8H7.5v-3.8Z"
      />
      <path
        fill="#A0CBFF"
        d="M7.5 13.5h3.8v3.8H7.5v-3.8Zm5-9.7h3.8v3.8h-3.8V3.8Z"
      />
      <path
        fill="#A0CBFF"
        d="M12.5 3.8h3.8v3.8h-3.8V3.8Zm0 4.8h3.8v3.8h-3.8V8.6Z"
      />
      <path
        fill="#A0CBFF"
        d="M12.5 8.6h3.8v3.8h-3.8V8.6Zm0 4.9h3.8v3.8h-3.8v-3.8Z"
      />
      <path fill="#A0CBFF" d="M12.5 13.5h3.8v3.8h-3.8v-3.8Z" />
    </svg>
  );
}
