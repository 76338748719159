import { AccountName, ErrorType } from "../../const";
import { ResponseStatusType } from "../../types/details";
import Message from "../message/message";
import BitgetStep3MessageConnected from "../steps-to-connect/messages/bitget-step3-message-connected";
import BybitStep3MessageConnected from "../steps-to-connect/messages/bybit-step3-message-connected";
import OkxStep3MessageConnected from "../steps-to-connect/messages/okx-step3-message-connected";
import BinanceFuturesChangeAmountErrorMessage from "./binance-futures-change-amount-error-message";
import BinanceSpotChangeAmountErrorMessage from "./binance-spot-change-amount-error-message";

type AccountsPages =
  | AccountName.BinanceFutures
  | AccountName.BinanceSpot
  | AccountName.BitgetFutures
  | AccountName.Bybit
  | AccountName.Okx;

type ChangeBalanceErrorMessageProps = {
  sendAmountToStartStatus: ResponseStatusType | null;
  availableAmount: number;
  loadAmountToStartStatus: ResponseStatusType | null;
  changePositionModeStatus: ResponseStatusType | null;
  checkPositionModeStatus: ResponseStatusType | null;
  accountName: AccountName | undefined;
  changeAmountStatus: ResponseStatusType | null;
  useAllAmountStatus: ResponseStatusType | null;
};

function ChangeBalanceErrorMessage({
  accountName,
  availableAmount,
  changePositionModeStatus,
  checkPositionModeStatus,
  loadAmountToStartStatus,
  sendAmountToStartStatus,
  changeAmountStatus,
  useAllAmountStatus,
}: ChangeBalanceErrorMessageProps) {
  const ErrorMessageMap = {
    [AccountName.BinanceSpot]: (
      <BinanceSpotChangeAmountErrorMessage
        availableAmount={availableAmount}
        loadAmountToStartStatus={loadAmountToStartStatus}
        sendAmountToStartStatus={sendAmountToStartStatus}
      />
    ),
    [AccountName.BinanceFutures]: (
      <BinanceFuturesChangeAmountErrorMessage
        availableAmount={availableAmount}
        loadAmountToStartStatus={loadAmountToStartStatus}
        sendAmountToStartStatus={sendAmountToStartStatus}
      />
    ),
    [AccountName.BitgetFutures]: (
      <BitgetStep3MessageConnected
        availableAmount={availableAmount}
        loadAmountToStartStatus={loadAmountToStartStatus}
        checkPositionModeStatus={checkPositionModeStatus}
        changePositionModeStatus={changePositionModeStatus}
        sendAmountToStartStatus={sendAmountToStartStatus}
      />
    ),
    [AccountName.Bybit]: (
      <BybitStep3MessageConnected
        availableAmount={availableAmount}
        loadAmountToStartStatus={loadAmountToStartStatus}
        checkPositionModeStatus={checkPositionModeStatus}
        changePositionModeStatus={changePositionModeStatus}
        sendAmountToStartStatus={sendAmountToStartStatus}
      />
    ),
    [AccountName.Okx]: (
      <OkxStep3MessageConnected
        availableAmount={availableAmount}
        loadAmountToStartStatus={loadAmountToStartStatus}
        checkPositionModeStatus={checkPositionModeStatus}
        changePositionModeStatus={changePositionModeStatus}
        sendAmountToStartStatus={sendAmountToStartStatus}
      />
    ),
  };

  return (
    <>
      {ErrorMessageMap[accountName as AccountsPages]}
      {useAllAmountStatus?.isSuccess ||
      !useAllAmountStatus ||
      !changeAmountStatus?.isSuccess ? null : (
        <Message
          status="error"
          message={useAllAmountStatus?.message ?? ErrorType.General}
        />
      )}
    </>
  );
}

export default ChangeBalanceErrorMessage;
