import Hint from "../../hint/hint";
import AlertStatusIcon from "../../icons/alert/alert-status-icon";
import { AuthStatus, HINTS_TEXT, StrategyStatus } from "../../../const";
import { StartegyAlertType } from "../../../types/details";
import { forwardRef } from "react";
import { useAppSelector } from "../../../hooks";

type OverviewAlertProps = {
  alert: StartegyAlertType;
  status: string;
  index: number;
};

function OverviewAlert(
  { alert, status, index }: OverviewAlertProps,
  ref: any
): JSX.Element {
  const { authStatus } = useAppSelector((state) => state);

  const isHint =
    authStatus === AuthStatus.NoAuth &&
    (status === StrategyStatus.Demo || status === StrategyStatus.ComingSoon);

  return (
    <div className="overview-board__alert">
      <p className={`alert alert--${alert.code}`}>
        <span className="alert__icon">
          <AlertStatusIcon status={alert.code} />
        </span>
        {alert.title}
      </p>
      {isHint && (
        <Hint
          type="overview-board__alert-hint"
          id={`strategy/alert/${index}`}
          hintText={HINTS_TEXT["overview/strategy/alert"]}
          ref={ref}
        />
      )}
    </div>
  );
}

export default forwardRef<HTMLDivElement | null, OverviewAlertProps>(
  OverviewAlert
);
