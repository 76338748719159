import DetailsCanvasSkeleton from "../skeletons/details-canvas-skeleton";
import DetailsStrategySkeleton from "../skeletons/details-strategy-skeleton";
import TradingResultsSkeleton from "../skeletons/trading-results-skeleton";

export default function DetailsBlocksSkeletons(): JSX.Element {
  return (
    <>
      <DetailsStrategySkeleton />
      <TradingResultsSkeleton />
      <DetailsCanvasSkeleton />
    </>
  );
}
