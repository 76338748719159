import TransparentDoubleDiskIcon from "../../icons/decor/transparent-double-disk-icon";
import OkxIcon from "../icons/okx-icon";

export default function OkxBriefly(): JSX.Element {
  return (
    <section className="landing__item okx-briefly">
      <h2 className="landing__title">Briefly about OKX</h2>
      <div className="okx-briefly__wrapper">
        <div className="okx-briefly__intro1">
          <p className="okx-briefly__paragraph">
            The OKX exchange is one of the most popular cryptocurrency exchanges
            in the world. It provides a wide range of tools and options for
            trading various cryptocurrencies such as Bitcoin (BTC), Ethereum
            (ETH), Litecoin (LTC) and many others.
          </p>
          <p className="okx-briefly__paragraph">
            One of the main features of the OKX exchange is its user-friendly
            interface and intuitive navigation. Traders can easily find the
            cryptocurrency they need and quickly open and close positions. In
            addition, the OKX exchange has various market analysis tools
            available to help traders make more informed trading decisions.
          </p>
          <p className="okx-briefly__paragraph">
            Another advantage of the OKX exchange is its high order execution
            speed. This means traders can quickly react to price changes and
            profit from their trades.
          </p>
        </div>
        <div className="okx-briefly__intro1__media">
          <picture>
            <source
              media="(min-width: 1080px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/laptop-desktop.png`}
              width="431"
              height="251"
            />
            <source
              media="(min-width: 678px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/laptop-tablet.png`}
              width="354"
              height="206"
            />
            <img
              className="okx-briefly__img"
              src={`${process.env.PUBLIC_URL}/img/laptop/laptop-mobile.png`}
              alt="Avatar"
              width="290"
              height="169"
            />
          </picture>
        </div>
        <div className="okx-briefly__intro2">
          <p className="okx-briefly__paragraph">
            In addition, the OKX exchange allows you to use margin trading. This
            allows traders to increase their profits through borrowed funds.
            However, you should remember that margin trading also involves high
            risks, so you need to be careful and monitor your positions.
          </p>
          <p className="okx-briefly__paragraph">
            Overall, the OKX exchange is a reliable platform for trading
            cryptocurrencies. Its user-friendly interface, fast order execution
            speed and availability of various analysis tools make it attractive
            to traders of all experience levels.
          </p>
          <h3 className="okx-briefly__heading-inner">
            OKX trading bots by SMARD
          </h3>
          <p className="okx-briefly__paragraph">
            The SMARD platform allows for efficient automated trading. With one
            account on this platform, you can work with various cryptocurrency
            exchanges such as OKX.
          </p>
          <p className="okx-briefly__paragraph">
            An easy-to-use, intuitive interface and a wide range of settings,
            both basic and advanced, make SMARD trading bots working with OKX
            popular among both beginners and professionals.
          </p>
        </div>
        <div className="okx-briefly__intro2__media">
          <span className="okx-briefly__disk">
            <TransparentDoubleDiskIcon />
          </span>
          <span className="okx-briefly__logo">
            <OkxIcon />
          </span>
          <span className="okx-briefly__note okx-briefly__note--tools">
            Analysis tools
          </span>
          <span className="okx-briefly__note okx-briefly__note--speed">
            Fast order execution
          </span>
          <span className="okx-briefly__note okx-briefly__note--interface">
            User-friendly interface
          </span>
        </div>
      </div>
    </section>
  );
}
