export default function DollarUpIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.5 13.48c1.12-.47 1.7.5.87 1.22a8 8 0 1 1-.01-13.42l.02.01c.98.65.24 1.94-.84 1.26l-.04-.03a6.5 6.5 0 1 0 0 10.96Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M7.56 12.75v-1.18c-.49-.01-.94-.05-1.35-.13-.4-.08-.76-.18-1.05-.3V9.9a6.56 6.56 0 0 0 2.4.56V8.32a7.78 7.78 0 0 1-1.39-.59c-.35-.2-.6-.43-.77-.7-.17-.27-.25-.6-.25-.98 0-.39.1-.72.3-1 .2-.28.49-.5.85-.67.36-.17.78-.26 1.26-.3v-.93h.76v.92a6.13 6.13 0 0 1 2.27.48l-.43 1.07a6.5 6.5 0 0 0-1.84-.43v2.1c.48.15.9.31 1.28.5.37.17.67.39.88.66.21.26.32.6.32 1.03 0 .57-.21 1.03-.63 1.4-.43.36-1.04.58-1.85.65v1.22h-.76Zm.76-2.32c.38-.05.66-.15.84-.3a.71.71 0 0 0 .28-.57c0-.16-.04-.3-.12-.4a.97.97 0 0 0-.36-.3 3.65 3.65 0 0 0-.64-.26v1.83ZM7.56 7V5.2c-.23.02-.42.07-.58.14a.82.82 0 0 0-.35.27.67.67 0 0 0-.12.4c0 .16.04.3.1.42a.9.9 0 0 0 .34.31c.16.1.36.18.6.26Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.4 3h1.07v.92a6.14 6.14 0 0 1 2.18.5l.13.05-.54 1.34-.13-.05a6.36 6.36 0 0 0-1.64-.4v1.82c.44.14.84.3 1.2.47.38.18.7.41.92.7.25.3.36.68.36 1.13 0 .61-.23 1.12-.69 1.51-.42.37-1.03.6-1.79.68v1.23H7.41v-1.19a8.03 8.03 0 0 1-1.22-.12 5.55 5.55 0 0 1-1.1-.32l-.08-.04V9.68l.2.1a6.41 6.41 0 0 0 2.2.53V8.43a7.67 7.67 0 0 1-1.31-.56 2.27 2.27 0 0 1-.83-.76A2 2 0 0 1 5 6.05c0-.41.1-.78.33-1.09.22-.3.52-.54.9-.72.35-.16.74-.25 1.18-.3V3Zm.3.3v.93h-.13c-.47.03-.87.13-1.21.28-.34.16-.6.37-.79.63-.18.25-.27.55-.27.91s.08.66.22.9c.15.24.39.46.73.65.34.2.8.4 1.36.58l.1.03v2.42h-.16a6.38 6.38 0 0 1-2.24-.5v.9c.26.1.57.2.93.26.4.08.84.12 1.32.13h.15v1.18h.46v-1.2l.14-.02a3.07 3.07 0 0 0 1.76-.61c.39-.34.58-.77.58-1.29 0-.4-.1-.7-.29-.94-.2-.25-.47-.46-.83-.62a10.3 10.3 0 0 0-1.25-.49l-.1-.03V5.02l.16.02a7.05 7.05 0 0 1 1.74.39l.31-.8a5.98 5.98 0 0 0-2.08-.41h-.14V3.3h-.46Zm0 1.74v2.17l-.19-.06a3.56 3.56 0 0 1-.63-.27c-.17-.1-.3-.21-.39-.36a.98.98 0 0 1-.13-.5c0-.19.05-.35.15-.49.1-.14.24-.24.41-.32.18-.08.39-.13.62-.15l.17-.02Zm-.3.34c-.13.03-.26.06-.36.1a.67.67 0 0 0-.29.22.52.52 0 0 0-.09.31c0 .15.03.27.09.36.05.09.14.17.28.25.1.06.23.12.38.17V5.4Zm.77 3.01.2.06c.27.09.5.18.66.27.18.1.32.21.41.35l-.12.08.13-.08c.1.14.14.3.14.49 0 .28-.11.52-.33.7-.22.17-.53.27-.92.31l-.17.03V8.4Zm.3.41v1.45c.28-.05.47-.13.6-.23a.56.56 0 0 0 .22-.46.54.54 0 0 0-.1-.32.82.82 0 0 0-.3-.25c-.1-.06-.25-.13-.42-.19Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m13 7.73 2.36-4.59c.04-.07.09-.1.14-.1.05 0 .1.03.14.1l2.25 4.65.05.2c0 .07 0 .15-.02.22a.44.44 0 0 1-.07.17c-.03.04-.07.07-.1.07l-.97-.02-.05 3.85c0 .2-.04.4-.12.54-.07.14-.17.22-.27.22l-1.92-.02c-.1 0-.2-.08-.27-.23-.07-.14-.11-.34-.11-.54l.05-3.85-.96-.01c-.04 0-.08-.02-.11-.07a.44.44 0 0 1-.07-.17.79.79 0 0 1 0-.22c0-.08.02-.15.05-.2Z"
      />
    </svg>
  );
}
