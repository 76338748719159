export default function ListIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 10 12"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 1.5H2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1Zm-6-1a2 2 0 0 0-2 2v7c0 1.1.9 2 2 2h6a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2H2Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.5 3.5c0-.28.22-.5.5-.5h6a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5ZM1.5 5.5c0-.28.22-.5.5-.5h6a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5ZM1.5 7.5c0-.28.22-.5.5-.5h6a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
