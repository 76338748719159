export default function BlueLockWithCrossIcon(): JSX.Element {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8792 13.1278C21.8792 11.706 20.7522 10.5534 19.362 10.5534H12.4396V26H19.362C20.7522 26 21.8792 24.8474 21.8792 23.4256V13.1278Z"
        fill="#2563EB"
      />
      <path
        d="M5.51723 10.5534C4.127 10.5534 3 11.706 3 13.1278V23.4256C3 24.8474 4.127 26 5.51723 26H12.4396V10.5534H5.51723Z"
        fill="#60A5FA"
      />
      <path
        d="M18.4367 9.72331V6C18.4367 3.79086 16.6458 2 14.4367 2H10.4426C8.23343 2 6.44257 3.79086 6.44257 6V9.72331"
        stroke="#2563EB"
        strokeWidth="1.5"
      />
      <path
        d="M6.44257 9.72331V6C6.44257 3.79086 8.23343 2 10.4426 2H12.4396"
        stroke="#60A5FA"
        strokeWidth="1.5"
      />
      <path
        d="M15.7729 21.6103L9.10608 14.9431M9.10622 21.6103L15.7731 14.9431"
        stroke="#A0CBFF"
        strokeWidth="2.09681"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4414 19.7612L15.0317 22.3516C15.4411 22.7611 16.1049 22.7611 16.5144 22.3517C16.9238 21.9423 16.9238 21.2784 16.5144 20.869L13.9223 18.2768L16.5145 15.6844C16.924 15.2749 16.9239 14.6111 16.5145 14.2017C16.1051 13.7923 15.4412 13.7923 15.0318 14.2017L12.4414 16.7923V19.7612Z"
        fill="#60A5FA"
      />
    </svg>
  );
}
