export default function OverviewIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.882353 5.07692H3.52941C4.01471 5.07692 4.41176 4.69615 4.41176 4.23077V0.846154C4.41176 0.380769 4.01471 0 3.52941 0H0.882353C0.397059 0 0 0.380769 0 0.846154V4.23077C0 4.69615 0.397059 5.07692 0.882353 5.07692ZM0.882353 11H3.52941C4.01471 11 4.41176 10.6192 4.41176 10.1538V6.76923C4.41176 6.30385 4.01471 5.92308 3.52941 5.92308H0.882353C0.397059 5.92308 0 6.30385 0 6.76923V10.1538C0 10.6192 0.397059 11 0.882353 11ZM6.17647 11H8.82353C9.30882 11 9.70588 10.6192 9.70588 10.1538V6.76923C9.70588 6.30385 9.30882 5.92308 8.82353 5.92308H6.17647C5.69118 5.92308 5.29412 6.30385 5.29412 6.76923V10.1538C5.29412 10.6192 5.69118 11 6.17647 11ZM11.4706 11H14.1176C14.6029 11 15 10.6192 15 10.1538V6.76923C15 6.30385 14.6029 5.92308 14.1176 5.92308H11.4706C10.9853 5.92308 10.5882 6.30385 10.5882 6.76923V10.1538C10.5882 10.6192 10.9853 11 11.4706 11ZM6.17647 5.07692H8.82353C9.30882 5.07692 9.70588 4.69615 9.70588 4.23077V0.846154C9.70588 0.380769 9.30882 0 8.82353 0H6.17647C5.69118 0 5.29412 0.380769 5.29412 0.846154V4.23077C5.29412 4.69615 5.69118 5.07692 6.17647 5.07692ZM10.5882 0.846154V4.23077C10.5882 4.69615 10.9853 5.07692 11.4706 5.07692H14.1176C14.6029 5.07692 15 4.69615 15 4.23077V0.846154C15 0.380769 14.6029 0 14.1176 0H11.4706C10.9853 0 10.5882 0.380769 10.5882 0.846154Z"
        fill="currentColor"
      />
    </svg>
  );
}
