import { useEffect } from "react";

import Affiliates from "../../components/affiliates/affiliates";
import HowToEarn from "../../components/steps/how-to-earn";
import PartnershipBlock from "../../components/partnership-block/partnership-block";
import PartnershipPromo from "../../components/promos/partnership-promo";
import SmardBestPluses from "../../components/pluses/smard-best-pluses";
import WhatIsSmardPluses from "../../components/pluses/what-is-smard-pluses";
import { deleteSeoData } from "../../utils/general";
import { AuthStatus } from "../../const";
import { setFirstScreenRendered } from "../../store/actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { PartnershipSeoSetting } from "../../components/seo-setting/seo-setting";

export default function PartnershipScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main main--landing landing">
      <PartnershipSeoSetting />
      <div className="landing__wrapper">
        <PartnershipPromo />
        <WhatIsSmardPluses />
        <HowToEarn />
        <SmardBestPluses />
        <Affiliates />
        <PartnershipBlock />
      </div>
    </main>
  );
}
