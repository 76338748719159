import {useEffect, useRef, useState} from "react";

import Copy from "../../components/copy/copy";
import { BybitSetupSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { AuthStatus, IP_ADDRESS, RERENDER_DELAY } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { debounce } from "../../utils/chart/chart";
import { deleteSeoData } from "../../utils/general";
import { checkIfDesktop } from "../../utils/breakpoint";

export default function BybitSetupScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  const autoHeadingRef = useRef<null | HTMLHeadingElement>(null);
  const appHeadingRef = useRef<null | HTMLHeadingElement>(null);

  const handleAutoBtnClick = () => {
    if (autoHeadingRef.current) {
      const target = autoHeadingRef.current.getBoundingClientRect().top - 5;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    }
  };

  const handleAppBtnClick = () => {
    if (appHeadingRef.current) {
      const target = appHeadingRef.current.getBoundingClientRect().top - 5;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    }
  };

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main setup">
      <TrackTwitter />
      <BybitSetupSeoSetting />
      <div className="setup__wrapper">
        <h1 className="setup__heading">Bybit APIs Setup</h1>
        <h2 className="setup__heading-inner">Note</h2>
        <p className="setup__intro">
          If you do not already have a Bybit account, you can create the one using this{" "}
          <a
              className="link"
              href="https://partner.bybit.com/b/smard"
              target="_blank"
              rel="noopener noreferrer"
          >
            link
          </a>.
        </p>
        <ul className="setup__intro-list">
          <li className="setup__intro-item">
            <button
                className="setup__intro-scroll"
                onClick={handleAutoBtnClick}
            >
              Fast API
            </button>
            {" "}
            <span className="setup__intro-text">(Less than 1 minute)</span>
          </li>
          <li className="setup__intro-item">
            <button className="setup__intro-scroll" onClick={handleAppBtnClick}>
              Manual connection
            </button>
            {" "}
            <span className="setup__intro-text">(3-5 minutes)</span>
          </li>
        </ul>
        <h2 className="setup__heading-inner" ref={autoHeadingRef}>Fast API</h2>
        <p className="setup__intro">Connect your Bybit account to SMARD in less than 1 minute using OAuth.</p>

        <ul className="setup__list">

          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to the{" "}
              <a
                  className="link"
                  href="https://smard.club/my/accounts/bybit/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Bybit details page
              </a>{" "}
              and click on the <b>"Connect my account"</b> button.
            </p>
            <img
                className="setup__img setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-fast-api-setup/1.png`}
                alt="Bybit setup"
                width="auto"
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              2. Authorize the connection request.
            </p><img
              className="setup__img setup__img--app9"
              src={`${process.env.PUBLIC_URL}/img/screens/bybit-fast-api-setup/2.png`}
              alt="Bybit setup"
              width={258}
              height="auto"
          />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              3. Confirm the connection.
            </p>
            <p className="setup__paragraph">
              Please do not change any permissions to connect to SMARD correctly.
            </p>
            <img
                className="setup__img setup__img--app8"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-fast-api-setup/3.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              4. Return to the {" "}
              <a
                  className="link"
                  href="https://smard.club/my/accounts/bybit/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Bybit details page
              </a>{" "} and specify the amount to be traded by SMARD and then press the "Start" button.
            </p>
            <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-fast-api-setup/4.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
            <p className="setup__paragraph">
              Congratulations! You are all set for automated trading with SMARD!
            </p>
          </li>

        </ul>

        <h2 className="setup__heading-inner" ref={appHeadingRef}>Manual connection</h2>

        <ul className="setup__list">

          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to the{" "}
              <a
                  className="link"
                  href="https://www.bybit.com/app/user/api-management"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                API section
              </a>{" "}
              on Bybit, create new key and click <b>"Connect to Third-Party Applications"</b> with the following
              settings:
            </p>
            <img
                className="setup__img setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/1.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>

        </ul>

        <h2 className="setup__heading-inner">Note</h2>
        <p className="setup__intro">
          <b>You must enable 2FA to be able to add API keys.</b> If you see this dialog box, click <b>"Set up"</b> and
          follow
          the Bybit instructions to connect the 2FA.
        </p>

        <img
            className="setup__img setup__img--app9"
            src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/2.png`}
            alt="Bybit setup"
            width={258}
            height="auto"
        />

        <ul className="setup__list">

          <li className="setup__item">
            <p className="setup__paragraph">
              2. Choose API key type:
            </p>
            <img
                className="setup__img setup__img--app9"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/3.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
            <ul className="setup__intro-list">
              <li className="setup__intro-item">Choose <b>System-generated API Keys</b> if you prefer Bybit to create both API key and API Secret for
                you. In the article we'll consider this type.
              </li>
              <li className="setup__intro-item">Choose <b>Self-generated API Keys</b> if you know how to manually create API key and API Secret.</li>
            </ul>
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              3. Change to <b>"Connect to Third-Party Applications"</b> and choose <b>SMARD</b>.
            </p>
            <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/4.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              4. Select permissions and click <b>“Submit”</b>.
            </p>
            <p className="setup__paragraph">
              You need to select the following permissions:
            </p>

            <ul className="setup__intro-list">
              <li className="setup__intro-item">Permissions: Read-Write</li>
              <li className="setup__intro-item">Checkbox: Unified Trading</li>
            </ul>

            <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/5.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />

          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              5. Enter verification codes, then click <b>"Next Step"</b>.
            </p>
            <img
                className="setup__img setup__img--app9"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/6.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              6. Save the keys or just don't close this window:
            </p>
            <img
                className="setup__img setup__img--app9"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/7.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              7. Finally, click on the <b>"Enter API keys manually"</b> button on the {" "}
              <a
                  className="link"
                  href="https://smard.club/my/accounts/bybit/"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Bybit details page
              </a>{" "}.
            </p>
            <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/8.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              8. Copy/paste the API Key, API Secret and specify the amount to trade.
            </p>
            <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/bybit-manual-setup/9.png`}
                alt="Bybit setup"
                width={258}
                height="auto"
            />
          </li>
          <p className="setup__paragraph">
            Congratulations! You are all set for automated trading with SMARD!
          </p>

        </ul>
      </div>
    </main>
  );
}
