type UseAllAmountCheckboxProps = {
  isUseAllAmount: boolean;
  setIsUseAllAmount: React.Dispatch<React.SetStateAction<boolean>>;
};

function UseAllAmountCheckbox({
  isUseAllAmount,
  setIsUseAllAmount,
}: UseAllAmountCheckboxProps) {
  const handleCheckboxChange = () => {
    setIsUseAllAmount(!isUseAllAmount);
  };

  return (
    <div
      style={{ cursor: "pointer" }}
      className="change-balance-form__btn-wrapper change-balance-form__btn-wrapper--start"
      onClick={handleCheckboxChange}
    >
      <label
        className="change-balance-checkbox-container"
        style={{ pointerEvents: "none" }}
      >
        <input type="checkbox" checked={isUseAllAmount} readOnly />
        <span
          className={`change-balance-checkbox-icon ${
            isUseAllAmount ? "checked" : ""
          }`}
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="13" height="13" rx="2" stroke="#CBD5E1" />
            {isUseAllAmount && (
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.1222 2.40273L5.3 9.68046L3.87782 7.90273C3.44824 7.36576 2.75176 7.36576 2.32218 7.90273C1.89261 8.4397 1.89261 9.3103 2.32218 9.84727L4.52218 12.5973C4.95176 13.1342 5.64824 13.1342 6.07782 12.5973L12.6778 4.34727C13.1074 3.8103 13.1074 2.9397 12.6778 2.40273C12.2482 1.86576 11.5518 1.86576 11.1222 2.40273Z"
                fill="#30E733"
              />
            )}
          </svg>
        </span>
      </label>
      <p className="change-balance-checkbox-note">
        Please always use all account available balance for trading
      </p>
    </div>
  );
}

export default UseAllAmountCheckbox;
