export default function SuccessIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 0.607422C10.0899 0.607422 13 3.51757 13 7.10742C13 10.6973 10.0899 13.6074 6.5 13.6074C2.91015 13.6074 0 10.6973 0 7.10742C0 3.51757 2.91015 0.607422 6.5 0.607422ZM6.4998 1.9082C3.62792 1.9082 1.2998 4.23632 1.2998 7.1082C1.2998 9.98008 3.62792 12.3082 6.4998 12.3082C9.37168 12.3082 11.6998 9.98008 11.6998 7.1082C11.6998 4.23632 9.37168 1.9082 6.4998 1.9082ZM8.64038 4.69722L5.2 8.1376L4.35962 7.29722C4.10578 7.04338 3.69422 7.04338 3.44038 7.29722C3.18654 7.55106 3.18654 7.96261 3.44038 8.21645L4.74038 9.51645C4.99422 9.7703 5.40578 9.7703 5.65962 9.51645L9.55962 5.61646C9.81346 5.36261 9.81346 4.95106 9.55962 4.69722C9.30578 4.44338 8.89422 4.44338 8.64038 4.69722Z"
        fill="currentColor"
      />
    </svg>
  );
}
