export default function TelegramIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1328 0.0920533L1.07407 5.79416C0.0463717 6.19948 0.0523172 6.76244 0.885516 7.01347L4.75169 8.19776L13.6969 2.65579C14.1199 2.40309 14.5063 2.53903 14.1887 2.81592L6.94128 9.2386H6.93958L6.94128 9.23944L6.67459 13.1526C7.06529 13.1526 7.2377 12.9766 7.45683 12.769L9.33471 10.9758L13.2408 13.809C13.961 14.1984 14.4783 13.9983 14.6575 13.1543L17.2216 1.28802C17.4841 0.254685 16.8199 -0.213193 16.1328 0.0920533Z"
        fill="currentColor"
      />
    </svg>
  );
}
