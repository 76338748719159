import { useState } from "react";

import DemoChart from "../charts/demo-chart";
import TogglesBase from "../toggles/toggles-base";
import { QuotesItemsType, QuotesItemType } from "../../types/details";

type DemoCanvasProps = {
  type: string;
  data: QuotesItemsType;
  isBitcoin: boolean;
  handleBitcoinMode: (mode: boolean) => void;
};

export default function DemoCanvas({
  type,
  data,
  isBitcoin,
  handleBitcoinMode,
}: DemoCanvasProps): JSX.Element {
  const [isAllDays, setAllDays] = useState<boolean>(true);

  const btcData = data.btc as QuotesItemType[];
  const ethData = data.eth as QuotesItemType[];
  const strategyData = data.strategy as QuotesItemType[];

  return (
    <section className="demo-canvas">
      <h3 className="visually-hidden">Demo chart</h3>

      {strategyData && btcData && ethData && (
        <DemoChart
          type={type}
          main={strategyData}
          btcBench={btcData}
          ethBench={ethData}
          isAllDays={isAllDays}
          isBitcoin={isBitcoin}
        />
      )}
      <TogglesBase
        type={type}
        isBitcoinMode={isBitcoin}
        isAllDays={isAllDays}
        setBitcoinMode={handleBitcoinMode}
        setAllDays={setAllDays}
      />
    </section>
  );
}
