import { Link } from "react-router-dom";

import { AppRoute } from "../../const";

export default function FooterList3(): JSX.Element {
  return (
    <ul className="footer-list">
      <li className="footer-list__item">
        <span className="footer__nav-text">Exchanges</span>
        <ul className="footer-list__inner-list">
          <li className="footer-list__item">
            <Link className="footer-list__link" to={AppRoute.Okx}>
              SMARD & OKX
            </Link>
          </li>
          <li className="footer-list__item">
            <Link className="footer-list__link" to={AppRoute.Bitget}>
              SMARD & Bitget
            </Link>
          </li>
          <li className="footer-list__item">
            <Link className="footer-list__link" to={AppRoute.Binance}>
              SMARD & Binance
            </Link>
          </li>
          <li className="footer-list__item">
            <Link className="footer-list__link" to={AppRoute.Bybit}>
              SMARD & Bybit
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  );
}
