import { memo, useMemo } from "react";
import OverviewBoard from "./overview-board";
import { useAppSelector } from "../../hooks/index";
import {
  getSortedByBtn,
  sortOverviewByStatusAndProfit,
} from "../../utils/component/overview";
import { StrategyStatus } from "../../const";

function OverviewList(): JSX.Element {
  const {
    strategies,
    okxDemo,
    binanceFuturesDemo,
    bybitDemo,
    binanceSpotDemo,
    bitgetFuturesDemo,
  } = useAppSelector((state) => state);

  const mergedDemos = useMemo(() => {
    const demoMapping = [
      { demo: okxDemo, code: "okx" },
      { demo: binanceFuturesDemo, code: "binance-futures" },
      { demo: bybitDemo, code: "bybit" },
      { demo: binanceSpotDemo, code: "binance-spot" },
      { demo: bitgetFuturesDemo, code: "bitget-futures" },
    ];

    return demoMapping
      .filter(({ demo }) => demo)
      .map(({ demo, code }) => ({ ...demo, code }));
  }, [
    okxDemo,
    binanceFuturesDemo,
    bybitDemo,
    binanceSpotDemo,
    bitgetFuturesDemo,
  ]);

  const filteredStrategies = useMemo(() => {
    return strategies.filter((strategy) => {
      const correspondingResult = mergedDemos.find((demo) => {
        return strategy.code === demo.code;
      });

      const sharpeRatio =
        correspondingResult?.strategy_results?.sharpe_ratio ?? NaN;

      return (
        sharpeRatio > 0.5 ||
        strategy.status === StrategyStatus.ComingSoon ||
        strategy.status === StrategyStatus.Started ||
        strategy.status === StrategyStatus.Stopping
      );
    });
  }, [mergedDemos, strategies]);

  return (
    <ul className="overview__list">
      {strategies.length !== 0 &&
        getSortedByBtn(filteredStrategies)
          .sort(sortOverviewByStatusAndProfit)
          .map(
            (item, index): JSX.Element => (
              <OverviewBoard
                key={index}
                overview={item}
                index={index}
                strategiesLength={strategies.length}
              />
            )
          )}
    </ul>
  );
}

export default memo(OverviewList);
