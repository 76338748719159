import BoardSign from "../../components/board-sign/board-sign";
import { ButtonTypes, TABLET_WIDTH, StrategyStatus } from "../../const";
import { StrategyType } from "../../types/strategy";
import { formatStrategy, localAmount } from "../amount";

// Проверка со статусом not-started
export const displayStrategyAmount = (
  type: string,
  status: string,
  isTabletAndMore: boolean,
  amount: number,
  currency: string | null,
  percent?: number,
  startTimestamp?: number | null
) => {
  if (type === "initial") {
    if (isTabletAndMore) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      }`;
    }
    if (currency) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      } ${currency.toUpperCase()}`;
    }
  }

  if (type === "current") {
    if (currency) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      } ${currency.toUpperCase()}`;
    }
  }

  if (type === "from-start") {
    if (status === StrategyStatus.NotStarted && (percent || percent === 0)) {
      return `${localAmount(formatStrategy(Math.abs(percent)).amount)}%`;
    }

    if (isTabletAndMore) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      }`;
    }

    if (currency) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      } ${currency.toUpperCase()}`;
    }
  }

  if (type === "last-month") {
    const startDate = new Date((startTimestamp ?? 0) * 1000);
    const currentDate = new Date();
    const thirtyDaysInMilliseconds = 2592000000;
    const thirtyDaysPassed =
      currentDate.getTime() - startDate.getTime() > thirtyDaysInMilliseconds;

    if (!thirtyDaysPassed && amount === 0) {
      return "...";
    }

    if (
      (status === StrategyStatus.NotStarted ||
        status === StrategyStatus.Demo) &&
      (percent || percent === 0)
    ) {
      return `${localAmount(formatStrategy(Math.abs(percent)).amount)}%`;
    }

    if (currency) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      } ${currency.toUpperCase()}`;
    }
  }
};

export const checkTrendingClass = (amount: number) => {
  if (amount >= 0) {
    return "up";
  }
  return "down";
};

const getStrategyWithRatingByBtn = (item: StrategyType) => {
  if (item.primary_button) {
    switch (item.primary_button.code) {
      case ButtonTypes.SUBSCRIBED:
        return { ...item, rating: 1 };
      case ButtonTypes.SUBSCRIBE:
        return { ...item, rating: 2 };
      case ButtonTypes.START:
        return { ...item, rating: 3 };
      case ButtonTypes.DETAILS:
        return { ...item, rating: 4 };
      case ButtonTypes.RENEW_KEYS:
        return { ...item, rating: 5 };
      case ButtonTypes.PAY:
        return { ...item, rating: 6 };
      default:
        return { ...item, rating: 0 };
    }
  }
  return { ...item, rating: 0 };
};

const sortStrategiesByRating = (
  a: StrategyType & { rating: number },
  b: StrategyType & { rating: number }
) => b.rating - a.rating;

export const getSortedByBtn = (array: StrategyType[]) => {
  const strategiesWithRating = array.map((item) =>
    getStrategyWithRatingByBtn(item)
  );
  return strategiesWithRating.sort(sortStrategiesByRating);
};

// For rendering tooltip of previous strategy over next strategy in desktop
export const setZIndex = (index: number, strategiesLength: number) => {
  if (document.documentElement.clientWidth >= TABLET_WIDTH) {
    return strategiesLength - index;
  }
  return index;
};

export const sortOverviewByStatusAndProfit = (
  a: StrategyType,
  b: StrategyType
) => {
  if (
    a.status === StrategyStatus.Started &&
    b.status !== StrategyStatus.Started
  ) {
    return -1;
  }
  if (
    a.status !== StrategyStatus.Started &&
    b.status === StrategyStatus.Started
  ) {
    return 1;
  }
  if (
    a.status === StrategyStatus.ComingSoon &&
    b.status !== StrategyStatus.ComingSoon
  ) {
    return 1;
  }
  if (
    a.status !== StrategyStatus.ComingSoon &&
    b.status === StrategyStatus.ComingSoon
  ) {
    return -1;
  }

  return (b.profit_from_start || 0) - (a.profit_from_start || 0);
};

export const addColoredSign = (
  isTabletAndMore: boolean,
  status: string,
  amount: number,
  percent: number
) => {
  if (
    status === StrategyStatus.NotStarted &&
    percent !== null &&
    percent !== 0
  ) {
    if (percent > 0) {
      return "sign sign--colored-plus";
    }
    return "sign sign--colored-minus";
  }

  if (amount !== null && amount !== 0) {
    if (!isTabletAndMore) {
      if (amount > 0) {
        return "sign sign--colored-plus";
      }
      return "sign sign--colored-minus";
    }
    return "";
  }
};

export const addBoardSign = (
  status: string,
  amount: number,
  percent: number
) => {
  if (
    status === StrategyStatus.NotStarted &&
    percent !== null &&
    percent !== 0
  ) {
    if (percent > 0) {
      return <BoardSign sign="plus" />;
    }
    return <BoardSign sign="minus" />;
  }

  if (amount !== null && amount !== 0) {
    if (amount > 0) {
      return <BoardSign sign="plus" />;
    }
    return <BoardSign sign="minus" />;
  }

  return "";
};

export const addBoardColorSign = (
  isTabletAndMore: boolean,
  status: string,
  amount: number,
  percent: number
) => {
  if (
    status === StrategyStatus.NotStarted &&
    percent !== null &&
    percent !== 0
  ) {
    if (percent > 0) {
      return <BoardSign sign="plus" isColored />;
    }
    return <BoardSign sign="minus" isColored />;
  }

  if (amount !== null && amount !== 0) {
    if (!isTabletAndMore) {
      if (amount > 0) {
        return <BoardSign sign="plus" isColored />;
      }
      return <BoardSign sign="minus" isColored />;
    }

    return <></>;
  }
};

export const addBlackSign = (
  status: string,
  amount: number,
  percent: number
) => {
  if (
    status === StrategyStatus.NotStarted &&
    percent !== null &&
    percent !== 0
  ) {
    if (percent > 0) {
      return "sign sign--plus";
    }
    return "sign sign--minus";
  }

  if (amount !== null && amount !== 0) {
    if (amount > 0) {
      return "sign sign--plus";
    }
    return "sign sign--minus";
  }

  return "";
};
