import { useState } from "react";

import BlockStarted from "./status/block-started";
import BlockDemo from "./status/block-demo";
import DetailsAlert from "./elements/details-alert";
import BlockNotStarted from "./status/block-not-started";
import BlockEmptyData from "./status/block-emty-data";
import BlockStopping from "./status/block-stopping";
import { debounce } from "../../utils/chart/chart";
import { useAppSelector } from "../../hooks";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { StrategyOverviewType } from "../../types/details";
import { StrategyStatus, RERENDER_DELAY } from "../../const";

export default function DetailsBoard(): JSX.Element {
  const {
    strategyDetails,
    isRenewKeysFormOpened,
    isIntegrationOpened,
    authStatus,
  } = useAppSelector((state) => state);
  const strategyStatus = strategyDetails?.status as string;
  const overview = strategyDetails?.overview as StrategyOverviewType;
  const shouldRenewKeysFormShow = strategyDetails?.renew_keys_form as boolean;
  const isConnected = strategyDetails?.is_account_connected === true;

  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const renderBlock = () => {
    switch (strategyStatus) {
      case StrategyStatus.Started:
        return (
          <BlockStarted
            overview={overview}
            isTabletAndMore={isTabletAndMore}
            isRenewKeysFormOpened={isRenewKeysFormOpened}
            shouldRenewKeysFormShow={shouldRenewKeysFormShow}
          />
        );

      case StrategyStatus.Demo:
        return (
          <BlockDemo
            overview={overview}
            isTabletAndMore={isTabletAndMore}
            isRenewKeysFormOpened={isRenewKeysFormOpened}
          />
        );
      case StrategyStatus.NotStarted:
        return (
          <BlockNotStarted
            overview={overview}
            isTabletAndMore={isTabletAndMore}
            isIntegrationOpened={isIntegrationOpened}
            isConnected={isConnected}
          />
        );

      case StrategyStatus.EmptyData:
        return (
          <BlockEmptyData
            overview={overview}
            isIntegrationOpened={isIntegrationOpened}
            authStatus={authStatus}
            isConnected={isConnected}
          />
        );

      case StrategyStatus.Stopping:
        return (
          <BlockStopping
            overview={overview}
            isTabletAndMore={isTabletAndMore}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <section className="details__item details__board details-board">
      <h2 className="visually-hidden">Strategy details board</h2>
      <div className="details-board__logo-wrapper">
        <img
          className={`details-board__logo details-board__logo--${overview.code}`}
          src={overview.logo_url}
          alt={`Logo ${overview.code}`}
          width="60"
          height="20"
        />
      </div>
      <div className="details-board__block">{renderBlock()}</div>
      {overview.alert && (
        <DetailsAlert overview={overview} strategyStatus={strategyStatus} />
      )}
    </section>
  );
}
