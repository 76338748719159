import TransparentDoubleDiskIcon from "../../icons/decor/transparent-double-disk-icon";
import BybitIcon from "../../icons/exchange/bybit-icon";

export default function BybitBriefly(): JSX.Element {
  return (
    <section className="landing__item okx-briefly">
      <h2 className="landing__title">About Bybit</h2>
      <div className="okx-briefly__wrapper">
        <div className="okx-briefly__intro1">
          <p className="okx-briefly__paragraph">
            Bybit offers a user-friendly platform for beginners and experienced traders alike.
            With its user-friendly interface and fast order execution,
            Bybit is a good choice for cryptocurrency trading.
          </p>
          <p className="okx-briefly__paragraph">
            Trade a variety of digital assets, including Bitcoin, Ethereum,
            Ripple, and more on one platform.
            Bybit prioritizes security with advanced measures such as
            cold storage and multi-signature wallets.
          </p>
          <p className="okx-briefly__paragraph">
            Take advantage of advanced trading tools and features,
            from customizable indicators to real-time charts,
            allowing you to make informed decisions.
            Whether you're new to cryptocurrencies or an experienced trader,
            Bybit provides the resources and support you need to have a positive user experience.
          </p>
        </div>
        <div className="okx-briefly__intro1__media">
          <picture>
            <source
              media="(min-width: 1080px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/bybit/laptop-desktop.png`}
              width="431"
              height="251"
            />
            <source
              media="(min-width: 678px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/bybit/laptop-tablet.png`}
              width="354"
              height="206"
            />
            <img
              className="okx-briefly__img"
              src={`${process.env.PUBLIC_URL}/img/laptop/bybit/laptop-mobile.png`}
              alt="Bybit screen"
              width="290"
              height="169"
            />
          </picture>
        </div>
        <div className="okx-briefly__intro2">
          <h3 className="okx-briefly__heading-inner">
            Bybit Trading Bots by SMARD
          </h3>
          <p className="okx-briefly__paragraph">
            SMARD is a platform for automating trading strategies on cryptocurrency exchanges,
            including Bybit. The software allows users to access effective
            trading strategies that can be used by both experienced traders
            and investors, as well as newcomers to the cryptocurrency world.
          </p>
          <p className="okx-briefly__paragraph">
            No strategy customisation is required, all the client needs
            to do is connect the SMARD account to the Bybit exchange
            in a few clicks using OAuth. After that, the platform will
            automatically manage a portfolio on your account.
          </p>
        </div>
        <div className="okx-briefly__intro2__media">
          <span className="okx-briefly__disk">
            <TransparentDoubleDiskIcon />
          </span>
          <span className="okx-briefly__logo okx-briefly__logo--bybit">
            <BybitIcon />
          </span>
          <span className="okx-briefly__note okx-briefly__note--tools">
            Transparent fee structure
          </span>
          <span className="okx-briefly__note okx-briefly__note--speed">
            Secure access
            <br />
            via API
          </span>
          <span className="okx-briefly__note okx-briefly__note--interface">
            High liquidity
          </span>
        </div>
      </div>
    </section>
  );
}