export default function OkxIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 87 27"
    >
      <path
        fill="#000"
        d="M25.52 0H.58c-.16 0-.3.06-.4.18A.62.62 0 0 0 0 .6v25.8c0 .16.06.31.17.42.1.12.26.18.41.18h24.94c.15 0 .3-.06.41-.18.1-.1.17-.26.17-.42V.6a.62.62 0 0 0-.17-.42.58.58 0 0 0-.41-.18ZM17.4 17.4c0 .16-.07.31-.17.42a.58.58 0 0 1-.41.18H9.28a.58.58 0 0 1-.41-.18.62.62 0 0 1-.17-.42V9.6c0-.16.06-.31.17-.42.1-.12.25-.18.4-.18h7.55c.15 0 .3.06.4.18.11.1.18.26.18.42v7.8ZM77.73 9h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6V9.6a.6.6 0 0 0-.58-.6ZM69.02 0h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6V.6c0-.33-.26-.6-.58-.6ZM86.42 0h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6V.6c0-.33-.26-.6-.58-.6ZM69.02 18h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6v-7.8c0-.33-.26-.6-.58-.6ZM86.42 18h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6v-7.8c0-.33-.26-.6-.58-.6ZM55.96 0h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6V.6c0-.33-.26-.6-.58-.6ZM55.96 18h-7.54a.6.6 0 0 0-.58.6v7.8c0 .33.26.6.58.6h7.54a.6.6 0 0 0 .58-.6v-7.8c0-.33-.26-.6-.58-.6ZM47.84 9.6a.62.62 0 0 0-.17-.43.58.58 0 0 0-.4-.18h-8.13V.6a.62.62 0 0 0-.17-.42.58.58 0 0 0-.4-.18h-7.54c-.16 0-.3.06-.41.18a.62.62 0 0 0-.17.42v25.78c0 .16.06.32.17.43.1.11.25.18.4.18h7.55c.15 0 .3-.07.4-.18.11-.11.18-.27.18-.43V18h8.12c.15 0 .3-.06.4-.17.11-.12.18-.27.18-.43V9.6Z"
      />
    </svg>
  );
}
