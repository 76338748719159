export default function ChartIcon({ width }: { width: number }): JSX.Element {
  return (
    <svg
      width={width}
      height="30"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6 5.45H15.4C14.63 5.45 14 6.08 14 6.85V13.85C14 14.62 14.63 15.25 15.4 15.25H15.6C16.37 15.25 17 14.62 17 13.85V6.85C17 6.08 16.37 5.45 15.6 5.45ZM10 1.25C9.23 1.25 8.6 1.88 8.6 2.65V13.85C8.6 14.62 9.23 15.25 10 15.25C10.77 15.25 11.4 14.62 11.4 13.85V2.65C11.4 1.88 10.77 1.25 10 1.25ZM4.4 9.25C3.63 9.25 3 9.88 3 10.65V13.85C3 14.62 3.63 15.25 4.4 15.25C5.17 15.25 5.8 14.62 5.8 13.85V10.65C5.8 9.88 5.17 9.25 4.4 9.25Z"
        fill="#828282"
      />
    </svg>
  );
}
