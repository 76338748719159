export default function BlueTrendingUpIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#60A5FA"
        d="M3.3 0A3.3 3.3 0 0 0 0 3.3v15.4A3.3 3.3 0 0 0 3.3 22H11V0H3.3Z"
      />
      <path
        fill="#2563EB"
        d="M18.7 22a3.3 3.3 0 0 0 3.3-3.3V3.3A3.3 3.3 0 0 0 18.7 0H11v22h7.7Z"
      />
      <mask
        id="a"
        width="11"
        height="13"
        x="10"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M10.13 2.81h9.88v11.68h-9.88z" />
      </mask>
      <g mask="url(#a)">
        <path
          stroke="#60A5FA"
          strokeLinecap="round"
          strokeWidth="1.65"
          d="m4.15 17.53 3.72-6.24c.11-.19.36-.26.55-.17l3.25 1.54c.2.1.44.02.55-.16l4.29-7.18m-4.09.47 4.26-1.3c.22-.06.44.05.5.24l1.17 3.87"
        />
      </g>
      <mask
        id="b"
        width="10"
        height="15"
        x="1"
        y="5"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M1.52 5.39H11v13.86H1.52z" />
      </mask>
      <g mask="url(#b)">
        <path
          stroke="#A0CBFF"
          strokeLinecap="round"
          strokeWidth="1.65"
          d="m4.15 17.53 3.72-6.24c.11-.19.36-.26.55-.17l3.25 1.54c.2.1.44.02.55-.16l4.29-7.18m-4.09.47 4.26-1.3c.22-.06.44.05.5.24l1.17 3.87"
        />
      </g>
    </svg>
  );
}
