export default function PlayIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fill="currentColor"
        d="m7.98 5.76-2.5-1.8a.3.3 0 0 0-.47.24v3.62a.3.3 0 0 0 .48.24l2.49-1.81a.3.3 0 0 0 0-.49Z"
      />
      <path
        fill="currentColor"
        d="M6 0a6 6 0 1 0 0 12A6 6 0 0 0 6 0Zm0 11A5 5 0 1 1 6 1a5 5 0 0 1 0 10Z"
      />
    </svg>
  );
}
