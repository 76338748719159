import { useState } from "react";

import Button from "../button/button";
import Message from "../message/message";
import VisaIcon from "../../icons/payment/visa-icon";
import MastercardIcon from "../../icons/payment/mastercard-icon";
import CardIcon from "../../icons/payment/card-icon";
import { CreditCardType, DepositType } from "../../types/payment";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { AuthStatus, RERENDER_DELAY } from "../../const";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "lodash";
import {
  setDeleteCardModalId,
  setDeleteCardModalOpened,
} from "../../store/actions";
import {chargeMe} from "../../store/api-actions";

type PaymentsCardType = {
  method: CreditCardType;
  cardsLength: number;
  isFirstValidCard: boolean;
};

export default function PaymentsCard({
  method,
  cardsLength,
  isFirstValidCard,
}: PaymentsCardType): JSX.Element {
  const dispatch = useAppDispatch();

  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  const { authStatus, paymentMethods } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isMessageShown = method?.data?.status === "charge_rejected";

  const handleDeleteClick = () => {
    dispatch(setDeleteCardModalOpened(true));
    dispatch(setDeleteCardModalId(method.data.code));
  };

  const depositObj = paymentMethods.find(
    (item) => item.type === "deposit"
  ) as DepositType;
  const balance = depositObj?.data?.amount;
  const handleChargeClick = () => {
    dispatch(chargeMe());
  };

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const findIcon = (icon: string) => {
    switch (icon) {
      case "visa":
        return <VisaIcon />;
      case "mastercard":
        return <MastercardIcon />;
      default:
        return <CardIcon />;
    }
  };

  const alertText = (
    <span className="message__note message__note--error1">
      <i className="message__micro">Failed to use this card.</i>
      {isTabletAndMore && (
        <i className="message__micro">
          {" "}
          <br className="payments-method__message-br" />
          Please check with the card issuer for&nbsp;details.
        </i>
      )}
    </span>
  );

  return (
    <div
      className={`payments-method__row
  ${cardsLength > 1 ? "payments-method__row--with-cards" : ""}
`}
    >
      <span className="payments-method__icon">
        {findIcon(method.data.payment_system)}
      </span>
      <span
        className={`payments-method__card ${
          isMessageShown ? "payments-method__card--with-alert" : ""
        }`}
      >{`*${method.data.last_4}`}</span>
      {isMessageShown ? (
        <div className="payments-method__message">
          <Message message={alertText} />
        </div>
      ) : isFirstValidCard && balance < 0 && (
        <Button
          classElement={`payments-method__btn-charge ${
            isNoAuth ? "btn--disabled" : ""
          }`}
          pattern="green-white"
          text="Charge now!"
          icon="credit-card"
          type="button"
          handleClick={handleChargeClick}
        />
      )}

      <Button
        classElement={`payments-method__btn-delete ${
          isNoAuth ? "btn--disabled" : ""
        }`}
        pattern="red-white"
        text="Delete"
        icon="error"
        type="button"
        handleClick={handleDeleteClick}
      />
    </div>
  );
}
