import AlertIcon from "../icons/alert/alert-icon";
import { useAppDispatch } from "../../hooks";
import { setStopModalOpened } from "../../store/actions";
import { stopStrategyAction } from "../../store/api-actions";

type StopModalProps = {
  id: string;
};

export default function StopModal({ id }: StopModalProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleCancelClick = () => {
    dispatch(setStopModalOpened(false));
  };

  const handleStopClick = () => {
    dispatch(stopStrategyAction(id));
  };

  return (
    <div className="modal">
      <div className="modal__wrapper ">
        <h2 className="modal__heading">
          <span className="modal__heading-icon">
            <AlertIcon />
          </span>
          Warning
        </h2>
        <p className="modal__text">
          Are you sure you want to stop using SMARD on this account?
        </p>
        <div className="modal__stop-wrapper">
          <button
            className="modal__btn modal__btn--cancel"
            type="button"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
          <button
            className="modal__btn modal__btn--stop"
            type="button"
            onClick={handleStopClick}
          >
            Stop
          </button>
        </div>
      </div>
    </div>
  );
}
