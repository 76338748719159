import DetailsLink from "../elements/details-link";
import Button from "../../button/button";
import DetailsFromStart from "../elements/details-from-start";
import DetailsLastMonth from "../elements/details-last-month";
import DetailsBtnsWithRegister from "../elements/details-btns-with-register";
import { StrategyOverviewType } from "../../../types/details";
import { setIntegrationOpened } from "../../../store/actions";
import { useAppDispatch } from "../../../hooks";
import { AccountName, StrategyStatus } from "../../../const";

type BlockNotStartedProps = {
  overview: StrategyOverviewType;
  isTabletAndMore: boolean;
  isConnected: boolean;
  isIntegrationOpened: boolean;
};

export default function BlockNotStarted({
  overview,
  isTabletAndMore,
  isIntegrationOpened,
  isConnected,
}: BlockNotStartedProps): JSX.Element {
  const dispatch = useAppDispatch();
  const isOkx = overview.code === AccountName.Okx;
  const isBitget = overview.code === AccountName.BitgetFutures;
  const isBybit = overview.code === AccountName.Bybit;

  const isBtnsWithRegisterShown = isOkx || isBitget || isBybit;

  const handleConnectClick = () => {
    dispatch(setIntegrationOpened(!isIntegrationOpened));
  };

  return (
    <div
      className={`block-not-started ${
        isBtnsWithRegisterShown ? "block-not-started--with-register" : ""
      }`}
    >
      <p className="block-not-started__historical">
        <span className="block-not-started__historical-big">Historical</span>
        <span className="block-not-started__historical-small">results</span>
      </p>

      <div className="block-not-started__total-wrapper">
        <DetailsFromStart
          overview={overview}
          strategyStatus={StrategyStatus.NotStarted}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsLastMonth
          overview={overview}
          strategyStatus={StrategyStatus.NotStarted}
          isTabletAndMore={isTabletAndMore}
        />
      </div>

      {isBtnsWithRegisterShown ? (
        <div className="block-not-started__btns-wrapper block-not-started__btns-wrapper--with-register">
          <DetailsBtnsWithRegister
            isIntegrationOpened={isIntegrationOpened}
            isConnected={isConnected}
            accountName={overview.code}
          />
        </div>
      ) : (
        <div className="block-not-started__btns-wrapper">
          <div className="block-not-started__connect-btn-wrapper">
            <Button
              classElement=""
              size="super"
              pattern={`${isIntegrationOpened ? "blue-white" : "white-blue"}`}
              text={`${isIntegrationOpened ? "Hide" : "Connect my account"}`}
              icon={`${isIntegrationOpened ? "arrow-up" : "arrow-right"}`}
              type="button"
              handleClick={handleConnectClick}
            />
            <div className="block-not-started__link">
              <DetailsLink accountName={overview.code} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
