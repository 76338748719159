import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
  ConnectOkxDataType,
  ConfIntegrationType,
  ConnectBybitDataType,
} from "../../types/details";
import { ThunkApiConfigType } from "../../types/api";
import { StateAction } from "../action-types";
import { trackEventWithValue } from "../../utils/tracking";
import { fetchStrategyDetails } from "../api-actions";
import { setIntegrationOpened } from "../actions";
import { checkAccountStatuses } from "./user";
import {
  AccountActionPath,
  AccountName,
  ApiRoute,
  ErrorType,
} from "../../const";
import {
  loadAmountToStart,
  setChangePositionModeLoading,
  setChangePositionModeStatus,
  setCheckPositionModeLoading,
  setCheckPositionModeStatus,
  setLoadAmountToStartLoading,
  setLoadAmountToStartStatus,
  setSendAmountToStartLoading,
  setSendAmountToStartStatus,
} from "../actions/start";

export const sendAmountToStartOkx = createAsyncThunk<
  void,
  ConnectOkxDataType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendAmountToStartStatus,
  async ({ amount, currency }, { dispatch, extra: api }) => {
    dispatch(setSendAmountToStartLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.Okx}${AccountActionPath.START}`,
        {
          amount,
          currency,
        }
      );
      trackEventWithValue("Strategy", "Launched", amount);
      trackEventWithValue(`Strategy(${AccountName.Okx})`, "Started", amount);
      dispatch(setIntegrationOpened(false));
      dispatch(fetchStrategyDetails(AccountName.Okx));
      dispatch(checkAccountStatuses());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else if (err instanceof AxiosError && err.response?.data.msg) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setSendAmountToStartLoading(false));
  }
);

export const sendAmountToStartBybit = createAsyncThunk<
  void,
  ConnectBybitDataType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendAmountToStartStatus,
  async ({ amount, currency }, { dispatch, extra: api }) => {
    dispatch(setSendAmountToStartLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.Bybit}${AccountActionPath.START}`,
        {
          amount,
          currency,
        }
      );
      trackEventWithValue("Strategy", "Launched", amount);
      trackEventWithValue(`Strategy(${AccountName.Bybit})`, "Started", amount);
      dispatch(setIntegrationOpened(false));
      dispatch(fetchStrategyDetails(AccountName.Bybit));
      dispatch(checkAccountStatuses());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else if (err instanceof AxiosError && err.response?.data.msg) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setSendAmountToStartLoading(false));
  }
);
export const sendAmountToStartBinanceFutures = createAsyncThunk<
  void,
  ConnectBybitDataType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendAmountToStartStatus,
  async ({ amount, currency }, { dispatch, extra: api }) => {
    dispatch(setSendAmountToStartLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BinanceFutures}${AccountActionPath.START}`,
        {
          amount,
          currency,
        }
      );
      trackEventWithValue("Strategy", "Launched", amount);
      trackEventWithValue(
        `Strategy(${AccountName.BinanceFutures})`,
        "Started",
        amount
      );
      dispatch(setIntegrationOpened(false));
      dispatch(fetchStrategyDetails(AccountName.BinanceFutures));
      dispatch(checkAccountStatuses());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else if (err instanceof AxiosError && err.response?.data.msg) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setSendAmountToStartLoading(false));
  }
);
export const sendAmountToStartBinanceSpot = createAsyncThunk<
  void,
  ConnectBybitDataType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendAmountToStartStatus,
  async ({ amount, currency }, { dispatch, extra: api }) => {
    dispatch(setSendAmountToStartLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BinanceSpot}${AccountActionPath.START}`,
        {
          amount,
          currency,
        }
      );
      trackEventWithValue("Strategy", "Launched", amount);
      trackEventWithValue(
        `Strategy(${AccountName.BinanceSpot})`,
        "Started",
        amount
      );
      dispatch(setIntegrationOpened(false));
      dispatch(fetchStrategyDetails(AccountName.BinanceSpot));
      dispatch(checkAccountStatuses());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else if (err instanceof AxiosError && err.response?.data.msg) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setSendAmountToStartLoading(false));
  }
);

export const sendAmountToStartBitget = createAsyncThunk<
  void,
  ConnectOkxDataType,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetSendAmountToStartStatus,
  async ({ amount, currency }, { dispatch, extra: api }) => {
    dispatch(setSendAmountToStartLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BitgetFutures}${AccountActionPath.START}`,
        {
          amount,
          currency,
        }
      );
      trackEventWithValue("Strategy", "Launched", amount);
      trackEventWithValue(
        `Strategy(${AccountName.BitgetFutures})`,
        "Started",
        amount
      );
      dispatch(setIntegrationOpened(false));
      dispatch(fetchStrategyDetails(AccountName.BitgetFutures));
      dispatch(checkAccountStatuses());
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else if (err instanceof AxiosError && err.response?.data.msg) {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: err.response?.data.msg,
          })
        );
      } else {
        dispatch(
          setSendAmountToStartStatus({
            isSuccess: false,
            message: ErrorType.General,
          })
        );
      }
    }
    dispatch(setSendAmountToStartLoading(false));
  }
);

// CHECK MODE
export const checkPositionModeOkx = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetCheckPositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setCheckPositionModeLoading(true));
    try {
      await api.get(
        `${ApiRoute.AccountDetails}${AccountName.Okx}${AccountActionPath.POSITION_MODE}`
      );
      dispatch(
        setCheckPositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setCheckPositionModeLoading(false));
  }
);

export const checkPositionModeBitget = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetCheckPositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setCheckPositionModeLoading(true));
    try {
      await api.get(
        `${ApiRoute.AccountDetails}${AccountName.BitgetFutures}${AccountActionPath.POSITION_MODE}`
      );
      dispatch(
        setCheckPositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setCheckPositionModeLoading(false));
  }
);
export const checkPositionModeBinance = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetCheckPositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setCheckPositionModeLoading(true));
    try {
      await api.get(
        `${ApiRoute.AccountDetails}${AccountName.BinanceFutures}${AccountActionPath.POSITION_MODE}`
      );
      dispatch(
        setCheckPositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setCheckPositionModeLoading(false));
  }
);

export const checkPositionModeBybit = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetCheckPositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setCheckPositionModeLoading(true));
    try {
      await api.get(
        `${ApiRoute.AccountDetails}${AccountName.Bybit}${AccountActionPath.POSITION_MODE}`
      );
      dispatch(
        setCheckPositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (
        err instanceof AxiosError &&
        err.response?.data.code &&
        err.response.status === 422
      ) {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      } else {
        dispatch(
          setCheckPositionModeStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    // dispatch(setCheckPositionModeLoading(false));  # TODO сделать для bybit
  }
);

export const changePositionModeOkx = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetChangePositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setChangePositionModeLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.Okx}${AccountActionPath.CHANGE_POSITION_MODE}`
      );
      dispatch(
        setChangePositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setChangePositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      }
    }
    dispatch(setChangePositionModeLoading(false));
  }
);

export const changePositionModeBitget = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetChangePositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setChangePositionModeLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BitgetFutures}${AccountActionPath.CHANGE_POSITION_MODE}`
      );
      dispatch(
        setChangePositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setChangePositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      }
    }
    dispatch(setChangePositionModeLoading(false));
  }
);

export const changePositionModeBinance = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetChangePositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setChangePositionModeLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BinanceFutures}${AccountActionPath.CHANGE_POSITION_MODE}`
      );
      dispatch(
        setChangePositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setChangePositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      }
    }
    dispatch(setChangePositionModeLoading(false));
  }
);
export const changePositionModeBybit = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.SetChangePositionModeStatus,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setChangePositionModeLoading(true));
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${AccountName.Bybit}${AccountActionPath.CHANGE_MARGIN_MODE}`
      );
      dispatch(
        setChangePositionModeStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setChangePositionModeStatus({
            isSuccess: false,
            code: err.response?.data.code,
            message: "",
          })
        );
      }
    }
    dispatch(setChangePositionModeLoading(false));
  }
);


export const fetchAmountToStartOkx = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadAmountToStart,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLoadAmountToStartLoading(true));
    try {
      const { data } = await api.post(
        `${ApiRoute.AccountDetails}${AccountName.Okx}${AccountActionPath.COIN_INFO}`
      );
      dispatch(loadAmountToStart(data));
      dispatch(
        setLoadAmountToStartStatus({
          isSuccess: true,
          // message при успехе временно перестали использовать
          message: "Keys are valid",
        })
      );
      dispatch(checkPositionModeOkx());
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        dispatch(
          setLoadAmountToStartStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setLoadAmountToStartLoading(false));
  }
);
export const fetchAmountToStartBinanceFutures = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadAmountToStart,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLoadAmountToStartLoading(true));
    try {
      const { data } = await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BinanceFutures}${AccountActionPath.COIN_INFO}`
      );
      dispatch(loadAmountToStart(data));
      dispatch(
        setLoadAmountToStartStatus({
          isSuccess: true,
          // message при успехе временно перестали использовать
          message: "Keys are valid",
        })
      );
      dispatch(checkPositionModeBinance());
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        dispatch(
          setLoadAmountToStartStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setLoadAmountToStartLoading(false));
  }
);
export const fetchAmountToStartBinanceSpot = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadAmountToStart,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLoadAmountToStartLoading(true));
    try {
      const { data } = await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BinanceSpot}${AccountActionPath.COIN_INFO}`
      );
      dispatch(loadAmountToStart(data));
      dispatch(
        setLoadAmountToStartStatus({
          isSuccess: true,
          // message при успехе временно перестали использовать
          message: "Keys are valid",
        })
      );
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        dispatch(
          setLoadAmountToStartStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setLoadAmountToStartLoading(false));
  }
);

export const fetchAmountToStartBitget = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadAmountToStart,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLoadAmountToStartLoading(true));
    try {
      const { data } = await api.post(
        `${ApiRoute.AccountDetails}${AccountName.BitgetFutures}${AccountActionPath.COIN_INFO}`
      );
      dispatch(loadAmountToStart(data));
      dispatch(
        setLoadAmountToStartStatus({
          isSuccess: true,
          // message при успехе временно перестали использовать
          message: "Keys are valid",
        })
      );
      dispatch(checkPositionModeBitget());
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        dispatch(
          setLoadAmountToStartStatus({
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setLoadAmountToStartLoading(false));
  }
);

export const fetchAmountToStartBybit = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Strategy.LoadAmountToStart,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLoadAmountToStartLoading(true));
    try {
      const { data } = await api.post(
        `${ApiRoute.AccountDetails}${AccountName.Bybit}${AccountActionPath.COIN_INFO}`
      );
      dispatch(loadAmountToStart(data));
      dispatch(
        setLoadAmountToStartStatus({
          isSuccess: true,
          // message при успехе временно перестали использовать
          message: "Keys are valid",
        })
      );
      // dispatch(checkPositionModeBybit());
    } catch (err) {
      if (err instanceof AxiosError) {
        dispatch(
          setLoadAmountToStartStatus({
            code: err.response?.data.code,
            isSuccess: false,
            message: "",
          })
        );
      }
    }
    dispatch(setLoadAmountToStartLoading(false));
  }
);
