import Message from "../../message/message";
import ConnectNote from "../notes/connect-note";
import { ErrorType } from "../../../const";
import { ResponseStatusType } from "../../../types/details";

type RenewKeysMessageProps = {
  isKeyLengthError: boolean;
  checkRenewKeysStatus: null | ResponseStatusType;
  saveRenewKeysStatus: null | ResponseStatusType;
};

export default function RenewKeysMessage({
  isKeyLengthError,
  checkRenewKeysStatus,
  saveRenewKeysStatus,
}: RenewKeysMessageProps): JSX.Element {
  const isSaveRenewKeysFail = saveRenewKeysStatus?.isSuccess === false;

  return isKeyLengthError ? (
    <Message status="error" message={ErrorType.KeyLength} />
  ) : isSaveRenewKeysFail ? (
    <Message status="error" message={ErrorType.General} />
  ) : (
    <Message
      status="error"
      message={<ConnectNote status={checkRenewKeysStatus} isOkx={false} />}
    />
  );
}
