export default function AccountsIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#828282"
        stroke="#828282"
        d="M12.6 4.7h-.2c-.5 0-.9.4-.9.9v7c0 .5.4.9.9.9h.2c.5 0 .9-.4.9-.9v-7c0-.5-.4-.9-.9-.9ZM7 .5c-.5 0-.9.4-.9.9v11.2c0 .5.4.9.9.9s.9-.4.9-.9V1.4c0-.5-.4-.9-.9-.9Zm-5.6 8c-.5 0-.9.4-.9.9v3.2c0 .5.4.9.9.9s.9-.4.9-.9V9.4c0-.5-.4-.9-.9-.9Z"
      />
    </svg>
  );
}
