import { AccountName } from "../../const";
import { useAppSelector } from "../../hooks";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import GeneralPromoInsight from "../insights/general-exchange-insight";
import BinanceButtonGroup from "./binance-promo/binance-button-group";

type BinancePromoProps = {
  isMobile: boolean;
};

export default function BinancePromo({
  isMobile,
}: BinancePromoProps): JSX.Element {
  const { isBinanceFuturesConnected, isBinanceSpotConnected, binanceSpotDemo } =
    useAppSelector((state) => state);

  const quotes = binanceSpotDemo?.quotes as QuotesItemsType;
  const results = binanceSpotDemo?.strategy_results as StrategyResultsType;

  return (
    <section className="landing__item exchange-promo">
      <div className="exchange-promo_container">
        <div className="exchange-promo__heading">
          <h1 className="landing__heading">
            AUTOMATE YOUR TRADING ON THE LARGEST CRYPTOCURRENCY EXCHANGE
          </h1>
        </div>

        <p className="exchange-promo__description">
          The strategy is based on fundamental research and aims to generate
          absolute returns by trading spot instruments. Most of the time, it
          carries zero risk to the market and stays in the base currency.
        </p>
        {isMobile ? null : <br />}
        {isBinanceFuturesConnected || isBinanceSpotConnected ? null : (
          <p className="exchange-promo__description">
            Connect your Binance account - no setup or trading knowledge
            required.
          </p>
        )}
        {isMobile ? null : <BinanceButtonGroup />}
      </div>
      {binanceSpotDemo ? (
        <GeneralPromoInsight
          isMobile={isMobile}
          quotes={quotes}
          results={results}
          type={AccountName.BinanceSpot}
        />
      ) : null}
      {isMobile ? <BinanceButtonGroup /> : null}
    </section>
  );
}
