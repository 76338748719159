import { createAction } from "@reduxjs/toolkit";

import { StateAction } from "../action-types";
import { CoinTogglesType } from "../../types/interface";

export const setCoinToggles = createAction<CoinTogglesType>(
  StateAction.Interface.setCoinToggles
);

export const setNavOpened = createAction<boolean>(
  StateAction.Interface.SetNavOpened
);
