import {
  renderBitcoinDot,
  renderCustomDot,
  renderEthereumDot,
  renderOkxDot,
  renderSmardDot,
} from "./chart-dots";

export const SmardEndDot = (props: any) => {
  const { cx, cy, isEndDotsShown } = props;
  return isEndDotsShown ? renderSmardDot(cx, cy) : <></>;
};

export const CustomEndDot = (props: any) => {
  const { cx, cy, isEndDotsShown, imgLink } = props;
  return isEndDotsShown ? renderCustomDot(cx, cy, imgLink) : <></>;
};

export const BitcoinEndDot = (props: any) => {
  const { cx, cy, isEndDotsShown } = props;
  return isEndDotsShown ? renderBitcoinDot(cx, cy) : <></>;
};

export const EthereumEndDot = (props: any) => {
  const { cx, cy, isEndDotsShown } = props;
  return isEndDotsShown ? renderEthereumDot(cx, cy) : <></>;
};

export const OkxEndDot = (props: any) => {
  const { cx, cy, isEndDotsShown } = props;
  return isEndDotsShown ? renderOkxDot(cx, cy) : <></>;
};
