import { SyntheticEvent, useRef } from "react";
import { NavLink } from "react-router-dom";

import DollarIcon from "../../icons/nav/dollar-icon";
import ChatIcon from "../../icons/nav/chat-icon";
import { AppRoute, AuthStatus } from "../../../const";
import { setNavOpened } from "../../../store/actions/interface";
import { useAppDispatch, useAppSelector } from "../../../hooks";

type NavExtraProps = {
  type: string;
};

export default function NavExtra({ type }: NavExtraProps): JSX.Element {
  const { authStatus } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();
  const demoBtnRef = useRef<null | HTMLButtonElement>(null);
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const handleClose = (evt: SyntheticEvent) => {
    const target = evt.target as Element;
    if (demoBtnRef?.current?.contains(target as Element)) {
      return;
    }
    dispatch(setNavOpened(false));
  };

  return (
    <ul className={`${type} nav-extra`} onClick={handleClose}>
      <li className="nav-extra__item">
        <NavLink className="nav-extra__link" to={AppRoute.Pricing}>
          <div className="nav-extra__icon-wrapper">
            <span className="nav-extra__icon nav-extra__icon--pricing">
              <DollarIcon />
            </span>
          </div>
          <span className="nav-extra__link-text">Win-win pricing</span>
        </NavLink>
      </li>
      <li className="nav-extra__item">
        <NavLink className="nav-extra__link" to={AppRoute.Faq}>
          <div className="nav-extra__icon-wrapper">
            <span className="nav-extra__icon nav-extra__icon--faq">
              <ChatIcon />
            </span>
          </div>
          <span className="nav-extra__link-text">FAQ</span>
        </NavLink>
      </li>
    </ul>
  );
}
