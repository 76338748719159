import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";
import { StrategyResultsType } from "../../types/details";
import { AccountName, AppRoute, AuthStatus, EMPTY_DATA } from "../../const";
import {
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";

type RelativeExtraProps = {
  results: StrategyResultsType;
  account: string;
};

export default function RelativeExtra({
  results,
  account,
}: RelativeExtraProps): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { authStatus } = useAppSelector((state) => state);
  const isAuth = authStatus === AuthStatus.Auth;
  const isOkx = account === AccountName.Okx;
  const isBitget = account === AccountName.BitgetFutures;
  const isBybit = account === AccountName.Bybit;

  const handleLearnMoreClick = () => {
    if (isOkx) {
      navigate(AppRoute.Okx);
      return;
    }

    if (isBitget) {
      navigate(AppRoute.Bitget);
      return;
    }

    if (isBybit) {
      navigate(AppRoute.Bybit);
      return;
    }
    
    navigate(AppRoute.Binance);
  };

  const handleGetStartedClick = () => {
    if (isAuth) {
      navigate(`${AppRoute.Accounts}/${account}/`);
    } else {
      dispatch(setAuthModalOpened(true));
    }
  };

  return (
    <div className="relative-extra">
      <ul className="relative-extra__list">
        <li className="relative-extra__row">
          <p className="relative-extra__name">Sharpe ratio</p>
          <p className="relative-extra__value">
            {results.sharpe_ratio !== null
              ? displayResultsAmount(results.sharpe_ratio)
              : EMPTY_DATA}
          </p>
        </li>
        <li className="relative-extra__row">
          <p className="relative-extra__name">Average monthly return</p>
          <p className="relative-extra__value">
            {results.avg_monthly_return !== null
              ? displayResultsPercent(results.avg_monthly_return)
              : EMPTY_DATA}
          </p>
        </li>
      </ul>
      <div className="relative-extra__btns">
        <Button
          pattern="white-blue"
          text="Get started"
          icon="arrow-right"
          type="button"
          handleClick={handleGetStartedClick}
        />
        <Button
          pattern="blue-white"
          text="Learn more"
          icon="eye"
          type="button"
          handleClick={handleLearnMoreClick}
        />
      </div>
    </div>
  );
}
