import { useState } from "react";
import { useLocation } from "react-router-dom";

import Hint from "../hint/hint";
import EmptyChartBlock from "../empty-chart-block/empty-chart-block";
import DetailsChart from "../charts/details-chart";
import { AppRoute, AuthStatus, HINTS_TEXT } from "../../const";
import { useAppSelector } from "../../hooks";
import { QuotesItemsType, QuotesItemType } from "../../types/details";
import TogglesBase from "../toggles/toggles-base";

type DetailsCanvasProps = {
  data: QuotesItemsType;
  isBitcoin: boolean;
  handleBitcoinMode: (mode: boolean) => void;
};

export default function DetailsCanvas({
  data,
  isBitcoin,
  handleBitcoinMode,
}: DetailsCanvasProps): JSX.Element {
  const { pathname } = useLocation();
  const { authStatus } = useAppSelector((state) => state);
  const [isAllDays, setAllDays] = useState<boolean>(true);

  const btcData = data.btc as QuotesItemType[];
  const ethData = data.eth as QuotesItemType[];
  const strategyData = data.strategy as QuotesItemType[];
  const isDetailsScreen = pathname.startsWith(AppRoute.Accounts);
  const isHintShown = authStatus === AuthStatus.NoAuth && isDetailsScreen;

  return (
    <section className="details__item details__canvas details-canvas block block--chart">
      <h3 className="visually-hidden">Details chart</h3>

      {strategyData &&
      btcData &&
      ethData &&
      strategyData.length > 1 &&
      btcData.length > 1 &&
      ethData.length > 1 ? (
        <DetailsChart
          main={strategyData}
          btcBench={btcData}
          ethBench={ethData}
          isAllDays={isAllDays}
          isBitcoin={isBitcoin}
        />
      ) : (
        <EmptyChartBlock />
      )}
      <div className="details-canvas__controls">
        <TogglesBase
          type="deatils"
          isBitcoinMode={isBitcoin}
          isAllDays={isAllDays}
          setBitcoinMode={handleBitcoinMode}
          setAllDays={setAllDays}
          hint={
            isHintShown ? (
              <Hint
                type=""
                id="details/details-canvas"
                hintText={HINTS_TEXT["details/chart"]}
                block="details-canvas"
              />
            ) : null
          }
        />
      </div>
    </section>
  );
}
