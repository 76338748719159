import { setCoinToggles } from "../../store/actions/interface";
import { useAppDispatch, useAppSelector } from "../../hooks";

export default function CoinPeriodToggles(): JSX.Element {
  const dispatch = useAppDispatch();

  const { coinToggles } = useAppSelector((state) => state);
  const isMonth = coinToggles.isMonth;

  return (
    <div className="coin-period-toggles">
      <p className="coin-period-toggles__item">
        <input
          className="visually-hidden coin-period-toggles__input"
          id="coin-controls-time-1m"
          type="radio"
          name="time-type"
          value="one-month"
          checked={isMonth}
          onChange={() =>
            dispatch(setCoinToggles({ ...coinToggles, isMonth: true }))
          }
        />
        <label
          className="coin-period-toggles__label"
          htmlFor="coin-controls-time-1m"
        >
          1M
        </label>
      </p>

      <p className="coin-period-toggles__item">
        <input
          className="visually-hidden coin-period-toggles__input"
          id="coin-controls-time-1y"
          type="radio"
          name="time-type"
          value="one-year"
          checked={!isMonth}
          onChange={() =>
            dispatch(setCoinToggles({ ...coinToggles, isMonth: false }))
          }
        />
        <label
          className="coin-period-toggles__label"
          htmlFor="coin-controls-time-1y"
        >
          1Y
        </label>
      </p>
    </div>
  );
}
