import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../button/button";
import BitgetRenewKeysFields from "../fields/bitget-renew-keys-fields";
import BitgetRenewKeysMessage from "../messages/bitget-renew-keys-message";
import { useAppSelector } from "../../../hooks";
import { StrategyStatus } from "../../../const";

type BitgetRenewKeysFormProps = {
  isAnimationEnd: boolean;
};

export default function BitgetRenewKeysForm({
  isAnimationEnd,
}: BitgetRenewKeysFormProps): JSX.Element {
  const [lengthFieldError, setLengthFieldError] = useState<string>("");

  const { strategyDetails, isAddKeysLoading, addKeysStatus } = useAppSelector(
    (state) => state
  );
  const strategyStatus = strategyDetails?.status as string;

  const isDemo = strategyStatus === StrategyStatus.Demo;
  const isAddKeysSuccess = addKeysStatus?.isSuccess;
  const isAddKeysFail = addKeysStatus?.isSuccess === false;
  const isFieldsDisabled = isDemo || isAddKeysLoading || isAddKeysSuccess;
  const isMessageShown = lengthFieldError || isAddKeysFail;

  const { register, getValues, setFocus } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (!isDemo && isAnimationEnd) {
      setFocus("key");
    }
  }, [isDemo, isAnimationEnd, setFocus]);

  return (
    <form className="renew-keys-form">
      <div className="renew-keys-form__wrapper">
        <BitgetRenewKeysFields
          getValues={getValues}
          register={register}
          isFieldsDisabled={isFieldsDisabled}
          lengthFieldError={lengthFieldError}
          setLengthFieldError={setLengthFieldError}
          addKeysStatus={addKeysStatus}
        />
        <div className="renew-keys__message">
          {isMessageShown && (
            <BitgetRenewKeysMessage
              lengthFieldError={lengthFieldError}
              addKeysStatus={addKeysStatus}
            />
          )}
        </div>
      </div>

      {/* Для вида */}
      <Button
        classElement="btn--disabled"
        pattern="white-blue"
        text="Renew keys"
        icon={isAddKeysLoading ? "arrow-loader" : "arrow-right"}
        type="button"
      />
    </form>
  );
}
