import {
    FieldValues,
    UseFormRegister,
    UseFormSetValue,
    UseFormTrigger,
  } from "react-hook-form";
  
  import TriangleDownIcon from "../../icons/triangle/triangle-down-icon";
  import { minNotional } from "../../../utils/env";
  
  type BinanceSpotStep3FieldsProps = {
    availableAmount: number;
    isLoading: boolean;
    register: UseFormRegister<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    trigger: UseFormTrigger<FieldValues>;
    isUseAllAmount: boolean;
  };
  
  export default function BinanceSpotStep3Fields({
    availableAmount,
    isLoading,
    setValue,
    trigger,
    register,
    isUseAllAmount,
  }: BinanceSpotStep3FieldsProps): JSX.Element {
    const validAmount = (value: number) =>
      Number(value) <= availableAmount && Number(value) >= minNotional;
  
    const amountDisabledClass =
      isLoading || isUseAllAmount ? "field--disabled" : "";
    const maxDisabledClass = isLoading || isUseAllAmount ? "field--disabled" : "";
  
    const handleMaxClick = () => {
      setValue("amount", availableAmount);
      trigger("amount");
    };
  
    return (
      <div className="connect-step__fields-amount-inner-wrapper">
        <div className="connect-step__amount-wrapper">
          <label className="visually-hidden" htmlFor="amount">
            Amount
          </label>
  
          <input
            className={`connect-step__field connect-step__field--amount field ${amountDisabledClass}`}
            type="number"
            id="amount"
            placeholder="Amount"
            autoComplete="off"
            {...register("amount", {
              required: true,
              validate: (value) => validAmount(value),
            })}
          />
  
          <button
            className={`connect-step__amount-btn ${maxDisabledClass}`}
            type="button"
            onClick={handleMaxClick}
          >
            MAX
          </button>
        </div>
  
        {/* currency field */}
        <div className="connect-step__field connect-step__field--currency field field--disabled">
          USDT
          <span className="connect-step__currency-icon">
            <TriangleDownIcon />
          </span>
        </div>
      </div>
    );
  }
  