import { useState } from "react";

import Hint from "../hint/hint";
import TableDate from "../table-items/table-date";
import TableInstrument from "../table-items/table-instrument";
import TableQuantity from "../table-items/table-quantity";
import TriangleDownIcon from "../icons/triangle/triangle-down-icon";
import TriangleUpIcon from "../icons/triangle/triangle-up-icon";
import { useAppSelector } from "../../hooks";
import { AuthStatus, HINTS_TEXT } from "../../const";
import { LastOrdersType } from "../../types/details";

export default function LastOrders(): JSX.Element {
  const { authStatus, strategyDetails } = useAppSelector((state) => state);
  const lastOrders = strategyDetails?.last_orders as LastOrdersType[];
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const [openedChart, setOpenedChart] = useState<null | number>(null);

  const handleChartToggle = (index: number) => {
    if (index === openedChart) {
      setOpenedChart(null);
      return;
    }
    setOpenedChart(index);
  };

  const titleRow = (
    <li className="last-orders__title-row">
      <div className="last-orders__row-wrapper">
        <p className="last-orders__title last-orders__title--date">Date</p>
        <p className="last-orders__title"></p>
        <p className="last-orders__title last-orders__title--instrument">
          Instrument
        </p>
        <p className="last-orders__title last-orders__title--quantity">
          Quantity
        </p>
      </div>
    </li>
  );

  const hint = (
    <Hint
      type="block__hint"
      id="details/recent-activity"
      hintText={HINTS_TEXT["details/recent-activity"]}
      block="recent-activity"
    />
  );

  return (
    <section className="details__item details__activity last-orders block">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Recent activity</h2>
        {isNoAuth && hint}
      </div>
      {lastOrders && lastOrders.length ? (
        <ul className="last-orders__table">
          {titleRow}
          {/* ROWS */}
          {lastOrders.map((item, index) => {
            return (
              <li
                className="last-orders__row"
                key={index}
                onClick={() => {
                  handleChartToggle(index);
                }}
              >
                <div className="last-orders__row-wrapper">
                  <TableDate date={item.date} />

                  <div className="last-orders__side">
                    {item.side === "sell" ? (
                      <span className="last-orders__side-icon last-orders__side-icon--sell">
                        <TriangleDownIcon />
                      </span>
                    ) : (
                      <span className="last-orders__side-icon last-orders__side-icon--buy">
                        <TriangleUpIcon />
                      </span>
                    )}
                  </div>

                  <TableInstrument
                    url={item.logo_url}
                    title={item.name}
                    ticker={item.ticker}
                  />

                  <div className="last-orders__quantity">
                    <TableQuantity amount={item.quantity} />
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="table-note">
          The software did not make any trades yet. You will see your trading
          activity here.
        </p>
      )}
    </section>
  );
}
