import { FaqData } from "../../../types/general";

export const FaqScreenTradingResults: FaqData[] = [
  {
    question: "What is the Sharpe ratio?",
    answer:
      "The Sharpe ratio is a measure used to evaluate the performance of risk-adjusted investments. It measures the excess return over the risk-free rate of the return divided by the standard deviation of the return. A higher Sharpe Ratio indicates better risk-adjusted performance.",
  },
  {
    question: "What is average monthly return and average order return?",
    answer: (
      <>
        <p className="faq-item__paragraph">
          Average monthly return measures the average investment return earned
          each month over a specific time period. It is calculated by dividing
          the number of monthly returns by the number of full months in the
          period.
        </p>
        <p className="faq-item__paragraph">
          Average order return measures the average return on each order placed
          during the period from the start of the algorithm to the current time.
          It is calculated by dividing the sum of the returns of orders by the
          number of placed orders.
        </p>
        <p className="faq-item__paragraph">
          Both metrics are useful for evaluating the effectiveness of an
          investment strategy.
        </p>
      </>
    ),
  },
  {
    question: "What are annualized returns?",
    answer:
      "Annualized returns measure the average rate of return an investment generates per year. It is calculated by adjusting the total return earned pro rata to a full year to reflect the annualized rate of return. This indicator is important for evaluating the historical performance of investments and takes into account compound interest.",
  },
];
