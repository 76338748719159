import { SyntheticEvent, useRef } from "react";

import AuthBlockModal from "../auth-block/auth-block-modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { dropRedirectAccount } from "../../services/redirect-account";
import {
  setAuthModalOpened,
  setAuthModalOpenedFromExchanges,
} from "../../store/actions";

export default function AuthModal(): JSX.Element {
  const dispatch = useAppDispatch();
  const modalRef = useRef<HTMLDivElement | null>(null);

  const { isAuthModalOpenedFromExchanges } = useAppSelector((state) => state);

  const handleClick = (evt: SyntheticEvent) => {
    const target = evt.target as Element;
    const parent = target.parentElement;
    if (
      modalRef.current?.contains(parent as Element) ||
      modalRef.current?.contains(target as Element)
    ) {
      return;
    }
    dispatch(setAuthModalOpened(false));

    if (isAuthModalOpenedFromExchanges) {
      dropRedirectAccount();
      dispatch(setAuthModalOpenedFromExchanges(false));
    }
  };

  // dispatch(setAuthModalOpened(true)) нужен только при клике по apple, так как только у него нет перезагрузки страницы при успешной авторизации, а просто переход на overview

  return (
    <div className="auth-modal" onClick={handleClick}>
      <h2 className="visually-hidden">Login</h2>
      <div className="auth-modal__wrapper" ref={modalRef}>
        <AuthBlockModal />
      </div>
    </div>
  );
}
