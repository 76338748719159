import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Button from "../../button/button";
import RenewKeysMessage from "../messages/renew-keys-message";
import RenewKeysFields from "../fields/renew-keys-fields";
import { useAppSelector } from "../../../hooks";
import { StrategyStatus } from "../../../const";

type RenewKeysFormProps = {
  isAnimationEnd: boolean;
};

export default function RenewKeysForm({
  isAnimationEnd,
}: RenewKeysFormProps): JSX.Element {
  const [isKeyLengthError, setKeyLengthError] = useState<boolean>(false);

  const {
    strategyDetails,
    checkRenewKeysStatus,
    saveRenewKeysStatus,
    isCheckRenewKeysLoading,
    isSaveRenewKeysLoading,
  } = useAppSelector((state) => state);
  const strategyStatus = strategyDetails?.status as string;
  const accountName = strategyDetails?.overview?.code as string;

  const { register, getValues, setFocus } = useForm({
    mode: "onChange",
  });

  const isDemo = strategyStatus === StrategyStatus.Demo;
  const isCheckRenewKeysSuccess = checkRenewKeysStatus?.isSuccess;
  const isCheckRenewKeysFail = checkRenewKeysStatus?.isSuccess === false;
  const isSaveRenewKeysFail = saveRenewKeysStatus?.isSuccess === false;
  const isLoading = isCheckRenewKeysLoading || isSaveRenewKeysLoading;
  const isFieldsDisabled =
    isDemo || isCheckRenewKeysLoading || isCheckRenewKeysSuccess;
  const isMessageShown =
    isKeyLengthError || isCheckRenewKeysFail || isSaveRenewKeysFail;

  useEffect(() => {
    if (!isDemo && isAnimationEnd) {
      setFocus("key");
    }
  }, [isDemo, isAnimationEnd, setFocus]);

  return (
    <form className="renew-keys-form">
      <div className="renew-keys-form__wrapper">
        <RenewKeysFields
          accountName={accountName}
          isFieldsDisabled={isFieldsDisabled}
          isKeyLengthError={isKeyLengthError}
          setKeyLengthError={setKeyLengthError}
          getValues={getValues}
          register={register}
          checkRenewKeysStatus={checkRenewKeysStatus}
        />

        {/* key message */}
        <div className="renew-keys__message">
          {isMessageShown && (
            <RenewKeysMessage
              isKeyLengthError={isKeyLengthError}
              checkRenewKeysStatus={checkRenewKeysStatus}
              saveRenewKeysStatus={saveRenewKeysStatus}
            />
          )}
        </div>
      </div>

      <Button
        classElement="btn--disabled"
        pattern="white-blue"
        text="Renew keys"
        icon={isLoading ? "arrow-loader" : "key-in-circle"}
        type="button"
      />
    </form>
  );
}
