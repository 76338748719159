import { useEffect } from "react";

import ReferralsHeading from "../../components/referrals-heading/refferals-heading";
import ReferralsLink from "../../components/referrals-link/referrals-link";
import ReferralsBalance from "../../components/referrals-balance/referrals-balance";
import ReferralsClients from "../../components/referrals-clients/referrals-clients";
import ReferralsActivity from "../../components/referrals-activity.tsx/referrals-activity";
import ReferralsIntro from "../../components/referrals-intro/referrals-intro";
import ReferralsHeadingSkeleton from "../../components/skeletons/referrals-heading-skeleton";
import ReferralsIntroSkeleton from "../../components/referrals-intro/referrals-intro-skeleton";
import { ReferralsSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchReferrals } from "../../store/api-actions";
import { AuthStatus } from "../../const";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { localAmount } from "../../utils/amount";
import {
  BalanceDetailsType,
  ReferralsActivityType,
  ReferralsItemType,
} from "../../types/referrals";

export default function ReferralsScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    authStatus,
    isFirstScreenRendered,
    isUserProfileLoading,
    isReferralsLoading,
    referrals,
  } = useAppSelector((state) => state);

  const refLink = referrals?.ref_link as string;
  const balance = referrals?.balance_details as BalanceDetailsType;
  const referralsList = referrals?.referrals as ReferralsItemType[];
  const activityList = referrals?.activity as ReferralsActivityType[];
  const rate = referrals?.affiliate_rate as number;

  const commission = `${localAmount(rate * 100)}%`;
  const isAuth = authStatus === AuthStatus.Auth;
  const isLoading = isReferralsLoading || isUserProfileLoading;

  useEffect(() => {
    dispatch(fetchReferrals());
  }, [dispatch]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && isAuth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main referrals">
      <ReferralsSeoSetting />
      <TrackTwitter />
      <h1 className="visually-hidden">Referral program</h1>

      {isLoading ? (
        <ReferralsHeadingSkeleton />
      ) : (
        <ReferralsHeading isAffiliate={false} commission={commission} />
      )}

      <div className="referrals__main-wrapper">
        {isLoading ? (
          <ReferralsIntroSkeleton />
        ) : (
          <ReferralsIntro isAffiliate={false} commission={commission} />
        )}
        <ReferralsBalance
          isLoading={isLoading}
          isAffiliate={false}
          balance={balance}
          commission={commission}
        />

        <ReferralsLink
          isAffiliate={false}
          isLoading={isLoading}
          refLink={refLink}
        />

        {!isUserProfileLoading && !isReferralsLoading && (
          <div className="referrals__table-wrapper">
            <ReferralsClients
              isAffiliate={false}
              authStatus={authStatus}
              referralsList={referralsList}
            />
            <ReferralsActivity
              authStatus={authStatus}
              isAffiliate={false}
              activityList={activityList}
            />
          </div>
        )}
      </div>
    </main>
  );
}
