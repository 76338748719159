import { useState } from "react";
import { Link } from "react-router-dom";

import LogoIcon from "../icons/logo-icon";
import LogoMobileIcon from "../icons/logo-mobile-icon";
import { AppRoute, RERENDER_DELAY } from "../../const";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";

export default function Logo(): JSX.Element {
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  return (
    <Link className="logo" to={AppRoute.Manual}>
      {isTabletAndMore ? (
        <span className="logo__icon">
          <LogoIcon />
        </span>
      ) : (
        <span className="logo__icon-mobile">
          <LogoMobileIcon />
        </span>
      )}
    </Link>
  );
}
