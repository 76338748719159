import { useEffect } from "react";
import { useParams } from "react-router-dom";

import CoinHeading from "../../components/coin-heading/coin-heading";
import CoinBoard from "../../components/coin-board/coin-board";
// import CoinFaq from "../../components/coin-faq/coin-faq";
import CoinDescription from "../../components/coin-description/coin-description";
import CoinCanvas from "../../components/canvases/coin-canvas";
import HeadingSkeleton from "../../components/skeletons/heading-skeleton";
import CoinBoardSkeleton from "../../components/skeletons/coin-board-skeleton";
import CoinCanvasSkeleton from "../../components/skeletons/coin-canvas-skeleton";
import { CoinSeoSetting } from "../../components/seo-setting/seo-setting";
import { AuthStatus } from "../../const";
import { fetchCoin } from "../../store/api-actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { loadCoin, setFirstScreenRendered } from "../../store/actions";
import {
  deleteExtraCanonicalLink,
  deleteExtraDescription,
  deleteExtraLinkedData,
} from "../../utils/general";

export default function CoinScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { coinName } = useParams();

  const { authStatus, isFirstScreenRendered, coin, isCoinLoading } =
    useAppSelector((state) => state);

  useEffect(() => {
    return () => {
      if (!isFirstScreenRendered && authStatus === AuthStatus.NoAuth) {
        dispatch(setFirstScreenRendered(true));
      }
    };
    // eslint-disable-next-line
  }, [authStatus]);

  useEffect(() => {
    dispatch(fetchCoin(coinName as string));
    return () => {
      dispatch(loadCoin(null));
    };
  }, [coinName, dispatch]);

  useEffect(() => {
    deleteExtraDescription();
    deleteExtraCanonicalLink();
    deleteExtraLinkedData();
  }, []);

  return (
    <main className="main main--landing landing">
      <div className="landing__wrapper">
        {isCoinLoading ? (
          <>
            <HeadingSkeleton />
            <div className="coin-skeleton-container">
              <CoinCanvasSkeleton />
              <CoinBoardSkeleton />
            </div>
          </>
        ) : (
          coin && (
            <>
              <CoinSeoSetting
                title={coin.title}
                ticker={coin.ticker}
                price={coin.price}
              />
              <CoinHeading coin={coin} />
              <div className="coin-container">
                {coin?.quotes && <CoinCanvas coin={coin} />}
                <CoinBoard coin={coin} />
              </div>
              <CoinDescription coin={coin} />
              {/* <CoinFaq coin={coin} /> */}
            </>
          )
        )}
      </div>
    </main>
  );
}
