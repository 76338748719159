export default function UsdcIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="#2775CA"
        d="M6.5 13c3.6 0 6.5-2.9 6.5-6.5S10.1 0 6.5 0 0 2.9 0 6.5 2.9 13 6.5 13Z"
      />
      <path
        fill="#fff"
        d="M8.29 7.53c0-.95-.57-1.27-1.7-1.4-.82-.12-.98-.33-.98-.71 0-.38.27-.63.8-.63.5 0 .77.17.9.57.03.08.11.14.2.14h.43c.1 0 .18-.08.18-.2v-.02c-.1-.6-.6-1.05-1.21-1.1v-.66c0-.1-.09-.19-.22-.22h-.4c-.12 0-.2.09-.22.22v.62c-.82.11-1.33.65-1.33 1.33 0 .9.54 1.25 1.68 1.38.76.14 1 .3 1 .73 0 .44-.38.74-.9.74-.7 0-.94-.3-1.02-.7-.03-.12-.11-.17-.2-.17h-.45c-.11 0-.2.08-.2.19v.03c.12.67.55 1.16 1.44 1.3v.65c0 .1.08.18.22.21h.4c.11 0 .2-.08.22-.21v-.65c.82-.14 1.36-.7 1.36-1.44Z"
      />
      <path
        fill="#fff"
        d="M5.12 10.37a4.03 4.03 0 0 1-2.41-5.2 4 4 0 0 1 2.4-2.4c.12-.06.17-.14.17-.28v-.38c0-.1-.05-.19-.16-.22a4.87 4.87 0 0 0-.1 9.32c.1.06.2 0 .23-.1.03-.03.03-.06.03-.12v-.37c0-.09-.08-.2-.16-.25Zm2.87-8.45c-.1-.05-.22 0-.24.11-.03.03-.03.05-.03.1v.39c0 .1.08.21.16.27a4.03 4.03 0 0 1 2.41 5.2 4 4 0 0 1-2.4 2.4c-.12.06-.17.14-.17.28v.38c0 .1.05.19.16.22.03 0 .08 0 .1-.03a4.87 4.87 0 0 0 3.18-6.12 4.91 4.91 0 0 0-3.17-3.2Z"
      />
    </svg>
  );
}
