type TableInstrumentProps = {
  url: string;
  title: string;
  ticker: string;
};

export default function TableInstrument({
  url,
  title,
  ticker,
}: TableInstrumentProps): JSX.Element {
  return (
    <div className="table-instrument">
      <img
        className="table-instrument-logo"
        src={url}
        alt={`${title} logo`}
      />
      <p className="table-instrument-title shadow shadow--instrument">
        {title}
      </p>
      <p className="table-instrument-ticker">{ticker}</p>
    </div>
  );
}
