import CardIcon from "../../icons/payment/card-icon";
import WalletIcon from "../../icons/payment/wallet-icon";
import ChainIcon from "../icons/nav/chain-icon";

type PricingFeesSectionProps = {
  isMobile: boolean;
};

function PricingFeesSection({ isMobile }: PricingFeesSectionProps) {
  return (
    <section className="landing__item--pricing-fees">
      <h1 className="visually-hidden">Pricing</h1>
      <h2 className="landing__title">
        {isMobile ? "Pricing details" : "Fees"}
      </h2>
      <ul className="pricing__list">
        <li className="pricing__item">
          <h3 className="pricing__item-heading">$1 per month</h3>
          <span className="pricing__note">Access fee</span>
          <p className="pricing__description">
            Minimum fee to access trading software
          </p>
        </li>

        <li className="pricing__item">
          <h3 className="pricing__item-heading">10%</h3>
          <span className="pricing__note">Service fee</span>
          <p className="pricing__description">
            We take a win-win approach and only charge 10% after you profit
          </p>
        </li>

        <li className="pricing__item">
          <h3 className="pricing__item-heading">Payment options</h3>
          <ul className="pricing__payments-list">
            <li className="pricing__payments-item">
              <span className="pricing__icon">
                <CardIcon />
              </span>
              Credit cards
            </li>
            <li className="pricing__payments-item">
              <span className="pricing__icon">
                <WalletIcon />
              </span>
              Crypto deposits
            </li>
            <li className="pricing__payments-item">
              <span className="pricing__icon">
                <ChainIcon />
              </span>
              Referral program fees
            </li>
          </ul>
        </li>
      </ul>
    </section>
  );
}

export default PricingFeesSection;
