export default function CoinIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 15 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.5 2.5C2.48 3.1 2.05 3.78 2.05 4.4c0 .6.43 1.3 1.43 1.88.99.58 2.4.96 4.01.96 1.6 0 3.02-.38 4-.96C12.52 5.7 12.95 5 12.95 4.4c0-.61-.43-1.3-1.43-1.89a8.15 8.15 0 0 0-4.01-.95c-1.6 0-3.02.38-4 .95Zm-.8-1.34A9.7 9.7 0 0 1 7.5 0a9.7 9.7 0 0 1 4.8 1.16c1.24.73 2.2 1.84 2.2 3.23 0 .17-.02.35-.05.51h.05V8c0 .18-.02.35-.05.52h.05v3.1c0 1.38-.96 2.5-2.2 3.22A9.7 9.7 0 0 1 7.5 16a9.7 9.7 0 0 1-4.8-1.16C1.47 14.1.5 13 .5 11.6v-6.7h.05a2.94 2.94 0 0 1-.05-.52c0-1.39.96-2.5 2.2-3.23Zm-.64 6.01V8c0 .6.43 1.3 1.43 1.89.99.57 2.4.95 4.01.95 1.6 0 3.02-.38 4-.95C12.52 9.3 12.95 8.6 12.95 8v-.83c-.2.16-.42.3-.65.44A9.7 9.7 0 0 1 7.5 8.77a9.7 9.7 0 0 1-4.8-1.16 6.03 6.03 0 0 1-.64-.44Zm0 3.62v.82c0 .61.43 1.3 1.43 1.89.99.57 2.4.95 4.01.95 1.6 0 3.02-.38 4-.95 1.01-.59 1.44-1.28 1.44-1.89v-.82c-.2.16-.42.3-.65.43A9.7 9.7 0 0 1 7.5 12.4a9.7 9.7 0 0 1-4.8-1.17 6.02 6.02 0 0 1-.64-.43Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
