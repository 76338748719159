import { useState } from "react";

import CalendarIcon from "../../icons/calendar-icon";
import SummaryItem from "./summary-item";
import CardWithDollarIcon from "../../icons/payment/card-with-dollar-icon";
import CoinsIcon from "../../icons/payment/coins-icon";
import AccountsIcon from "../../icons/nav/accounts-icon";
import WalletIcon from "../../icons/payment/wallet-icon";
import BoardSign from "../board-sign/board-sign";
import DollarUpIcon from "../../icons/payment/dollar-up-icon";
import DollarDownIcon from "../../icons/payment/dollar-down-icon";
import PercentUpIcon from "../../icons/percent/percent-up-icon";
import PercentDownIcon from "../../icons/percent/percent-down-icon";
import DollarInCalendarUpIcon from "../../icons/payment/dollar-in-calendar-up-icon";
import DollarInCalendarDownIcon from "../../icons/payment/dollar-in-calendar-down-icon";
import DollarInCalendarIcon from "../../icons/payment/dollar-in-calendar-icon";
import { showFullDate } from "../../utils/datetime";
import { formatStrategy, localAmount } from "../../utils/amount";
import { OverviewSummaryType } from "../../types/strategy";
import { checkIfDesktop } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";

type OverviewSummaryProps = {
  summary: OverviewSummaryType;
};

export default function OverviewSummary({
  summary,
}: OverviewSummaryProps): JSX.Element {
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  const isPayShown = formatStrategy(summary.deposit_amount).amount < 0;

  const findColor = (amount: number) => {
    const formatted = formatStrategy(amount).amount;
    return formatted === 0 ? "" : formatted > 0 ? "up" : "down";
  };

  const addBoardMinus = (amount: number) => {
    if (formatStrategy(amount).amount < 0) {
      return <BoardSign sign="minus" />;
    }
    return <></>;
  };

  const displayPercent = (amount: number, isColored?: boolean) => {
    if (isColored) {
      return `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
        formatStrategy(amount).short
      }%`;
    }

    return (
      <>
        {addBoardMinus(amount)}
        {`${localAmount(formatStrategy(Math.abs(amount * 100)).amount)}${
          formatStrategy(amount).short
        }%`}
      </>
    );
  };

  const displayCoinAmount = (amount: number, isColored?: boolean) => {
    const result = `${localAmount(formatStrategy(Math.abs(amount)).amount)}${
      formatStrategy(amount).short
    } USDT`;

    if (isColored) {
      return result;
    }

    return (
      <>
        {addBoardMinus(amount)}
        {result}
      </>
    );
  };

  const displayDollarAmount = (amount: number, isColored?: boolean) => {
    const result = `$${localAmount(formatStrategy(Math.abs(amount)).amount)}${
      formatStrategy(amount).short
    }`;

    if (isColored) {
      return result;
    }
    return (
      <>
        {addBoardMinus(amount)}
        {result}
      </>
    );
  };

  // ICONS

  const chooseDollarTrendingIcon = (amount: number) => {
    const formatted = formatStrategy(amount).amount;
    if (formatted >= 0) {
      return <DollarUpIcon />;
    }
    return <DollarDownIcon />;
  };

  const choosePercentTrendingIcon = (amount: number) => {
    const formatted = formatStrategy(amount).amount;
    if (formatted >= 0) {
      return <PercentUpIcon />;
    }
    return <PercentDownIcon />;
  };

  const chooseDollarMonthReturnTrendingIcon = (amount: number) => {
    const formatted = formatStrategy(amount).amount;
    if (formatted >= 0) {
      return <DollarInCalendarUpIcon />;
    }
    return <DollarInCalendarDownIcon />;
  };

  // DESKTOP ITEMS OF 2ND LIST

  const serviceFee = (
    <SummaryItem
      title="Service fee"
      type="service-fee"
      amount={displayPercent(summary.service_fee)}
      icon={<CoinsIcon />}
    />
  );

  const monthReturn = (
    <SummaryItem
      title="Last 30 days return"
      type="month-return"
      amount={displayCoinAmount(summary.last_30_days_return, true)}
      icon={chooseDollarMonthReturnTrendingIcon(summary.last_30_days_return)}
      amountModificator={findColor(summary.last_30_days_return)}
    />
  );

  const servicePaid = (
    <SummaryItem
      title="Service paid"
      type="service-paid"
      amount={displayDollarAmount(summary.service_paid)}
      icon={<CardWithDollarIcon />}
    />
  );

  return (
    <section className="overview__summary overview-summary block-design">
      <h2 className="visually-hidden">Overview summary</h2>
      {/* 1 LIST */}
      <ul className="overview-summary__list">
        <SummaryItem
          title="Current balance"
          type="current-balace"
          amount={displayCoinAmount(summary.aum)}
          icon={<AccountsIcon />}
        />
        <SummaryItem
          title="All time return"
          type="all-time-return"
          amount={displayCoinAmount(summary.all_time_return, true)}
          icon={chooseDollarTrendingIcon(summary.all_time_return)}
          amountModificator={findColor(summary.all_time_return)}
        />

        {isPayShown ? (
          <>
            <SummaryItem
              title="To pay"
              type="to-pay"
              amount={displayDollarAmount(summary.deposit_amount, true)}
              icon={<DollarInCalendarIcon />}
              amountModificator={findColor(summary.deposit_amount)}
            />
            <SummaryItem title="" type="pay-button" amount="" icon={<></>} />
          </>
        ) : (
          <>
            <SummaryItem
              title="SMARD wallet"
              type="smard-wallet"
              amount={displayDollarAmount(summary.deposit_amount)}
              icon={<WalletIcon />}
            />
            {summary.next_payment_date !== null && (
              <SummaryItem
                title="Next payment on"
                type="next-payment"
                amount={showFullDate(summary.next_payment_date)}
                icon={<CalendarIcon />}
              />
            )}
          </>
        )}
      </ul>

      {isDesktop ? (
        <>
          {/* 2 LIST */}
          <ul className="overview-summary__list">
            {serviceFee}
            {monthReturn}
            {servicePaid}
          </ul>
        </>
      ) : (
        <ul className="overview-summary__list">
          {serviceFee}
          {monthReturn}
          {servicePaid}
        </ul>
      )}
    </section>
  );
}
