import { useNavigate } from "react-router-dom";
import { AppRoute, AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";
import Button from "../button/button";

function PricingButtonGroup() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { authStatus, accountStatuses } = useAppSelector((state) => state);

  const isTradingStarted = accountStatuses?.already_launched;
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const handleDemoClick = () => {
    navigate(AppRoute.Overview);
  };

  const handleStartedClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(AppRoute.Overview);
    } else {
      dispatch(setAuthModalOpened(true));
    }
  };

  const handleViewClick = () => {
    navigate(AppRoute.Overview);
  };

  return (
    <div className="pricing__btns">
      {isTradingStarted === null ? (
        <div className="landing__btn-gap" />
      ) : isTradingStarted ? (
        <Button
          pattern="white-blue"
          text="View trading results"
          type="button"
          icon="eye"
          size="super"
          handleClick={handleViewClick}
        />
      ) : (
        <>
          <Button
            classElement="promo__btn"
            pattern="white-blue"
            text="Get started"
            icon="arrow-right"
            type="button"
            handleClick={handleStartedClick}
          />
          {isNoAuth ? (
            <Button
              classElement="promo__btn"
              pattern="blue-white"
              text="View free demo"
              icon="eye"
              type="button"
              size="big"
              handleClick={handleDemoClick}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default PricingButtonGroup;
