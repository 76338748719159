import { Link } from "react-router-dom";

import { AppRoute } from "../../const";

export default function FooterList2(): JSX.Element {
  return (
    <ul className="footer-list">
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Faq}>
          FAQ
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Partnership}>
          Affiliate program
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Terms}>
          Terms of service
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Policy}>
          Privacy policy
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Disclaimers}>
          Disclaimers
        </Link>
      </li>
    </ul>
  );
}
