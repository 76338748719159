export default function ChangeAmountIcon(): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.74397 3.33368C2.05512 3.33368 1.49671 3.89248 1.49671 4.58179V15.2538C1.49671 15.9431 2.05512 16.5019 2.74396 16.5019H13.4086C14.0975 16.5019 14.6559 15.9431 14.6559 15.2538V9.91779C14.6559 9.5042 14.9909 9.16892 15.4042 9.16892C15.8175 9.16892 16.1526 9.5042 16.1526 9.91779V15.2538C16.1526 16.7703 14.9241 17.9996 13.4086 17.9996H2.74396C1.22851 17.9996 0 16.7703 0 15.2538V4.58179C0 3.0653 1.22852 1.83594 2.74397 1.83594H8.07629C8.48959 1.83594 8.82464 2.17122 8.82464 2.58481C8.82464 2.9984 8.48959 3.33368 8.07629 3.33368H2.74397Z"
        fill="#3B82F6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6638 5.46181L12.5419 4.33919L5.97192 10.9137C5.37905 11.507 4.9299 12.2284 4.65896 13.0224L4.48909 13.5202L4.98658 13.3502C5.78006 13.0791 6.5009 12.6296 7.09378 12.0364L13.6638 5.46181ZM14.7221 4.40275L13.6003 3.28012L14.7615 2.11812L15.8833 3.24075L14.7221 4.40275ZM17.2944 3.94679C17.6841 3.55686 17.6841 2.92464 17.2944 2.53471L15.467 0.706041C15.0774 0.316105 14.4456 0.316105 14.0559 0.706041L4.91359 9.85467C4.15902 10.6098 3.58738 11.5278 3.24255 12.5384L2.74428 13.9986C2.47641 14.7837 3.2265 15.5343 4.01099 15.2662L5.47023 14.7676C6.4801 14.4225 7.39754 13.8505 8.15211 13.0954L17.2944 3.94679Z"
        fill="#3B82F6"
      />
    </svg>
  );
}
