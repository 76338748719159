import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { DivergenceType, StrategyDetailsType } from "../../types/details";
import { useAppSelector } from "../../hooks";
import { LAST_PATHNAME } from "../../const";

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();
  const strategyDetails = useAppSelector(
    (state) => state.strategyDetails as StrategyDetailsType
  );
  const divergence = strategyDetails?.divergence as DivergenceType[];

  const lastPathname = localStorage.getItem(LAST_PATHNAME);

  useEffect(() => {
    if (hash && divergence) {
      // тк будет фокус на компонент
      return;
    }

    // Прокрутка вверх только если новая страница
    // При обновлении не будет прокрутки
    if (
      lastPathname === null ||
      lastPathname.length === 0 ||
      pathname !== lastPathname
    ) {
      localStorage.setItem(LAST_PATHNAME, pathname);
      window.scrollTo(0, 0);
    }
  }, [divergence, hash, lastPathname, pathname]);

  return null;
}
