import { useState } from "react";

import { checkIfDesktop } from "../../utils/breakpoint";
import { RERENDER_DELAY } from "../../const";
import { debounce } from "../../utils/chart/chart";

export default function OverviewSummarySkeleton(): JSX.Element {
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  const renderAmountBlock = () => (
    <li className="sk-overview-summary__item">
      <div className="sk-overview-summary__amount">
        <div className="sk-overview-summary__amount-inner"></div>
      </div>
      <div className="sk-overview-summary__note">
        <div className="sk-overview-summary__note-inner"></div>
      </div>
    </li>
  );

  return (
    <div className="sk-overview-summary animate-pulse">
      <ul className="sk-overview-summary__list">
        {renderAmountBlock()}
        {renderAmountBlock()}
        {renderAmountBlock()}
        {renderAmountBlock()}
      </ul>
      {isDesktop ? (
        <>
          <ul className="sk-overview-summary__list">
            {renderAmountBlock()}
            {renderAmountBlock()}
            {renderAmountBlock()}
            {renderAmountBlock()}
          </ul>
          <ul className="sk-overview-summary__list">
            {renderAmountBlock()}
            {renderAmountBlock()}
            {renderAmountBlock()}
            {renderAmountBlock()}
          </ul>
        </>
      ) : (
        <ul className="sk-overview-summary__list">
          {renderAmountBlock()}
          {renderAmountBlock()}
          {renderAmountBlock()}
          {renderAmountBlock()}
          {renderAmountBlock()}
          {renderAmountBlock()}
          {renderAmountBlock()}
          {renderAmountBlock()}
        </ul>
      )}
    </div>
  );
}
