import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import { AppRoute, AuthStatus } from "../../const";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";

export default function Start(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authStatus } = useAppSelector((state) => state);

  const handleStartClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(AppRoute.Overview);
    } else {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("HIW", "Clicked");
  };

  const handleLoginClick = () => {
    dispatch(setAuthModalOpened(true));
  };

  return (
    <section className="landing__item start">
      <h2 className="landing__title">
        How to start automated crypto
        <span className="visually-hidden">currency</span> trading{" "}
      </h2>
      <ul className="start__list">
        <li className="start__item">
          <span className="start__number">01</span>
          {authStatus === AuthStatus.NoAuth ? (
            <button
              className="start__info start__info--btn-login"
              aria-label="Open login modal"
              onClick={handleLoginClick}
            >
              Login to the platform
            </button>
          ) : (
            <p className="start__info">Login to the platform</p>
          )}
        </li>
        <li className="start__item">
          <span className="start__number">02</span>
          <p className="start__info">Select your exchange</p>
        </li>
        <li className="start__item">
          <span className="start__number">03</span>
          <p className="start__info">
            Connect your API keys and enter the amount you want to trade
          </p>
        </li>
        <li className="start__item">
          <span className="start__number">04</span>
          <p className="start__info">Make profit</p>
        </li>
      </ul>

      <Button
        classElement="start__btn"
        pattern="white-blue"
        text="Get started"
        icon="arrow-right"
        type="button"
        handleClick={handleStartClick}
      />
    </section>
  );
}
