export default function TriangleDownIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.330314 1.48938L3.21591 3.74524C3.65042 4.08492 4.35232 4.08492 4.78683 3.74524L7.67243 1.48938C8.37433 0.940664 7.87298 0 6.8814 0H1.11021C0.118629 0 -0.371588 0.940664 0.330314 1.48938Z"
        fill="currentColor"
      />
    </svg>
  );
}
