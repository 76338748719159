import { Dispatch, SetStateAction } from "react";
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { useAppDispatch } from "../../../hooks";
import { ResponseStatusType } from "../../../types/details";
import { updateRenewKeysBitget } from "../../../store/api-actions/keys";
import { setAddKeysStatus } from "../../../store/actions/keys";
import {
  AccountName,
  BITGET_API_KEY_LENGTH,
  BITGET_MAX_PASSPHRASE_LENGTH,
  BITGET_MIN_PASSPHRASE_LENGTH,
  BITGET_SECRET_KEY_LENGTH,
} from "../../../const";

type BitgetRenewKeysFieldsProps = {
  isFieldsDisabled: boolean | undefined;
  getValues: UseFormGetValues<FieldValues>;
  register: UseFormRegister<FieldValues>;
  addKeysStatus: null | ResponseStatusType;
  lengthFieldError: string;
  setLengthFieldError: Dispatch<SetStateAction<string>>;
};

export default function BitgetRenewKeysFields({
  isFieldsDisabled,
  getValues,
  register,
  addKeysStatus,
  lengthFieldError,
  setLengthFieldError,
}: BitgetRenewKeysFieldsProps): JSX.Element {
  const dispatch = useAppDispatch();
  const fieldClass = isFieldsDisabled ? "field--disabled" : "";

  const isFieldsValid = (
    keyLength: number,
    secretLength: number,
    passphraseLength: number
  ) =>
    // ПЕРЕМЕННЫЕ ДЛЯ BITGET!
    keyLength === BITGET_API_KEY_LENGTH &&
    secretLength === BITGET_SECRET_KEY_LENGTH &&
    passphraseLength >= BITGET_MIN_PASSPHRASE_LENGTH &&
    passphraseLength <= BITGET_MAX_PASSPHRASE_LENGTH;

  // handleKeyChange
  const handleKeyChange = () => {
    const data = getValues(["key", "secret", "passphrase"]);
    const [key, secret, passphrase] = data;

    if (addKeysStatus) {
      dispatch(setAddKeysStatus(null));
    }

    if (isFieldsValid(key.length, secret.length, passphrase.length)) {
      if (lengthFieldError) {
        setLengthFieldError("");
      }

      setTimeout(() => {
        dispatch(
          updateRenewKeysBitget({
            id: AccountName.BitgetFutures,
            key,
            secret,
            passphrase,
          })
        );
      }, 500);

      return;
    }

    if (key && secret && passphrase) {
      if (key.length !== BITGET_API_KEY_LENGTH) {
        setLengthFieldError("API key");
        return;
      }

      if (secret.length !== BITGET_SECRET_KEY_LENGTH) {
        setLengthFieldError("secret key");
        return;
      }

      if (
        passphrase.length < BITGET_MIN_PASSPHRASE_LENGTH ||
        passphrase.length > BITGET_MAX_PASSPHRASE_LENGTH
      ) {
        setLengthFieldError("passphrase");
        return;
      }
    }

    if (lengthFieldError) {
      setLengthFieldError("");
    }
  };

  return (
    <div className="renew-keys-form__inner-wrapper">
      {/* api-key */}
      <label className="visually-hidden" htmlFor="api-key">
        Api key
      </label>
      <input
        className={`renew-keys-form__field renew-keys-form__field--triple  field ${fieldClass}`}
        type="text"
        id="api-key"
        placeholder="API Key"
        autoComplete="off"
        {...register("key", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
      {/* secret-key */}
      <label className="visually-hidden" htmlFor="secret-key">
        Secret key
      </label>
      <input
        className={`renew-keys-form__field renew-keys-form__field--triple field ${fieldClass}`}
        type="text"
        id="secret-key"
        placeholder="Secret Key"
        autoComplete="off"
        {...register("secret", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
      {/* passphrase */}
      <label className="visually-hidden" htmlFor="passphrase">
        Passphrase
      </label>
      <input
        className={`renew-keys-form__field renew-keys-form__field--triple field ${fieldClass}`}
        type="text"
        id="passphrase"
        placeholder="Passphrase"
        autoComplete="off"
        {...register("passphrase", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
    </div>
  );
}
