import PaymentsFeeSkeleton from "../skeletons/payments-fee-skeleton";
import { useAppSelector } from "../../hooks";
import { localAmount } from "../../utils/amount";

export default function PaymentsAd(): JSX.Element {
  const { paymentsInfo, isPaymentsInfoLoading } = useAppSelector(
    (state) => state
  );

  const displayFee = (amount: number) => `${localAmount(amount * 100)}%`;

  return (
    <section className="block payments__item payments__ad payments-ad">
      <h2 className="visually-hidden">Payments ad</h2>

      <div className="payments-ad__block">
        <p className="payments-ad__heading">
          <span className="payments-ad__topic">
            <em className="payments-ad__amount">$1</em>
            &ensp;per month
          </span>
          <span className="payments-ad__note">Access fee</span>
        </p>
        <p className="payments-ad__info">
          To get access to the trading software.
        </p>
      </div>

      <div className="payments-ad__block">
        <p className="payments-ad__heading">
          <span className="payments-ad__topic payments-ad__topic--fee">
            {isPaymentsInfoLoading ? (
              <PaymentsFeeSkeleton />
            ) : (
              (paymentsInfo?.fee_rate || paymentsInfo?.fee_rate === 0) && (
                <em className="payments-ad__amount payments-ad__amount--fee">
                  {displayFee(paymentsInfo.fee_rate)}
                </em>
              )
            )}
          </span>
          <span className="payments-ad__note">Service fee</span>
        </p>
        <p className="payments-ad__info">
          Focused on a win-win approach, you only pay when you&nbsp;earn.
        </p>
      </div>
    </section>
  );
}
