import { useState } from "react";

import ActionBtn from "../action-btn/action-btn";
import Button from "../button/button";
import ReferralsLinkSkeleton from "../skeletons/referrals-link-skeleton";
import ReferralsTitleSkeleton from "../skeletons/referrals-title-skeleton";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import { checkIfMobile } from "../../utils/breakpoint";
import PersonsRoundIcon from "../../icons/person/persons-round-icon";

type ReferralsLinkProps = {
  isAffiliate: boolean;
  isLoading: boolean;
  refLink: string;
};

export default function ReferralsLink({
  isAffiliate,
  isLoading,
  refLink,
}: ReferralsLinkProps): JSX.Element {
  const [isMobile, setMobile] = useState<boolean>(checkIfMobile());

  window.addEventListener(
    "resize",
    debounce(() => {
      setMobile(checkIfMobile());
    }, RERENDER_DELAY)
  );

  return (
    <section className="block referrals-link">
      <div className="referrals-link__wrapper">
        <div className="referrals-link__icon">
          <PersonsRoundIcon />
        </div>

        {isLoading ? (
          <ReferralsTitleSkeleton />
        ) : (
          <h2 className="block__heading referrals-link__heading">
            {isAffiliate ? "Your affiliate link" : "Your referral link"}
          </h2>
        )}
      </div>

      {isLoading ? (
        <ReferralsLinkSkeleton />
      ) : (
        <form className="referrals-link__form">
          <div className="referrals-link__form-main-wrapper">
            <div className="referrals-link__form-wrapper">
              <div className="referrals-link__field-wrapper shadow shadow--field">
                <label className="visually-hidden" htmlFor="referrals-link">
                  Referrals link
                </label>
                <input
                  className="referrals-link__field field field--disabled"
                  type="text"
                  id="referrals-link"
                  value={refLink || "Your individual link will be here"}
                  readOnly
                />
              </div>
              <div className="referrals-link__btns-wrapper">
                <div className="referrals-link__btn referrals-link__btn--copy">
                  <ActionBtn link={refLink} action="copy" />
                </div>
                {isMobile && (
                  <div className="referrals-link__btn referrals-link__btn--share">
                    <ActionBtn link={refLink} action="share" />
                  </div>
                )}
              </div>
            </div>
            {isAffiliate && (
              <Button
                classElement=""
                pattern="white-blue"
                text="Marketing materials"
                icon="arrow-right"
                type="link"
                size="super"
                linkAddress="https://drive.google.com/drive/folders/1MSOWRXst2TtJdS179JlSeAuj-8A5cjKZ?usp=sharing"
              />
            )}
          </div>
        </form>
      )}
    </section>
  );
}
