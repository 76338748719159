import TooltipIcon from "../icons/help/tooltip-icon";

type TooltipProps = {
  amount: string;
};

export default function Tooltip({ amount }: TooltipProps): JSX.Element {
  return (
    <div className="tooltip">
      <button className="tooltip__button" type="button" aria-label="Tooltip">
        <TooltipIcon />
      </button>
      <p className="tooltip__message">{amount}</p>
    </div>
  );
}
