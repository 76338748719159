export default function ChainIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9407 0.105469H19.176C18.3025 0.105469 17.5878 0.815995 17.5878 1.68442C17.5878 2.55284 18.3025 3.26336 19.176 3.26336H23.9407C26.5613 3.26336 28.7054 5.39494 28.7054 8.00021C28.7054 10.6055 26.5613 12.737 23.9407 12.737H19.176C18.3025 12.737 17.5878 13.4476 17.5878 14.316C17.5878 15.1844 18.3025 15.8949 19.176 15.8949H23.9407C28.3242 15.8949 31.8819 12.3581 31.8819 8.00021C31.8819 3.64231 28.3242 0.105469 23.9407 0.105469ZM9.6466 8.00021C9.6466 8.86863 10.3613 9.57915 11.2348 9.57915H20.7642C21.6378 9.57915 22.3525 8.86863 22.3525 8.00021C22.3525 7.13178 21.6378 6.42126 20.7642 6.42126H11.2348C10.3613 6.42126 9.6466 7.13178 9.6466 8.00021ZM12.8231 12.737H8.05836C5.43778 12.737 3.29366 10.6055 3.29366 8.00021C3.29366 5.39494 5.43778 3.26336 8.05836 3.26336H12.8231C13.6966 3.26336 14.4113 2.55284 14.4113 1.68442C14.4113 0.815995 13.6966 0.105469 12.8231 0.105469H8.05836C3.67483 0.105469 0.117188 3.64231 0.117188 8.00021C0.117188 12.3581 3.67483 15.8949 8.05836 15.8949H12.8231C13.6966 15.8949 14.4113 15.1844 14.4113 14.316C14.4113 13.4476 13.6966 12.737 12.8231 12.737Z"
        fill="currentColor"
      />
    </svg>
  );
}
