import { StrategyType } from "../../../types/strategy";
import { displayStrategyAmount } from "../../../utils/component/overview";

type OverviewCurrentProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyType;
};

export default function OverviewCurrent({
  strategyStatus,
  isTabletAndMore,
  overview,
}: OverviewCurrentProps): JSX.Element {
  const amount = overview.under_management;
  const currency = overview.currency;

  return currency && amount !== null ? (
    <div className="overview-board__total overview-board__total--current">
      <p className="overview-board__amount">
        {displayStrategyAmount(
          "current",
          strategyStatus,
          isTabletAndMore,
          amount,
          currency
        )}
      </p>
      <span className="overview-board__text">Current balance</span>
    </div>
  ) : (
    <></>
  );
}
