export default function PaymentsOrderSkeleton(): JSX.Element {
  return (
    <div className="sk-payments-order animate-pulse">
      <div className="sk-payments-order__card">
        <div className="sk-payments-order__card-img"></div>
        <div className="sk-payments-order__text"></div>
        <div className="sk-payments-order__card-btn"></div>
      </div>
      <div className="sk-payments-order__card">
        <div className="sk-payments-order__card-img"></div>
        <div className="sk-payments-order__text"></div>
        <div className="sk-payments-order__card-btn"></div>
      </div>
      <div className="sk-payments-order__form">
        <div className="sk-payments-order__form-img"></div>
        <div className="sk-payments-order__form-wrapper">
          <div className="sk-payments-order__field sk-payments-order__field--number" />

          <div className="sk-payments-order__fields-wrapper">
            <div className="sk-payments-order__field sk-payments-order__field--expiry" />

            <div className="sk-payments-order__field sk-payments-order__field--cvc" />
          </div>

          <div className="sk-payments-order__form-btn"></div>
        </div>
      </div>
    </div>
  );
}
