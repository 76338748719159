export default function KeyInCircleIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.9 7.28a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm1.14 0a5.35 5.35 0 1 0 10.7 0 5.35 5.35 0 0 0-10.7 0Zm5.53-3.44H9.8l1.03 1.04V7.1L9.8 8.14H7.82a.43.43 0 0 0-.3.13l-2.46 2.45h-1.1v-1.1L6.4 7.15a.43.43 0 0 0 .12-.3V4.87l1.04-1.04Zm2.4 1.72a.86.86 0 1 0-1.72 0 .86.86 0 0 0 1.72 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
