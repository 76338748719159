import { StrategyStatus } from "../../../const";
import { StrategyOverviewType } from "../../../types/details";
import { formatStrategy } from "../../../utils/amount";
import { showDateWithShortYear } from "../../../utils/datetime";
import {
  addBoardColorSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";

type DetailsFromStartProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyOverviewType;
};

export default function DetailsFromStart({
  strategyStatus,
  isTabletAndMore,
  overview,
}: DetailsFromStartProps): JSX.Element {
  const amount = overview.profit_from_start;
  const percent = overview.profit_from_start_percent;
  const timestamp = overview.start_timestamp;
  const currency = overview.currency;
  const numberForChecking =
    strategyStatus === StrategyStatus.NotStarted ? percent : amount;

  return amount !== null &&
    timestamp &&
    percent !== null &&
    numberForChecking !== null ? (
    <div
      className={`details-board__total details-board__total--from-start ${
        formatStrategy(numberForChecking).amount >= 0
          ? "details-board__total--up"
          : "details-board__total--down"
      }`}
    >
      <p className="details-board__amount">
        {addBoardColorSign(
          isTabletAndMore,
          strategyStatus,
          formatStrategy(amount).amount,
          formatStrategy(percent).amount
        )}
        <span className="details-board__number">
          {displayStrategyAmount(
            "from-start",
            strategyStatus,
            isTabletAndMore,
            amount,
            currency,
            percent
          )}
        </span>
      </p>
      <span className="details-board__text">{`Since ${showDateWithShortYear(
        timestamp
      )}`}</span>
    </div>
  ) : (
    <></>
  );
}
