import { SyntheticEvent, useRef } from "react";
import { NavLink } from "react-router-dom";

import NavExtra from "../nav-extra/nav-extra";
import OverviewIcon from "../../icons/nav/overview-icon";
import AccountsIcon from "../../../icons/nav/accounts-icon";
import CreditCardIcon from "../../icons/nav/credit-card-icon";
import TriangleUpIcon from "../../icons/triangle/triangle-up-icon";
import TriangleDownIcon from "../../icons/triangle/triangle-down-icon";
import NavReferralsItem from "./nav-referrals-item";
import AnimatedNavNotifications from "./animated-nav-notifications";
import AnimatedNavAccounts from "./animated-nav-accounts";
import { setNavAccountsOpened } from "../../../store/actions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { AuthStatus, AppRoute } from "../../../const";
import { setNavOpened } from "../../../store/actions/interface";

export default function Nav(): JSX.Element {
  const dispatch = useAppDispatch();

  const accountsLinkRef = useRef<HTMLAnchorElement | null>(null);
  const hintReferralsRef = useRef<HTMLDivElement | null>(null);

  const {
    messages,
    userProfile,
    navAccounts,
    isNavAccountsLoading,
    isNavAccountsOpened,
    isUserProfileLoading,
    authStatus,
    isNavOpened,
  } = useAppSelector((state) => state);

  const isAffiliate = userProfile?.isAffiliate as boolean;
  const isAuth = authStatus === AuthStatus.Auth;

  const handleNavListClick = (evt: SyntheticEvent) => {
    const target = evt.target as Element;
    if (
      accountsLinkRef?.current?.contains(target as Element) ||
      target.className === "nav__subitem" ||
      hintReferralsRef?.current?.contains(evt.target as Element)
    ) {
      return;
    }
    dispatch(setNavOpened(false));
  };

  const handleAccountsClick = (evt: SyntheticEvent) => {
    evt.preventDefault();
    dispatch(setNavAccountsOpened(!isNavAccountsOpened));
  };

  return (
    <nav
      className={`page-header__nav nav ${
        isNavOpened ? "nav--opened" : "nav--closed"
      }`}
    >
      <NavExtra type="nav__nav-extra" />
      <ul className="nav__list" onClick={handleNavListClick}>
        <li className="nav__item">
          <NavLink className="nav__link" to={AppRoute.Overview} end>
            <div className="nav__icon-wrapper">
              <span className="nav__icon nav__icon--overview">
                <OverviewIcon />
              </span>
            </div>
            <span className="nav__link-text">Overview</span>
          </NavLink>
        </li>
        <li className="nav__item">
          <a
            className="nav__link"
            href="!#"
            ref={accountsLinkRef}
            onClick={handleAccountsClick}
          >
            <div className="nav__icon-wrapper">
              <span className="nav__icon nav__icon--accounts">
                <AccountsIcon/>
              </span>
            </div>
            <span className="nav__link-text">Accounts</span>

            <span className="nav__icon nav__icon--arrow">
              {isNavAccountsOpened ? <TriangleUpIcon /> : <TriangleDownIcon />}
            </span>
          </a>
          <AnimatedNavAccounts
            navAccounts={navAccounts}
            isNavAccountsLoading={isNavAccountsLoading}
            isNavAccountsOpened={isNavAccountsOpened}
          />
        </li>

        <NavReferralsItem
          messages={messages}
          authStatus={authStatus}
          isAffiliate={isAffiliate}
          isUserProfileLoading={isUserProfileLoading}
          ref={hintReferralsRef}
        />

        <li className="nav__item">
          <NavLink className="nav__link" to={AppRoute.Payments}>
            <div className="nav__icon-wrapper">
              <span className="nav__icon nav__icon--payments">
                <CreditCardIcon />
              </span>
            </div>
            <span className="nav__link-text">Payments</span>
            {isAuth && messages && (
              <AnimatedNavNotifications messagesNumber={messages.payments} />
            )}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
