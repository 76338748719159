import { AnimatePresence, motion } from "framer-motion";

import { checkNotificationsAmount } from "../../../utils/general";

type AnimatedNavNotificationsProps = {
  messagesNumber: null | number;
};

export default function AnimatedNavNotifications({
  messagesNumber,
}: AnimatedNavNotificationsProps): JSX.Element {
  return (
    <AnimatePresence initial={false}>
      {messagesNumber !== null && messagesNumber > 0 && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <span className="nav__notifications-amount">
            {checkNotificationsAmount(messagesNumber)}
          </span>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
