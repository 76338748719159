import { Outlet, useLocation } from "react-router-dom";

import PageHeader from "../header/page-header/page-header";
import Footer from "../../components/footer/footer";
import { AppRoute } from "../../const";
import ShortHeader from "../header/short-header/short-header";

export default function Layout(): JSX.Element {
  const { pathname } = useLocation();

  if (pathname.includes(AppRoute.Coins)) {
    return (
      <div className="page page--landing">
        <div className="page__cointainer page__cointainer--landing">
          <ShortHeader />
          <Outlet />
          <Footer />
        </div>
      </div>
    );
  }

  return (
    <div className="page">
      <div className="page__cointainer">
        <PageHeader />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}
