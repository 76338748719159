export default function PersonsRoundIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 31 30"
    >
      <path
        fill="currentColor"
        d="M22.33 6.86c-.2.08-.33.3-.33.51 0 .2.15.37.9.95a11.1 11.1 0 0 1 4 7.88c.04.44.1.81.14.88.18.28.6.31.84.06.1-.11.12-.2.12-.67a12.2 12.2 0 0 0-2.76-7.34c-.68-.8-2.06-2.05-2.5-2.24-.22-.1-.24-.1-.41-.03ZM7.6 7.59a9.14 9.14 0 0 0-2.06 1.93A11.81 11.81 0 0 0 3 16.48c0 .46.02.55.12.66.15.16.37.22.57.13.26-.1.32-.26.36-1.1a10.5 10.5 0 0 1 3.26-7.01c.96-.96 1.02-1.05.88-1.3-.13-.26-.32-.34-.6-.27ZM3.92 20.06c-.46.1-.88.35-1.23.73a2.5 2.5 0 1 0 1.23-.72ZM14.92.06c-.46.1-.88.35-1.23.73a2.5 2.5 0 1 0 1.23-.72ZM25.92 20.06c-.46.1-.88.35-1.23.73a2.5 2.5 0 1 0 1.23-.72ZM11.4 27.23c-.17.2-.2.32-.07.54.1.2.25.25 1.07.45.97.24 1.87.34 2.99.34 1.25 0 2.1-.1 3.22-.4.66-.18.75-.22.84-.42.07-.15.07-.21 0-.38-.15-.33-.3-.35-1.15-.15-1.08.26-1.81.35-2.84.35a11 11 0 0 1-3.16-.4c-.55-.15-.72-.14-.9.07Z"
      />
      <path
        fill="currentColor"
        d="M0 29a4 4 0 0 1 4-4h1a4 4 0 0 1 4 4 1 1 0 0 1-1 1H1a1 1 0 0 1-1-1ZM11 9a4 4 0 0 1 4-4h1a4 4 0 0 1 4 4 1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1ZM22 29a4 4 0 0 1 4-4h1a4 4 0 0 1 4 4 1 1 0 0 1-1 1h-7a1 1 0 0 1-1-1Z"
      />
    </svg>
  );
}
