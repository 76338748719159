export default function BlueDollarIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2941 12.7191C11.0824 12.1119 9.79421 11.6493 8.51874 11.1693C7.77896 10.8917 7.07108 10.5679 6.4461 10.1168C5.21527 9.22624 5.45123 7.78052 6.89251 7.20802C7.30066 7.0461 7.72795 6.99405 8.16161 6.97092C9.83247 6.88996 11.4204 7.16754 12.9319 7.82678C13.6844 8.15641 13.9331 8.05232 14.1882 7.34102C14.456 6.58925 14.6793 5.82591 14.928 5.06836C15.0938 4.55947 14.8897 4.22406 14.3604 4.01009C13.391 3.62264 12.3962 3.34507 11.3503 3.19471C9.98553 3.00388 9.98553 2.99809 9.97915 1.75478C9.97277 0.0025702 9.97277 0.00257016 8.03406 0.00257016C7.75345 0.00257016 7.47285 -0.0032127 7.19225 0.00257016C6.28666 0.0257016 6.13361 0.170273 6.1081 0.997223C6.09534 1.36733 6.1081 1.73743 6.10172 2.11332C6.09534 3.21206 6.08897 3.19471 4.92829 3.57638C2.12225 4.50164 0.387616 6.2365 0.202673 9.01227C0.0368621 11.47 1.45263 13.1297 3.67833 14.3383C5.04946 15.0843 6.56727 15.5238 8.0213 16.1078C8.58889 16.3334 9.13096 16.5936 9.60289 16.9521C10.9995 17.9988 10.7444 19.7395 9.08632 20.3987C8.19987 20.7515 7.2624 20.8382 6.29942 20.7284C4.8135 20.5607 3.39135 20.2079 2.0521 19.5776C1.26769 19.2075 1.03811 19.3058 0.770257 20.0749C0.540672 20.7399 0.336598 21.4107 0.132522 22.0815C-0.141704 22.9837 -0.0396667 23.1976 0.910558 23.6198C2.12225 24.1518 3.41686 24.4236 4.73697 24.6144C5.7701 24.7648 5.80199 24.8053 5.81474 25.7768C5.82112 26.2163 5.82112 26.6616 5.82749 27.1011C5.83387 27.6562 6.12723 27.9801 6.75859 27.9916C7.47285 28.0032 8.19349 28.0032 8.90775 27.9858C9.49447 27.9743 9.79421 27.6851 9.79421 27.1473C9.79421 26.5459 9.82609 25.9387 9.80058 25.3373C9.7687 24.7243 10.0621 24.412 10.7125 24.2501C12.2112 23.88 13.4867 23.1514 14.4688 22.0642C17.1983 19.0571 16.1588 14.6563 12.2941 12.7191Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09119 11.3319C10.2016 11.7559 11.314 12.1807 12.3675 12.7191C16.1566 14.6563 17.1757 19.0571 14.4996 22.0642C13.5367 23.1514 12.2862 23.88 10.8169 24.2501C10.1791 24.412 9.89147 24.7243 9.92273 25.3373C9.93882 25.7241 9.93162 26.1134 9.92444 26.5019C9.92046 26.7174 9.91648 26.9328 9.91648 27.1473C9.91648 27.6851 9.62261 27.9743 9.04737 27.9858C8.75142 27.9932 8.45436 27.9974 8.15713 27.999V20.6988C8.51917 20.6384 8.87481 20.5398 9.22244 20.3987C10.8481 19.7395 11.0982 17.9988 9.7289 16.9521C9.26621 16.5936 8.73474 16.3334 8.17826 16.1078C8.17122 16.105 8.16417 16.1021 8.15713 16.0992V10.9634C8.32455 11.0354 8.49438 11.1036 8.66596 11.1693C8.80758 11.2236 8.94937 11.2778 9.09119 11.3319ZM8.15713 6.98108C8.20991 6.97714 8.26281 6.9738 8.31582 6.97092C9.95399 6.88996 11.5109 7.16754 12.9928 7.82678C13.7306 8.15641 13.9744 8.05232 14.2245 7.34102C14.3922 6.86097 14.542 6.37621 14.692 5.89124C14.7768 5.61674 14.8617 5.34217 14.9498 5.06836C15.1124 4.55947 14.9123 4.22406 14.3933 4.01009C13.4429 3.62264 12.4675 3.34507 11.4421 3.19471C10.1041 3.00388 10.1041 2.99809 10.0978 1.75486L10.0978 1.75478C10.0948 0.902464 10.0932 0.464735 9.87363 0.239927C9.64181 0.00257017 9.16697 0.00257016 8.19077 0.00257016C8.17955 0.00257016 8.16834 0.00256056 8.15713 0.00254252V6.98108ZM7.67082 2.24972e-05C7.65099 -6.40648e-06 7.63116 -8.57214e-06 7.61133 2.24972e-05H7.67082Z"
        fill="#2563EB"
      />
    </svg>
  );
}
