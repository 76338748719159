import { showFullDate, showTime } from "../../utils/datetime";

type TableDateProps = {
  date: number;
  isNotifications?: boolean;
};

export default function TableDate({
  date,
  isNotifications,
}: TableDateProps): JSX.Element {
  return isNotifications ? (
    <div className="table-date table-date--notifications">
      <p className="table-date-full table-date-full--notifications">
        {showFullDate(date)}
      </p>
      <span>at</span>
      <p className="table-date-time table-date-time--notifications">
        {showTime(date)}
      </p>
    </div>
  ) : (
    <div className="table-date">
      <p className="table-date-full">{showFullDate(date)}</p>
      <p className="table-date-time">{showTime(date)}</p>
    </div>
  );
}
