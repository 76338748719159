export default function AppleIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3188 10.9248C14.3091 9.28541 15.0606 8.04988 16.5782 7.13886C15.7294 5.93698 14.4453 5.27595 12.7526 5.14855C11.1499 5.02356 9.39637 6.07159 8.75431 6.07159C8.07577 6.07159 6.52413 5.19182 5.30324 5.19182C2.78364 5.23028 0.105957 7.17732 0.105957 11.1387C0.105957 12.3093 0.322409 13.5184 0.755313 14.7636C1.33414 16.4029 3.42084 20.4196 5.59752 20.3547C6.73571 20.3282 7.54072 19.5566 9.02184 19.5566C10.4592 19.5566 11.2034 20.3547 12.4729 20.3547C14.669 20.3234 16.5563 16.6721 17.106 15.028C14.1607 13.6554 14.3188 11.0089 14.3188 10.9248ZM11.7628 3.59332C12.9958 2.14626 12.8839 0.829008 12.8474 0.355469C11.7579 0.417966 10.4981 1.08861 9.78063 1.9131C8.99022 2.79768 8.5257 3.89139 8.62541 5.12451C9.80252 5.21345 10.8775 4.61492 11.7628 3.59332Z"
        fill="currentColor"
      />
    </svg>
  );
}
