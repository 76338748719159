import { REDIRECT_ACCOUNT_NAME } from "../const";

type RedirectAccountType = string;

const saveRedirectAccount = (account: RedirectAccountType): void => {
  sessionStorage.setItem(REDIRECT_ACCOUNT_NAME, account);
};

const getRedirectAccount = (): RedirectAccountType => {
  const account = sessionStorage.getItem(REDIRECT_ACCOUNT_NAME);
  return account ?? "";
};

const dropRedirectAccount = (): void => {
  sessionStorage.removeItem(REDIRECT_ACCOUNT_NAME);
};

export { saveRedirectAccount, getRedirectAccount, dropRedirectAccount };
