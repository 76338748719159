export default function BlueBigGearsIcon(): JSX.Element {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3345_5319)">
        <path
          d="M32.6505 9.12539C32.6505 8.93614 32.6505 8.72581 32.6301 8.53629L35 7.12723L31.9355 1.6586L29.5451 3.08902C29.2184 2.8576 28.9119 2.66836 28.5645 2.49993V-0.409668H22.4355V2.49993C22.0881 2.66808 21.7816 2.8576 21.4549 3.08875L19.0645 1.6586L16 7.12723L18.3699 8.53629C18.3495 8.72581 18.3495 8.93587 18.3495 9.12539C18.3495 9.31463 18.3495 9.52496 18.3699 9.71421L16 11.1235L19.0645 16.5922L21.4549 15.162C21.7816 15.3932 22.0881 15.5827 22.4355 15.7508V18.5903H28.5645V15.7508C28.9117 15.5827 29.2181 15.3932 29.5451 15.162L31.9355 16.5922L35 11.1235L32.6301 9.71448C32.6505 9.52496 32.6505 9.31463 32.6505 9.12539ZM25.5 12.2804C23.8041 12.2804 22.4355 10.8713 22.4355 9.12539C22.4355 7.37947 23.8041 5.97041 25.5 5.97041C27.1959 5.97041 28.5645 7.37947 28.5645 9.12539C28.5645 10.8713 27.1956 12.2804 25.5 12.2804Z"
          fill="#60A5FA"
        />
        <path
          d="M20.892 23.0556C20.892 22.5135 20.8292 21.9506 20.7042 21.3461L23 20.0329L19.8693 14.613L17.5942 15.9264C16.7178 15.1966 15.7161 14.6339 14.6307 14.2379V11.5903H8.36934V14.2379C7.28394 14.6339 6.28224 15.1966 5.40582 15.9264L3.13066 14.613L0 20.0329L2.29609 21.3461C2.17081 21.9506 2.10803 22.5135 2.10803 23.0556C2.10803 23.5976 2.17081 24.1606 2.29609 24.7651L0 26.0782L3.13066 31.4982L5.40555 30.1847C6.28224 30.9146 7.28394 31.4773 8.36934 31.8733V34.5903H14.6307V31.8733C15.7161 31.4773 16.7178 30.9146 17.5942 30.1847L19.8693 31.4982L23 26.0782L20.7039 24.7651C20.8292 24.1606 20.892 23.5976 20.892 23.0556ZM11.5 28.2671C8.6199 28.2671 6.28224 25.9322 6.28224 23.0556C6.28224 20.179 8.6199 17.8441 11.5 17.8441C14.3801 17.8441 16.7178 20.179 16.7178 23.0556C16.7178 25.9322 14.3801 28.2671 11.5 28.2671Z"
          fill="#60A5FA"
        />
        <path
          d="M32.5053 9.71448L35 11.1235L31.7742 16.5922L29.258 15.1618C28.9141 15.3932 28.5912 15.5824 28.2258 15.7508V18.5903H25V12.2804C26.7851 12.2804 28.2258 10.8713 28.2258 9.12539C28.2258 7.37947 26.7851 5.97041 25 5.97041V-0.409668H28.2258V2.49993C28.5912 2.66808 28.9141 2.8576 29.258 3.08875L31.7742 1.6586L35 7.12723L32.5053 8.53629C32.5269 8.72581 32.5269 8.93587 32.5269 9.12539C32.5269 9.31463 32.5269 9.52496 32.5053 9.71448Z"
          fill="#2563EB"
        />
        <path
          d="M20.804 24.7651L23 26.0782L20.0055 31.4982L17.8292 30.1847C16.9909 30.9146 16.0328 31.4773 14.9945 31.8733V34.5903H12V28.2671C14.7549 28.2671 16.9909 25.9322 16.9909 23.0556C16.9909 20.179 14.7549 17.8441 12 17.8441V11.5903H14.9945V14.2379C16.0328 14.6339 16.9909 15.1966 17.8292 15.9264L20.0055 14.613L23 20.0329L20.804 21.3461C20.9236 21.9506 20.9836 22.5135 20.9836 23.0556C20.9836 23.5976 20.9236 24.1606 20.804 24.7651Z"
          fill="#2563EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_3345_5319">
          <rect width="35" height="35" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
