import SkeletonChartIcon from "../icons/skeleton-chart-icon";

export default function CalcCanvasSkeleton(): JSX.Element {
  return (
    <div className="sk-calc-canvas animate-pulse">
      <div className="sk-calc-canvas__wrapper">
        <div className="sk-calc-canvas__heading"></div>
        <div className="sk-calc-canvas__top">
          <div className="sk-calc-canvas__total">
            <div className="sk-calc-canvas__amount"></div>
            <div className="sk-calc-canvas__amount"></div>
          </div>
          <div className="sk-calc-canvas__toggles">
            <div className="sk-calc-canvas__toggle">
              <div className="sk-calc-canvas__toggle-name"></div>
              <div className="sk-calc-canvas__toggle-bar"></div>
            </div>
            <div className="sk-calc-canvas__toggle">
              <div className="sk-calc-canvas__toggle-name"></div>
              <div className="sk-calc-canvas__toggle-bar"></div>
            </div>
          </div>
        </div>

        <div className="sk-calc-canvas__chart-wrapper">
          <div className="sk-calc-canvas__chart">
            <SkeletonChartIcon />
          </div>
          <div className="sk-calc-canvas__bottom"></div>
        </div>
      </div>
    </div>
  );
}
