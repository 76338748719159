import FaqItem from "./faq-item";
import { mainFaqData } from "./faq-data/main-faq-data";

export default function MainFaq(): JSX.Element {
  return (
    <div className="landing__item faq faq--landing">
      <h2 className="landing__title  landing__title--faq">
        Frequently Asked Questions
      </h2>
      <ul className="faq__inner-list">
        {mainFaqData.map((faq) => {
          return (
            <FaqItem
              question={faq.question}
              answer={<p className="faq-item__paragraph">{faq.answer}</p>}
            />
          );
        })}
      </ul>
    </div>
  );
}
