import { AuthStatus } from "../../const";
import { useAppSelector } from "../../hooks";
import { CoinType } from "../../types/coins";

type CoinHeadingProps = {
  coin: CoinType;
};

export default function CoinHeading({ coin }: CoinHeadingProps): JSX.Element {
  const { authStatus } = useAppSelector((state) => state);
  const isAuth = authStatus === AuthStatus.Auth;

  return (
    <h1 className={`coin__heading ${isAuth ? "coin__heading--auth" : ""}`}>
      <img
        className="coin__logo"
        width={24}
        height={24}
        src={coin.logo_url}
        alt={`${coin.title} logo`}
      />
      <span className="coin__heading-text shadow shadow--coin-heading">{`${coin.title} (${coin.ticker})`}</span>
    </h1>
  );
}
