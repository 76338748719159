export default function BitgetIcon(): JSX.Element {
  return (
      <svg width="100%" height="100%" viewBox="0 0 63 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M44.9027 16.6891V0.5H48.158V16.6891H44.9027Z" fill="#F7A600"/>
          <path
              d="M6.97547 21.4974H0V5.30834H6.69488C9.9501 5.30834 11.844 7.08259 11.844 9.85697C11.8446 10.5505 11.6522 11.2305 11.2883 11.8209C10.9245 12.4113 10.4036 12.8889 9.78384 13.2002C10.7892 13.6549 12.0778 14.6784 12.0778 16.8371C12.0804 19.8608 9.95011 21.4974 6.97547 21.4974ZM6.43769 8.12948H3.25522V11.8572H6.44029C7.8224 11.8572 8.59398 11.1065 8.59398 9.99205C8.59398 8.87762 7.81979 8.12948 6.43769 8.12948ZM6.64812 14.7017H3.25522V18.6815H6.65072C8.12635 18.6815 8.8278 17.7723 8.8278 16.6812C8.8278 15.5902 8.11856 14.6991 6.64812 14.6991V14.7017Z"
              fill="#15192A"/>
          <path
              d="M22.0045 14.8576V21.4974H18.7701V14.8576L13.7613 5.30834H17.2971L20.4146 11.8338L23.4802 5.30834H27.0159L22.0045 14.8576Z"
              fill="#15192A"/>
          <path
              d="M36.2412 21.4974H29.2632V5.30834H35.9581C39.2133 5.30834 41.1072 7.08259 41.1072 9.85697C41.1077 10.5505 40.9153 11.2305 40.5515 11.8209C40.1877 12.4113 39.6668 12.8889 39.047 13.2002C40.0524 13.6549 41.341 14.6784 41.341 16.8371C41.3436 19.8608 39.2133 21.4974 36.2412 21.4974ZM35.7035 8.12948H32.5184V11.8572H35.7035C37.0856 11.8572 37.8572 11.1065 37.8572 9.99205C37.8572 8.87762 37.083 8.12948 35.7009 8.12948H35.7035ZM35.9139 14.7017H32.5184V18.6815H35.9165C37.3921 18.6815 38.0936 17.7723 38.0936 16.6812C38.0936 15.5902 37.3869 14.6991 35.9165 14.6991L35.9139 14.7017Z"
              fill="#15192A"/>
          <path d="M58.6459 8.12948V21.5H55.401V8.12948H51.0365V5.30834H63V8.12948H58.6459Z" fill="#15192A"/>
      </svg>

  );
}


