export default function WalletIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.23077C0 1.44646 1.41777 0 3.16667 0H15.8333C17.5822 0 19 1.44646 19 3.23077V10.7692C19 12.5535 17.5822 14 15.8333 14H3.16667C1.41777 14 0 12.5535 0 10.7692V3.23077ZM15.8333 1.5L3.16667 1.5C2.29222 1.5 1.5 2.33862 1.5 3.23077V3.5V5V9V10.5V10.7692C1.5 11.6614 2.28961 12.5 3.16406 12.5L15.8333 12.5C16.7078 12.5 17.5 11.6614 17.5 10.7692V3.23077C17.5 2.5 16.7078 1.5 15.8333 1.5Z"
        fill="currentColor"
      />
      <path
        d="M8 8.35C8 8.64738 7.68148 9 7.39 9H1.5V10.5H7.5C8.66593 10.5 9.5 9.68954 9.5 8.5V5.65385C9.5 4.46431 8.66593 3.5 7.5 3.5H1.5V5H7.39C7.68148 5 8 5.35262 8 5.65V8.35Z"
        fill="currentColor"
      />
      <path
        d="M6.5 7C6.5 7.69036 5.94036 8.25 5.25 8.25C4.55964 8.25 4 7.69036 4 7C4 6.30964 4.55964 5.75 5.25 5.75C5.94036 5.75 6.5 6.30964 6.5 7Z"
        fill="currentColor"
      />
    </svg>
  );
}
