import BlueDollarIcon from "../../icons/blue/blue-dollar";
import ExchangeIcon from "../../icons/blue/exchange-icon";
import GoodInStarIcon from "../../icons/blue/good-in-star-icon";
import BlueClockIcon from "../icons/blue-icon/blue-clock-icon";
import PlusItem from "./plus-item";
import BlueTrendingUpIcon from "../../icons/blue/blue-trending-up-icon";

export default function WhatIsSmardPluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">What is SMARD?</h2>
      <ul className="pluses pluses--what-is-smard pluses--tablet-3-columns">
        {/* для  2 колонок в планшете */}
        {/* <ul className="pluses pluses--what-is-smard pluses--tablet-2-columns pluses--desktop-3-columns"> */}
        <PlusItem
          type="easy"
          icon={<GoodInStarIcon />}
          title="Easy to use"
          description={
            <p className="pluses__info">
              Strategies are fully automated and clients do not need to
              understand trading or how to set up a bot manually.
            </p>
          }
        />

        <PlusItem
          type="no-payments"
          icon={<BlueDollarIcon />}
          title="No upfront payments"
          description={
            <p className="pluses__info">
              Your referrals do not need to make any upfront payments or
              transfer money to a 3rd party.
            </p>
          }
        />

        <PlusItem
          type="long"
          icon={<BlueClockIcon />}
          title="Long term"
          description={
            <p className="pluses__info">
              SMARD is designed for long-term investments, which allows you to
              receive payments from each referral for a long time.
            </p>
          }
        />

        <PlusItem
          type="support"
          icon={<ExchangeIcon />}
          title="Support multiple exchanges"
          description={
            <p className="pluses__info">
              We work for a global audience of crypto-enthusiasts by supporting
              various exchanges.
            </p>
          }
        />
        <PlusItem
          type="effect"
          icon={<BlueTrendingUpIcon />}
          title="Momentum effect"
          description={
            <p className="pluses__info">
              Our algorithm utilizes the momentum effect, supported by
              fundamental literature, as well as a number of proven techniques
              to improve trading efficiency, allowing for consistent
              outperformance of the market over the long run.
            </p>
          }
        />
      </ul>
    </section>
  );
}
