import { PricingSliderTypes } from "./pricing-slider";

type PricingSliderAmountProps = {
  valueToDisplay: string;
  type: PricingSliderTypes;
  isLoss: boolean;
};

function PricingSliderAmount({
  valueToDisplay,
  type,
  isLoss,
}: PricingSliderAmountProps) {
  const chooseHeading = () => {
    if (type === PricingSliderTypes.AMOUNT && isLoss) {
      return "Loss";
    }
    if (type === PricingSliderTypes.AMOUNT && !isLoss) {
      return "Profit";
    }
    if (type === PricingSliderTypes.FEES && isLoss) {
      return "Access fee";
    }
    if (type === PricingSliderTypes.FEES && !isLoss) {
      return "Service fee";
    }
  };

  const chooseColor = () => {
    if (isLoss && type === PricingSliderTypes.AMOUNT) {
      return "#f93232";
    }
    if (!isLoss && type === PricingSliderTypes.AMOUNT) {
      return "#2bbc8b";
    }
    return "#3B82F6";
  };

  return (
    <div className="pricing__slider-wrapper--amount">
      <p
        className="pricing__amount-heading"
        style={{
          color: chooseColor(),
        }}
      >
        {chooseHeading()}
      </p>
      <p
        className="pricing__amount"
        style={{
          color: chooseColor(),
        }}
      >
        {valueToDisplay}
      </p>
    </div>
  );
}

export default PricingSliderAmount;
