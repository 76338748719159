export default function CoinBoardSkeleton(): JSX.Element {
  return (
    <div className="sk-coin-board animate-pulse">
      <div className="sk-coin-board__block-price">
        <div className="sk-coin-board__title"></div>
        <div className="sk-coin-board__amount"></div>
        <div className="sk-coin-board__percent"></div>
      </div>
      <div className="sk-coin-board__block">
        <div className="sk-coin-board__item sk-coin-board__item--price">
          <div className="sk-coin-board__title"></div>
          <div className="sk-coin-board__amount"></div>
          <div className="sk-coin-board__percent"></div>
        </div>
        <div className="sk-coin-board__item">
          <div className="sk-coin-board__title"></div>
          <div className="sk-coin-board__amount"></div>
        </div>
        <div className="sk-coin-board__item">
          <div className="sk-coin-board__title"></div>
          <div className="sk-coin-board__amount"></div>
          <div className="sk-coin-board__percent"></div>
        </div>
        <div className="sk-coin-board__item">
          <div className="sk-coin-board__title"></div>
          <div className="sk-coin-board__amount"></div>
        </div>
      </div>
    </div>
  );
}
