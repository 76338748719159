import { useState } from "react";
import DemoResults from "../demo-results/demo-results";
import DemoCanvas from "../canvases/demo-canvas";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import { AccountName } from "../../const";

type GeneralPromoInsightProps = {
  isMobile: boolean;
  results: StrategyResultsType;
  quotes: QuotesItemsType;
  type: AccountName;
};

export default function GeneralPromoInsight({
  isMobile,
  quotes,
  results,
  type,
}: GeneralPromoInsightProps): JSX.Element {
  const [isBitcoinMode, setBitcoinMode] = useState<boolean>(true);

  return (
    <section className="demo-insight">
      <div className="demo-insight__wrapper">
        {quotes && (
          <DemoCanvas
            type={type}
            data={quotes}
            isBitcoin={isBitcoinMode}
            handleBitcoinMode={setBitcoinMode}
          />
        )}
        {results && (
          <DemoResults
            isMobile={isMobile}
            results={results}
            isBitcoin={isBitcoinMode}
          />
        )}
      </div>
    </section>
  );
}
