import { AccountName } from "../../../const";
import ListIcon from "../../icons/list-icon";

type DetailsLinkProps = { accountName: string };

export default function DetailsLink({
  accountName,
}: DetailsLinkProps): JSX.Element {
  const isFlexibleMomentum =
    accountName === AccountName.BinanceFutures ||
    accountName === AccountName.Okx ||
    accountName === AccountName.Bybit ||
    accountName === AccountName.BitgetFutures;

  const pdfLink = isFlexibleMomentum
    ? `${process.env.PUBLIC_URL}/documents/SMARD_Futures_Flexible_Momentum.pdf`
    : `${process.env.PUBLIC_URL}/documents/SMARD_Spot_Time_Series_Momentum.pdf`;

  return (
    <a
      className="detail-board__link link"
      href={pdfLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="detail-board__link-icon detail-board__link-icon--text">
        <ListIcon />
      </span>
      Read whitepaper
    </a>
  );
}
