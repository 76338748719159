export default function TransparentDoubleDiskIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="263"
      height="263"
      fill="none"
      viewBox="0 0 263 263"
    >
      <path
        fill="#2563EB"
        fillOpacity=".05"
        fillRule="evenodd"
        d="M132 177.33c24.81 0 44.93-20.3 44.93-45.33 0-25.04-20.12-45.33-44.93-45.33S87.07 106.97 87.07 132c0 25.04 20.12 45.33 44.93 45.33Zm0 66.67c61.3 0 111-50.14 111-112S193.3 20 132 20 21 70.14 21 132s49.7 112 111 112Z"
        clipRule="evenodd"
      />
      <path
        fill="#2563EB"
        fillOpacity=".05"
        fillRule="evenodd"
        d="M131.5 184.73a53.23 53.23 0 1 0 0-106.46 53.23 53.23 0 0 0 0 106.46Zm0 78.27c72.63 0 131.5-58.88 131.5-131.5C263 58.87 204.12 0 131.5 0 58.87 0 0 58.87 0 131.5S58.87 263 131.5 263Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
