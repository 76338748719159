import Hint from "../hint/hint";
import TableDate from "../table-items/table-date";
import PersonIcon from "../../icons/person/person-icon";
import PersonActiveIcon from "../../icons/person/person-active-icon";
import MinusIcon from "../../icons/sign/minus-icon";
import { AuthStatus, HINTS_TEXT } from "../../const";
import { ReferralsItemType } from "../../types/referrals";
import { localInvoiceAmount } from "../../utils/amount";

type ReferralsClientsProps = {
  authStatus: string;
  isAffiliate: boolean;
  referralsList: ReferralsItemType[];
};

export default function ReferralsClients({
  isAffiliate,
  referralsList,
  authStatus,
}: ReferralsClientsProps): JSX.Element {
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const hint = (
    <Hint
      block="referrals-clients"
      type="block__hint"
      id="referrals/clients"
      hintText={HINTS_TEXT["referrals/clients"]}
    />
  );

  const titleRow = (
    <li className="referrals-clients__row">
      <p className="referrals-clients__title referrals-clients__title--name">
        Name
      </p>
      <p className="referrals-clients__title referrals-clients__title--date">
        Date joined
      </p>
      <p className="referrals-clients__title referrals-clients__title--amount">
        Total earnings
      </p>
      <p className="referrals-clients__title referrals-clients__title--last-month">
        Last 30 days earnings
      </p>
    </li>
  );

  const showAmount = (amount: number) =>
    `$${localInvoiceAmount(Math.abs(amount))}`;

  const checkSignIcon = (amount: number) => {
    const formatted = amount;

    if (formatted >= 0) {
      return "";
    }

    return (
      <span className="referrals-clients__amount-icon referrals-clients__amount-icon--minus">
        <MinusIcon />
      </span>
    );
  };

  return (
    <section className="referrals-clients block">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">
          {isAffiliate ? "Affiliate sign-ups" : "Referred clients"}
        </h2>
        {isNoAuth && hint}
      </div>

      {referralsList?.length ? (
        <ul className="referrals-clients__table">
          {titleRow}
          {referralsList
          .slice()
          .sort((a, b) => b.amount - a.amount)
          .map((item, index) => {
            return (
              <li className="referrals-clients__row" key={index}>
                {/* NAME */}
                <div className="referrals-clients__name shadow shadow--name">
                  {item.is_active ? (
                    <span className="referrals-clients__icon referrals-clients__icon--active">
                      <PersonActiveIcon isActive />
                    </span>
                  ) : (
                    <span className="referrals-clients__icon">
                      <PersonIcon />
                    </span>
                  )}
                  <p className="referrals-clients__text">{item.name}</p>
                </div>

                {/* DATE */}
                <TableDate date={item.date_joined} />

                {/* TOTAL EARNINGS */}
                <div className="referrals-clients__amount">
                  {checkSignIcon(item.amount)}
                  <p className="referrals-clients__absolute">
                    {showAmount(item.amount)}
                  </p>
                </div>

                {/* LAST 30 DAYS */}
                <div className="referrals-clients__last-month">
                  {checkSignIcon(item.last_30_days_amount)}
                  <p className="referrals-clients__absolute">
                    {showAmount(item.last_30_days_amount)}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="table-note">
          {`No one has accepted your invite yet. You will see the list of ${
            isAffiliate ? "affiliate" : "referred"
          } users here.`}
        </p>
      )}
    </section>
  );
}
