export default function BagWithMoneyIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0V28Z"
        fill="#2563EB"
      />
      <path
        d="M0 14C0 21.732 6.26801 28 14 28V0C6.26801 0 0 6.26801 0 14Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 22.9997C15.3887 23.011 20.0608 22.688 20.0471 17.9132C20.0549 17.8635 20.0784 17.6856 20.0824 17.4497C19.9608 17.4622 19.8353 17.4704 19.7098 17.4704C18.7294 17.4704 17.7961 17.0608 17.102 16.3284C16.4078 15.6001 16.0235 14.6152 16.0235 13.5766C16.0235 12.6373 16.3412 11.7394 16.9176 11.0359C16.8908 11.0104 16.864 10.9844 16.837 10.9583L16.8369 10.9582L16.8368 10.9582C16.785 10.908 16.7326 10.8573 16.6784 10.8083C16.6196 10.8166 16.5608 10.8207 16.498 10.8207H14V22.9997ZM14 10.4028H16.498C16.7529 10.4028 16.9804 10.2911 17.149 10.1173C17.3137 9.94348 17.4157 9.69934 17.4157 9.43451C17.4157 9.16554 17.3137 8.92554 17.149 8.74761L17.1451 8.74347C16.9765 8.57381 16.749 8.46622 16.498 8.46622H14V10.4028ZM14 8.04415H16.3608V7.09656C16.3608 6.79449 16.2431 6.52138 16.0549 6.32276C15.8667 6.12414 15.6078 6 15.3216 6H14V8.04415ZM17.3843 11.1228C16.7882 11.7518 16.4196 12.6207 16.4196 13.5766C16.4196 14.5366 16.7882 15.4056 17.3843 16.0346C17.9804 16.6594 18.8 17.0484 19.7098 17.0484C20.6196 17.0484 21.4392 16.6594 22.0353 16.0346C22.6314 15.4056 23 14.5366 23 13.5766C23 12.6207 22.6314 11.7518 22.0353 11.1228C21.4392 10.4938 20.6196 10.109 19.7098 10.109C18.8 10.109 17.9804 10.4938 17.3843 11.1228Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.898 7.09656V8.04415H14V6H11.9373C11.651 6 11.3922 6.12414 11.2039 6.32276C11.0157 6.52138 10.898 6.79449 10.898 7.09656ZM14 10.8207V22.9997C13.7989 22.9981 13.6666 22.9895 13.6275 22.9863C13.3176 23.0112 7.19216 23.3794 7.21176 17.9091C7.20784 17.8966 6.90984 14.1766 10.5804 10.8083C10.6392 10.8166 10.698 10.8207 10.7569 10.8207H14ZM14 8.46622V10.4028H10.7569C10.5059 10.4028 10.2784 10.2911 10.1098 10.1173C9.9451 9.93934 9.83922 9.69934 9.83922 9.43451C9.83922 9.16968 9.94118 8.92968 10.1059 8.75174L10.1098 8.74761C10.2784 8.57381 10.5059 8.46622 10.7569 8.46622H14ZM8.55686 9.5214V12.4925C8.55686 12.6083 8.46667 12.7035 8.35686 12.7035H6.92157C6.81177 12.7035 6.72157 12.6083 6.72157 12.4925V9.5214H6.2C6.0902 9.5214 6 9.42623 6 9.31037C6 9.25658 6.01961 9.20692 6.05098 9.16968L7.48235 7.26622C7.55294 7.17518 7.67451 7.15863 7.76078 7.23311C7.77647 7.24553 7.79216 7.26208 7.80392 7.27863L9.23529 9.18209C9.30196 9.27313 9.29019 9.40554 9.20392 9.47589C9.16471 9.50485 9.12157 9.5214 9.07843 9.5214H8.55686Z"
        fill="#A0CBFF"
      />
      <g clipPath="url(#clip0_4979_9988)">
        <path
          d="M15.0628 16.6341C14.7328 16.4606 14.3818 16.3284 14.0344 16.1913C13.8329 16.1119 13.6401 16.0194 13.4698 15.8905C13.1345 15.6361 13.1988 15.223 13.5914 15.0595C13.7026 15.0132 13.819 14.9983 13.9371 14.9917C14.3923 14.9686 14.8248 15.0479 15.2365 15.2362C15.4415 15.3304 15.5093 15.3007 15.5788 15.0975C15.6517 14.8827 15.7125 14.6646 15.7803 14.4481C15.8255 14.3027 15.7699 14.2069 15.6257 14.1458C15.3616 14.0351 15.0906 13.9557 14.8057 13.9128C14.434 13.8583 14.434 13.8566 14.4322 13.5014C14.4305 13.0007 14.4305 13.0007 13.9024 13.0007C13.8259 13.0007 13.7495 12.9991 13.6731 13.0007C13.4264 13.0073 13.3847 13.0486 13.3777 13.2849C13.3743 13.3907 13.3777 13.4964 13.376 13.6038C13.3743 13.9177 13.3725 13.9128 13.0563 14.0218C12.292 14.2862 11.8195 14.7819 11.7691 15.575C11.7239 16.2772 12.1096 16.7514 12.7159 17.0967C13.0894 17.3098 13.5028 17.4354 13.8989 17.6023C14.0535 17.6667 14.2012 17.7411 14.3297 17.8435C14.7102 18.1426 14.6407 18.6399 14.189 18.8283C13.9475 18.9291 13.6922 18.9538 13.4298 18.9225C13.0251 18.8745 12.6377 18.7737 12.2729 18.5936C12.0592 18.4879 11.9967 18.516 11.9237 18.7357C11.8611 18.9258 11.8056 19.1174 11.75 19.3091C11.6753 19.5668 11.7031 19.628 11.9619 19.7486C12.292 19.9006 12.6446 19.9782 13.0042 20.0328C13.2857 20.0757 13.2943 20.0873 13.2978 20.3649C13.2996 20.4904 13.2996 20.6177 13.3013 20.7432C13.303 20.9019 13.3829 20.9944 13.5549 20.9977C13.7495 21.001 13.9458 21.001 14.1404 20.996C14.3002 20.9927 14.3818 20.9101 14.3818 20.7565C14.3818 20.5846 14.3905 20.4111 14.3836 20.2393C14.3749 20.0642 14.4548 19.9749 14.632 19.9287C15.0402 19.8229 15.3877 19.6147 15.6552 19.3041C16.3987 18.4449 16.1156 17.1876 15.0628 16.6341Z"
          fill="#60A5FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.1908 16.2377C14.4933 16.3589 14.7963 16.4802 15.0833 16.6341C16.1154 17.1876 16.3931 18.4449 15.6641 19.3041C15.4018 19.6147 15.0611 19.8229 14.6609 19.9287C14.4871 19.9749 14.4088 20.0642 14.4173 20.2393C14.4217 20.3498 14.4197 20.461 14.4178 20.5721C14.4167 20.6336 14.4156 20.6952 14.4156 20.7565C14.4156 20.9101 14.3356 20.9927 14.1789 20.996C14.0982 20.9981 14.0173 20.9993 13.9364 20.9998V18.914C14.035 18.8967 14.1319 18.8686 14.2266 18.8283C14.6694 18.6399 14.7375 18.1426 14.3645 17.8435C14.2385 17.7411 14.0937 17.6667 13.9421 17.6023C13.9402 17.6015 13.9383 17.6006 13.9364 17.5998V16.1324C13.982 16.153 14.0282 16.1725 14.075 16.1913C14.1135 16.2068 14.1522 16.2223 14.1908 16.2377ZM13.9364 14.9946C13.9507 14.9935 13.9651 14.9925 13.9796 14.9917C14.4258 14.9686 14.8499 15.0479 15.2536 15.2362C15.4546 15.3304 15.521 15.3007 15.5891 15.0975C15.6348 14.9603 15.6756 14.8218 15.7165 14.6832C15.7396 14.6048 15.7627 14.5264 15.7867 14.4481C15.831 14.3027 15.7765 14.2069 15.6351 14.1458C15.3762 14.0351 15.1105 13.9557 14.8312 13.9128C14.4667 13.8583 14.4667 13.8566 14.465 13.5014L14.465 13.5014C14.4642 13.2539 14.4637 13.1287 14.401 13.0654C14.3368 13.0007 14.2073 13.0007 13.9455 13.0007C13.9425 13.0007 13.9394 13.0007 13.9364 13.0007V14.9946ZM13.8045 13C13.7987 13 13.7929 13 13.7871 13H13.8045Z"
          fill="#2563EB"
        />
      </g>
      <path
        d="M18.4793 11.7109C18.7251 11.7109 18.9223 11.8058 19.0709 11.9956C19.221 12.1853 19.296 12.4518 19.296 12.7949C19.296 13.1381 19.226 13.4061 19.0859 13.599C18.9459 13.7919 18.7436 13.8884 18.4793 13.8884C18.2392 13.8884 18.0455 13.7919 17.8983 13.599C17.7526 13.4061 17.6797 13.1381 17.6797 12.7949C17.6797 12.4518 17.7483 12.1853 17.8855 11.9956C18.0241 11.8058 18.222 11.7109 18.4793 11.7109ZM18.4878 12.2897C18.4264 12.2897 18.3835 12.3363 18.3592 12.4296C18.3364 12.5214 18.3249 12.6463 18.3249 12.8044C18.3249 12.9594 18.3364 13.0843 18.3592 13.1792C18.3835 13.2725 18.4264 13.3191 18.4878 13.3191C18.5493 13.3191 18.5914 13.2733 18.6143 13.1815C18.6372 13.0883 18.6486 12.9625 18.6486 12.8044C18.6486 12.6463 18.6372 12.5214 18.6143 12.4296C18.5914 12.3363 18.5493 12.2897 18.4878 12.2897ZM20.8587 11.7607L19.1202 15.2285H18.4835L19.3528 13.4946L19.7874 12.6277L20.222 11.7607H20.8587ZM20.863 13.0914C21.1088 13.0914 21.306 13.1863 21.4546 13.376C21.6047 13.5658 21.6797 13.8323 21.6797 14.1754C21.6797 14.517 21.6097 14.7842 21.4696 14.9771C21.3296 15.17 21.1273 15.2665 20.863 15.2665C20.6229 15.2665 20.4292 15.17 20.282 14.9771C20.1363 14.7842 20.0634 14.517 20.0634 14.1754C20.0634 13.8323 20.132 13.5658 20.2692 13.376C20.4078 13.1863 20.6057 13.0914 20.863 13.0914ZM20.8715 13.6702C20.8101 13.6702 20.7672 13.7168 20.7429 13.8101C20.7201 13.9018 20.7086 14.0268 20.7086 14.1849C20.7086 14.3399 20.7201 14.4648 20.7429 14.5597C20.7672 14.6529 20.8101 14.6996 20.8715 14.6996C20.933 14.6996 20.9752 14.6537 20.998 14.562C21.0223 14.4687 21.0345 14.343 21.0345 14.1849C21.0345 14.0204 21.0223 13.8939 20.998 13.8054C20.9752 13.7152 20.933 13.6702 20.8715 13.6702Z"
        fill="#2563EB"
      />
      <defs>
        <clipPath id="clip0_4979_9988">
          <rect
            width="8"
            height="8"
            fill="white"
            transform="translate(10 13)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
