import { formatStrategy, localAmount } from "./amount";

export const minNotional = Number(
  process.env.REACT_APP_START_TRADING_MIN_NOTIONAL
);

export const formatedMinNotional = `${localAmount(
  formatStrategy(Number(process.env.REACT_APP_START_TRADING_MIN_NOTIONAL))
    .amount
)}${
  formatStrategy(Number(process.env.REACT_APP_START_TRADING_MIN_NOTIONAL)).short
}`;
