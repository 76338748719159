import { Link } from "react-router-dom";

import Copy from "../../copy/copy";
import { AccountName, AppRoute, IP_ADDRESS } from "../../../const";

type RenewKeysInfoProps = {
  accountName: string;
};

export default function RenewKeysInfo({
  accountName,
}: RenewKeysInfoProps): JSX.Element {
  const isBinanceFutures = accountName === AccountName.BinanceFutures;

  return (
    <div className="renew-keys-info">
      <ul className="renew-keys-info__list">
        <li className="renew-keys-info__item">
          1. Generate API keys in Binance, see the detailed tutorial{" "}
          <Link className="link" to={AppRoute.BinanceSetup}>
            here
          </Link>
          ;
        </li>
        <li className="renew-keys-info__item renew-keys-info__item--with-copy">
          2. Whitelist SMARD's IPs: copy
          <Copy text={IP_ADDRESS} />
          and paste it to Binance;
        </li>

        {isBinanceFutures ? (
          <li className="renew-keys-info__item">
            3. Click on <b>enable futures</b> in keys settings in Binance to
            enable trading on your keys;
          </li>
        ) : (
          <li className="renew-keys-info__item">
            3. Click on <b>spot & margin trading</b> in keys settings in Binance
            to enable trading on your keys;
          </li>
        )}

        <li className="renew-keys-info__item">
          4. Enter API key and Secret key below;
        </li>
        <li className="renew-keys-info__item">
          5. Click on the "Renew keys" button.
        </li>
      </ul>
    </div>
  );
}
