import { SyntheticEvent, forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Hint from "../../hint/hint";
import Button from "../../button/button";
import { StrategyType } from "../../../types/strategy";
import { sendSubscribedAccount } from "../../../store/api-actions/overview";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  AppRoute,
  AuthStatus,
  ButtonTypes,
  HINTS_TEXT,
  StrategyStatus,
} from "../../../const";

type OverviewBtnProps = {
  overview: StrategyType;
  index: number;
  isNavigateAble: boolean;
};

function OverviewBtn(
  { overview, index, isNavigateAble }: OverviewBtnProps,
  ref: any
): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { authStatus } = useAppSelector((state) => state);
  const [isSubscribed, setSubscribed] = useState<boolean>(false);
  const { buttonRef, hintButtonRef } = ref.current;

  const status = overview.status;
  const btn = overview.primary_button;
  const btnCode = overview.primary_button?.code;
  const btnTitle = overview.primary_button?.title;
  const accountName = overview.code;

  const isAuth = authStatus === AuthStatus.Auth;
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isBtnSubscribe = btnCode === ButtonTypes.SUBSCRIBE;
  const isHint =
    isNoAuth &&
    (status === StrategyStatus.Demo || status === StrategyStatus.ComingSoon);

  const handleClickOnButton = (evt: SyntheticEvent) => {
    if (isBtnSubscribe) {
      dispatch(sendSubscribedAccount(accountName));
      setSubscribed(true);
      return;
    }

    if (isNavigateAble) {
      navigate(`${AppRoute.Accounts}/${accountName}/`);
      return;
    }

    evt.preventDefault();
  };

  const findIcon = () => {
    switch (btnCode) {
      case ButtonTypes.DETAILS:
        return "eye";
      case ButtonTypes.PAY:
        return "credit-card";
      case ButtonTypes.RENEW_KEYS:
        return "key-in-circle";
      case ButtonTypes.START:
        return "arrow-right";
      case ButtonTypes.SUBSCRIBE:
      case ButtonTypes.SUBSCRIBED:
        return "check";
      default:
        return "";
    }
  };

  const findPattern = () => {
    switch (btnCode) {
      case ButtonTypes.DETAILS:
      case ButtonTypes.SUBSCRIBE:
        return "blue-white";
      case ButtonTypes.PAY:
        return "green-white";
      case ButtonTypes.RENEW_KEYS:
      case ButtonTypes.START:
        return "white-blue";
      case ButtonTypes.SUBSCRIBED:
        return "green-transparent";
      default:
        return "";
    }
  };

  return btn ? (
    <div className="overview-board__btn-wrapper">
      {isAuth && isBtnSubscribe ? (
        <Button
          classElement={`${isSubscribed ? "btn--blocked" : ""}`}
          pattern={`${isSubscribed ? "green-transparent" : "blue-white"}`}
          text={`${isSubscribed ? "Subscribed" : "Subscribe"}`}
          icon={`${isSubscribed ? "check-loader" : "check"}`}
          type="button"
          ref={buttonRef}
          handleClick={handleClickOnButton}
        />
      ) : (
        <Button
          classElement={`${isBtnSubscribe && isNoAuth ? "btn--disabled" : ""}`}
          pattern={findPattern()}
          text={btnTitle as string}
          icon={findIcon()}
          type="button"
          ref={buttonRef}
          handleClick={handleClickOnButton}
        />
      )}
      {isHint && (
        <div className="overview-board__btn-hint">
          <Hint
            type={`overview-board__btn-hint overview-board__btn-hint--${btnCode}`}
            id={`strategy/button/${index}`}
            hintText={HINTS_TEXT[`overview/strategy/${btnCode}`]}
            ref={hintButtonRef}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
}

export default forwardRef<
  {
    buttonRef: React.RefObject<HTMLButtonElement> | null;
    hintButtonRef: React.RefObject<HTMLDivElement> | null;
  },
  OverviewBtnProps
>(OverviewBtn);
