import { SyntheticEvent, useEffect, useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import UsdcIcon from "../../icons/coin/usdc-icon";
import UsdtIcon from "../../icons/coin/usdt-icon";
import Button from "../button/button";
import { AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { confirmCrytoWalletCharge } from "../../store/api-actions";
import { CryptoWalletType } from "../../types/payment";
import { formatCountdownTime } from "../../utils/datetime";

type CryptoFormProps = {
  closeCryptoForm: () => void;
  isAnimationLaunched?: boolean;
};

export default function CryptoForm({
  closeCryptoForm,
  isAnimationLaunched,
}: CryptoFormProps): JSX.Element {
  const dispatch = useAppDispatch();
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const hourSeconds = 3600;

  const cryptoWallet = useAppSelector(
    (state) => state.cryptoWallet as CryptoWalletType
  );

  const { depositInLastPaymentsStatus, authStatus } = useAppSelector(
    (state) => state
  );

  const handleSubmit = (evt: SyntheticEvent) => {
    evt.preventDefault();
    dispatch(
      confirmCrytoWalletCharge({
        charge_id: cryptoWallet.charge_id,
        charge_code: cryptoWallet.charge_code,
        wallet: cryptoWallet.wallet,
      })
    );
  };

  const copyToClipboard = () => {
    if (navigator.clipboard && cryptoWallet && cryptoWallet.wallet) {
      navigator.clipboard.writeText(cryptoWallet.wallet);
      setIsCopied(true);
    }
  };

  setTimeout(() => {
    if (isCopied) {
      setIsCopied(false);
    }
  }, 500);

  useEffect(() => {
    if (depositInLastPaymentsStatus || depositInLastPaymentsStatus === false) {
      // При успехе и ошибке закрываем форму
      closeCryptoForm();
    }
    // eslint-disable-next-line
  }, [depositInLastPaymentsStatus]);

  return (
    <form className="payments-method__row crypto-form">
      {cryptoWallet && (
        <div className="crypto-form__qr-wrapper">
          <QRCode
            value={`ethereum:${cryptoWallet.wallet}`}
            size={160}
            logoImage={`${process.env.PUBLIC_URL}/img/logo-mobile.svg`}
            logoWidth={35}
            logoHeight={33}
            removeQrCodeBehindLogo={true}
            qrStyle="dots"
          />
          <div className="crypto-form__countdown-wrapper">
            <CountdownCircleTimer
              isPlaying
              colors={["#2bbc8b", "#fa5b5b", "#fa5b5b"]}
              size={40}
              strokeWidth={5}
              onComplete={() => closeCryptoForm()}
              duration={hourSeconds}
              colorsTime={[hourSeconds, hourSeconds / 20, 0]}
              // test
              // duration={10}
              // colorsTime={[10, 0.5, 0]}
            >
              {({ remainingTime }) => (
                <span className="crypto-form__countdown-time">
                  {formatCountdownTime(remainingTime)}
                </span>
              )}
            </CountdownCircleTimer>
          </div>
        </div>
      )}

      <label className="crypto-form__address-label" htmlFor="crypto-address">
        Address
      </label>
      <div className="crypto-form__address-wrapper">
        <div className="crypto-form__field-wrapper shadow shadow--field">
          <input
            className="crypto-form__field"
            type="text"
            id="crypto-address"
            defaultValue={
              authStatus === AuthStatus.Auth && cryptoWallet
                ? cryptoWallet.wallet
                : "Address for crypto deposit will be here"
            }
            readOnly
          />
        </div>
        <button
          className={`crypto-form__copy crypto-form__copy
              ${isCopied ? "crypto-form__copy--copied" : ""}

              ${
                authStatus === AuthStatus.NoAuth
                  ? "crypto-form__copy--disabled"
                  : ""
              }
              `}
          type="button"
          aria-label="Copy"
          onClick={copyToClipboard}
        ></button>
      </div>

      {authStatus === AuthStatus.Auth && (
        <>
          <span className="crypto-form__note">Please note!</span>
          <ul className="crypto-form__list">
            <li className="crypto-form__item">
              Send
              <p className="crypto-form__icon-wrapper">
                <span className="crypto-form__icon crypto-form__icon--usdt">
                  <UsdtIcon />
                </span>
              </p>
              USDT and
              <p className="crypto-form__icon-wrapper">
                <span className="crypto-form__icon crypto-form__icon--usdc">
                  <UsdcIcon />
                </span>
              </p>
              USDC via<b className="crypto-form__bold">ERC20</b>network only.
              Other coins and networks are not supported, if you use them you'll
              lose your funds.
            </li>
            <li className="crypto-form__item">
              Be careful when transferring funds from an account connected to
              SMARD, if you leave a smaller amount in the account than the
              strategy expects it will pause trading.
            </li>
          </ul>
        </>
      )}
      <Button
        classElement={`crypto-form__btn ${
          isAnimationLaunched || authStatus === AuthStatus.NoAuth
            ? "btn--disabled"
            : ""
        }`}
        pattern="white-blue"
        text="I've paid"
        icon="credit-card"
        type="submit"
        handleClick={handleSubmit}
      />
    </form>
  );
}
