import { useEffect } from "react";
import AppleLogin from "react-apple-login";

import AppleIcon from "../icons/socials/apple-icon";
import { trackEvent } from "../../utils/tracking";
import { AUTH_APPLE_CLIENT_ID, AUTH_APPLE_URI } from "../../const";
import { useAppDispatch } from "../../hooks";
import { sendUserAppleInfo } from "../../store/api-actions/user";

type LoginAppleProps = {
  isAuthModalOpened: boolean;
  isOverview: boolean;
};

export default function LoginApple({
  isAuthModalOpened,
  isOverview,
}: LoginAppleProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleAppleClick = () => {
    trackEvent("AppleAuthButton", "Clicked");
    trackEvent("AuthButton", "Clicked");
  };

  const appleLoginOnSuccess = (event: any) => {
    dispatch(
      sendUserAppleInfo({
        info: event.detail,
        isOverview: isOverview,
      })
    );
  };

  // const appleLoginOnError = () => {};

  useEffect(() => {
    if (isAuthModalOpened) {
      return;
    }

    document.addEventListener("AppleIDSignInOnSuccess", appleLoginOnSuccess);

    return () => {
      document.removeEventListener(
        "AppleIDSignInOnSuccess",
        appleLoginOnSuccess
      );
    };
    // eslint-disable-next-line
  }, [isAuthModalOpened]);

  return (
    <div className="login login--apple">
      <AppleLogin
        clientId={AUTH_APPLE_CLIENT_ID}
        redirectURI={AUTH_APPLE_URI}
        usePopup={true}
        scope="name email"
        state="web"
        render={(renderProps) => (
          <button
            className="login__apple-btn"
            type="button"
            aria-label="Login with apple"
            onClick={() => {
              renderProps.onClick();
              handleAppleClick();
            }}
          ></button>
        )}
      />
      <span className="login__icon login__icon--apple">
        <AppleIcon />
      </span>
    </div>
  );
}
