import { useEffect, useRef } from "react";

import { AuthStatus } from "../../const";
import { useAppSelector } from "../../hooks";
import { initTwitter } from "../../utils/twitter";

export function TwitterTracking() {
  const { authStatus } = useAppSelector((state) => state);
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isScriptEmbeded = useRef<boolean>(false);

  useEffect(() => {
    if (isNoAuth && !isScriptEmbeded.current) {
      initTwitter();
      isScriptEmbeded.current = true;
    }
  }, [isNoAuth]);

  return null;
}
