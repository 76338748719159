import RhombIcon from "../../icons/steps/rhomb-icon";
import StepOkxIcon from "../../icons/steps/step-okx-icon";
import StepBybitIcon from "../../icons/steps/step-bybit-icon";
import BitgetIcon from "../../../icons/exchange/bitget-icon";
import { AccountName } from "../../../const";

type ConnectPoint1Props = {
  pointId: string;
  accountName: string;
};

export default function ConnectPoint1({
  pointId,
  accountName,
}: ConnectPoint1Props): JSX.Element {
  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;

  return (
    <div className="connect-point connect-point--active" id={pointId}>
      <span
        className={`connect-point__icon connect-point__icon--${
          isOkx ? "okx" : isBitget ? "bitget" : isBybit ? "bybit" : "rhomb"
        }`}
      >
        {isOkx ? <StepOkxIcon /> : isBitget ? <BitgetIcon /> :isBybit ? <StepBybitIcon /> : <RhombIcon />}
      </span>
    </div>
  );
}
