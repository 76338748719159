import { Helmet } from "react-helmet-async";
import { findAmountDecimals } from "../../utils/amount";
import { AccountName } from "../../const";

export function ManualSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>SMARD — Automated Crypto Trading Bot</title>
      <meta
        name="description"
        content="Powerful platform for automated crypto trading ⚡No upfront payments ✔ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/" />
      <script type="application/ld+json">{`
        {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "📈 Smard",
        "item": "https://smard.club/"
        },{
        "@type": "ListItem",
        "position": 2,
        "name": "🔥 Make profit!",
        "item": "https://smard.club/#root"
        }]
        }
    `}</script>
      <script type="application/ld+json">{`
        {
      "@context": "https://schema.org/",
      "@type": "Organization",
      "name": "SMARD",
      "legalName": "Smard LLC",
      "logo": "https://smard.club/assets/img/logo.svg",
      "url": "https://smard.club",
      "sameAs":  [
    "https://www.youtube.com/@smard_club",
    "https://t.me/smard_club",
    "https://medium.com/@smard.club",
    "https://www.trustpilot.com/review/smard.club"
   ],
   "address": {
    "@type": "PostalAddress",
    "addressLocality": "Sheridan",
    "addressRegion": "WY",
    "addressCountry": "USA",
    "postalCode": "82801",
    "streetAddress": "1309 Coffeen Avenue STE 1200"
  },
  "email": "success@smard.club"
}
`}</script>
    </Helmet>
  );
}

export function DetailsSeoSetting({
  account,
}: {
  account: string;
}): JSX.Element {
  const showSettings = () => {
    switch (account) {
      case AccountName.BinanceFutures:
        return (
          <Helmet>
            <title>Binance Futures Trading Bot by SMARD</title>
            <meta
              name="description"
              content="Powerful platform for automated Binance Futures crypto trading ✍ Works with Binance Futures ⭐ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
            />
            <link
              rel="canonical"
              href="https://smard.club/my/accounts/binance-futures/"
            />
            <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "📈 Smard",
        "item": "https://smard.club/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "✅ Accounts",
        "item": "https://smard.club/my/accounts/"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "✅ Binance Futures",
        "item": "https://smard.club/my/accounts/binance-futures/"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "🔥 Make profit!",
        "item": "https://smard.club/my/accounts/binance-futures/#root"
      }]
    }
`}</script>
            <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Binance Futures Trading Bot",
  "offers": {
    "@type": "Offer",
    "availability": "https://schema.org/InStock",
    "price": "1.00",
    "priceCurrency": "USD",
    "url": "https://smard.club/pricing/"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "reviewCount": "6"
  }
 }
`}</script>
          </Helmet>
        );

      case AccountName.BinanceSpot:
        return (
          <Helmet>
            <title>Binance Spot Trading Bot by SMARD</title>
            <meta
              name="description"
              content="Powerful platform for automated Binance Spot crypto trading ✍ Works with Binance Spot ⚡Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
            />
            <link
              rel="canonical"
              href="https://smard.club/my/accounts/binance-spot/"
            />
            <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "📈 Smard",
        "item": "https://smard.club/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "✅ Accounts",
        "item": "https://smard.club/my/accounts/"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "✅ Binance Spot",
        "item": "https://smard.club/my/accounts/binance-spot/"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "🔥 Make profit!",
        "item": "https://smard.club/my/accounts/binance-spot/#root"
      }]
    }
`}</script>
            <script type="application/ld+json">{`
            {
              "@context": "https://schema.org",
              "@type": "Product",
              "name": "Binance Spot Trading Bot",
              "offers": {
              "@type": "Offer",
              "availability": "https://schema.org/InStock",
              "price": "1.00",
              "priceCurrency": "USD",
              "url": "https://smard.club/pricing/"
            },
              "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "reviewCount": "6"
  }
 }
`}</script>
          </Helmet>
        );
      case AccountName.Okx:
        return (
          <Helmet>
            <title>OKX Trading Bot by SMARD</title>
            <link rel="canonical" href="https://smard.club/my/accounts/okx/" />
            <meta
              name="description"
              content="Powerful platform for automated OKX crypto trading ✍ Works with OKX ⭐ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
            />
          </Helmet>
        );
      case AccountName.BitgetFutures:
        return (
          <Helmet>
            <title>Bitget Futures Trading Bot by SMARD</title>
            <link
              rel="canonical"
              href="https://smard.club/my/accounts/bitget-futures/"
            />
            <meta
              name="description"
              content="Powerful platform for automated Bitget Futures crypto trading ✍ Works with Bitget Futures ⭐ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
            />
          </Helmet>
        );

      default:
        return <ManualSeoSetting />;
    }
  };

  return showSettings();
}

export function DisclaimersSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Disclaimers | SMARD</title>
      <meta
        name="description"
        content="Terms of Website Use and Risks Disclaimer ✔ General ✔ No offer and no advice ✔ General risks of trading and investments ✔ Exclusion of warranties ✔ Limitation of liability ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/disclaimers/" />
    </Helmet>
  );
}

export function FaqSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Frequently Asked Questions | SMARD</title>
      <meta
        name="description"
        content="Frequently Asked Questions ✔ About Connection ✔ About Profitability ✔ About Security ✔ About Trading results explained ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/faq/" />
      <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "📈 Smard",
        "item": "https://smard.club/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "✅ FAQ",
        "item": "https://smard.club/faq/"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "🔥 Trading results explained",
        "item": "https://smard.club/faq/#root"
      }]
    }
`}</script>

      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Do I have to deposit funds?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>No. Your assets remain at your disposal via the broker or exchange. You can use them at any time to trade on your own without SMARD software.</p>"
    }
  },{
    "@type": "Question",
    "name": "How do I connect SMARD?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>Log into your personal Binance account and release the keys in the 'API Management' section. Please read a detailed guide how to create API keys and connect SMARD for trading <a href='https://smard.club/faq/binance-api-setup/'>here</a>.</p>"
    }
  },{
    "@type": "Question",
    "name": "Can I trade on my account while it is being traded by SMARD?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>Yes, you can continue to use your account to invest or trade, just do not touch the position that SMARD has formed. If at the time of trading, SMARD does not see the expected position, then it will pause and will not continue until you recover the expected position in the assets.</p>"
    }
  },{
    "@type": "Question",
    "name": "How much profit does SMARD make?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>No one can ever guarantee profitability, whether in the securities markets or cryptocurrencies. Any investment involves risk.</p><p>SMARD relies on backtesting of historical data, which shows a profit of 70% from the beginning of 2022 until the end of October 2022. If we compare the profitability of SMARD from the beginning of 2022 to major crypto assets, SMARD outperforms Bitcoin and Ethereum by around 300% due to a massive downfall in crypto markets. SMARD is focused on long-term investment, not on short-term benefits.</p>"
    }
  },{
    "@type": "Question",
    "name": "How much can I start with?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>We provide access to the trading software starting from $1,000 (or equivalent).</p>"
    }
  },{
    "@type": "Question",
    "name": "What are my risks?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>SMARD only works for buying long positions and operates without leverage. That is, it does not take uncalculated risks. At the moment, SMARD trades instruments that are the most liquid, selecting the top 40-50 instruments depending on the exchange.</p><p>SMARD uses both its own tested algorithms and a set of trading strategies that are described in the fundamental literature. For example, SMARD uses a momentum effect that has been investigated and confirmed by <a href='https://alphaarchitect.com/2015/12/quantitative-momentum-investing-philosophy/'>experts</a>.</p>"
    }
  },{
    "@type": "Question",
    "name": "Why does the algorithm not trade?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>The SMARD algorithm is designed for long-term investing and does not take unnecessary risks or chase a specified number of trades. It searches for entry points with the highest probability of success and low risk. When the market is in consolidation, the algorithm decides to skip entries into positions.</p>"
    }
  },{
    "@type": "Question",
    "name": "Who can trade on my account?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>Only you. Either on your own, or using SMARD software.</p>"
    }
  },{
    "@type": "Question",
    "name": "Can SMARD withdraw money from my account?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>No. If the settings on a platform allow you to give access to the withdrawal of funds, then do not give the keys issued to be used by SMARD such an opportunity. In any case, there is no such functionality in SMARD.</p>"
    }
  },{
    "@type": "Question",
    "name": "Will I have access to my money?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>Yes, you will have full access to your account at all times and can not only see which assets the strategy has bought but also sell them and withdraw money from the account if you need it. If the SMARD does not detect the expected instruments on the account, then it will pause trading activities.</p>"
    }
  },{
    "@type": "Question",
    "name": "Do you use internal information about customer trading activity yourself?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>No. SMARD works in such a way that each client's transactions are unique, and a distinct portfolio is assembled for each client, which depends on the monetary amount of the portfolio and on the launch time. Thus, there is no concentration of volumes in any particular assets.</p>"
    }
  },{
    "@type": "Question",
    "name": "Is SMARD a regulated entity?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>No. SMARD is a technology provider and trading enhancement software that trades on your account on your behalf. SMARD does not take or withdraw money itself, the money is always in the complete control of the client. The client makes the decision to use SMARD.</p>"
    }
  },{
    "@type": "Question",
    "name": "Privacy",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>SMARD is serious about privacy. We do not report user data anywhere, we use cookies only for advertisements and information pages, and we do not place any cookies in personal sections of the site.</p>"
    }
  },{
    "@type": "Question",
    "name": "What is the Sharpe ratio?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>The Sharpe ratio is a measure used to evaluate the performance of risk-adjusted investments. It measures the excess return over the risk-free rate of the return divided by the standard deviation of the return. A higher Sharpe Ratio indicates better risk-adjusted performance.</p>"
    }
  },{
    "@type": "Question",
    "name": "What is average monthly return and average order return?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>Average monthly return measures the average investment return earned each month over a specific time period. It is calculated by dividing the number of monthly returns by the number of full months in the period.</p><p>Average order return measures the average return on each order placed during the period from the start of the algorithm to the current time. It is calculated by dividing the sum of the returns of orders by the number of placed orders.</p><p>Both metrics are useful for evaluating the effectiveness of an investment strategy.</p>"
    }
  },{
    "@type": "Question",
    "name": "What are annualized returns?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "<p>Annualized returns measure the average rate of return an investment generates per year. It is calculated by adjusting the total return earned pro rata to a full year to reflect the annualized rate of return. This indicator is important for evaluating the historical performance of investments and takes into account compound interest.</p>"
    }
  }]
}
`}</script>
    </Helmet>
  );
}

export function BinanceSetupSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Binance APIs Setup | SMARD</title>
      <meta
        name="description"
        content="6 easy steps to connect SMARD to your Binance account in the secured way ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/faq/binance-api-setup/" />
    </Helmet>
  );
}

export function BybitSetupSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Bybit APIs Setup | SMARD</title>
      <meta
        name="description"
        content="Connect your Bybit account to SMARD in less than 1 minute"
      />
      <link rel="canonical" href="https://smard.club/faq/bybit-api-setup/" />
    </Helmet>
  );
}

export function BybitUpgradeUTASeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Upgrade to Unified Trading Account on Bybit</title>
      <meta
        name="description"
        content="Upgrade your Standard Account to a Unified Trading Account (UTA) on Bybit"
      />
      <link
        rel="canonical"
        href="https://smard.club/faq/bybit-upgrade-to-unified-trading-account/"
      />
    </Helmet>
  );
}

export function OkxSetupSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>OKX APIs Setup | SMARD</title>
      <meta
        name="description"
        content="Connect your OKX account to SMARD in less than 1 minute"
      />
      <link rel="canonical" href="https://smard.club/faq/okx-api-setup/" />
    </Helmet>
  );
}

export function BitgetSetupSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Bitget Futures APIs Setup | SMARD</title>
      <meta
        name="description"
        content="Connect your Bitget Futures account to SMARD in less than 1 minute"
      />
      <link rel="canonical" href="https://smard.club/faq/bitget-futures-api-setup/" />
    </Helmet>
  );
}

export function OkxChangeModeSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Change Account Mode on OKX</title>
      <meta
        name="description"
        content="Follow the steps in the instructions to configure the account mode in your OKX account"
      />
      <link
        rel="canonical"
        href="https://smard.club/faq/okx-change-account-mode/"
      />
    </Helmet>
  );
}

export function BitgetChangeModeSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Change Account Mode on Bitget Futures</title>
      <meta
        name="description"
        content="Follow the steps in the instructions to configure the account mode in your Bitget Futures account"
      />
      <link
        rel="canonical"
        href="https://smard.club/faq/bitget-futures-change-account-mode/"
      />
    </Helmet>
  );
}

export function OverviewSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Welcome Page | SMARD</title>
      <meta
        name="description"
        content="Powerful platform for automated crypto trading ⭐ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/my/" />
    </Helmet>
  );
}

export function PaymentsSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Payments | SMARD</title>
      <meta
        name="description"
        content="You can choose a convenient payment method ✔ Access fee ✔ Service fee ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/my/payments/" />
    </Helmet>
  );
}

export function PricingSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Subscription Fees | SMARD</title>
      <meta
        name="description"
        content="Get access to our trading software for a small fee and only pay the service fee when you earn ✔ Your success = our success ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/pricing/" />
    </Helmet>
  );
}

export function TermsSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Terms of Service | SMARD</title>
      <meta
        name="description"
        content="Terms of service ✔ General ✔ Registration and account protection ✔ Services ✔ Fees and taxes ✔ License to use the Trading enhancement software ✔ Termination and Suspension ✔ Liability ✔ Waiver of rights ✔ Severance ✔ Notices ✔ Assignment ✔ Entire agreement ✔ Governing law and dispute resolution ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/terms-of-service/" />
    </Helmet>
  );
}

export function PolicySeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Privacy Policy | SMARD</title>
      <meta
        name="description"
        content="Privacy policy ✔ What information We collect ✔ Why do We need this information ✔ Cookies ✔ How long We will store it ✔ Our safeguarding measures ✔ Necessary sharing ✔ Rights You can exercise ✔ Personal Data breach notification ✔ Amendments ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/privacy-policy/" />
    </Helmet>
  );
}

export function AffiliatesSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Affiliates | SMARD</title>
      <link rel="canonical" href="https://smard.club/my/affiliates/" />
    </Helmet>
  );
}

export function ReferralsSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Referral Program | SMARD</title>
      <meta
        name="description"
        content="Referral program ✔ Invite friends to earn 10% of their profits ✔ Your referral link ✔ Referral balance details ✔ My referrals ✔ Activity ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/my/referrals/" />
    </Helmet>
  );
}

export function CoinsSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Cryptocurrency Prices, Charts & Market Cap</title>
      <meta
        name="description"
        content="List of top cryptocurrencies and their real time prices ✍ Bitcoin, Ethereum, Tether and many other⚡Dynamic changes in the last 24 hours ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/price/" />
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "📈 Smard",
    "item": "https://smard.club/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "📈 Price",
    "item": "https://smard.club/price/"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "📈 Just updated!",
    "item": "https://smard.club/price/#chart"
  }]
}
  `}</script>
      <script type="application/ld+json">{`
{"@context":"http://schema.org","@type":"CreativeWorkSeries","aggregateRating":{"@type":"AggregateRating","bestRating":5.0,"worstRating":1.0,"ratingCount":386,"ratingValue":5,"itemReviewed":{"@type":"Thing","name":"Cryptocurrency Prices and Live Charts"}}}
`}</script>
    </Helmet>
  );
}

export function PartnershipSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Affiliate program | SMARD</title>
      <meta
        name="description"
        content="Monetize your traffic and earn commissions when you share SMARD with your audience."
      />
    </Helmet>
  );
}

export function CoinSeoSetting({
  title,
  ticker,
  price,
}: {
  title: string;
  ticker: string;
  price: null | number;
}): JSX.Element {
  const checkedPrice =
    price === null
      ? null
      : Number.isInteger(price)
      ? price
      : findAmountDecimals(price) === 1
      ? price.toFixed(1)
      : price.toFixed(2);

  const beginning =
    price !== null
      ? `The current price of ${title} is $ ${checkedPrice}【${ticker} to USD】 ✍ `
      : "";

  return (
    <Helmet>
      <title>{`${title} Price and Live Chart | ${ticker} to USD`}</title>
      <meta
        name="description"
        content={`${beginning}${title} dynamic rate in the last 24 hours ➤ SMARD`}
      />
      <link
        rel="canonical"
        href={`https://smard.club/price/${title.toLowerCase()}/`}
      />
      <script type="application/ld+json">{`
    {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "📈 Smard",
        "item": "https://smard.club/"
      },{
        "@type": "ListItem",
        "position": 2,
        "name": "📈 Price",
        "item": "https://smard.club/price/"
      },{
        "@type": "ListItem",
        "position": 3,
        "name": "📈 ${title}",
        "item": "https://smard.club/price/${title.toLowerCase()}/"
      },{
        "@type": "ListItem",
        "position": 4,
        "name": "📈 ${ticker} to USD live",
        "item": "https://smard.club/price/${title.toLowerCase()}/#chart"
      }]
    }
`}</script>
      <script type="application/ld+json">{`
{"@context":"http://schema.org","@type":"CreativeWorkSeries","aggregateRating":{"@type":"AggregateRating","bestRating":5.0,"worstRating":1.0,"ratingCount":134,"ratingValue":5,"itemReviewed":{"@type":"Thing","name":"${title} Price and Live Chart"}}}
`}</script>
    </Helmet>
  );
}

export function OkxLandingSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>OKX Trading Bot by SMARD</title>
      <meta
        name="description"
        content="Powerful platform for automated OKX crypto trading ✍ Works with OKX ⚡ No upfront payments ⭐ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/exchanges/okx/" />
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "📈 Smard",
    "item": "https://smard.club/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "📈 OKX",
    "item": "https://smard.club/my/accounts/okx/"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "📈 Overtakes BTC and ETH!",
    "item": "https://smard.club/my/accounts/okx/#demo"
  }]
}

  `}</script>
      <script type="application/ld+json">{`
{"@context":"http://schema.org","@type":"CreativeWorkSeries","aggregateRating":{"@type":"AggregateRating","bestRating":5.0,"worstRating":1.0,"ratingCount":146,"ratingValue":5,"itemReviewed":{"@type":"Thing","name":"OKX Trading Bot"}}}
`}</script>
    </Helmet>
  );
}

export function BitgetLandingSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Bitget Trading Bot by SMARD</title>
      <meta
        name="description"
        content="Powerful platform for automated Bitget crypto trading ✍ Works with Bitget ⚡ Maximum results ⭐ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/exchanges/bitget/" />
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "📈 Smard",
    "item": "https://smard.club/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "📈 Bitget",
    "item": "https://smard.club/my/accounts/bitget/"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "📈 Overtakes BTC and ETH!",
    "item": "https://smard.club/my/accounts/bitget/#demo"
  }]
}
  `}</script>
      <script type="application/ld+json">{`
{"@context":"http://schema.org","@type":"CreativeWorkSeries","aggregateRating":{"@type":"AggregateRating","bestRating":5.0,"worstRating":1.0,"ratingCount":146,"ratingValue":5,"itemReviewed":{"@type":"Thing","name":"Smard Bitget Trading Bot"}}}
`}</script>
    </Helmet>
  );
}

export function BybitLandingSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Bybit Futures Trading Bot by SMARD</title>
      <meta
        name="description"
        content="Powerful platform for automated Bybit Futures trading ✍ Works with Bybit Futures ✔ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/exchanges/bybit/" />
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "📈 Smard",
    "item": "https://smard.club/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "📈 Bybit",
    "item": "https://smard.club/my/accounts/bybit/"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "📈 Overtakes BTC and ETH!",
    "item": "https://smard.club/my/accounts/bybit/#demo"
  }]
}
  `}</script>
      <script type="application/ld+json">{`
{"@context":"http://schema.org","@type":"CreativeWorkSeries","aggregateRating":{"@type":"AggregateRating","bestRating":5.0,"worstRating":1.0,"ratingCount":146,"ratingValue":5,"itemReviewed":{"@type":"Thing","name":"Smard Bybit Trading Bot"}}}
`}</script>
    </Helmet>
  );
}

export function BinanceLandingSeoSetting(): JSX.Element {
  return (
    <Helmet>
      <title>Binance Trading Bot by SMARD</title>
      <meta
        name="description"
        content="Powerful platform for automated Binance crypto trading ✍ Works with Binance Spot, Futures & US ⚡ Up to 5% average monthly return ✔ Overtakes BTC and ETH in the long run ✔ 10% monthly service fee charged on profits only ➤ SMARD"
      />
      <link rel="canonical" href="https://smard.club/exchanges/binance/" />
      <script type="application/ld+json">{`
{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "📈 Smard",
    "item": "https://smard.club/"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "📈 Binance",
    "item": "https://smard.club/my/accounts/binance/"
  },{
    "@type": "ListItem",
    "position": 3,
    "name": "📈 Overtakes BTC and ETH!",
    "item": "https://smard.club/my/accounts/binance/#demo"
  }]
}
  `}</script>
      <script type="application/ld+json">{`
{"@context":"http://schema.org","@type":"CreativeWorkSeries","aggregateRating":{"@type":"AggregateRating","bestRating":5.0,"worstRating":1.0,"ratingCount":258,"ratingValue":5,"itemReviewed":{"@type":"Thing","name":"Binance Trading Bot"}}}
`}</script>
    </Helmet>
  );
}
