import { useEffect, useState } from "react";

function useIsConnectButtonVisible(sharpe: number | null | undefined) {
  const [isConnectButtonVisible, setIsConnectButtonVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (sharpe && sharpe !== null && sharpe > 0.5) {
      setIsConnectButtonVisible(true);
    }
  }, [sharpe]);

  return isConnectButtonVisible;
}

export default useIsConnectButtonVisible;
