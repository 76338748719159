export default function LogoIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 150 40"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        fill="#1A1A1A"
        d="M66.31 29c-2.77.05-5.45-.95-7.5-2.8l2.3-2.72c1.73 1.41 3.5 2.12 5.28 2.12.8 0 1.42-.15 1.87-.46a1.42 1.42 0 0 0 .68-1.25 1.39 1.39 0 0 0-.7-1.2c-.47-.32-1.43-.65-2.86-1.02a20.9 20.9 0 0 1-2.45-.74 8.18 8.18 0 0 1-1.78-.96c-.55-.38-.98-.9-1.24-1.51a5.5 5.5 0 0 1-.4-2.19c0-1.58.59-2.85 1.74-3.82A6.73 6.73 0 0 1 65.71 11c2.39-.04 4.71.75 6.57 2.22l-2.02 2.9c-1.67-1.14-3.2-1.72-4.6-1.72-.73 0-1.29.15-1.68.44a1.36 1.36 0 0 0-.6 1.15 1.36 1.36 0 0 0 .74 1.26c.49.29 1.51.63 3.08 1.03 1.92.5 3.34 1.14 4.25 1.92.9.78 1.36 1.9 1.36 3.37 0 1.7-.6 3.03-1.78 3.99A7.3 7.3 0 0 1 66.31 29ZM75.81 28V11h4.03l4.47 7.19L88.78 11h4.03v17h-3.7V16.9l-4.8 7.26h-.09l-4.74-7.19V28h-3.67ZM95.81 28l7.28-17h3.44l7.28 17h-3.9l-1.57-3.79h-7.17L99.61 28h-3.8Zm6.7-7.07h4.5l-2.25-5.47-2.25 5.47ZM116.81 28V11h7.94c2.2 0 3.88.57 5.06 1.72 1 .98 1.49 2.29 1.48 3.94 0 2.6-1.23 4.36-3.72 5.27l4.24 6.07h-4.47l-3.77-5.44h-2.96V28h-3.8Zm3.82-8.74h3.87c.93 0 1.65-.23 2.16-.67a2.25 2.25 0 0 0 .77-1.79c0-.79-.26-1.4-.8-1.8a3.53 3.53 0 0 0-2.2-.63h-3.8v4.89ZM133.81 28V11h6.77c2.71 0 4.93.8 6.65 2.42a7.97 7.97 0 0 1 2.58 6.08c0 2.43-.86 4.45-2.6 6.07a9.32 9.32 0 0 1-6.63 2.43h-6.77Zm3.82-3.37h2.96c1.57 0 2.84-.47 3.8-1.42a4.95 4.95 0 0 0 1.43-3.7c0-1.51-.49-2.74-1.46-3.7a5.16 5.16 0 0 0-3.78-1.44h-2.96v10.26Z"
      />
      <path
        fill="#F93232"
        d="m11.81 28.33-.83 1.41L8.47 34 .8 21h6.68l4.32 7.33Z"
      />
      <path
        fill="#1A1A1A"
        d="m27.81 0-2.42 4.33-.8 1.45h-9.94L8.07 17.57 7.27 19H.8L11.42 0h16.39ZM45.81 20.2 34.31 40h-16.5l2.44-4.2.81-1.4h10l8.25-14.2-5-8.6.81-1.4L37.56 6l8.25 14.2Z"
      />
      <path
        fill="#32E532"
        d="m35.81 2.83-3.22 5.65-14.74 25.87-1.6 2.82L14.63 40h-3.22L9.8 37.17l3.22-5.64v-.01L27.76 5.65 30.98 0h3.22l1.61 2.83Z"
      />
    </svg>
  );
}
