export default function BlueGearsIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 35 36"
    >
      <g clipPath="url(#a)">
        <path
          fill="#60A5FA"
          d="M32.7 10v-.6L35 8l-3-5.5-2.5 1.4-1-.6V.4h-6v3l-1 .5L19 2.5 16 8l2.4 1.4v1.2L16 12l3 5.5 2.5-1.4 1 .6v2.8h6v-2.8l1-.6 2.4 1.4L35 12l-2.4-1.4V10Zm-7.2 3.2c-1.7 0-3-1.4-3-3.2 0-1.8 1.3-3.2 3-3.2s3 1.4 3 3.2c0 1.8-1.3 3.2-3 3.2Z"
        />
        <path
          fill="#60A5FA"
          d="M20.9 24c0-.5 0-1.1-.2-1.7L23 21l-3.1-5.5-2.3 1.3a10 10 0 0 0-3-1.7v-2.6H8.4V15c-1.1.4-2.1 1-3 1.7l-2.3-1.3L0 21l2.3 1.3a8.5 8.5 0 0 0 0 3.4L0 27l3.1 5.5 2.3-1.3a10 10 0 0 0 3 1.7v2.7h6.2v-2.7c1.1-.4 2.1-1 3-1.7l2.3 1.3L23 27l-2.3-1.3.2-1.7Zm-9.4 5.2a5.2 5.2 0 1 1 0-10.4 5.2 5.2 0 0 1 0 10.4Z"
        />
        <path
          fill="#2563EB"
          d="M32.5 10.6 35 12l-3.2 5.5-2.5-1.4-1 .6v2.8H25v-6.3c1.8 0 3.2-1.4 3.2-3.2 0-1.8-1.4-3.2-3.2-3.2V.4h3.2v3l1 .5 2.6-1.4L35 8l-2.5 1.4v1.2ZM20.8 25.7 23 27l-3 5.5-2.2-1.3c-.8.7-1.8 1.3-2.8 1.7v2.7h-3v-6.4c2.8 0 5-2.3 5-5.2 0-2.9-2.2-5.2-5-5.2v-6.3h3V15c1 .4 2 1 2.8 1.7l2.2-1.3 3 5.5-2.2 1.3a8.9 8.9 0 0 1 0 3.4Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h35v35.2H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
