export default function MailIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0.271973H2C0.9 0.271973 0 1.19763 0 2.32898V14.671C0 15.8024 0.9 16.728 2 16.728H18C19.1 16.728 20 15.8024 20 14.671V2.32898C20 1.19763 19.1 0.271973 18 0.271973ZM17.6 4.64311L11.06 8.84969C10.41 9.27137 9.59 9.27137 8.94 8.84969L2.4 4.64311C2.15 4.47855 2 4.20085 2 3.90259C2 3.21349 2.73 2.80209 3.3 3.16207L10 7.47149L16.7 3.16207C17.27 2.80209 18 3.21349 18 3.90259C18 4.20085 17.85 4.47855 17.6 4.64311Z"
        fill="currentColor"
      />
    </svg>
  );
}
