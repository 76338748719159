export type Dictionary = {
  [key: string]: string;
};

export const HINTS_TEXT: Dictionary = {
  "page-header/demo":
    "You are in demo mode. Explore your personal area's features after login",

  "overview/strategy/details":
    "You may see detailed information of trading results from connected accounts",
  "overview/strategy/subscribe":
    "Get notified when we connect to a new exchange or broker you are interested in",
  "overview/strategy/start":
    "When pressed, you will be brought to the page where you can connect the software to your account",
  "overview/strategy/pay":
    "Add balance or add a card to continue successful trading",
  "overview/strategy/alert":
    "You will see alerts that require your attention here",

  "details/strategy/stop":
    "Select if you wish to stop using SMARD on the account",
  "details/strategy/renew-keys": "You may update keys anytime you want",
  "details/conf-integration/start":
    "Log in to connect the software to your account",

  "details/chart": "Compare the SMARD performance to BTC or ETH as a benchmark",
  "details/current-position": "The list of current positions made by SMARD",
  "details/strategy-results":
    "Detailed overview of strategy performance since its start",
  "details/recent-activity":
    "You will see recent activity of SMARD trades and the results here",

  "payments/order": "You can choose a convenient payment method",
  "payments/last": "You will see your last payments here",

  "nav/referrals": "Invite friends to earn 10% from their commissions",
  "referrals/balance": "Total balance of referral gain",
  "referrals/clients": "List of clients referred by you",
  "referrals/activity":
    "Refferal gain debited to your account from your referrees",

  "details/strategy/pay":
    "Add balance or add a card to continue successful trading",

  "coin/volume24h":
    "An indicator of how much a cryptocurrency was traded within the past 24 hours",

  "coin/circulating-supply":
    "Quantity of coins or tokens that are accessible for public purchase or sale. If these can be traded, they are classified as part of the circulating supply",
};

export enum ApiRoute {
  Login = "/api/v1/auth/check/",
  AppleLogin = "/auth/apple/",
  User = "/api/v1/user/profile/",
  Accounts = "/api/v1/accounts/",
  AccountDetails = "/api/v1/user/accounts/",
  OverviewSummary = "/api/v1/user/summary/",
  Strategies = "/api/v1/user/overview/",
  Messages = "/api/v1/user/notifications/",
  Notifications = "/api/v1/user/events/",
  NotificationsRead = "/api/v1/user/events/read/",
  Faq = "/api/v1/faq-data/",
  Referrals = "/api/v1/user/referrals/",
  ClientSecret = "/api/v1/payments/stripe/setup_intent/client_secret/",
  PaymentMethods = "/api/v1/payments/methods/",
  Payments = "/api/v1/payments/",
  StripeCard = "/api/v1/payments/methods/stripe/",
  LastPayments = "/api/v1/payments/transactions/",
  PaymentMethodsOrder = "/api/v1/payments/methods/order/",
  ChargeMe = "/api/v1/payments/methods/charge_me/",
  CryptoWallet = "/api/v1/payments/coinbase/charge/",
  LastPaymentsDeposit = "/api/v1/payments/coinbase/charge/confirm/",
  FailedTransactions = "/api/v1/payments/transactions/failed/",
  FailedTransactionsAgain = "/api/v1/payments/transactions/failed/try-again/",
  MonthlyProfit = "/api/v1/monthly-performance/",
  AccountStatuses = "/api/v1/user/accounts/check-active/",
  TradableCoins = "/api/v1/tradable-coins/",
  Demo = "/api/v1/demo-data/",
  LogsToServer = "/api/v1/logs/",
}

export const AccountActionPath = {
  ADD_KEYS: "/add-keys/",
  COIN_INFO: "/coin-info/",
  START: "/start/",
  RENEW: "/renew-keys/",
  STOP: "/stop/",
  SUBSCRIBE: "/subscribe/",
  FIX_DIVERGENCE: "/fix_divergence/",
  ACCOUNT_KEYS: "/keys/",
  CHANGE_POSITION_MODE: "/change-account-position-mode/",
  CHANGE_MARGIN_MODE: "/change-account-margin-mode/",
  POSITION_MODE: "/account-position-mode/",
  CHANGE_AMOUNT: "/change_amount/",
  USE_ALL: "/use_all/",
};

export const ParamsSource = {
  EXCHANGES_OKX_LANDING: "?source=exchanges_okx_landing",
  EXCHANGES_BITGET_LANDING: "?source=exchanges_bitget_landing",
};

export const ParamsRedirect = {
  OKX: "okx_fast_connect",
  BITGET: "bitget_fast_connect",
};

export enum AppRoute {
  Manual = "/",
  Overview = "/my/",
  Accounts = "/my/accounts",
  Pricing = "/pricing/",
  Faq = "/faq/",
  Disclaimers = "/disclaimers/",
  Policy = "/privacy-policy/",
  Terms = "/terms-of-service/",
  Referrals = "/my/referrals/",
  Payments = "/my/payments/",
  BinanceSetup = "/faq/binance-api-setup/",
  OkxSetup = "/faq/okx-api-setup/",
  BybitSetup = "/faq/bybit-api-setup/",
  BybitChangeUta = "/faq/bybit-upgrade-to-unified-trading-account/",
  BitgetSetup = "/faq/bitget-futures-api-setup/",
  OkxChangeMode = "/faq/okx-change-account-mode/",
  BitgetChangeMode = "/faq/bitget-futures-change-account-mode/",
  BybitChangeMode = "/faq/bybit-futures-change-account-mode/",
  Error = "/server-error/",
  Affiliates = "/my/affiliates/",
  Partnership = "/affiliate-program/",
  Coins = "/price/",
  Okx = "/exchanges/okx/",
  Bitget = "/exchanges/bitget/",
  Binance = "/exchanges/binance/",
  Bybit = "/exchanges/bybit/",
  NotFound = "*",
}

export enum ErrorType {
  General = "Something went wrong. Please try later",
  KeyLength = "The expected length of both keys is 64 symbols",
  OkxKeyLength = "Invalid credentials. Please try again",
  BybitKeyLength = "The expected length of API key is 18 symbols and secret is 36 symbols.",
}

export enum AuthStatus {
  Auth = "AUTH",
  NoAuth = "NO_AUTH",
  Unknown = "UNKNOWN",
}

export enum NavAccountStatus {
  ComingSoon = "coming-soon",
  Available = "available",
}

export enum StrategyStatus {
  NotStarted = "not-started",
  EmptyData = "empty-data",
  Demo = "demo",
  Started = "started",
  Stopping = "stopping",
  Paused = "paused",
  ComingSoon = "coming-soon",
}

export enum AlertStatus {
  Alert = "alert",
  ComingSoon = "coming-soon",
  NotConnected = "not-connected",
  Paused = "paused",
  Stopping = "stopping",
}

export enum AccountName {
  BinanceSpot = "binance-spot",
  BinanceFutures = "binance-futures",
  Okx = "okx",
  Bitget = "bitget",
  BitgetFutures = "bitget-futures",
  Bybit = "bybit",

  Binance = "binance",
  DiversifiedBinanceSpotBinanceFutures = "diversified-binance-spot-binance-futures",
}

export const ButtonTypes = {
  SUBSCRIBE: "subscribe",
  SUBSCRIBED: "subscribed",
  START: "start",
  DETAILS: "details",
  PAY: "pay",
  RENEW_KEYS: "renew-keys",
};

export const CoinsSorting = {
  INC_MC: "increase_market_cap",
  DESC_MC: "descend_market_cap",
  INC_V24H: "increase_volume24h",
  DESC_V24H: "descend_volume24h",
  INC_C24H: "increase_change24h",
  DESC_C24H: "descend_change24h",
};

export const ConnectSteps = {
  STEP1: "step1",
  STEP2: "step2",
  STEP3: "step3",
};

export const ConnectStepPoints = {
  STEP_POINT1: "step1point",
  STEP_POINT2: "step2point",
  STEP_POINT3: "step3point",
};

export const StepsLineColors = {
  NORMAL: "#DBEAFE",
  DONE: "#95dec5",
};

// ENV
export const AUTH_TELEGRAM_BOT_NAME = process.env
  .REACT_APP_AUTH_TELEGRAM_BOT_NAME as string;
export const AUTH_TELEGRAM_URL = `${process.env.REACT_APP_SITE_ORIGIN}/auth/telegram/`;
export const AUTH_GOOGLE_URL = `${process.env.REACT_APP_SITE_ORIGIN}/auth/google/gateway/`;
export const AUTH_APPLE_URI = process.env
  .REACT_APP_APPLE_REDIRECT_URI as string;
export const AUTH_APPLE_CLIENT_ID = process.env
  .REACT_APP_APPLE_CLIENT_ID as string;
export const AUTH_LOGOUT = `${process.env.REACT_APP_SITE_ORIGIN}/auth/logout/`;

// GENERAL
export const ACCOUNTS_NUMBER = 11;
export const EMAILJS_TEMPLATE_ID = "template_78tea6j";
export const EMAILJS_WITHDRAW_TEMPLATE_ID = "template_rrx8f9u";
export const IP_ADDRESS = "144.76.218.238 194.59.40.18";
export const IP_ADDRESS_WITH_COMMA = "144.76.218.238,194.59.40.18";
export const EMPTY_DATA = "...";
export const RERENDER_DELAY = 500;
export const DESKTOP_WIDTH = 1080;
export const TABLET_WIDTH = 768;
export const FOR_CHART_MORE_MOBILE_WIDTH = 370;
export const FOR_FAQ_CHART_MORE_MOBILE_WIDTH = 400;
export const START_CURRENCY = "usdt";
export const KEY_LENGTH = 64;
export const SOON = "soon";
export const ONLY_DIGITS = /^(-)?[1-9](\d+)?$/;
export const EMAIL_REG_EXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const REDIRECT_ACCOUNT_NAME = "account-for-redirect";
export const LAST_PATHNAME = "last-pathname";
export const SEARCH_ROBOTS = [
  "Googlebot",
  "bingbot",
  "BingPreview",
  "DuckDuckBot",
  "Slurp",
  "Baiduspider",
  "YandexBot",
];
export const TWQ_ERROR1 = "window.twq is not a function";
export const TWQ_ERROR2 = "Uncaught TypeError: window.twq is not a function";

// OKX AND BITGET ERROR CODES
export const OKX_BITGET_COMMON_ERROR = -11111;
export const OKX_BITGET_INVALID_SECRET_KEY = 50113;
export const OKX_BITGET_INVALID_API_KEY = 50119;
export const OKX_BITGET_INVALID_PASSPHRASE = 50105;
export const OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE = 49999;
export const OKX_BITGET_CHANGE_MODE_ERROR = 51070;

// BINANCE FUTURES/SPOT ERROR CODES
export const BINANCE_COMMON_ERROR = -11111;
export const BINANCE_INVALID_SECRET_KEY = 50113;
export const BINANCE_INVALID_API_KEY = 50119;


// BYBIT ERROR CODES
export const BYBIT_COMMON_ERROR = -11111;
export const BYBIT_INVALID_SECRET_KEY = 50113;
export const BYBIT_INVALID_API_KEY = 50119;
export const BYBIT_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE = 49999;
export const BYBIT_CHANGE_MODE_ERROR = 51070;
export const BYBIT_ACCOUNT_NOT_UNIFIED_ERROR = 40402;
export const BYBIT_WRONG_PERMISSIONS = 40301;
export const BYBIT_WRONG_MARGIN_MODE = 40201;
export const BYBIT_KEYS_READ_ONLY = 40401;


// OKX
export const OKX_API_KEY_LENGTH = 36;
export const OKX_SECRET_KEY_LENGTH = 32;
export const OKX_MIN_PASSPHRASE_LENGTH = 8;
export const OKX_MAX_PASSPHRASE_LENGTH = 32;
export const IS_OKX_POPUP_SHOWN = "is-okx-popup-shown";
export const OKX_REGISTER_LINK = "https://www.okx.com/join/72377893";
export const OKX_API_SETTINGS_PAGE_LINK =
  "https://www.okx.com/ru/account/my-api";

// BITGET
export const BITGET_API_KEY_LENGTH = 35;
export const BITGET_SECRET_KEY_LENGTH = 64;
export const BITGET_MIN_PASSPHRASE_LENGTH = 8;
export const BITGET_MAX_PASSPHRASE_LENGTH = 32;
export const IS_BITGET_POPUP_SHOWN = "is-bitget-popup-shown";
export const BITGET_API_SETTINGS_PAGE_LINK =
  "https://www.bitget.com/ru/account/newapi";
export const BITGET_REGISTER_LINK = "https://partner.bitget.com/bg/YAS3TY";

// BYBIT
export const BYBIT_API_KEY_LENGTH = 18;
export const BYBIT_SECRET_KEY_LENGTH = 36;
export const IS_BYBIT_POPUP_SHOWN = "is-bybit-popup-shown";
export const BYBIT_REGISTER_LINK = "https://partner.bybit.com/b/smard";
export const BYBIT_API_SETTINGS_PAGE_LINK =
  "https://www.bybit.com/app/user/api-management";
