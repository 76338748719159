export default function CardCheckedIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 14"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 2c0-1 1-2 2-2h15c1 0 2 1 2 2v10c0 1-1 2-2 2H2c-1 0-2-1-2-2V2Zm16.5-.5h-14c-.5 0-1 .5-1 1v9c0 .5.5 1 1 1h14c.5 0 1-.5 1-1v-9c0-.5-.5-1-1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M1.5 3.5h16v3h-16v-3ZM4.39 7.07c-.45.12-.77.31-1.11.65-.99.99-1 2.5-.03 3.52.55.58 1.3.84 2.14.74a2.54 2.54 0 0 0 2.1-2.87c-.16-.99-.93-1.84-1.88-2.06a3.23 3.23 0 0 0-1.22.02ZM6.2 8.64c.21.27.17.36-.6 1.13-.92.93-.91.93-1.45.38-.45-.44-.5-.6-.27-.82.17-.18.4-.18.63.02l.18.16.5-.5c.45-.47.52-.51.7-.51.15 0 .23.03.31.14Z"
      />
    </svg>
  );
}
