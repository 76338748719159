import Hint from "../hint/hint";
import TableDate from "../table-items/table-date";
import VisaIcon from "../../icons/payment/visa-icon";
import MastercardIcon from "../../icons/payment/mastercard-icon";
import WalletIcon from "../../icons/payment/wallet-icon";
import PaymentsLastSkeleton from "../skeletons/payments-last-skeleton";
import PlusIcon from "../../icons/sign/plus-icon";
import MinusIcon from "../../icons/sign/minus-icon";
import { AuthStatus, HINTS_TEXT } from "../../const";
import { useAppSelector } from "../../hooks";
import { localInvoiceAmount } from "../../utils/amount";

export default function PaymentsLast(): JSX.Element {
  const {
    lastPayments,
    authStatus,
    isLastPaymentsLoading,
    isPaymentMethodsLoading,
    isFailedTransactionsLoading,
  } = useAppSelector((state) => state);
  const payments = lastPayments;

  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isLoading =
    isLastPaymentsLoading ||
    isPaymentMethodsLoading ||
    isFailedTransactionsLoading;

  const hint = (
    <Hint
      block="payments-last"
      type="block__hint"
      id="payments/last"
      hintText={HINTS_TEXT["payments/last"]}
    />
  );

  const titleRow = (
    <li className="payments-last__row">
      <p className="payments-last__title payments-last__title--date">Date</p>
      <p className="payments-last__title payments-last__title--reason">
        Reason
      </p>
      <p className="payments-last__title payments-last__title--source">
        Source
      </p>
      <p className="payments-last__title payments-last__title--amount">
        Amount
      </p>
    </li>
  );

  const displayAmount = (amount: number) => {
    return `$${localInvoiceAmount(Math.abs(amount))}`;
  };

  const checkAmountColor = (amount: number) => {
    const formatted = amount;

    if (formatted === 0) {
      return "";
    }

    if (formatted > 0) {
      return "payments-last__absolute--plus";
    }

    return "payments-last__absolute--minus";
  };

  const checkSignIcon = (amount: number) => {
    const formatted = amount;

    if (formatted === 0) {
      return "";
    }

    if (formatted > 0) {
      return (
        <span className="payments-last__amount-icon payments-last__amount-icon--plus">
          <PlusIcon />
        </span>
      );
    }

    return (
      <span className="payments-last__amount-icon payments-last__amount-icon--minus">
        <MinusIcon />
      </span>
    );
  };

  const findIcon = (icon: string) => {
    switch (icon) {
      case "visa":
        return <VisaIcon />;
      case "mastercard":
        return <MastercardIcon />;
      default:
        return <WalletIcon />;
    }
  };

  return (
    <section className="block payments__item payments__last payments-last">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Last payments</h2>
        {isNoAuth && hint}
      </div>

      {isLoading ? (
        <PaymentsLastSkeleton />
      ) : payments.length ? (
        <ul className="payments-last__table">
          {titleRow}
          {payments.map((item, index) => (
            <li className="payments-last__row" key={index}>
              {/* DATE */}
              <TableDate date={item.timestamp} />

              {/* REASON */}
              <div className="payments-last__reason">
                <p className="payments-last__text payments-last__text--reason">
                  {item.reason}
                </p>
              </div>

              {/* SOURCE */}
              <div className="payments-last__method">
                <span
                  className={`payments-last__icon payments-last__icon--${item.method.icon}`}
                >
                  {findIcon(item.method.icon)}
                </span>
                <p className="payments-last__text">{item.method.text}</p>
              </div>

              {/* AMOUNT */}
              <div className="payments-last__amount">
                {typeof item.amount === "string" ? (
                  <p className="payments-last__absolute payments-last__absolute--pending">
                    {item.amount}
                  </p>
                ) : (
                  <>
                    {checkSignIcon(item.amount)}
                    <p
                      className={`payments-last__absolute ${checkAmountColor(
                        item.amount
                      )}`}
                    >
                      {displayAmount(item.amount)}
                    </p>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="table-note">You will see your last payments here.</p>
      )}
    </section>
  );
}
