import { Navigate } from "react-router-dom";

import { AppRoute, AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getRedirectAccount } from "../../services/redirect-account";
import { setFirstScreenRendered } from "../../store/actions";
import { isSearchRobot } from "../../utils/general";

// 1) Для редиректа HIW на Overview в Auth режиме в качестве 1й страницы
// 2) Для редиректа HIW на Details в Auth режиме в качестве 1й страницы...
// при переходе с кнопок стратегий в HIW
export default function FirstMainRoute(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus } = useAppSelector((state) => state);

  const navigateToManual = () => {
    // Для игнора FirstMainRoute после и предотвращения overlooping в Auth режиме
    dispatch(setFirstScreenRendered(true));
    return <Navigate to={AppRoute.Manual} />;
  };

  const checkFirstRoute = () => {
    if (authStatus === AuthStatus.Auth) {
      const account = getRedirectAccount();
      if (account) {
        return <Navigate to={`${AppRoute.Accounts}/${account}/`} />;
      }
      return <Navigate to={AppRoute.Overview} />;
    }

    // NoAuth
    return navigateToManual();
  };

  return isSearchRobot() ? (
    navigateToManual()
  ) : authStatus === AuthStatus.Unknown ? (
    <></>
  ) : (
    checkFirstRoute()
  );
}
