import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import Button from "../../../button/button";
import ConnectPoint1 from "../../points/connect-point1";
import ConnectHelp from "../../general/connect-help";
import {
  AccountName,
  ConnectSteps,
  BYBIT_API_SETTINGS_PAGE_LINK,
} from "../../../../const";

type BybitConnectStep1Props = {
  pointId: string;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isStepsAnimationEnd?: boolean;
};

export default function BybitConnectStep1({
  pointId,
  activeStep,
  setActiveStep,
  isStepsAnimationEnd,
}: BybitConnectStep1Props): JSX.Element {
  const nextBtnRef = useRef<HTMLButtonElement | null>(null);

  const isStepActive = activeStep === ConnectSteps.STEP1;
  const blockClass = `${isStepActive ? "block--active" : "block--disabled"}`;

  const handleNextClick = () => {
    setActiveStep(ConnectSteps.STEP2);
  };

  useEffect(() => {
    if (nextBtnRef.current && isStepActive && isStepsAnimationEnd) {
      nextBtnRef.current.focus();
    }
  }, [isStepActive, isStepsAnimationEnd]);

  return (
    <div
      className={`details__item connect-step block block--step ${blockClass}`}
    >
      <h3 className="block__heading">
        Log in to Bybit and&nbsp;create&nbsp;API&nbsp;keys
      </h3>
      <p className="connect-step__intro">
        Go to{" "}
        <a
          className="link"
          href={BYBIT_API_SETTINGS_PAGE_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          API section
        </a>{" "}
        on Bybit, create new key and click "Connect to Third-Party Applications" with
        the&nbsp;following&nbsp;settings:
      </p>
      <ul className="connect-step__list">
        <li className="connect-step__item">Application name: SMARD</li>
        <li className="connect-step__item">Permissions: Read-Write</li>
        <li className="connect-step__item">Checkbox: Unified Trading</li>
      </ul>
      <div className="connect-step__step1-btn-wrapper">
        <Button
          pattern="white-blue"
          text="Next"
          icon="arrow-right"
          type="button"
          handleClick={handleNextClick}
          ref={nextBtnRef}
        />
        <ConnectHelp accountName={AccountName.Bybit} />
      </div>
      <ConnectPoint1 pointId={pointId} accountName={AccountName.Bybit} />
    </div>
  );
}
