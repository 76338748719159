export default function GoodInStarIcon(): JSX.Element {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.02509e-07C12.4149 -0.000435873 11.8295 0.283337 11.5955 0.851316L8.47738 7.49968L1.30571 8.63477C0.682088 8.79693 0.214371 9.28339 0.0584647 9.76986C-0.0974412 10.4185 0.0584649 11.0671 0.526183 11.3914L5.67108 16.5804L4.42383 24.0395C4.26792 24.6881 4.57973 25.3368 5.04745 25.6611C5.51517 25.9854 6.13879 26.1475 6.76242 25.8232L13 22.5003V5.02509e-07Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.02509e-07C13.5851 -0.000435873 14.1705 0.283337 14.4045 0.851316L17.5226 7.49968L24.6943 8.63477C25.3179 8.79693 25.7856 9.28339 25.9415 9.76986C26.0974 10.4185 25.9415 11.0671 25.4738 11.3914L20.3289 16.5804L21.5762 24.0395C21.7321 24.6881 21.4203 25.3368 20.9525 25.6611C20.4848 25.9854 19.8612 26.1475 19.2376 25.8232L13 22.5003V5.02509e-07Z"
        fill="#2563EB"
      />
      <g clipPath="url(#clip0_4980_11049)">
        <path
          d="M16.0407 17.8772H13.4315C12.0765 17.8772 10.7518 17.6708 9.49429 17.2636C9.37088 17.2237 9.29004 17.1271 9.29004 17.0199V13.1628C9.29004 13.0738 9.34588 12.9909 9.43843 12.9425C9.45764 12.9324 11.3709 11.9218 11.843 10.9809L12.4723 9.65103C12.6317 9.26707 12.9112 9.11064 13.1182 9.047C13.3862 8.96466 13.6878 8.99113 13.9676 9.12157C14.4163 9.33082 14.5406 9.93931 14.3587 11.0365C14.2889 11.4581 14.1885 11.8609 14.1151 12.1293H16.5097C17.1352 12.1293 17.644 12.5399 17.644 13.0447C17.644 13.2376 17.5697 13.4168 17.4429 13.5646C17.7738 13.7247 17.9958 14.017 17.9958 14.3504C17.9958 14.6837 17.7738 14.9761 17.4429 15.1362C17.5697 15.2839 17.644 15.4631 17.644 15.656C17.644 16.0177 17.3828 16.331 17.0047 16.4797C17.1127 16.6197 17.1751 16.785 17.1751 16.9617C17.175 17.4665 16.6662 17.8772 16.0407 17.8772Z"
          fill="#DCE7FA"
        />
        <path
          d="M17.9957 14.3503C17.9957 14.0169 17.7738 13.7246 17.4428 13.5645C17.5696 13.4168 17.644 13.2375 17.644 13.0446C17.644 12.5398 17.1351 12.1292 16.5096 12.1292H14.115C14.1884 11.8608 14.2888 11.458 14.3587 11.0364C14.5406 9.93922 14.4163 9.33073 13.9675 9.12148C13.7213 9.00667 13.4581 8.97251 13.2158 9.02211V17.8753C13.2876 17.8765 13.3595 17.8771 13.4315 17.8771H16.0407C16.6661 17.8771 17.175 17.4665 17.175 16.9617C17.175 16.7849 17.1126 16.6197 17.0046 16.4796C17.3828 16.331 17.644 16.0176 17.644 15.656C17.644 15.4631 17.5696 15.2839 17.4429 15.1361C17.7738 14.976 17.9957 14.6836 17.9957 14.3503Z"
          fill="#A0CBFF"
        />
        <path
          d="M9.61522 17.2807H7.32931C7.14959 17.2807 7.00391 17.1631 7.00391 17.0181V13.161C7.00391 13.016 7.14961 12.8984 7.32931 12.8984H9.61522C9.79494 12.8984 9.94063 13.016 9.94063 13.161V17.0181C9.94065 17.1631 9.79496 17.2807 9.61522 17.2807Z"
          fill="#2563EB"
        />
      </g>
      <defs>
        <clipPath id="clip0_4980_11049">
          <rect
            width="11"
            height="8.87719"
            fill="white"
            transform="translate(7 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
