import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import LogoIcon from "../icons/logo-icon";
import { useAppSelector } from "../../hooks";
import { AppRoute, AuthStatus } from "../../const";
import { trackEvent } from "../../utils/tracking";

export default function CoinBanner(): JSX.Element {
  const navigate = useNavigate();
  const { authStatus } = useAppSelector((state) => state);

  const handleBannerBtnClick = () => {
    if (authStatus === AuthStatus.NoAuth) {
      navigate(AppRoute.Manual);
    } else {
      navigate(AppRoute.Overview);
    }
    trackEvent("BannerBtn", "Clicked");
  };

  return (
    <div className="coin__item coin-description__banner">
      <div className="coin-description__banner-text">
        <span className="coin-description__banner-logo">
          <LogoIcon />
        </span>
        <p className="coin-description__banner-title">
          <em>Trade crypto like a pro</em> without&nbsp;being&nbsp;one
        </p>
        <Button
          classElement="coin-description__banner-btn"
          pattern="white-blue"
          text="Get started"
          icon="arrow-right"
          type="button"
          handleClick={handleBannerBtnClick}
        />
      </div>
    </div>
  );
}
