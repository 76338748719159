import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import "./sass/index.scss";
import App from "./components/app/app";
import { store } from "./store";
import { fetchNavAccounts } from "./store/api-actions";
import {
  checkAccountStatuses,
  checkAuthedAccounts,
  checkBinanceFuturesConnected,
  checkBinanceSpotConnected,
  checkBitgetConnected,
  checkBybitConnected,
  checkOkxConnected,
  fetchUserProfile,
} from "./store/api-actions/user";
import {
  fetchBinanceFuturesDemo,
  fetchBinanceSpotDemo,
  fetchBitgetFuturesDemo,
  fetchBybitDemo,
  fetchOkxDemo,
} from "./store/api-actions/demo";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

store.dispatch(checkAuthedAccounts());
store.dispatch(fetchUserProfile());
store.dispatch(checkAccountStatuses());
store.dispatch(fetchNavAccounts());
store.dispatch(checkOkxConnected());
store.dispatch(checkBitgetConnected());
store.dispatch(checkBybitConnected());
store.dispatch(checkBinanceSpotConnected());
store.dispatch(checkBinanceFuturesConnected());
store.dispatch(fetchOkxDemo());
store.dispatch(fetchBinanceFuturesDemo());
store.dispatch(fetchBinanceSpotDemo());
store.dispatch(fetchBybitDemo());
store.dispatch(fetchBitgetFuturesDemo());

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);
