export default function DemoIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 57 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M.38 13.03H5.3c3.96 0 6.7-2.75 6.7-6.3C12 3.15 9.25.43 5.3.43H.4v12.6Zm2.77-2.5v-7.6H5.3c2.27 0 3.8 1.57 3.8 3.8a3.6 3.6 0 0 1-3.8 3.8H3.15Zm11.71 2.5h9.6v-2.46H17.6V7.92h5.94V5.46h-5.94V2.9h6.75V.43h-9.5v12.6Zm12.46 0h2.72V4.86l3.5 5.33h.08l3.55-5.38v8.22h2.75V.43h-2.99l-3.31 5.33L30.3.43h-3v12.6Zm22.15.22a6.52 6.52 0 0 0 6.71-6.52A6.46 6.46 0 0 0 49.5.22a6.52 6.52 0 0 0-6.71 6.51 6.46 6.46 0 0 0 6.68 6.52Zm.03-2.56a3.83 3.83 0 0 1-3.81-3.96c0-2.2 1.54-3.96 3.78-3.96 2.23 0 3.81 1.8 3.81 3.96 0 2.2-1.55 3.96-3.78 3.96Z"
      />
    </svg>
  );
}
