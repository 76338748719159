import DollarIcon from "../../icons/steps/dollar-icon";

type ConnectPoint1Props = {
  pointId: string;
  isPointActive: boolean;
};

export default function ConnectPoint3({
  pointId,
  isPointActive,
}: ConnectPoint1Props): JSX.Element {
  return (
    <div
      className={`connect-point ${
        isPointActive ? "connect-point--active" : ""
      }`}
      id={pointId}
    >
      <span className="connect-point__icon connect-point__icon--dollar">
        <DollarIcon />
      </span>
    </div>
  );
}
