import { SyntheticEvent, forwardRef } from "react";

import ArrowLeftIcon from "../icons/btn/arrow-left-icon";
import ArrowRightIcon from "../icons/btn/arrow-right-icon";
import ArrowLoaderIcon from "../icons/btn/arrow-loader";
import KeyInCircleIcon from "../icons/btn/key-in-circle-icon";
import ArrowUpIcon from "../icons/btn/arrow-up-icon";
import TriangleDownIcon from "../icons/triangle/triangle-down-icon";
import TriangleUpIcon from "../icons/triangle/triangle-up-icon";
import EyeIcon from "../icons/btn/eye-icon";
import ErrorIcon from "../icons/action/error-icon";
import CreditCardIcon from "../icons/nav/credit-card-icon";
import SuccessIcon from "../icons/action/success-icon";
import PlusInCircleIcon from "../../icons/button/plus-in-circle-icon";
import ChainIcon from "../../icons/button/chain-icon";

type ButtonProps = {
  classElement?: string;
  size?: string;
  pattern: string;
  text: string;
  icon: string;
  type: "button" | "submit" | "link";
  linkAddress?: string;
  isSelfLink?: boolean;
  handleClick?: (evt: SyntheticEvent) => void;
};

function Button(
  {
    classElement,
    size,
    pattern,
    text,
    icon,
    type,
    linkAddress,
    isSelfLink,
    handleClick,
  }: ButtonProps,
  ref: any
): JSX.Element {
  const checkIcon = () => {
    switch (icon) {
      case "arrow-right":
        return <ArrowRightIcon />;
      case "arrow-left":
        return <ArrowLeftIcon />;
      case "arrow-up":
        return <ArrowUpIcon />;
      case "arrow-loader":
        return <ArrowLoaderIcon />;
      case "key-in-circle":
        return <KeyInCircleIcon />;
      case "triangle-down":
        return <TriangleDownIcon />;
      case "triangle-up":
        return <TriangleUpIcon />;
      case "error":
        return <ErrorIcon />;
      case "eye":
        return <EyeIcon />;
      case "credit-card":
        return <CreditCardIcon />;
      case "check":
        return <SuccessIcon />;
      case "check-loader":
        return <SuccessIcon />;
      case "plus-in-circle":
        return <PlusInCircleIcon />;
      case "chain":
        return <ChainIcon />;
      default:
        return null;
    }
  };

  const btnSize = size ? `btn--${size}` : "";
  const btnClass = classElement ?? "";

  return type === "link" ? (
    <a
      className={`btn ${btnSize} btn--${pattern} ${classElement}`}
      href={linkAddress}
      target={isSelfLink ? "_self" : "_blank"}
      rel="noopener noreferrer"
      onClick={handleClick}
    >
      <span className={`btn__icon btn__icon--${icon}`}>{checkIcon()}</span>
      <span className="btn__text">{text}</span>
    </a>
  ) : (
    <button
      className={`btn ${btnSize} btn--${pattern} ${btnClass}`}
      onClick={handleClick}
      type={type}
      ref={ref}
    >
      <span className={`btn__icon btn__icon--${icon}`}>{checkIcon()}</span>
      <span className="btn__text">{text}</span>
    </button>
  );
}

export default forwardRef<HTMLButtonElement | null, ButtonProps>(Button);
