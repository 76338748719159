import GridItem from "./grid-item";

export default function PartnerLogos(): JSX.Element {
  return (
    <ul className="grid">
      <GridItem name="Criptonosation" img="partner-logo/cryptonisation.svg" />
      <GridItem
        name="Coin code cap"
        img="partner-logo/coin-code-cap.svg"
        link="https://signals.coincodecap.com/"
      />
      <GridItem
        name="Software testing help"
        img="partner-logo/software-testing-help.svg"
      />
    </ul>
  );
}
