export default function PlusIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.35059"
        y="0.751343"
        width="8.99752"
        height="1.6875"
        transform="rotate(90.0846 5.35059 0.751343)"
        fill="currentColor"
      />
      <rect y="4.4054" width="9" height="1.68704" fill="CurrentColor" />
    </svg>
  );
}
