import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CardForm from "../card-form/card-form";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_KEY as string
);

type CardFormWrapperType = {
  cardsLength: number;
};

export default function CardFormWrapper({
  cardsLength,
}: CardFormWrapperType): JSX.Element {
  const options = {
    locale: "en",
  } as const;

  return (
    <Elements stripe={stripePromise} options={options}>
      <CardForm cardsLength={cardsLength} />
    </Elements>
  );
}
