export default function SandglassIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 11 11"
    >
      <circle cx="5.8" cy="5.2" r="4.5" stroke="currentColor" />
      <path
        fill="currentColor"
        d="M4.5 1.9c-.4 0-.7.3-.7.7v1c0 .2 0 .4.2.5l1.1 1.1L4 6.4l-.2.4v1c0 .5.3.8.7.8H7c.4 0 .7-.3.7-.7v-1c0-.2 0-.4-.2-.5L6.5 5.2l1.1-1.1.2-.5v-1c0-.4-.3-.7-.7-.7H4.5ZM7 6.7v.9c0 .1-.1.3-.3.3h-2a.3.3 0 0 1-.3-.3v-.9l1.3-1.3 1.3 1.3ZM5.8 5.1 4.5 3.7V3c0-.2.1-.3.3-.3h2c.2 0 .3.1.3.3v.8L5.8 5.1Z"
      />
    </svg>
  );
}
