import { useEffect, useRef } from "react";

import PartnershipForm from "../partnership-form/parnership-form";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setPartnershipFormFocused } from "../../store/actions";

export default function PartnershipBlock(): JSX.Element {
  const dispatch = useAppDispatch();
  const formTitleRef = useRef<null | HTMLHeadingElement>(null);
  const { isPartnershipFormFocused } = useAppSelector((state) => state);

  useEffect(() => {
    if (isPartnershipFormFocused && formTitleRef.current) {
      const scrolledPixels = window.scrollY;
      const viewportElTop = formTitleRef.current.getBoundingClientRect().top;
      const top = scrolledPixels + viewportElTop - 80;

      window.scrollTo({
        top: top as number,
        behavior: "smooth",
      });
    }
  }, [isPartnershipFormFocused, formTitleRef]);

  useEffect(() => {
    const handleClick = () => {
      if (isPartnershipFormFocused) {
        dispatch(setPartnershipFormFocused(false));
        (document.activeElement as HTMLElement).blur();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [dispatch, isPartnershipFormFocused]);

  return (
    <section className="landing__item partnership-block">
      <div className="partnership-block__wrapper">
        <h2 className="landing__title" ref={formTitleRef}>
          SMARD is dedicated to providing our affiliates
          with&nbsp;exceptional&nbsp;terms
        </h2>
        <p className="partnership-block__intro">
          Beyond the conventional revenue share and discounts, we're committed
          to customizing solutions that align perfectly with your unique
          requirements. Please reach out to our team for a detailed discussion.
        </p>
        <PartnershipForm />
      </div>
      <div className="partnership-block__img"></div>
    </section>
  );
}
