import ReactSlider from "react-slider";

type PricingSliderProps = {
  setValue: React.Dispatch<React.SetStateAction<number>>;
  value: number;
  type: PricingSliderTypes;
};

export enum PricingSliderTypes {
  FEES = "fees",
  AMOUNT = "amount",
}

function PricingSlider({ value, type, setValue }: PricingSliderProps) {
  const handleChange = (valueNow: number) => {
    setValue(valueNow);
  };

  return (
    <div className="pricing__slider-wrapper">
      <div className="pricing-slider__heading">
        {type === PricingSliderTypes.AMOUNT
          ? "Initial amount"
          : "Last 30 days performance"}
      </div>
      <ReactSlider
        className="pricing__slider"
        ariaLabel="Audience investment"
        thumbClassName="pricing__thumb"
        trackClassName="pricing__track"
        min={type === PricingSliderTypes.AMOUNT ? 1000 : -5}
        max={type === PricingSliderTypes.AMOUNT ? 20000 : 10}
        step={1}
        defaultValue={value}
        onChange={handleChange}
        renderThumb={(props, state) => (
          <div {...props}>
            <span className="pricing__mark">
              {type === PricingSliderTypes.AMOUNT ? "$" : "%"}
            </span>
            <span className="pricing__value">{state.valueNow}</span>
          </div>
        )}
      />
    </div>
  );
}

export default PricingSlider;
