import { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import Button from "../button/button";
import Message from "../message/message";
import { PartnershipFormType } from "../../types/general";
import { EMAILJS_TEMPLATE_ID, EMAIL_REG_EXP } from "../../const";
import { trackEvent } from "../../utils/tracking";

export default function PartnershipForm(): JSX.Element {
  // isErrorOnEmail только для красной рамки если email не пустое и при этом не прошла валидация
  const [isErrorOnEmail, setErrorOnEmail] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sentFormStatus, setSentFormStatus] = useState<string>("");
  const refBtn = useRef<null | HTMLButtonElement>(null);

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { isValid },
  } = useForm<PartnershipFormType>({ mode: "onChange" });

  const onSubmit: SubmitHandler<PartnershipFormType> = () => {
    setLoading(true);
    const data = getValues(["name", "email", "message", "telegram"]);
    const [name, email, message, telegram] = data;

    const onSuccess = (response: any) => {
      setSentFormStatus("success");
      setLoading(false);
      reset();
      trackEvent("AffiliateForm", "Sent");

      if (refBtn.current) {
        refBtn.current.blur();
      }
    };

    const onError = (err: any) => {
      setSentFormStatus("error");
      setLoading(false);
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        EMAILJS_TEMPLATE_ID,
        {
          from_name: name,
          // именно тернарный оператор, а не ??, тк и пустая строка отсылается
          telegram: telegram ? telegram : "The field is empty",
          email: email ? email : "The field is empty",
          message: message,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string
      )
      .then(
        (response) => {
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      );
  };

  // handleChange
  const handleInput = () => {
    if (sentFormStatus) {
      setSentFormStatus("");
    }
  };

  const checkIfEmailValid = (value: string) =>
    EMAIL_REG_EXP.test(value.toString().trim());
  const handleEmailChange = () => {
    const data = getValues(["email"]);
    const [email] = data;
    setErrorOnEmail(!checkIfEmailValid(email) && Boolean(getValues("email")));
  };

  // Применяется общая валидация полей из-за условия
  const validateForm = () => {
    // Boolean тк важно булевое значение возвращать
    if (getValues().name || getValues().email || getValues().telegram) {
      // Проверка, тк при монтировании объекта идет проверка на фалидацию формы
      // и значения будут у всех undefined, с методом trim() будет ошибка
      // При вводе любого поля, остальные пустые поля уже ""
      return (
        Boolean(getValues().name.trim()) &&
        (checkIfEmailValid(getValues().email) ||
          Boolean(getValues().telegram.trim()))
      );
    }
  };

  return (
    <form className="partnership-form" onSubmit={handleSubmit(onSubmit)}>
      <p className="partnership-form__title">
        Submit the form and start earning
      </p>
      <label className="visually-hidden" htmlFor="user-name">
        Name
      </label>
      <input
        className="partnership-form__field field"
        id="user-name"
        type="text"
        placeholder="Name"
        onInput={handleInput}
        {...register("name", {
          // единственный параметр всегда обязательный, но увсех стоит общая валидация, поэтому required необязателен
          validate: validateForm,
        })}
      />

      <label className="visually-hidden" htmlFor="telegram">
        Telegram
      </label>
      <input
        className="partnership-form__field field"
        id="telegram"
        type="text"
        placeholder="Telegram"
        onInput={handleInput}
        {...register("telegram", {
          validate: validateForm,
        })}
      />

      <label className="visually-hidden" htmlFor="user-email">
        Email address
      </label>
      <input
        className={`partnership-form__field field ${
          isErrorOnEmail ? "field--invalid" : ""
        }`}
        type="text"
        id="user-email"
        placeholder="Email"
        {...register("email", {
          onChange: handleEmailChange,
          validate: validateForm,
        })}
      />

      <label className="visually-hidden" htmlFor="user-message">
        Message
      </label>
      <textarea
        className="field partnership-form__field partnership-form__field--comment"
        id="user-message"
        placeholder="Please describe your methods for attracting clients in as much detail as possible, including the amount, source, and geo of traffic. Also, please attach links to your websites and other media resources you plan to use. Applications without detailed information will not be considered."
        onInput={handleInput}
        {...register("message")}
      ></textarea>

      <div className="partnership-form__btn-wrapper">
        <Button
          classElement={`partnership-form__btn  ${
            isLoading || !isValid ? "btn--disabled" : ""
          }`}
          pattern="white-blue"
          text="Send"
          icon={isLoading ? "arrow-loader" : "arrow-right"}
          type="submit"
          ref={refBtn}
        />
        <p className="partnership-form__note">
          or send us email at{" "}
          <a className="link" href="mailto:affiliates@smard.club">
            affiliates@smard.club
          </a>
        </p>
      </div>

      {/* Message */}

      {!sentFormStatus ? (
        <Message message="" />
      ) : sentFormStatus === "success" ? (
        <Message
          status="success"
          message="Your message was successfully sent"
        />
      ) : (
        <Message
          status="error"
          message="Your message was not sent. Please try later"
        />
      )}
    </form>
  );
}
