export const StateAction = {
  User: {
    // Auth
    RequireAuth: "user/requireAuth",
    LoadUserAuthAccountsStatus: "user/loadUserAuthAccountsStatus",
    LoadUserProfile: "user/loadUserProfile",
    SetUserProfileLoading: "user/setUserProfileLoading",
    // Other
    SendUserAppleInfo: "user/sendUserAppleInfo",
    RedirectToRoute: "user/redirectToRoute",
    SetAccountStatuses: "user/setAccountStatuses",
    setIsAccountStatusesLoading: "user/setIsAccountStatusesLoading",
    SetOkxConnected: "user/setOkxConnected",
    SetBybitConnected: "user/setBybitConnected",
    SetBinanceSpotConnected: "user/setBinanceSpotConnected",
    SetBinanceFuturesConnected: "user/setBinanceFuturesConnected",
    SetOkxRedirectFromApple: "user/setOkxRedirectFromApple",
    SetBitgetConnected: "user/setBitgetConnected",
    SetBitgetRedirectFromApple: "user/setBitgetRedirectFromApple",
  },
  Message: {
    RequireMessages: "messages/requireMessages",
    LoadNotifications: "messages/loadNotifications",
    SetNotificationsLoading: "messages/setNotificationsLoading",
    ResetNotificationsInMessages: "messages/resetNotificationsInMessages",
  },
  Nav: {
    LoadNavAccounts: "nav/loadNavAccounts",
    SetAccountsLoading: "nav/setAccountsLoading",
  },
  Overview: {
    LoadOverviewSummary: "overview/loadOverviewSummary",
    SetOverviewSummaryLoading: "overview/setOverviewSummaryLoading",
    SetOverviewSummaryStatus: "overview/loadOverviewSummaryStatus",
    LoadStrategies: "overview/loadStrategies",
    SetStrategiesLoading: "overview/setStrategiesLoading",
    AddSubscribedAccount: "overview/addSubscribedAccount",
  },
  Strategy: {
    // Details
    LoadStrategyDetails: "strategy/loadStrategyDetails",
    SetStrategyDetailsLoading: "strategy/setStrategyDetailsLoading",

    // Integration
    LoadAmountToStart: "strategy/loadAmountToStart",
    SetLoadAmountToStartStatus: "strategy/setLoadAmountToStartStatus",
    SetLoadAmountToStartLoading: "strategy/setLoadAmountToStartLoading",

    SetCheckPositionModeStatus: "strategy/setCheckPositionModeStatus",
    SetCheckPositionModeLoading: "strategy/setCheckPositionModeLoading",

    SetChangePositionModeStatus: "strategy/setChangePositionModeStatus",
    setCheckAccountUnifiedStatus: "strategy/setCheckAccountUnifiedStatus",
    SetChangePositionModeLoading: "strategy/setChangePositionModeLoading",

    SetSendAmountToStartStatus: "strategy/setSendAmountToStartStatus",
    SetSendAmountToStartLoading: "strategy/setSendAmountToStartLoading",

    SetAddKeysStatus: "strategy/setAddKeysStatus",
    SetAddKeysLoading: "strategy/setAddKeysLoading",

    SetIntegrationOpened: "strategy/setIntegrationOpened",

    SetUseAllAmount: "strategy/setUseAllAmount",
    LoadUseAllAmount: "strategy/loadUseAllAmount",
    SetChangeAmount: "strategy/setChangeAmount",
    SetLoadUseAllAmountIsLoading: "strategy/setLoadUseAllAmountIsLoading",
    SetUseAllAmountIsLoading: "strategy/setUseAllAmountIsLoading",
    SetChangeAmountIsLoading: "strategy/setChangeAmountIsLoading",
    SetChangeAmountStatus: "strategy/SetChangeAmountStatus",
    SetUseAllAmountStatus: "strategy/setUseAllAmountStatus",

    // Renew keys
    SetSendRenewKeysToCheckStatus: "strategy/setSendRenewKeysToCheckStatus",
    SetSendRenewKeysToCheckLoading: "strategy/setSendRenewKeysToCheckLoading",

    SetSendRenewKeysToUpdateStatus: "strategy/setSendRenewKeysToUpdateStatus",
    SetSendRenewKeysToUpdateLoading: "strategy/setSendRenewKeysToUpdateLoading",

    SetRenewKeysOpened: "strategy/setRenewKeysOpened",

    // Divergence
    FixDivergence: "strategy/fixDivergence",
    SetFixDivergenceLoading: "strategy/setFixDivergenceLoading",
  },
  Referrals: {
    LoadReferrals: "referrals/loadReferrals",
    SetReferralsLoading: "referrals/setReferralsLoading",
  },
  Chart: {
    LoadFaqChart: "chart/loadFaqChart",
    LoadCalcChart: "chart/loadCalcChart",
    SetCalcChartLoading: "chart/setCalcChartLoading",
  },
  Payment: {
    // Stripe
    LoadClientSecret: "payment/loadClientSecret",
    // Payment info
    LoadPaymentsInfo: "payment/loadPaymentsInfo",
    SetLoadPaymentsInfoLoading: "payment/setLoadPaymentsInfoLoading",
    // Methods list
    LoadPaymentMethods: "payment/loadPaymentMethods",
    SetPaymentMethodsLoading: "payment/setPaymentMethodsLoading",
    SetSendMethodsOrderStatus: "payment/setSendMethodsOrderStatus",
    SetChargeMe: "payment/setChargeMe",
    // Card
    AddCard: "payment/addCard",
    SetAddCardLoading: "payment/setAddCardLoading",
    SetSendAddedCardStatus: "payment/setSendAddedCardStatus",
    DeleteCard: "payment/deleteCard",
    // Last payments
    LoadLastPayments: "payment/loadLastPayments",
    SetLastPaymentsLoading: "payment/setLastPaymentsLoading",
    // Crypto
    LoadCryptoWallet: "payment/loadCryptoWallet",
    AddDepositInLastPayments: "payment/addDepositInLastPayments",
    SetSendDepositInLastPaymentsStatus:
      "payment/setSendDepositInLastPaymentsStatus",
    // Failed transactions
    LoadFailedTransactions: "payment/loadFailedTransactions",
    SetFailedTransactionsLoading: "payment/setFailedTransactionsLoading",
    SetFailedTransactionsAgainLoading:
      "payment/setFailedTransactionsAgainLoading",
    // Focus
    SetCryptoFormChosen: "payment/setCryptoFormChosen",
    SetCardFormChosen: "payment/setCardFormChosen",
  },
  Interface: {
    // Screen
    SetFirstScreenRendered: "interface/setFirstScreenRendered",
    SetPartnershipFormFocused: "interface/setPartnershipFormFocused",
    // Hint
    SetOpenedHint: "interface/setOpenedHint",
    AddReadHintId: "interface/addReadHintId",
    // Opened
    SetNavAccountsOpened: "interface/setNavAccountsOpened",
    SetPersonalOpened: "interface/setPersonalOpened",
    SetNotificationsOpened: "interface/setNotificationsOpened",
    SetNavOpened: "interface/setNavOpened",
    SetChangeBalanceFormOpened: "interface/setCHangeBalanceFormOpened",
    //
    setCoinToggles: "interface/setCoinToggles",
    //
    SendLogsToServer: "interface/sendLogsToServer",
  },
  Modal: {
    // AuthModal
    SetAuthModalOpened: "modal/setAuthModalOpened",
    SetAuthModalOpenedFromExchanges: "modal/setAuthModalOpenedFromExchanges",
    // ErrorModal
    SetErrorModalOpened: "modal/setErrorModalOpened",
    ResetErrorInMessages: "modal/resetErrorInMessages",
    // StopModal
    SetStopModalOpened: "modal/setStopModalOpened",
    SetStopModalId: "modal/setStopModalId",
    // DeleteCardModal
    SetDeleteCardModalOpened: "modal/setDeleteCardModalOpened",
    SetDeleteCardModalId: "modal/setDeleteCardModalId",
    // OkxModal
    SetOkxModalOpened: "modal/setOkxModalOpened",
    SetOkxModalRendered: "modal/setOkxModalRendered",
    // BitgetModal
    SetBitgetModalOpened: "modal/setBitgetModalOpened",
    SetBitgetModalRendered: "modal/setBitgetModalRendered",
    // BybitModal
    SetBybitModalOpened: "modal/setBybitModalOpened",
    SetBybitModalRendered: "modal/setBybitModalRendered",
  },
  Calendar: {
    SetIntegrationCalendarOpened: "calendar/setIntegrationCalendarOpened",
  },
  Coins: {
    LoadTradableCoins: "coins/loadTradableCoins",
    SetTradableCoinsLoading: "coins/setTradableCoinsLoading",
    LoadCoin: "coins/loadCoin",
    SetCoinLoading: "coins/setCoinLoading",
  },
  // Demo
  Demo: {
    LoadOkxDemo: "demo/loadOkxDemo",
    SetOkxLoading: "demo/setOkxLoading",

    LoadBybitDemo: "demo/loadBybitDemo",
    SetBybitLoading: "demo/setBybitLoading",

    LoadBinanceSpotDemo: "demo/loadBinanceSpotDemo",
    SetBinanceSpotDemoLoading: "demo/setBinanceSpotLoading",

    LoadBinanceFuturesDemo: "demo/loadBinanceFuturesDemo",
    SetBinanceFuturesDemoLoading: "demo/setBinanceFuturesLoading",

    LoadBitgetFuturesDemo: "demo/loadBitgetFuturesDemo",
    SetBitgetFuturesDemoLoading: "demo/setBitgetFuturesLoading",

    LoadDiversifiedDemo: "demo/loadDiversifiedDemo",
  },
};
