export default function ShareIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74228e-08 8L6.99382e-07 22C7.47465e-07 23.1 0.850001 24 1.88889 24L15.1111 24C16.15 24 17 23.1 17 22L17 8C17 6.9 16.15 6 15.1111 6L12.2778 6C11.7583 6 11.3333 6.45 11.3333 7C11.3333 7.55 11.7583 8 12.2778 8L14.1667 8C14.6861 8 15.1111 8.45 15.1111 9L15.1111 21C15.1111 21.55 14.6861 22 14.1667 22L2.83333 22C2.31389 22 1.88889 21.55 1.88889 21L1.88889 9C1.88889 8.45 2.31389 8 2.83333 8L4.72222 8C5.24167 8 5.66667 7.55 5.66667 7C5.66667 6.45 5.24167 6 4.72222 6L1.88889 6C0.85 6 3.89031e-08 6.89 8.74228e-08 8Z"
        fill="currentColor"
      />
      <path
        d="M8.5 13C8.98325 13 9.375 12.4698 9.375 11.9961L9.375 2.92765L10.5063 4.03654C10.7954 4.31991 11.2366 4.36351 11.5725 4.16733L11.6613 4.10788L11.7437 4.03654C12.0591 3.72741 12.0834 3.24099 11.8165 2.90458L11.7437 2.8238L9.11872 0.251168L9.08025 0.215668L9.02089 0.168365L8.92355 0.106911L8.82492 0.0610789L8.73274 0.0307387L8.60333 0.00592312L8.5 -1.5299e-07L8.43417 0.00239071L8.32437 0.0173567L8.22688 0.0426529L8.12968 0.0803297L8.04438 0.125197L7.95273 0.188392L7.88128 0.251168L5.25628 2.8238C4.91457 3.15869 4.91457 3.70165 5.25628 4.03654C5.5717 4.34567 6.06803 4.36945 6.41129 4.10788L6.49372 4.03654L7.625 2.92936L7.625 11.9961C7.625 12.4698 8.01675 13 8.5 13Z"
        fill="currentColor"
      />
    </svg>
  );
}
