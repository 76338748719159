import { StrategyOverviewType } from "../../../types/details";
import { formatStrategy } from "../../../utils/amount";
import {
  addBoardSign,
  displayStrategyAmount,
} from "../../../utils/component/overview";

type DetailsLastMonthProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyOverviewType;
};

export default function DetailsLastMonth({
  strategyStatus,
  isTabletAndMore,
  overview,
}: DetailsLastMonthProps): JSX.Element {
  const currency = overview.currency;
  const amount = overview.profit_last_30_days;
  const percent = overview.profit_last_30_days_percent;

  return amount !== null && percent !== null ? (
    <div className="details-board__total details-board__total--last-month">
      <p className="details-board__amount">
        {addBoardSign(
          strategyStatus,
          formatStrategy(amount).amount,
          formatStrategy(percent).amount
        )}
        <span className="details-board__number">
          {displayStrategyAmount(
            "last-month",
            strategyStatus,
            isTabletAndMore,
            amount,
            currency,
            percent
          )}
        </span>
      </p>
      <span className="details-board__text">Last 30 days</span>
    </div>
  ) : (
    <></>
  );
}
