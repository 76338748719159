export default function EyeIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.39258 0.849854C4.43803 0.849854 1.91485 2.68758 0.892578 5.28167C1.91485 7.87576 4.43803 9.71349 7.39258 9.71349C10.3471 9.71349 12.8703 7.87576 13.8926 5.28167C12.8703 2.68758 10.3471 0.849854 7.39258 0.849854ZM7.39258 8.23622C5.76167 8.23622 4.43803 6.91258 4.43803 5.28167C4.43803 3.65076 5.76167 2.32713 7.39258 2.32713C9.02349 2.32713 10.3471 3.65076 10.3471 5.28167C10.3471 6.91258 9.02349 8.23622 7.39258 8.23622ZM7.39258 3.50894C6.41167 3.50894 5.61985 4.30076 5.61985 5.28167C5.61985 6.26258 6.41167 7.0544 7.39258 7.0544C8.37349 7.0544 9.16531 6.26258 9.16531 5.28167C9.16531 4.30076 8.37349 3.50894 7.39258 3.50894Z"
        fill="currentColor"
      />
    </svg>
  );
}
