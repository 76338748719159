type PersonIconProps = {
  isNav?: boolean;
};

export default function PersonIcon({ isNav }: PersonIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 15 15"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.5 6.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Zm0 1.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM0 14.25C0 10.8 2.8 8 6.26 8h2.48C12.2 8 15 10.8 15 14.25a.75.75 0 0 1-1.5 0A4.76 4.76 0 0 0 8.74 9.5H6.26a4.76 4.76 0 0 0-4.76 4.75.75.75 0 0 1-1.5 0Z"
        clipRule="evenodd"
        stroke="currentColor"
        strokeWidth={isNav ? 0.2 : 0}
      />
    </svg>
  );
}
