export default function BellIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 26"
    >
      <path
        fill="CurrentColor"
        d="M19.61 19.72 17.91 18v-6.67c0-4.09-2.16-7.52-5.93-8.42V2c0-1.1-.89-2-1.98-2-1.1 0-1.98.9-1.98 2v.9c-3.78.91-5.93 4.33-5.93 8.43V18L.4 19.72c-.83.84-.25 2.28.92 2.28h17.36c1.19 0 1.77-1.44.94-2.28Zm-4.34-.39H4.73v-8c0-3.3 1.99-6 5.27-6 3.28 0 5.27 2.7 5.27 6v8ZM10 26c1.45 0 2.64-1.2 2.64-2.67H7.36A2.65 2.65 0 0 0 10 26Z"
      />
    </svg>
  );
}
