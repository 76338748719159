import BoardSign from "../board-sign/board-sign";
import PaymentApprovedIcon from "../../icons/payment/payment-approved-icon";
import PaymentsBalanceSkeleton from "../skeletons/payments-balance-skeleton";
import { localAmount } from "../../utils/amount";
import { useAppSelector } from "../../hooks";
import { DepositType } from "../../types/payment";

export default function PaymentsBalance(): JSX.Element {
  const { isPaymentMethodsLoading, paymentMethods } = useAppSelector(
    (state) => state
  );

  const depositObj = paymentMethods.find(
    (item) => item.type === "deposit"
  ) as DepositType;
  const balance = depositObj?.data?.amount;

  const addColorSignInBalance = (amount: number) => {
    const formatted = Math.floor(amount);
    if (formatted < 0) {
      return <BoardSign sign="minus" isColored />;
    }
    return <></>;
  };

  const displayColoredBalance = (amount: number) =>
    `$${localAmount(Math.abs(Math.floor(amount)))}`;

  const checkColorInBalance = (amount: number) => {
    const formatted = Math.floor(amount);
    if (formatted === 0) {
      return "";
    }
    if (formatted > 0) {
      return "payments-balance__amount--plus";
    }
    return "payments-balance__amount--minus";
  };

  return (
    <section className="block payments__item payments-balance">
      <div className="payments-balance__wrapper">
        <span className="payments-balance__icon">
          <PaymentApprovedIcon />
        </span>
        <h2 className="block__heading payments-balance__heading">
          Current balance
        </h2>
      </div>
      {isPaymentMethodsLoading ? (
        <PaymentsBalanceSkeleton />
      ) : (
        (balance || balance === 0) && (
          <p
            className={`payments-balance__amount ${checkColorInBalance(
              balance
            )}`}
          >
            {addColorSignInBalance(balance)}
            {displayColoredBalance(balance)}
          </p>
        )
      )}
    </section>
  );
}
