export default function AffiliateIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0002 11.4431C19.0009 11.9728 18.7951 12.4705 18.4209 12.845L16.9552 14.3117C16.8821 14.385 16.7955 14.4431 16.7001 14.4827C16.6048 14.5223 16.5026 14.5426 16.3995 14.5424C16.2956 14.5426 16.1927 14.522 16.0968 14.4818C16.0008 14.4417 15.9137 14.3828 15.8406 14.3086C15.5336 13.9981 15.5351 13.4961 15.8437 13.1873L16.8261 12.2041H12.3601C11.4802 12.2041 10.7647 12.9243 10.7647 13.8094C10.7647 14.6946 11.4716 15.4147 12.3408 15.4147C12.776 15.4147 13.1289 15.7697 13.1289 16.2076C13.1289 16.6456 12.776 17.0006 12.3408 17.0006C11.4932 17.0006 10.6989 16.6676 10.1046 16.0629C9.51372 15.4616 9.18848 14.6614 9.18848 13.8094C9.18848 12.0497 10.6112 10.6182 12.3601 10.6182H16.7538L15.8452 9.71424C15.5358 9.40618 15.5329 8.90407 15.839 8.5928C16.145 8.28148 16.6441 8.27855 16.9536 8.58657L18.4171 10.0427C18.7924 10.4161 18.9994 10.9134 19.0002 11.4431Z"
        fill="currentColor"
      />
      <path
        d="M7.28651 0.886784L7.28164 0.887844C6.59552 1.03726 5.95207 1.40495 5.40576 1.98165C4.88704 2.53185 4.65373 2.94309 4.47048 3.64429L4.47036 3.64477C3.85185 6.00538 5.68018 8.37017 8.12288 8.37108C10.555 8.36159 12.344 6.25597 11.9617 3.98123C11.5803 1.80465 9.47468 0.39576 7.28651 0.886784Z"
        stroke="currentColor"
        strokeWidth={1.57815}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 16.1466C0 12.2784 3.16421 9.14258 7.06745 9.14258H9.19276C10.1459 9.14258 11.0549 9.32956 11.8846 9.66852C10.9318 9.77729 10.0786 10.2102 9.43438 10.8529C9.35429 10.8494 9.27373 10.8476 9.19276 10.8476H7.06745C4.1144 10.8476 1.72048 13.22 1.72048 16.1466C1.72048 16.6174 1.33534 16.9991 0.860239 16.9991C0.385142 16.9991 0 16.6174 0 16.1466Z"
        fill="currentColor"
      />
    </svg>
  );
}
