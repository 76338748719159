import { useForm } from "react-hook-form";
import { minNotional } from "../../utils/env";
import Button from "../button/button";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useEffect, useState } from "react";
import { AccountName, RERENDER_DELAY } from "../../const";
import { checkIfDesktop } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { useParams } from "react-router-dom";
import {
  loadAmountToStart,
  setChangePositionModeStatus,
  setCheckPositionModeStatus,
  setLoadAmountToStartStatus,
} from "../../store/actions/start";
import {
  loadUseAllAmount,
  setChangeAmountStatus,
  SetChangeBalanceFormOpened,
  setUseAllAmountStatus,
} from "../../store/actions";
import {
  changeAmountAction,
  fetchUseAllAmount,
  setUseAllAmountAction,
} from "../../store/api-actions";
import ChangeBalanceErrorMessage from "./change-balance-error-message";
import { fetchAmountToStartForCurrentAccount } from "./change-balance-fetch-amount-to-start";
import ChangeBalanceFields from "./change-balance-fields";
import UseAllAmountCheckbox from "../use-all-amount-checkbox/use-all-amount-checkbox";

type AccountsPages =
  | AccountName.BitgetFutures
  | AccountName.Bybit
  | AccountName.Okx;

function ChangeBalanceForm() {
  const { accountName } = useParams();

  const dispatch = useAppDispatch();
  const {
    amountToStart,
    isSendAmountForStartLoading,
    isChangePositionModeLoading,
    isLoadAmountToStartLoading,
    changePositionModeStatus,
    loadAmountToStartStatus,
    checkPositionModeStatus,
    isCheckPositionModeLoading,
    useAllAmount,
    changeAmountIsLoading,
    changeAmountStatus,
    useAllAmountStatus,
    strategyDetails,
  } = useAppSelector((state) => state);

  const [isDesktop, setIsDesktop] = useState<boolean>(checkIfDesktop());
  const [isShowMessage, setShowMessage] = useState<boolean>(false);
  const [isUseAllAmount, setIsUseAllAmount] = useState<boolean>(
    useAllAmount ?? false
  );
  const [availableAmount, setAvailableAmount] = useState<number>(0);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    setFocus,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isChangeAmountFail = changeAmountStatus?.isSuccess === false;
  const isUseAllAmountFail = useAllAmountStatus?.isSuccess === false;
  const isLoadAmountSuccess = loadAmountToStartStatus?.isSuccess;
  const isLoading =
    isCheckPositionModeLoading ||
    isSendAmountForStartLoading ||
    isChangePositionModeLoading ||
    isLoadAmountToStartLoading;
  const submitDisabledClass =
    !isValid ||
    isLoading ||
    isChangeModeFail ||
    isCheckModeFail ||
    isLoadAmountFail
      ? "btn--disabled"
      : "";

  const currentBalance = strategyDetails?.overview.under_management;
  const unusedCash = strategyDetails?.current_position?.find(
    (pos) => pos.code === "usdt"
  )?.quantity;
  const coinInfo = Math.floor(amountToStart?.usdt as number);

  const SubmitButton = (
    <Button
      classElement={submitDisabledClass}
      pattern="white-blue"
      text="Change amount"
      icon={isLoading ? "arrow-loader" : "arrow-right"}
      type="submit"
      size="big"
    />
  );

  const onSubmit = () => {
    if (!currentBalance) return;
    const data = getValues(["amount"]);
    const [amount] = data;
    const amountDiff = Number(amount) - currentBalance;

    dispatch(
      changeAmountAction({
        id: accountName as string,
        amount_diff: amountDiff,
        new_amount: amount,
      })
    );

    dispatch(
      setUseAllAmountAction({
        id: accountName as string,
        use_all: isUseAllAmount,
      })
    );
  };

  useEffect(() => {
    if (coinInfo && unusedCash && currentBalance && amountToStart) {
      const maxAmount = currentBalance + coinInfo - Math.floor(unusedCash);
      setAvailableAmount(maxAmount);
      setValue("amount", availableAmount);
    }
  }, [
    amountToStart,
    availableAmount,
    coinInfo,
    currentBalance,
    setValue,
    unusedCash,
  ]);

  useEffect(() => {
    trigger("amount");

    if (useAllAmount) {
      setIsUseAllAmount(useAllAmount);
    }
    if (useAllAmount === false) {
      setFocus("amount");
    }
  }, [availableAmount, setFocus, setValue, trigger, useAllAmount]);

  useEffect(() => {
    dispatch(fetchUseAllAmount(accountName as string));
    dispatch(fetchAmountToStartForCurrentAccount[accountName as AccountsPages]);
  }, [dispatch]);

  useEffect(() => {
    if (
      isLoadAmountFail ||
      isChangeAmountFail ||
      isUseAllAmountFail ||
      (isLoadAmountSuccess && checkPositionModeStatus)
    ) {
      setShowMessage(true);
    }
  }, [
    checkPositionModeStatus,
    isChangeAmountFail,
    isLoadAmountFail,
    isLoadAmountSuccess,
    isUseAllAmountFail,
  ]);

  useEffect(() => {
    if (isUseAllAmount) {
      setValue("amount", availableAmount);
    }
  }, [isUseAllAmount]);

  useEffect(() => {
    return () => {
      dispatch(loadUseAllAmount(null));
      dispatch(loadAmountToStart(null));
      dispatch(setLoadAmountToStartStatus(null));
      dispatch(setCheckPositionModeStatus(null));
      dispatch(setChangePositionModeStatus(null));
      dispatch(setChangeAmountStatus(null));
      dispatch(setUseAllAmountStatus(null));
    };
  }, []);

  useEffect(() => {
    if (changeAmountStatus?.isSuccess && useAllAmountStatus?.isSuccess) {
      dispatch(SetChangeBalanceFormOpened(false));
    }
  }, [changeAmountStatus, dispatch, useAllAmountStatus]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  return (
    <form
      className="change-balance-form block"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h3 className="block__heading">
        Change the amount to be traded by SMARD
      </h3>
      <p className="change-balance-form__intro">
        {`You can increase or decrease it here. Minimum is ${minNotional} USDT.`}
      </p>
      <div className="change-balance-form__fields-wrapper">
        <ChangeBalanceFields
          availableAmount={availableAmount}
          setValue={setValue}
          trigger={trigger}
          register={register}
          isSendAmountForStartLoading={changeAmountIsLoading}
          sendAmountFailed={isChangeAmountFail}
          isUseAllAmount={isUseAllAmount}
          currentBalance={currentBalance ?? 0}
        />
        {isDesktop ? SubmitButton : null}
      </div>
      <div className="change-balance-form__message">
        {isShowMessage ? (
          <ChangeBalanceErrorMessage
            accountName={accountName as AccountName}
            availableAmount={availableAmount}
            changePositionModeStatus={changePositionModeStatus}
            checkPositionModeStatus={checkPositionModeStatus}
            loadAmountToStartStatus={loadAmountToStartStatus}
            sendAmountToStartStatus={changeAmountStatus}
            changeAmountStatus={changeAmountStatus}
            useAllAmountStatus={useAllAmountStatus}
          />
        ) : null}
      </div>
      <UseAllAmountCheckbox
        isUseAllAmount={isUseAllAmount}
        setIsUseAllAmount={setIsUseAllAmount}
      />
      {isDesktop ? null : SubmitButton}
    </form>
  );
}

export default ChangeBalanceForm;
