import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import Tooltip from "../tooltip/tooltip";
import Button from "../button/button";
import PositionDiscrepancySkeleton from "../skeletons/position-discrepancy";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import { formatAmount, localAmount } from "../../utils/amount";
import { DivergenceType } from "../../types/details";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { checkFixingDivergence } from "../../store/api-actions";
import { addShadowIfLongInstrument } from "../../utils/general";
import { checkWidthForDiscrepancyBreak } from "../../utils/breakpoint";

type PositionDiscrepancyProps = {
  divergence: DivergenceType[];
};

export default function PositionDiscrepancy({
  divergence,
}: PositionDiscrepancyProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { accountName } = useParams();
  const { hash } = useLocation();

  const { isFixDivergenceLoading } = useAppSelector((state) => state);
  const blockRef = useRef<null | HTMLElement>(null);

  const [isBreak, setBreak] = useState<boolean>(
    checkWidthForDiscrepancyBreak()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setBreak(checkWidthForDiscrepancyBreak());
    }, RERENDER_DELAY)
  );

  const handleFixOnClick = () => {
    dispatch(checkFixingDivergence(accountName as string));
  };

  useEffect(() => {
    if (hash === "#discrepancy" && blockRef.current) {
      blockRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash, blockRef]);

  return (
    <section
      className="position-discrepancy block block--more-bottom"
      ref={blockRef}
    >
      <h2 className="block__heading">Position discrepancy detected</h2>

      <p className="table-note table-note--alert">
        Your trading activity has been paused due to a discrepancy in the
        balance between your personal account and SMARD’s
        expected&nbsp;position.
        <br />
        Please manually restore SMARD positions.
      </p>

      {isFixDivergenceLoading ? (
        <PositionDiscrepancySkeleton rows={divergence.length + 1} />
      ) : (
        <ul className="position-discrepancy__table">
          <li className="position-discrepancy__row">
            <p className="position-discrepancy__row-heading position-discrepancy__row-heading--instrument">
              Instrument
            </p>
            <p className="position-discrepancy__row-heading position-discrepancy__row-heading--expected">
              Expected
              <br
                className={
                  isBreak
                    ? "position-discrepancy__break"
                    : "position-discrepancy__break--none"
                }
              />{" "}
              position
            </p>

            <p className="position-discrepancy__row-heading position-discrepancy__row-heading--detected">
              Detected
              <br
                className={
                  isBreak
                    ? "position-discrepancy__break"
                    : "position-discrepancy__break--none"
                }
              />{" "}
              position
            </p>
            <p className="position-discrepancy__row-heading position-discrepancy__row-heading--missing">
              Missing
              <br
                className={
                  isBreak
                    ? "position-discrepancy__break"
                    : "position-discrepancy__break--none"
                }
              />{" "}
              quantity
            </p>
          </li>

          {divergence &&
            divergence.map((item, i) => (
              <li className="position-discrepancy__row" key={i}>
                <div className="position-discrepancy__instrument">
                  <img
                    className="position-discrepancy__instrument-logo"
                    src={item.logo_url}
                    alt={`${item.title} logo`}
                  />
                  <p
                    className={`position-discrepancy__instrument-title ${addShadowIfLongInstrument(
                      item.title
                    )}`}
                  >
                    {item.title}
                  </p>
                  <p className="position-discrepancy__instrument-ticker">
                    {item.ticker}
                  </p>
                </div>
                <div className="position-discrepancy__expected">
                  <p className="position-discrepancy__expected-amount">
                    {formatAmount(item.expected_position).amount === null
                      ? formatAmount(item.expected_position).short
                      : `
                      ${localAmount(
                        formatAmount(item.expected_position).amount as number
                      )}${formatAmount(item.expected_position).short}
                    `}
                  </p>
                  {formatAmount(item.expected_position).isAmountTooltip && (
                    <Tooltip amount={localAmount(item.expected_position)} />
                  )}
                </div>

                <div className="position-discrepancy__detected">
                  <p className="position-discrepancy__detected-amount">
                    {formatAmount(item.detected_position).amount === null
                      ? formatAmount(item.detected_position).short
                      : `
                      ${localAmount(
                        formatAmount(item.detected_position).amount as number
                      )}${formatAmount(item.detected_position).short}
                    `}
                  </p>
                  {formatAmount(item.detected_position).isAmountTooltip && (
                    <Tooltip amount={localAmount(item.detected_position)} />
                  )}
                </div>

                <div className="position-discrepancy__missing">
                  <p className="position-discrepancy__missing-amount">
                    {formatAmount(item.lacking_amount).amount === null
                      ? formatAmount(item.lacking_amount).short
                      : `
                      ${localAmount(
                        formatAmount(item.lacking_amount).amount as number
                      )}${formatAmount(item.lacking_amount).short}
                    `}
                  </p>
                  {formatAmount(item.lacking_amount).isAmountTooltip && (
                    <Tooltip amount={localAmount(item.lacking_amount)} />
                  )}
                </div>
              </li>
            ))}
        </ul>
      )}

      <Button
        classElement={`position-discrepancy__btn ${
          isFixDivergenceLoading ? "btn--disabled" : ""
        }`}
        pattern="white-blue"
        text="I've fixed"
        icon={`${isFixDivergenceLoading ? "arrow-loader" : "check"}`}
        type="button"
        handleClick={handleFixOnClick}
      />
    </section>
  );
}
