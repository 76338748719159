import { createAction } from "@reduxjs/toolkit";
import { AmountToStartType, ResponseStatusType } from "../../types/details";
import { StateAction } from "../action-types";

// START
export const loadAmountToStart = createAction<null | AmountToStartType>(
  StateAction.Strategy.LoadAmountToStart
);
export const setLoadAmountToStartStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.SetLoadAmountToStartStatus
  );
export const setLoadAmountToStartLoading = createAction<boolean>(
  StateAction.Strategy.SetLoadAmountToStartLoading
);

// SEND AMOUNT
export const setSendAmountToStartStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.SetSendAmountToStartStatus
  );
export const setSendAmountToStartLoading = createAction<boolean>(
  StateAction.Strategy.SetSendAmountToStartLoading
);

// MODE
export const setCheckPositionModeStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.SetCheckPositionModeStatus
  );
export const setCheckPositionModeLoading = createAction<boolean>(
  StateAction.Strategy.SetCheckPositionModeLoading
);
export const setChangePositionModeStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.SetChangePositionModeStatus
  );
export const setChangePositionModeLoading = createAction<boolean>(
  StateAction.Strategy.SetChangePositionModeLoading
);
export const setCheckAccountUnifiedStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.setCheckAccountUnifiedStatus
  );
