export default function MastercardIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 14"
    >
      <path
        fill="#CBD5E1"
        fillRule="evenodd"
        d="M0 3.23A3.2 3.2 0 0 1 3.17 0h12.66A3.2 3.2 0 0 1 19 3.23v7.54A3.2 3.2 0 0 1 15.83 14H3.17A3.2 3.2 0 0 1 0 10.77V3.23ZM15.83 1.5H3.17c-.88 0-1.67.84-1.67 1.73v7.54c0 .9.79 1.73 1.66 1.73h12.67c.88 0 1.67-.84 1.67-1.73V3.23c0-.9-.8-1.73-1.67-1.73Z"
        clipRule="evenodd"
      />
      <path
        fill="#231F20"
        fillRule="evenodd"
        d="M7.1 10.54v.9h-.19v-.11a.34.34 0 0 1-.28.13c-.26 0-.46-.2-.46-.47 0-.27.2-.47.46-.47.12 0 .22.05.28.13v-.1h.2Zm-.45.16c-.17 0-.27.13-.27.29 0 .16.1.29.27.29.16 0 .27-.13.27-.3 0-.15-.1-.28-.27-.28Zm7.1.29c0-.16.1-.29.26-.29.17 0 .28.13.28.29 0 .16-.11.29-.28.29-.17 0-.27-.13-.27-.3Zm.72-.8v1.25h-.2v-.11a.34.34 0 0 1-.28.13c-.25 0-.45-.2-.45-.47 0-.27.2-.47.45-.47.13 0 .22.05.28.13v-.47h.2Zm-4.93.5c.13 0 .21.08.23.22H9.3c.02-.13.1-.22.24-.22Zm-.44.3c0-.28.18-.47.45-.47.25 0 .43.2.43.47v.08H9.3c.03.16.15.21.27.21.1 0 .19-.03.27-.09l.1.15a.55.55 0 0 1-.38.12c-.27 0-.46-.18-.46-.47Zm2.82 0c0-.16.1-.29.27-.29.16 0 .27.13.27.29 0 .16-.1.29-.27.29s-.27-.13-.27-.3Zm.73-.45v.9h-.2v-.11a.34.34 0 0 1-.28.13c-.26 0-.45-.2-.45-.47 0-.27.2-.47.45-.47.13 0 .22.05.28.13v-.1h.2ZM10.8 11c0 .27.19.47.48.47.14 0 .23-.03.33-.1l-.1-.16a.4.4 0 0 1-.23.08c-.16 0-.28-.12-.28-.29 0-.17.12-.28.28-.29a.4.4 0 0 1 .23.08l.1-.15a.48.48 0 0 0-.33-.11c-.29 0-.48.2-.48.47Zm2.3-.34a.27.27 0 0 1 .23-.13c.04 0 .1.01.14.03l-.06.18a.32.32 0 0 0-.12-.02c-.13 0-.2.08-.2.23v.5h-.2v-.9h.2v.11Zm-5.03-.04a.68.68 0 0 0-.37-.09c-.23 0-.38.1-.38.29 0 .14.11.23.32.26l.09.01c.1.02.16.05.16.1 0 .07-.07.1-.2.1a.48.48 0 0 1-.3-.09l-.1.15c.1.08.24.12.39.12.26 0 .41-.12.41-.3 0-.15-.12-.23-.31-.26l-.1-.01c-.08-.01-.15-.03-.15-.09 0-.07.06-.1.17-.1.12 0 .23.04.28.07l.09-.16Zm2.3.04a.27.27 0 0 1 .23-.13c.04 0 .1.01.14.03l-.06.18a.32.32 0 0 0-.12-.02c-.13 0-.2.08-.2.23v.5h-.2v-.9h.2v.11Zm-1.44-.1h-.32v-.28h-.2v.27h-.18v.18h.18v.4c0 .22.08.34.31.34.09 0 .18-.03.25-.07l-.06-.17a.37.37 0 0 1-.17.06c-.1 0-.13-.06-.13-.15v-.4h.32v-.19ZM6 10.87v.56h-.2v-.5c0-.15-.06-.23-.2-.23-.13 0-.22.08-.22.23v.5h-.2v-.5c0-.15-.06-.23-.19-.23s-.22.08-.22.23v.5h-.2v-.9h.2v.12a.3.3 0 0 1 .26-.14c.14 0 .24.06.3.16.08-.12.2-.16.32-.16.22 0 .35.15.35.36Z"
        clipRule="evenodd"
      />
      <path fill="#FF5F00" d="M10.99 9.03H8v-5.3H11v5.3Z" />
      <path
        fill="#EB001B"
        d="M8.2 6.37c0-1.07.5-2.03 1.3-2.65A3.4 3.4 0 0 0 4 6.37a3.39 3.39 0 0 0 5.5 2.65 3.36 3.36 0 0 1-1.3-2.65"
      />
      <path
        fill="#F79E1B"
        d="M15 6.37a3.39 3.39 0 0 1-5.5 2.65 3.36 3.36 0 0 0 0-5.3A3.4 3.4 0 0 1 15 6.37"
      />
    </svg>
  );
}
