export default function BlueLockIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 28"
    >
      <path
        fill="#2563EB"
        fillRule="evenodd"
        d="M18.9 13.1c0-1.4-1.1-2.5-2.5-2.5h-7V26h7c1.4 0 2.5-1.2 2.5-2.6V13.1Z"
        clipRule="evenodd"
      />
      <path
        fill="#60A5FA"
        d="M2.5 10.6A2.5 2.5 0 0 0 0 13v10.3C0 24.8 1.1 26 2.5 26h7V10.6h-7Z"
      />
      <path
        fill="#A0CBFF"
        d="M9.4 14.8c-1.1 0-2.1 1-2.1 2.4 0 .7.4 1.4 1 1.9v4.3h1.1v-8.6Z"
      />
      <path
        fill="#60A5FA"
        d="M10.7 19c.5-.4.9-1 .9-1.8 0-1.3-1-2.4-2.2-2.4v8.6h1.3v-4.3Z"
      />
      <path
        stroke="#2563EB"
        strokeWidth={1.5}
        d="M15.4 9.7V6a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4v3.7"
      />
      <path stroke="#60A5FA" strokeWidth={1.5} d="M3.4 9.7V6a4 4 0 0 1 4-4h2" />
    </svg>
  );
}
