import { CalcChartType, CalcChartWithCompoundType } from "../../types/chart";
import { addPlusIfPositive, makePositiveAmount } from "../amount";
import { sortAmounts } from "./chart";

export const formatDataForCalcChart = (
  data: CalcChartType[],
  initial: number
) => {
  const formattedData: CalcChartWithCompoundType[] = [
    { timestamp: data[0].timestamp, amount: initial, compound: 0 },
  ];
  let current = initial;

  data.forEach(({ timestamp, performance }, index) => {
    // Отсчет пойдет от второго
    if (performance !== null && index) {
      current = current * (1 + performance / 100);

      const obj = {
        timestamp: timestamp,
        amount: current,
        compound: (current / initial - 1) * 100,
      };

      formattedData.push(obj);
    }
  });

  return formattedData;
};

export const findMinMaxForCalcChart = (amounts: number[]) => {
  const sortedAmounts = [...amounts].sort(sortAmounts);

  return [
    sortedAmounts[0] * 0.9,
    sortedAmounts[sortedAmounts.length - 1] * 1.1,
  ];
};

export const formatCompound = (amount: number) => {
  const positive = makePositiveAmount(amount);

  if (positive < 0.001) {
    return 0;
  }
  return Number(amount.toFixed(2));
};

export const displayCompound = (amount: number) => {
  const formatted = formatCompound(amount);
  if (formatted === 0) {
    return `0%`;
  }
  return `${addPlusIfPositive(formatted)}${formatted}%`;
};
