import { createAction } from "@reduxjs/toolkit";
import { StateAction } from "../action-types";
import { ResponseStatusType } from "../../types/details";

// KEYS
export const setSendRenewKeysToCheckLoading = createAction<boolean>(
  StateAction.Strategy.SetSendRenewKeysToCheckLoading
);
export const setSendRenewKeysToCheckStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.SetSendRenewKeysToCheckStatus
  );

export const setSendRenewKeysToUpdateLoading = createAction<boolean>(
  StateAction.Strategy.SetSendRenewKeysToUpdateLoading
);
export const setSendRenewKeysToUpdateStatus =
  createAction<null | ResponseStatusType>(
    StateAction.Strategy.SetSendRenewKeysToUpdateStatus
  );

// ADD KEYS
export const setAddKeysLoading = createAction<boolean>(
  StateAction.Strategy.SetAddKeysLoading
);
export const setAddKeysStatus = createAction<null | ResponseStatusType>(
  StateAction.Strategy.SetAddKeysStatus
);
