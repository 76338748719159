import { useState } from "react";

import RelativeExtra from "../relative-extra/relative-extra";
import RelativeChart from "../charts/relative-chart";
import OkxIcon from "../../icons/account/okx-icon";
import BinanceSpotIcon from "../../icons/account/binance-spot-icon";
import BinanceFuturesIcon from "../../icons/account/binance-futures-icon";
import BitgetIcon from "../../icons/account/bitget-icon";
import BybitIcon from "../../icons/account/bybit-icon";
import PeriodToggle from "../toggles/period-toggle";
import { AccountName } from "../../const";
import { QuotesItemType, StrategyResultsType } from "../../types/details";

type RelativeCanvasProps = {
  strategyData: QuotesItemType[];
  bench: QuotesItemType[];
  results: StrategyResultsType;
  isMostPopular: boolean;
  account: string;
  findMinMaxMain: (isAllDays: boolean) => number[];
  findMinMaxBench: (isAllDays: boolean) => number[];
};

export default function RelativeCanvas({
  strategyData,
  bench,
  results,
  isMostPopular,
  account,
  findMinMaxMain,
  findMinMaxBench,
}: RelativeCanvasProps): JSX.Element {
  const [isAllDays, setAllDays] = useState<boolean>(true);

  const type = `relative-${account}`;

  const displayLogo = (account: string) => {
    switch (account) {
      case AccountName.Okx:
        return <OkxIcon />;
      case AccountName.BinanceSpot:
        return <BinanceSpotIcon />;
      case AccountName.BinanceFutures:
        return <BinanceFuturesIcon />;
      case AccountName.BitgetFutures:
        return <BitgetIcon />;
      case AccountName.Bybit:
        return <BybitIcon />;
      default:
        <></>;
    }
  };

  return (
    <div className="relative-canvas">
      <span
        className={`relative-canvas__logo relative-canvas__logo--${account}`}
      >
        {displayLogo(account)}
      </span>
      {isMostPopular && <span className="relative-canvas__popular">New</span>}

      <h3 className="relative-canvas__heading">Historical results</h3>
      <div className="relative-canvas__wrapper">
        <div className="relative-canvas__chart">
          <RelativeChart
            main={strategyData}
            bench={bench}
            isAllDays={isAllDays}
            account={account}
            findMinMaxMain={findMinMaxMain}
            findMinMaxBench={findMinMaxBench}
          />
        </div>
        <div className="relative-canvas__bottom">
          <div className="relative-canvas__toggle">
            <PeriodToggle
              type={type}
              isAllDays={isAllDays}
              setAllDays={setAllDays}
            />
          </div>
          <RelativeExtra results={results} account={account} />
        </div>
      </div>
    </div>
  );
}
