export default function ArrowLeftIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13 6.5a6.5 6.5 0 1 0-13 0 6.5 6.5 0 0 0 13 0Zm-1.3 0a5.2 5.2 0 1 1-10.4 0 5.2 5.2 0 0 1 10.4 0Zm-1.95-.65a.65.65 0 1 1 0 1.3H4.82l.84.84.05.06a.65.65 0 0 1-.97.86L2.8 6.96l-.05-.05-.04-.07-.04-.06-.03-.08-.02-.07v-.08L2.6 6.5v-.08l.02-.1.03-.06.03-.07.05-.08.03-.04.03-.03 1.95-1.95.06-.05c.26-.2.63-.18.86.05l.05.06.05.07c.15.25.11.57-.1.79l-.84.84h4.93Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
