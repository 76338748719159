import { useLocation } from "react-router-dom";
import { AppRoute, EMPTY_DATA } from "../../const";
import { ComissionsType, StrategyResultsType } from "../../types/details";
import {
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";
import ChartIcon from "../../icons/chart-icon";
import AnnualizedReturnIcon from "../../icons/annualized-return-icon";
import MonthlyReturnicon from "../../icons/monthly-return-icon";

type DemoResultsProps = {
  results: StrategyResultsType;
  isBitcoin: boolean;
  isMobile: boolean;
};

export default function DemoResults({
  results,
  isBitcoin,
  isMobile,
}: DemoResultsProps): JSX.Element {
  const { pathname } = useLocation();

  const commissions = results?.commissions as ComissionsType;
  const isOkxLanding = pathname === AppRoute.Okx;
  const isBitgetLanding = pathname === AppRoute.Bitget;
  const isBybitLanding = pathname === AppRoute.Bybit;

  return (
    <section className="trading-results-demo block">
      <div className="trading-results__value-container">
        <ChartIcon width={isMobile ? 14 : 21} />
        <li className="trading-results-demo__row">
          <p className="trading-results-demo__value">
            {displayResultsAmount(results.sharpe_ratio)}
          </p>

          <p className="trading-results-demo__name">Sharpe ratio</p>
        </li>
      </div>
      <div className="trading-results__value-container">
        <AnnualizedReturnIcon width={isMobile ? 14 : 21} />

        <li className="trading-results-demo__row">
          <p className="trading-results-demo__value">
            {displayResultsPercent(results.annualized_return)}
          </p>

          <p className="trading-results-demo__name">Annualized return</p>
        </li>
      </div>
      <div className="trading-results__value-container">
        <MonthlyReturnicon width={isMobile ? 14 : 21} />
        <li className="trading-results-demo__row">
          <p className="trading-results-demo__value">
            {displayResultsPercent(results.avg_monthly_return)}
          </p>
          <p className="trading-results-demo__name">Monthly return</p>
        </li>
      </div>
    </section>
  );
}
