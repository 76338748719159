import { Dispatch, SetStateAction, useEffect, useState } from "react";

import BitgetConnectStep1 from "./steps/bitget-steps/bitget-connect-step1";
import BitgetConnectStep2 from "./steps/bitget-steps/bitget-connect-step2";
import BitgetConnectStep3 from "./steps/bitget-steps/bitget-connect-step3";
import { ConnectStepPoints, ConnectSteps } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAddKeysStatus } from "../../store/actions/keys";
import {
  loadAmountToStart,
  setChangePositionModeStatus,
  setCheckPositionModeStatus,
  setLoadAmountToStartStatus,
  setSendAmountToStartStatus,
} from "../../store/actions/start";

type BitgetStepsToConnectProps = {
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isStepsAnimationEnd?: boolean;
};

export default function BitgetStepsToConnect({
  activeStep,
  setActiveStep,
  // эти пропы нужны, так как фокусировка в мобилке при выезде блоков на первой кнопке ломала плавную анимацию, а вследствие с этим, и пунктирные линии
  isStepsAnimationEnd,
}: BitgetStepsToConnectProps): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    isChangePositionModeLoading,
    amountToStart,
    addKeysStatus,
    loadAmountToStartStatus,
    changePositionModeStatus,
    sendAmountToStartStatus,
    isSendAmountForStartLoading,
    isAddKeysLoading,
    isLoadAmountToStartLoading,
    checkPositionModeStatus,
    isCheckPositionModeLoading,
  } = useAppSelector((state) => state);
  const amount = amountToStart?.usdt as number;

  const [availableAmount, setAvailableAmount] = useState<number>(amount);

  useEffect(() => {
    if (amount || amount === 0) {
      setAvailableAmount(Math.floor(amount));
    }
  }, [amount]);

  useEffect(() => {
    return () => {
      dispatch(setAddKeysStatus(null));
      dispatch(loadAmountToStart(null));
      dispatch(setLoadAmountToStartStatus(null));
      dispatch(setCheckPositionModeStatus(null));
      dispatch(setChangePositionModeStatus(null));
      dispatch(setSendAmountToStartStatus(null));
      setActiveStep(ConnectSteps.STEP1);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BitgetConnectStep1
        pointId={ConnectStepPoints.STEP_POINT1}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        isStepsAnimationEnd={isStepsAnimationEnd}
      />
      <BitgetConnectStep2
        pointId={ConnectStepPoints.STEP_POINT2}
        activeStep={activeStep}
        addKeysStatus={addKeysStatus}
        setActiveStep={setActiveStep}
        isLoading={
          isAddKeysLoading ||
          isLoadAmountToStartLoading ||
          isCheckPositionModeLoading
        }
        loadAmountToStartStatus={loadAmountToStartStatus}
        checkPositionModeStatus={checkPositionModeStatus}
      />
      <BitgetConnectStep3
        pointId={ConnectStepPoints.STEP_POINT3}
        activeStep={activeStep}
        availableAmount={availableAmount}
        isLoading={
          isChangePositionModeLoading ||
          isSendAmountForStartLoading ||
          isCheckPositionModeLoading
        }
        setActiveStep={setActiveStep}
        sendAmountToStartStatus={sendAmountToStartStatus}
        changePositionModeStatus={changePositionModeStatus}
        loadAmountToStartStatus={loadAmountToStartStatus}
        checkPositionModeStatus={checkPositionModeStatus}
      />
    </>
  );
}
