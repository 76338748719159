import { useState } from "react";

import CalcChart from "../charts/calc-chart";
import CalcSliders from "./calc-sliders";
import AccountToggles from "../toggles/account-toggles";
import { formatNumberWithSpaces, makePositiveAmount } from "../../utils/amount";
import { CalcChartType, CalcChartsType } from "../../types/chart";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import {
  displayCompound,
  formatCompound,
  formatDataForCalcChart,
} from "../../utils/chart/calc-chart";

type CalcBlockProps = {
  chart: CalcChartsType;
};

export default function CalcBlock({ chart }: CalcBlockProps): JSX.Element {
  const DEFAULT_PERIOD = 30;
  const DEFAULT_INITIAL = 10000;

  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );
  const [isSpot, setSpot] = useState<boolean>(false);
  const [initialAmount, setInitialAmount] = useState<number>(DEFAULT_INITIAL);
  const [period, setPeriod] = useState<number>(DEFAULT_PERIOD);
  const spotData = chart.spot as CalcChartType[];
  const futuresData = chart.futures as CalcChartType[];
  const calcChart = isSpot ? spotData : futuresData;

  const formattedData = formatDataForCalcChart(
    calcChart.slice(-period - 1),
    initialAmount
  );
  const totalCompound = formattedData[formattedData.length - 1].compound;
  const totalAmount =
    Math.round(formattedData[formattedData.length - 1].amount) - initialAmount;

  const checkTotalAmount = (amount: number) => {
    const formattedAmount = formatNumberWithSpaces(makePositiveAmount(amount));
    if (amount > 0) {
      return `+$${formattedAmount}`;
    }

    if (amount < 0) {
      return `-$${formattedAmount}`;
    }

    return `$${formatNumberWithSpaces(amount)}`;
  };

  const checkTrendingClass = (amount: number) => {
    const formatted = formatCompound(amount);

    if (formatted === 0) {
      return "";
    }

    if (formatted > 0) {
      return "calc-block__total-profit--up";
    }

    return "calc-block__total-profit--down";
  };

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const heading = (
    <h2 className={isTabletAndMore ? "calc-block__heading" : "landing__title"}>
      Test our historical return
    </h2>
  );
  const mainClass = isTabletAndMore ? "calc-block" : "landing__item calc-block";

  return (
    <section className={mainClass}>
      {!isTabletAndMore && heading}
      <div className="calc-block__wrapper">
        {isTabletAndMore && heading}
        <div className="calc-block__top">
          <p className="calc-block__total">
            <span className="calc-block__total-amount">
              {checkTotalAmount(totalAmount)}
            </span>
            <span
              className={`calc-block__total-profit ${checkTrendingClass(
                totalCompound
              )}`}
            >
              {displayCompound(totalCompound)}
            </span>
          </p>
          <CalcSliders
            calcChart={calcChart}
            initialAmount={initialAmount}
            setInitialAmount={setInitialAmount}
            period={period}
            setPeriod={setPeriod}
          />
        </div>
        <div className="calc-block__chart-wrapper">
          <CalcChart data={formattedData} />
          <AccountToggles type="calc" isSpot={isSpot} handleSpot={setSpot} />
        </div>
      </div>
    </section>
  );
}
