export default function BybitIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 63 21"
    >
      <path fill="#F7A600" d="M44.9 16.19V0h3.26v16.19H44.9Z" />
      <path
        fill="#15192A"
        d="M6.98 21H0V4.8h6.7c3.25 0 5.14 1.78 5.14 4.56a3.74 3.74 0 0 1-2.06 3.34c1 .45 2.3 1.48 2.3 3.64 0 3.02-2.13 4.66-5.1 4.66ZM6.44 7.63H3.26v3.73h3.18c1.38 0 2.15-.75 2.15-1.87 0-1.11-.77-1.86-2.15-1.86Zm.2 6.57H3.27v3.98h3.4c1.47 0 2.17-.9 2.17-2 0-1.09-.71-1.98-2.18-1.98ZM22 14.36V21h-3.23v-6.64l-5-9.55h3.53l3.11 6.52 3.07-6.52h3.54L22 14.36ZM36.24 21h-6.98V4.8h6.7c3.25 0 5.15 1.78 5.15 4.56a3.74 3.74 0 0 1-2.06 3.34c1 .45 2.3 1.48 2.3 3.64 0 3.02-2.14 4.66-5.1 4.66ZM35.7 7.63h-3.18v3.73h3.18c1.39 0 2.16-.75 2.16-1.87 0-1.11-.78-1.86-2.16-1.86Zm.21 6.57h-3.4v3.98h3.4c1.48 0 2.18-.9 2.18-2 0-1.09-.7-1.98-2.17-1.98ZM58.65 7.63V21H55.4V7.63h-4.36V4.8H63v2.82h-4.35Z"
      />
    </svg>
  );
}