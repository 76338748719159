import { createAsyncThunk } from "@reduxjs/toolkit";
// import { AxiosError } from "axios";

import { ThunkApiConfigType } from "../../types/api";
import { StateAction } from "../action-types";
import { ApiRoute } from "../../const";
import {
  loadPaymentsInfo,
  setLoadPaymentsInfoLoading,
} from "../actions/payments";

export const fetchPaymentInfo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Payment.LoadPaymentsInfo,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setLoadPaymentsInfoLoading(true));
    try {
      const { data } = await api.get(ApiRoute.Payments);
      dispatch(loadPaymentsInfo(data?.data));
    } catch {}
    dispatch(setLoadPaymentsInfoLoading(false));
  }
);
