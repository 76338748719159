import { AUTH_TELEGRAM_BOT_NAME, AUTH_TELEGRAM_URL } from "../../const";
import TelegramIcon from "../icons/socials/telegram-icon";
import TelegramLogin from "../telegram-login/telegram-login";

export default function LoginTelegramPersonal(): JSX.Element {
  return (
    <div className="personal__link">
      <p className="personal__icon-wrapper">
        <span className="personal__icon personal__icon--telegram">
          <TelegramIcon />
        </span>
      </p>
      <p className="personal__heading">Connect to Telegram</p>
      <TelegramLogin
        dataAuthUrl={AUTH_TELEGRAM_URL}
        botName={AUTH_TELEGRAM_BOT_NAME}
        radius="0"
        typeClass="personal__link-telegram-frame"
      />
    </div>
  );
}
