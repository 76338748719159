import BinanceSpotIcon from "../../icons/account/binance-spot-icon";
import TransparentDoubleDiskIcon from "../../icons/decor/transparent-double-disk-icon";

export default function BinanceBriefly(): JSX.Element {
  return (
    <section className="landing__item okx-briefly">
      <h2 className="landing__title">Briefly about Binance</h2>
      <div className="okx-briefly__wrapper">
        <div className="okx-briefly__intro1">
          <p className="okx-briefly__paragraph">
            Binance is the largest cryptocurrency exchange in the world, founded
            in 2017. It has over 61.5 million active users and processes $6
            billion in trading volume daily.
          </p>
          <p className="okx-briefly__paragraph">
            Binance offers low transaction fees, making it attractive to traders
            who want to minimize their trading costs. Being the largest
            cryptocurrency exchange in the world means that Binance has a wide
            global audience.
          </p>
          <p className="okx-briefly__paragraph">
            Binance is an attractive place for traders who want to trade
            cryptocurrencies using a variety of strategies and analysis tools.
            Its user-friendly interface, fast order execution speed, and
            availability of various analysis tools make it attractive to traders
            of all experience levels.
          </p>
        </div>
        <div className="okx-briefly__intro1__media">
          <picture>
            <source
              media="(min-width: 1080px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/binance/laptop-desktop.png`}
              width="431"
              height="251"
            />
            <source
              media="(min-width: 678px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/binance/laptop-tablet.png`}
              width="354"
              height="206"
            />
            <img
              className="okx-briefly__img"
              src={`${process.env.PUBLIC_URL}/img/laptop/binance/laptop-mobile.png`}
              alt="Avatar"
              width="290"
              height="169"
            />
          </picture>
        </div>
        <div className="okx-briefly__intro2">
          <h3 className="okx-briefly__heading-inner">
            Binance trading bots by SMARD
          </h3>
          <p className="okx-briefly__paragraph">
            The SMARD provides a platform for efficient automated trading. With
            one account on this platform, you can work with various
            cryptocurrency exchanges, Binance being one of them.
          </p>
          <p className="okx-briefly__paragraph">
            An easy-to-use, intuitive interface and high performance make SMARD
            trading bots working with Binance popular among beginners and
            professionals.
          </p>
        </div>
        <div className="okx-briefly__intro2__media">
          <span className="okx-briefly__disk">
            <TransparentDoubleDiskIcon />
          </span>
          <span className="okx-briefly__logo okx-briefly__logo--binance">
            <BinanceSpotIcon />
          </span>
          <span className="okx-briefly__note okx-briefly__note--tools">
            Biggest exchange
          </span>
          <span className="okx-briefly__note okx-briefly__note--speed">
            High liquidity
          </span>
          <span className="okx-briefly__note okx-briefly__note--interface">
            Reduced commissions
          </span>
        </div>
      </div>
    </section>
  );
}
