import Message from "../../message/message";
import BitgetMinNotionalError from "../notes/bitget/bitget-min-notional-error";
import BitgetChangeModeNote from "../notes/bitget/bitget-change-mode-note";
import BitgetChangeModeAgainNote from "../notes/bitget/bitget-change-mode-again-note";
import AmountRangeNote from "../notes/amount-rage-note";
import { minNotional } from "../../../utils/env";
import { ResponseStatusType } from "../../../types/details";
import {
  ErrorType,
  OKX_BITGET_CHANGE_MODE_ERROR,
  OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE,
} from "../../../const";

type BitgetStep3MessageConnectedProps = {
  availableAmount: number;
  loadAmountToStartStatus: null | ResponseStatusType;
  checkPositionModeStatus: null | ResponseStatusType;
  changePositionModeStatus: null | ResponseStatusType;
  sendAmountToStartStatus: null | ResponseStatusType;
};

export default function BitgetStep3MessageConnected({
  sendAmountToStartStatus,
  changePositionModeStatus,
  loadAmountToStartStatus,
  checkPositionModeStatus,
  availableAmount,
}: BitgetStep3MessageConnectedProps): JSX.Element {
  const isCodeEqualToUnexpectedMode = (code: number) =>
    code === OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE;
  const isCodeEqualToChangeModeError = (code: number) =>
    code === OKX_BITGET_CHANGE_MODE_ERROR;

  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isStartStatusFail = sendAmountToStartStatus?.isSuccess === false;
  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const startStatus = sendAmountToStartStatus;
  const checkModeStatus = checkPositionModeStatus;

  const shouldChangeModeNote =
    (startStatus &&
      isStartStatusFail &&
      startStatus.code &&
      isCodeEqualToUnexpectedMode(startStatus.code)) ||
    (checkModeStatus &&
      checkPositionModeStatus &&
      checkModeStatus.code &&
      isCodeEqualToUnexpectedMode(checkModeStatus.code));

  const shouldChangeModeAgainNote =
    changePositionModeStatus &&
    isChangeModeFail &&
    changePositionModeStatus.code &&
    isCodeEqualToChangeModeError(changePositionModeStatus.code);

  return shouldChangeModeAgainNote ? (
    <Message status="error" message={<BitgetChangeModeAgainNote />} />
  ) : shouldChangeModeNote ? (
    <Message status="error" message={<BitgetChangeModeNote />} />
  ) : isStartStatusFail ? (
    <Message status="error" message={sendAmountToStartStatus.message} />
  ) : isLoadAmountFail || isCheckModeFail || isChangeModeFail ? (
    <Message status="error" message={ErrorType.General} />
  ) : availableAmount >= minNotional ? (
    <Message
      status="neutral"
      message={<AmountRangeNote availableAmount={availableAmount} />}
    />
  ) : (
    <Message status="error" message={<BitgetMinNotionalError />} />
  );
}
