import { AnimatePresence } from "framer-motion";

import DetailsBoard from "../../components/details-board/details-board";
import AnimatedStepsToConnect from "../../components/steps-to-connect/wrapper/animated-steps-to-connect";
import AnimatedRenewKeys from "../../components/renew-keys/animated-renew-keys";
import OkxConnectStep3Connected from "../steps-to-connect/steps/okx-steps/okx-connect-step3-connected";
import BitgetConnectStep3Connected from "../steps-to-connect/steps/bitget-steps/bitget-connect-step3-connected";
import BybitConnectStep3Connected from "../steps-to-connect/steps/bybit-steps/bybit-connect-step3-connected";
import { useAppSelector } from "../../hooks";
import { AccountName, AuthStatus, StrategyStatus } from "../../const";
import { StrategyOverviewType } from "../../types/details";
import AnimatedChangeBalanceForm from "../change-balance/animated-change-balance-form";

export default function DetailsBlocks1(): JSX.Element {
  const {
    isRenewKeysFormOpened,
    strategyDetails,
    isIntegrationOpened,
    authStatus,
  } = useAppSelector((state) => state);

  const status = strategyDetails?.status as string;
  const overview = strategyDetails?.overview as StrategyOverviewType;
  const accountName = overview?.code as string;
  const isAccountConnected = strategyDetails?.is_account_connected;

  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;
  const isNotStarted = status === StrategyStatus.NotStarted;
  const isStarted = status === StrategyStatus.Started;
  const isAuth = authStatus === AuthStatus.Auth;

  const isOkxStep3ConnectedShown = isAccountConnected && isOkx && isNotStarted;
  const isBybitStep3ConnectedShown =
    isAccountConnected && isBybit && isNotStarted;
  const isBitgetStep3ConnectedShown =
    isAccountConnected && isBitget && isNotStarted;
  // Проверка с !isStarted, тк после запуска может быть открытой
  const isConnectStepsShown = !isStarted && isAuth && isIntegrationOpened;

  return (
    <>
      {/* BOARD */}
      {overview && <DetailsBoard />}

      {/* CHANGE BALANCE */}
      {status === StrategyStatus.Started ? (
        <div className="details__steps-to-connect">
          <AnimatedChangeBalanceForm />
        </div>
      ) : null}

      {/* RENEW KEYS */}
      <AnimatePresence initial={false}>
        {isRenewKeysFormOpened && (
          <AnimatedRenewKeys accountName={accountName} />
        )}
      </AnimatePresence>

      {/* STEPS TO CONNECT */}
      {/* Без анимации */}
      {isOkxStep3ConnectedShown && (
        <div className="details__steps-to-connect">
          <OkxConnectStep3Connected />
        </div>
      )}

      {isBybitStep3ConnectedShown && (
        <div className="details__steps-to-connect">
          <BybitConnectStep3Connected />
        </div>
      )}

      {isBitgetStep3ConnectedShown && (
        <div className="details__steps-to-connect">
          <BitgetConnectStep3Connected />
        </div>
      )}

      {/* С анимацией */}
      <AnimatePresence initial={false}>
        {isConnectStepsShown && (
          <AnimatedStepsToConnect accountName={accountName} />
        )}
      </AnimatePresence>
    </>
  );
}
