export default function ChatIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 4.77783V6.66672M9.5 10.4539L9.49056 10.4436"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 17.3294V2.88889C18 2.38792 17.801 1.90748 17.4468 1.55324C17.0925 1.19901 16.6121 1 16.1111 1H2.88889C2.38792 1 1.90748 1.19901 1.55324 1.55324C1.19901 1.90748 1 2.38792 1 2.88889V12.3333C1 12.8343 1.19901 13.3147 1.55324 13.669C1.90748 14.0232 2.38792 14.2222 2.88889 14.2222H13.3146C13.5977 14.2223 13.8772 14.2859 14.1324 14.4085C14.3876 14.5311 14.6119 14.7095 14.7889 14.9306L16.9904 17.6827C17.0637 17.7745 17.1636 17.8413 17.2765 17.8738C17.3894 17.9063 17.5096 17.9029 17.6205 17.8641C17.7313 17.8253 17.8274 17.753 17.8954 17.6572C17.9634 17.5615 18 17.4469 18 17.3294Z"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
}
