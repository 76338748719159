import { AccountName } from "../../const";

type DetailsNoteProps = {
  accountName: string;
};

export default function DetailsNote({
  accountName,
}: DetailsNoteProps): JSX.Element {
  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;

  const name = isOkx ? "OKX" : isBitget ? "Bitget" : isBybit ? "Bybit" : "Binance";

  return (
    <section className="details__item details__note details-note block block--more-bottom">
      <h2 className="block__heading">Please note</h2>
      <ul className="details-note__list">
        <li className="details-note__item">
          1. SMARD <b>does not require withdrawal access</b> in {name}. All your
          assets remain in your account and under your control.
        </li>
        <li className="details-note__item">
          2. Complete trust to you: SMARD <b>does not ask to pay upfront</b> or
          link your card. The first payment will be after one calendar month
          from the start of trading only.
        </li>
        <li className="details-note__item">
          3. Win-win pricing: <b>you only pay the service fee when you earn</b>,
          your success = our success.
        </li>
        <li className="details-note__item">
          4. SMARD <b>does not take uncalculated risks</b>: operates without
          leverage, uses stop losses to control the risk and trade highly liquid
          instruments only.
        </li>
        <li className="details-note__item">
          5. You can continue using your account to invest or trade, just do not
          touch the position that SMARD has formed otherwise the trading will
          stop.
        </li>
      </ul>
    </section>
  );
}
