import { useEffect, useRef } from "react";

import NotificationsSkeleton from "../../skeletons/notifications-skeleton";
import TableDate from "../../table-items/table-date";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { readNotifications } from "../../../store/api-actions";
import { findMessageIcon } from "../../../utils/notifications";

type NotificationsProps = {
  closeNotifications: () => void;
  notificationsBtnRef: React.RefObject<HTMLButtonElement>;
};

export default function Notifications({
  closeNotifications,
  notificationsBtnRef,
}: NotificationsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const { isNotificationsLoading, notifications } = useAppSelector(
    (state) => state
  );
  const notificationsRef = useRef<HTMLDivElement | null>(null);
  const notificationsListRef = useRef<HTMLUListElement | null>(null);

  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      if (
        !notificationsRef?.current?.contains(evt.target as Element) &&
        !notificationsBtnRef?.current?.contains(evt.target as Element)
      ) {
        closeNotifications();
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [
    notificationsRef,
    notificationsBtnRef,
    closeNotifications,
    isNotificationsLoading,
  ]);

  useEffect(() => {
    if (
      notifications &&
      notifications.some((item) => item.status === "NEW") &&
      // Для случая если быстро открыли и закрыли окно и ноутификации не отобразились
      notificationsListRef.current
    ) {
      dispatch(readNotifications());
    }
  }, [dispatch, notifications, notificationsListRef]);

  return (
    <div
      ref={notificationsRef}
      className="notifications private-window private-window--notifications shadow shadow--notifications"
    >
      {isNotificationsLoading || !notifications ? (
        <NotificationsSkeleton />
      ) : notifications.length > 0 ? (
        <ul
          className="notifications__list private-window__list private-window__list--notifications"
          ref={notificationsListRef}
        >
          {notifications.map((item) => (
            <li className="notifications__item" key={item.id}>
              <p className="notifications__icon-wrapper">
                {findMessageIcon(item.name)}
                {item.status === "NEW" && (
                  <span className="notifications__unread"></span>
                )}
              </p>
              <div className="notifications__text-wrapper">
                <p
                  className={`notifications__message ${
                    item.status === "NEW"
                      ? "notifications__message--unread"
                      : ""
                  }`}
                >
                  {item.data}
                </p>
                <TableDate date={item.created_at} isNotifications />
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="notifications__note">You do not have any notifications</p>
      )}

      <button className="notifications__close" onClick={closeNotifications}>
        <span className="visually-hidden">Close notifications</span>
      </button>
    </div>
  );
}
