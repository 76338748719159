export default function VisaIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 14"
    >
      <path
        fill="#CBD5E1"
        fillRule="evenodd"
        d="M0 3.23A3.2 3.2 0 0 1 3.17 0h12.66A3.2 3.2 0 0 1 19 3.23v7.54A3.2 3.2 0 0 1 15.83 14H3.17A3.2 3.2 0 0 1 0 10.77V3.23ZM15.83 1.5H3.17c-.88 0-1.67.84-1.67 1.73v7.54c0 .9.79 1.73 1.66 1.73h12.67c.88 0 1.67-.84 1.67-1.73V3.23c0-.9-.8-1.73-1.67-1.73Z"
        clipRule="evenodd"
      />
      <path
        fill="#15195A"
        d="M8.28 9.34H7.11l.73-4.26h1.18l-.74 4.26ZM12.54 5.18c-.23-.08-.6-.18-1.05-.18-1.16 0-1.98.58-1.98 1.4-.01.62.58.96 1.03 1.16.45.21.6.35.6.54 0 .28-.36.41-.7.41-.47 0-.72-.07-1.1-.22l-.16-.07-.16.95c.28.12.78.23 1.31.23 1.23 0 2.04-.57 2.04-1.45 0-.49-.3-.86-.98-1.16-.41-.2-.66-.33-.66-.53 0-.18.2-.37.67-.37.38 0 .67.08.88.17l.1.04.16-.92Z"
      />
      <path
        fill="#15195A"
        fillRule="evenodd"
        d="M14.64 5.07h.91l.95 4.26h-1.09l-.14-.64h-1.5l-.25.64h-1.23l1.74-3.9c.12-.28.33-.36.61-.36Zm-.07 1.56-.47 1.2h.98l-.27-1.24-.08-.36-.12.3-.04.1ZM2.02 5.07H3.9c.26.01.46.09.53.36l.4 1.94.01.02.13.59 1.15-2.9h1.24L5.53 9.32H4.28l-1.04-3.7c-.36-.2-.78-.36-1.24-.47l.02-.09Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
