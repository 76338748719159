export default function BitgetIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 11 12"
    >
      <path
        fill="currentColor"
        d="M4.9 4.3h2.94l3.01 3.05c.2.2.2.51 0 .71L7 12H3.96l.91-.9 3.37-3.4-3.32-3.4"
      />
      <path
        fill="currentColor"
        d="M6.1 7.7H3.16L.15 4.65a.5.5 0 0 1-.11-.56.5.5 0 0 1 .1-.16L4.02 0h3.03l-.91.9-3.37 3.4 3.32 3.4"
      />
    </svg>
  );
}
