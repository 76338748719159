import { useAppDispatch, useAppSelector } from "../../hooks";
import { setCoinToggles } from "../../store/actions/interface";

export default function CoinStartegyToggles(): JSX.Element {
  const dispatch = useAppDispatch();

  const { coinToggles } = useAppSelector((state) => state);
  const isBinanceSpot = coinToggles.isBinanceSpot;

  const spotBtn = (
    <p className="account-toggles__btn">
      <input
        className="visually-hidden"
        id="coin-spot-strategy"
        type="radio"
        name="coin-strategy-type"
        value="spot"
        checked={isBinanceSpot}
        onChange={() =>
          dispatch(setCoinToggles({ ...coinToggles, isBinanceSpot: true }))
        }
      />
      <label className="account-toggles__label" htmlFor="coin-spot-strategy">
        Binance
      </label>
    </p>
  );

  const futuresBtn = (
    <p className="account-toggles__btn">
      <input
        className="visually-hidden"
        id="coin-futures-strategy"
        type="radio"
        name="coin-strategy-type"
        value="spot"
        checked={!isBinanceSpot}
        onChange={() =>
          dispatch(setCoinToggles({ ...coinToggles, isBinanceSpot: false }))
        }
      />
      <label className="account-toggles__label" htmlFor="coin-futures-strategy">
        Bybit
      </label>
    </p>
  );

  return (
    <div className="account-toggles account-toggles--blue">
      {spotBtn}
      <span className="account-toggles__text">or</span>
      {futuresBtn}
      <span className="account-toggles__text">trading results</span>
    </div>
  );
}
