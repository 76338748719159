export default function PercentInCircleIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M5.3 4c.55 0 1 .21 1.33.64.34.43.5 1.03.5 1.8s-.15 1.37-.47 1.8c-.31.44-.77.66-1.36.66-.54 0-.98-.22-1.3-.65a2.94 2.94 0 0 1-.5-1.81c0-.77.15-1.37.46-1.8.31-.43.76-.64 1.34-.64Zm.02 1.3c-.14 0-.24.1-.3.32-.04.2-.07.48-.07.84 0 .35.03.63.08.84.05.21.15.32.29.32.14 0 .23-.1.28-.31.05-.21.08-.5.08-.85 0-.36-.03-.64-.08-.84-.05-.21-.14-.32-.28-.32Zm5.33-1.19-3.9 7.8H5.3l1.95-3.9.98-1.95.98-1.95h1.43Zm.01 3c.56 0 1 .21 1.33.64.34.42.51 1.02.51 1.8 0 .76-.16 1.36-.47 1.8-.32.43-.77.65-1.37.65-.54 0-.97-.22-1.3-.65a2.93 2.93 0 0 1-.5-1.8c0-.78.16-1.38.47-1.8.3-.43.75-.64 1.33-.64Zm.02 1.3c-.14 0-.23.1-.29.31-.05.21-.07.5-.07.85s.02.63.07.84c.06.21.15.31.3.31.13 0 .22-.1.28-.3.05-.21.08-.5.08-.85 0-.37-.03-.66-.08-.86-.06-.2-.15-.3-.29-.3Z"
      />
    </svg>
  );
}
