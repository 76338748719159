export default function LightDarkBlueDiskIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#2563EB"
        fillRule="evenodd"
        d="M10 14.05a4.05 4.05 0 1 0 0-8.1 4.05 4.05 0 0 0 0 8.1ZM10 20a10 10 0 1 0 0-20 10 10 0 0 0 0 20Z"
        clipRule="evenodd"
      />
      <path
        fill="#60A5FA"
        fillRule="evenodd"
        d="M10 0a10 10 0 1 0 0 20v-5.95a4.05 4.05 0 0 1 0-8.1V0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
