import { useLocation } from "react-router-dom";

import LoginApple from "../login/login-apple";
import LoginGoogle from "../login/login-google";
import LoginTelegram from "../login/login-telegram";
import AuthDescription from "../auth-description/auth-description";
import { AppRoute } from "../../const";
import { useAppSelector } from "../../hooks";

type AuthBlockProps = {
  type: string;
};

export default function AuthBlock({ type }: AuthBlockProps): JSX.Element {
  const { pathname } = useLocation();
  const { isAuthModalOpened } = useAppSelector((state) => state);
  const isOverview = pathname === AppRoute.Overview;

  const isAuthInShortHeader = type
    .trim()
    .split(" ")
    .some((item) => item === "short-header__auth");
  const getAuthUrl = (link: string) => link;

  return (
    <div className={`${type} auth`}>
      <div className="auth__wrapper">
        <p className="auth__heading">Start with</p>
        <ul className="auth__list">
          <li className="auth__item">
            <LoginGoogle getAuthUrl={getAuthUrl} />
          </li>
          <li className="auth__item">
            <LoginTelegram
              isAuthInShortHeader={isAuthInShortHeader}
              isAuthModalOpened={isAuthModalOpened}
              getAuthUrl={getAuthUrl}
            />
          </li>
          <li className="auth__item">
            <LoginApple
              isAuthModalOpened={isAuthModalOpened}
              isOverview={isOverview}
            />
          </li>
        </ul>
      </div>
      <AuthDescription />
    </div>
  );
}
