import { useEffect, useState } from "react";
import { Xwrapper } from "react-xarrows";

import OkxStepsToConnect from "../okx-steps-to-connect";
import BybitStepsToConnect from "../bybit-steps-to-connect";
import { AccountName, ConnectStepPoints, ConnectSteps } from "../../../const";
import { displayLine } from "../../../utils/line";
import BitgetStepsToConnect from "../bitget-steps-to-connect";
import BinanceFuturesStepsToConnect from "../binance-futures-steps-to-connect";
import BinanceSpotStepsToConnect from "../binance-spot-steps-to-connect";

type StepsToConnectWrapperProps = {
  accountName: string;
  isStepsAnimationEnd?: boolean;
  clearAnimationEnd?: () => void;
};

export default function StepsToConnectWrapper({
  accountName,
  // эти пропы нужны, так как фокусировка в мобилке при выезде блоков на первой кнопке ломала плавную анимацию, а вследствие с этим, и пунктирные линии
  isStepsAnimationEnd,
  clearAnimationEnd,
}: StepsToConnectWrapperProps): JSX.Element {
  const [activeStep, setActiveStep] = useState<string>(ConnectSteps.STEP1);

  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;
  const isBinanceFutures = accountName === AccountName.BinanceFutures;

  const isFirstLineDone =
    activeStep === ConnectSteps.STEP2 || activeStep === ConnectSteps.STEP3;
  const isSecondLineDone = activeStep === ConnectSteps.STEP3;

  useEffect(() => {
    return () => {
      if (clearAnimationEnd) {
        clearAnimationEnd();
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <h2 className="visually-hidden">Steps to connect</h2>
      <Xwrapper>
        {isOkx ? (
          <OkxStepsToConnect
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isStepsAnimationEnd={isStepsAnimationEnd}
          />
        ) : isBitget ? (
          <BitgetStepsToConnect
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isStepsAnimationEnd={isStepsAnimationEnd}
          />
        ) : isBybit ? (
          <BybitStepsToConnect
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isStepsAnimationEnd={isStepsAnimationEnd}
          />
        ) : isBinanceFutures ? (
          <BinanceFuturesStepsToConnect
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isStepsAnimationEnd={isStepsAnimationEnd}
          />
        ) : (
          <BinanceSpotStepsToConnect
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isStepsAnimationEnd={isStepsAnimationEnd}
          />
        )}

        {displayLine(
          ConnectStepPoints.STEP_POINT1,
          ConnectStepPoints.STEP_POINT2,
          isFirstLineDone
        )}
        {displayLine(
          ConnectStepPoints.STEP_POINT2,
          ConnectStepPoints.STEP_POINT3,
          isSecondLineDone
        )}
      </Xwrapper>
    </section>
  );
}
