import { Link } from "react-router-dom";

import Button from "../../button/button";
import Copy from "../../copy/copy";
import { createBitgetConnectLink } from "../../../utils/id";
import {
  AppRoute,
  BITGET_API_SETTINGS_PAGE_LINK,
  IP_ADDRESS_WITH_COMMA,
  StrategyStatus,
} from "../../../const";

type BitgetRenewKeysInfoProps = {
  status: string | undefined;
};

export default function BitgetRenewKeysInfo({
  status,
}: BitgetRenewKeysInfoProps): JSX.Element {
  const bitgetConnectLink = createBitgetConnectLink();
  const isDemo = status === StrategyStatus.Demo;

  return (
    <div className="renew-keys-info">
      <p className="renew-keys-info__paragraph">
        Click on the "Reconnect" button below to update your credentials using
        Fast API.
      </p>
      <Button
        classElement={`renew-keys-info__btn renew-keys-info__btn--reconnect ${
          isDemo ? "btn--disabled" : ""
        }`}
        pattern="white-blue"
        text="Reconnect"
        icon="arrow-right"
        type="link"
        linkAddress={bitgetConnectLink}
        isSelfLink
      />
      <p className="renew-keys-info__paragraph">
        Or you can update the keys manually by following the instruction below:
      </p>
      <ul className="renew-keys-info__list">
        <li className="renew-keys-info__item">
          1. Go to{" "}
          <a
            className="link"
            href={BITGET_API_SETTINGS_PAGE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            the&nbsp;API&nbsp;keys&nbsp;section
          </a>{" "}
          on Bitget and click "Create API" with the following settings{" "}
          <Link className="link" to={AppRoute.BitgetSetup}>
            (Full&nbsp;guide)
          </Link>
          ;
        </li>
        <li className="renew-keys-info__item">
          2. Create Note and Passphrase;
        </li>
        <li className="renew-keys-info__item">
          3. Enable permissions:
          <ul className="renew-keys-info__inner-list">
            <li className="renew-keys__inner-item">
              Choose the Read-write option
            </li>
            <li className="renew-keys__inner-item">
              Enable permissions for Futures (Orders and Holdings)
              and&nbsp;Spot&nbsp;(Trade)
            </li>
          </ul>
        </li>
        <li className="renew-keys-info__item">
          4. Enter the IP whitelist addresses:
          <Copy text={IP_ADDRESS_WITH_COMMA} />
        </li>
        <li className="renew-keys-info__item">
          5. Enter API key, Secret key and Passphrase below;
        </li>
        <li className="renew-keys-info__item">
          6. Click on the "Renew keys" button.
        </li>
      </ul>
    </div>
  );
}
