import { useEffect } from "react";
import {
  renderBitcoinDot,
  renderCustomDot,
  renderEthereumDot,
  renderOkxDot,
  renderSmardDot,
} from "./chart-dots";

export const SmardActiveDot = (props: any) => {
  const { cx, cy, setEndDotsShown } = props;

  useEffect(() => {
    if (setEndDotsShown) {
      setEndDotsShown(false);
    }

    return () => {
      if (setEndDotsShown) {
        setEndDotsShown(true);
      }
    };
    // eslint-disable-next-line
  }, []);

  return renderSmardDot(cx, cy);
};

export const SmardActiveDotNoEnd = (props: any) => {
  const { cx, cy } = props;
  return renderSmardDot(cx, cy);
};

export const BitcoinActiveDot = ({ cx, cy }: any) => renderBitcoinDot(cx, cy);

export const EthereumActiveDot = ({ cx, cy }: any) => renderEthereumDot(cx, cy);

export const OkxActiveDot = ({ cx, cy }: any) => renderOkxDot(cx, cy);

export const CustomActiveDot = (props: any) => {
  const { cx, cy, setEndDotsShown, imgLink } = props;

  useEffect(() => {
    if (setEndDotsShown) {
      setEndDotsShown(false);
    }

    return () => {
      if (setEndDotsShown) {
        setEndDotsShown(true);
      }
    };
    // eslint-disable-next-line
  }, []);

  return renderCustomDot(cx, cy, imgLink);
};
