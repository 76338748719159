import { useState } from "react";
import { Link } from "react-router-dom";

import ListIcon from "../../icons/list-icon";
import PlayIcon from "../../icons/play-icon";
import { checkIfDesktop } from "../../../utils/breakpoint";
import { debounce } from "../../../utils/chart/chart";
// import { useAppDispatch } from "../../../hooks";
// import { setIntegrationCalendarOpened } from "../../../store/actions";
import { AccountName, AppRoute, RERENDER_DELAY } from "../../../const";

type ConnectHelpProps = {
  accountName: string;
};

export default function ConnectHelp({
  accountName,
}: ConnectHelpProps): JSX.Element {
  // const dispatch = useAppDispatch();
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());
  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;
  const tutorialLink = isOkx
    ? AppRoute.OkxSetup
    : isBitget
    ? AppRoute.BitgetSetup
    : isBybit
    ? AppRoute.BybitSetup
    : AppRoute.BinanceSetup;

  const videoLink = isDesktop
    ? "https://www.youtube.com/embed/9NRutR-glZE?&autoplay=1&mute=1"
    : "https://www.youtube.com/embed/GexSnSbqz8I?&autoplay=1&mute=1";

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  // const handleCalendarClick = () => {
  //   dispatch(setIntegrationCalendarOpened(true));
  // };

  return (
    <ul className="connect-help">
      {/* <li className="connect-help__item">
        <button
          className="connect-help__link link"
          type="button"
          onClick={handleCalendarClick}
        >
          <span className="connect-help__icon connect-help__icon--calendar">
            <CalendarIcon />
          </span>
          <span>Help me to start</span>
        </button>
      </li> */}

      <li className="connect-help__item">
        <Link className="connect-help__link link" to={tutorialLink}>
          <span className="connect-help__icon connect-help__icon--text">
            <ListIcon />
          </span>
          <span>Detailed text tutorial</span>
        </Link>
      </li>

      {/* only for binance */}
      {!isOkx && !isBitget && !isBybit && (
        <li className="connect-help__item">
          <a
            className="connect-help__link link"
            href={videoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="connect-help__icon connect-help__icon--video">
              <PlayIcon />
            </span>
            <span>Video tutorial</span>
          </a>
        </li>
      )}
    </ul>
  );
}
