type SkeletonChartIconProps = {
  isForEmptyChart?: boolean;
};

export default function SkeletonChartIcon({
  isForEmptyChart,
}: SkeletonChartIconProps): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 814 396"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 209.55C0 209.55 0.856619 209.183 8.14 199.925C15.4234 190.667 31.8817 176 48.1617 190.667C63.7633 205.333 97.3285 214.992 112.93 236.992C129.21 258.992 127.527 242 143.807 227.333C159.408 212.667 175.688 264 191.29 227.333C207.57 190.667 223.172 117.333 239.452 110C255.053 102.667 271.333 161.333 287.613 183.333C303.215 205.333 319.495 190.667 335.097 176C351.377 161.333 366.978 146.667 383.258 168.667C398.86 190.667 402.149 202.217 417.751 209.55C434.031 216.883 462.623 220 478.903 176C494.505 132 510.785 88 526.387 66C542.667 44 558.947 44 574.548 58.6667C590.828 73.3333 606.43 102.667 622.71 154C638.312 205.333 654.592 121.316 670.193 165.316C686.473 209.316 702.075 223.982 718.355 165.316C733.957 106.649 750.237 132 765.838 66C782.118 0 797.72 0 805.86 0H814V396H805.86C797.72 396 782.118 396 765.838 396C750.237 396 733.957 396 718.355 396C702.075 396 686.473 396 670.193 396C654.592 396 638.312 396 622.71 396C606.43 396 590.828 396 574.548 396C558.947 396 542.667 396 526.387 396C510.785 396 494.505 396 478.903 396C462.623 396 447.022 396 430.742 396C415.14 396 398.86 396 383.258 396C366.978 396 351.377 396 335.097 396C319.495 396 303.215 396 287.613 396C271.333 396 255.053 396 239.452 396C223.172 396 207.57 396 191.29 396C175.688 396 159.408 396 143.807 396C127.527 396 111.925 396 95.645 396C80.0433 396 63.7633 396 48.1617 396C31.8817 396 16.28 396 8.14 396H0V209.55Z"
        fill="#e1ebf8"
        fillOpacity={isForEmptyChart ? "0.6" : "1"}
      />
    </svg>
  );
}
