import {
  DESKTOP_WIDTH,
  FOR_CHART_MORE_MOBILE_WIDTH,
  FOR_FAQ_CHART_MORE_MOBILE_WIDTH,
  TABLET_WIDTH,
} from "../const";

export const checkIfDesktop = () =>
  document.documentElement.clientWidth >= DESKTOP_WIDTH;

export const checkIfTabletAndMore = () =>
  document.documentElement.clientWidth >= TABLET_WIDTH;

export const checkIfMobile = () =>
  document.documentElement.clientWidth < TABLET_WIDTH;

export const checkIfChartMoreMobileWidth = () =>
  document.documentElement.clientWidth >= FOR_CHART_MORE_MOBILE_WIDTH;

export const checkIfFaqChartMoreMobileWidth = () =>
  document.documentElement.clientWidth >= FOR_FAQ_CHART_MORE_MOBILE_WIDTH;

export const checkWidthForDiscrepancyBreak = () =>
  document.documentElement.clientWidth < 600 ||
  (document.documentElement.clientWidth >= TABLET_WIDTH &&
    document.documentElement.clientWidth < 900);

export const checkWidthIfForFooterStyle = () =>
  document.documentElement.clientWidth >= 420 &&
  document.documentElement.clientWidth < TABLET_WIDTH;
