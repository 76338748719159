import SmardIcon from "../icons/coins/smard-icon";

type AccountTogglesProps = {
  type: string;
  isSpot: boolean;
  handleSpot: (mode: boolean) => void;
};

export default function AccountToggles({
  type,
  isSpot,
  handleSpot,
}: AccountTogglesProps): JSX.Element {
  const spotBtn = (
    <p className="account-toggles__btn">
      <input
        className="visually-hidden"
        id={`${type}-spot-strategy`}
        type="radio"
        name={`${type}-strategy-type`}
        value="spot"
        checked={isSpot}
        onChange={() => handleSpot(true)}
      />
      <label
        className="account-toggles__label"
        htmlFor={`${type}-spot-strategy`}
      >
        Binance
      </label>
    </p>
  );

  const futuresBtn = (
    <p className="account-toggles__btn">
      <input
        className="visually-hidden"
        id={`${type}-futures-strategy`}
        type="radio"
        name={`${type}-strategy-type`}
        value="futures"
        checked={!isSpot}
        onChange={() => handleSpot(false)}
      />
      <label
        className="account-toggles__label"
        htmlFor={`${type}-futures-strategy`}
      >
        Bybit
      </label>
    </p>
  );

  switch (type) {
    case "calc":
      return (
        <div className="account-toggles account-toggles--green">
          <span className="account-toggles__text">Based on</span>
          {spotBtn}
          <span className="account-toggles__text">or</span>
          {futuresBtn}
          <span className="account-toggles__text">trading results</span>
        </div>
      );
    case "faq":
      return (
        <div className="account-toggles account-toggles--green">
          <span className="account-toggles__text">of</span>
          <span className="account-toggles__icon">
            <SmardIcon />
          </span>
          {spotBtn}
          <span className="account-toggles__text">or</span>
          {futuresBtn}
          <span className="account-toggles__text">trading results</span>
        </div>
      );
    default:
      return <></>;
  }
}
