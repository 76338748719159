import { useState } from "react";
import { motion } from "framer-motion";

import StepsToConnectWrapper from "./steps-to-connect-wrapper";

type AnimatedStepsToConnectProps = {
  accountName: string;
};

export default function AnimatedStepsToConnect({
  accountName,
}: AnimatedStepsToConnectProps): JSX.Element {
  const [isStepsAnimationEnd, setStepsAnimationEnd] = useState<boolean>(false);

  return (
    <motion.div
      initial={{
        height: 0,
        marginBottom: "-13px",
        opacity: 0,
      }}
      animate={{
        height: "auto",
        marginBottom: 0,
        opacity: 1,
      }}
      exit={{
        height: 0,
        marginBottom: "-13px",
        opacity: 0,
      }}
      onAnimationComplete={() => {
        setStepsAnimationEnd(true);
      }}
      style={{ overflow: "hidden" }}
      transition={{ duration: 0.5 }}
      className="details__steps-to-connect details__steps-to-connect--animated"
    >
      <StepsToConnectWrapper
        accountName={accountName}
        isStepsAnimationEnd={isStepsAnimationEnd}
        clearAnimationEnd={() => {
          setStepsAnimationEnd(false);
        }}
      />
    </motion.div>
  );
}
