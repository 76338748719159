export default function CoinCanvasSkeleton(): JSX.Element {
  return (
    <div className="sk-coin-canvas animate-pulse">
      <div className="sk-coin-canvas__percent">
        <div className="sk-coin-canvas__percent-item">
          <div className="sk-coin-canvas__percent-icon"></div>
          <div className="sk-coin-canvas__percent-text"></div>
        </div>
        <div className="sk-coin-canvas__percent-item">
          <div className="sk-coin-canvas__percent-icon"></div>
          <div className="sk-coin-canvas__percent-text"></div>
        </div>
      </div>
      <div className="sk-coin-canvas__chart"></div>
      <div className="sk-coin-canvas__controls">
        <div className="sk-coin-canvas__controls-item"></div>
        <div className="sk-coin-canvas__controls-item"></div>
      </div>
    </div>
  );
}
