import { useState } from "react";
import { checkIfDesktop } from "../../../utils/breakpoint";
import { debounce } from "../../../utils/chart/chart";
import { RERENDER_DELAY } from "../../../const";

export default function DetailsStopNote(): JSX.Element {
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  return !isDesktop ? (
    <p className="details-stop-note">
      <span className="details-stop-note__text">
        Please wait while trading is being stopped
      </span>
    </p>
  ) : (
    <p className="details-stop-note details-stop-note--big">
      <span className="details-stop-note__big">Please wait</span>
      <span className="details-stop-note__text">
        while trading is being stopped
      </span>
    </p>
  );
}
