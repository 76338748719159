import { useNavigate } from "react-router-dom";
import {
  AccountName,
  AppRoute,
  AuthStatus,
  BYBIT_REGISTER_LINK,
} from "../../../const";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setAuthModalOpened } from "../../../store/actions";
import { createBybitConnectLink } from "../../../utils/id";
import { trackEvent } from "../../../utils/tracking";
import Button from "../../button/button";
import useIsConnectButtonVisible from "../../../hooks/useIsConnectButtonVisible";

function BybitButtonGroup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus, isBybitConnected, bybitDemo } = useAppSelector(
    (state) => state
  );
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const bybitConnectLink = createBybitConnectLink();

  const isConnectButtonVisible = useIsConnectButtonVisible(
    bybitDemo?.strategy_results.sharpe_ratio
  );

  const handleViewClick = () => {
    navigate(`${AppRoute.Accounts}/${AccountName.Bybit}/`);
  };

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("BybitLandingFastConnect", "Clicked");
  };

  const handleRegisterClick = () => {
    trackEvent("BybitLandingRegisterAccount", "Clicked");
  };

  return (
    <div className="exchange-promo__btns">
      {isBybitConnected === null ? (
        <div className="landing__btn-gap"></div>
      ) : isBybitConnected ? (
        <Button
          pattern="white-blue"
          text="View trading results"
          type="button"
          icon="eye"
          size="super"
          handleClick={handleViewClick}
        />
      ) : (
        <>
          <Button
            pattern="blue-white"
            text="Register a Bybit account"
            icon="key-in-circle"
            type="link"
            size="super-big"
            linkAddress={BYBIT_REGISTER_LINK}
            handleClick={handleRegisterClick}
          />
          {isConnectButtonVisible ? (
            <Button
              pattern="white-blue"
              text="Connect an existing account"
              icon="check"
              type={isNoAuth ? "button" : "link"}
              size="huge"
              linkAddress={bybitConnectLink}
              isSelfLink
              handleClick={handleConnectClick}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

export default BybitButtonGroup;
