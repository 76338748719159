export default function TooltipIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.5 0a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13Zm0 11.7a5.2 5.2 0 1 1 0-10.4 5.2 5.2 0 0 1 0 10.4Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M3.1 6.5c0-.32.07-.54.23-.66.17-.13.37-.2.6-.2.23 0 .43.07.6.2.16.12.25.34.25.65 0 .3-.09.53-.26.67a.91.91 0 0 1-.6.2.91.91 0 0 1-.59-.2c-.16-.14-.24-.36-.24-.67ZM5.66 6.5c0-.32.08-.54.24-.66.16-.13.36-.2.6-.2.23 0 .42.07.59.2.17.12.25.34.25.65 0 .3-.08.53-.25.67a.91.91 0 0 1-.6.2.91.91 0 0 1-.6-.2c-.15-.14-.23-.36-.23-.67ZM8.22 6.5c0-.32.08-.54.24-.66.17-.13.36-.2.6-.2.23 0 .43.07.6.2.16.12.24.34.24.65 0 .3-.08.53-.25.67a.91.91 0 0 1-.6.2.91.91 0 0 1-.59-.2c-.16-.14-.24-.36-.24-.67Z"
      />
    </svg>
  );
}
