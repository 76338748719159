export const mainFaqData = [
    {
      question: "Do I need to transfer funds to get started?",
      answer:
        "No, all funds remain on your exchange account under your complete control. Connect to SMARD strategies using API keys without withdrawal access.",
    },
    {
      question: "If I start trading with SMARD, will the whole process be automated?",
      answer:
        "Yes, all SMARD strategies are fully automated. Once connected, you don't need to set up anything or make trades.",
    },
    {
      question: "Where is SMARD based?",
      answer:
        "The official address is 1309 Coffeen Avenue STE 1200, Sheridan, Wyoming 82801, US.",
    },
    {
      question: "Do crypto trading bots actually work?",
      answer:
        "Automated trading bots can generate profits using a suitable trading strategy tailored to the prevailing market conditions. Using the SMARD cryptocurrency trading bot does not require an in-depth understanding of current market trends or the need to align your strategy with those trends. SMARD utilizes a ready-made algorithm based on momentum effect-based scientific research, along with a range of proven trading performance techniques, allowing it to systematically identify future market winners.",
    },
    {
      question: "How do bots trade?",
      answer:
        "Cryptocurrency trading with bots is automated, unlike the manual trading many traders perform. These bots use various signals to determine when to open and close positions. With SMARD, there is no need to customize any aspect of the strategy; all portfolio management techniques have already been implemented for you.",
    },
    {
      question: "What are the benefits of crypto trading bots?",
      answer:
        "Cryptocurrency trading bots offer several benefits, including enhanced efficiency in analyzing market data and executing trades quickly. Moreover, they enable 24/7 trading, eliminating the need for traders to be physically present, and help eliminate emotional decision-making by relying on data-driven strategies.",
    },
    {
      question: "How much does a trading bot cost?",
      answer:
        "The cost of bots may vary depending on the company that provides them. On average, tariffs cost from $15 to $50 per month, depending on the conditions and amount. SMARD has no fixed rates and requires no upfront payments. The first payment after 30 days is 10% of the client's profit only.",
    },
    {
      question: "Can I trade on my account while it is being traded by bot?",
      answer:
        "Yes, you can continue to use your account to invest or trade; just do not touch the position that the bot has formed. If, at the time of trading, SMARD does not see the expected position, then it will pause and not continue until you recover the expected position in the assets.",
    },
  ];
  