export default function StepOkxIcon(): JSX.Element {
  return (
  <svg width="100%" height="100%" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25Z"
          fill="#03BF80"/>
    <path d="M11.7027 14.9382V8H13.0978V14.9382H11.7027Z" fill="white"/>
    <path
        d="M7.9906 16.9989H5V10.0607H7.86924C9.26433 10.0607 10.076 10.8211 10.076 12.0101C10.0762 12.3073 9.99378 12.5988 9.83786 12.8518C9.68193 13.1049 9.45868 13.3095 9.19307 13.443C9.62396 13.6378 10.1762 14.0764 10.1762 15.0016C10.1773 16.2975 9.26433 16.9989 7.9906 16.9989ZM7.76012 11.2698H6.39509V12.8674H7.76012C8.35245 12.8674 8.68313 12.5456 8.68313 12.068C8.68313 11.5904 8.35134 11.2698 7.75901 11.2698H7.76012ZM7.85031 14.0865H6.39509V15.7921H7.85142C8.48383 15.7921 8.78445 15.4024 8.78445 14.9348C8.78445 14.4672 8.48161 14.0853 7.85142 14.0853L7.85031 14.0865Z"
        fill="white"/>
    <path d="M17.5926 11.2698V17H16.2019V11.2698H14.3314V10.0607H19.4586V11.2698H17.5926Z" fill="white"/>
  </svg>
  );
}

