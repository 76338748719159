import { createAsyncThunk } from "@reduxjs/toolkit";

import { ThunkApiConfigType } from "../../types/api";
import { StateAction } from "../action-types";
import { AccountActionPath, ApiRoute } from "../../const";
import {
  addSubscribedAccount,
  loadOverviewSummary,
  loadStrategies,
  setOverviewSummaryLoading,
  setOverviewSummaryStatus,
  setStrategiesLoading,
} from "../actions/overview";

export const fetchOverviewSummary = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Overview.LoadStrategies,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setOverviewSummaryLoading(true));
    try {
      const { data } = await api.get(ApiRoute.OverviewSummary);
      if (data) {
        dispatch(loadOverviewSummary(data));
      }
      dispatch(
        setOverviewSummaryStatus({
          isSuccess: true,
          message: "",
        })
      );
    } catch {}
    dispatch(setOverviewSummaryLoading(false));
  }
);

export const fetchStrategies = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Overview.LoadStrategies,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setStrategiesLoading(true));
    try {
      const { data } = await api.get(ApiRoute.Strategies);
      dispatch(loadStrategies(data));
    } catch {}
    dispatch(setStrategiesLoading(false));
  }
);

export const sendSubscribedAccount = createAsyncThunk<
  void,
  string,
  ThunkApiConfigType
>(
  StateAction.Overview.AddSubscribedAccount,
  async (id, { dispatch, extra: api }) => {
    try {
      await api.post(
        `${ApiRoute.AccountDetails}${id}${AccountActionPath.SUBSCRIBE}`
      );

      dispatch(addSubscribedAccount(id));
    } catch {}
  }
);
