import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import CurrentPosition from "../../components/current-position/current-position";
import LastOrders from "../../components/last-orders/last-orders";
import DetailsNote from "../../components/details-note/details-note";
import DetailsBlocks2Inner from "./details-blocks2-inner";
import AnimatedPositionDiscrepancy from "../../components/position-discrepancy/animated-position-discrepancy";
import { debounce } from "../../utils/chart/chart";
import { useAppSelector } from "../../hooks";
import { checkIfDesktop } from "../../utils/breakpoint";
import { RERENDER_DELAY, StrategyStatus } from "../../const";
import {
  CurrentPositionType,
  DivergenceType,
  LastOrdersType,
  StrategyOverviewType,
} from "../../types/details";

export default function DetailsBlocks2(): JSX.Element {
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  const { strategyDetails } = useAppSelector((state) => state);
  const currentPosition =
    strategyDetails?.current_position as CurrentPositionType[];

  const status = strategyDetails?.status as string;
  const divergence = strategyDetails?.divergence as DivergenceType[];
  const overview = strategyDetails?.overview as StrategyOverviewType;
  const accountName = overview?.code as string;
  const lastOrders = strategyDetails?.last_orders as LastOrdersType[];

  const isNotStarted = status === StrategyStatus.NotStarted;
  const isEmptyData = status === StrategyStatus.EmptyData;

  // const isLastOrdersShown = isOkx || isBinanceFutures || isBitget || isBybit;
  const isNoteShown = isNotStarted || isEmptyData;

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  return (
    <>
      {/* DIVERGENCE */}
      {/* Здесь анимация, тк после фикса, должна плавно уехать вверх */}
      <AnimatePresence initial={false}>
        {!isDesktop && divergence && divergence.length > 0 && (
          <AnimatedPositionDiscrepancy divergence={divergence} />
        )}
      </AnimatePresence>

      {/* TRADING RESULTS  and CHART*/}
      <DetailsBlocks2Inner />

      {/* NOTE */}
      {isNoteShown && <DetailsNote accountName={accountName} />}

      <div className="details__wrapper">
        {/* DIVERGANCE */}
        <AnimatePresence initial={false}>
          {isDesktop && divergence && divergence.length > 0 && (
            <AnimatedPositionDiscrepancy divergence={divergence} />
          )}
        </AnimatePresence>
        {/* CURRENT POSITION */}
        {currentPosition && <CurrentPosition />}
        {/* RECENT ACTIVITY */}
        {lastOrders && <LastOrders />}
      </div>
    </>
  );
}
