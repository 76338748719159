import { useEffect, useState } from "react";

import OkxPromo from "../../components/promos/okx-promo";
import OkxSteps from "../../components/steps/okx-steps";
import OkxBriefly from "../../components/brieflies/okx-briefly";
import OkxFaq from "../../components/faq/okx-faq";
import Bots from "../../components/bots/bots";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { deleteSeoData } from "../../utils/general";
import { OkxLandingSeoSetting } from "../../components/seo-setting/seo-setting";
import { trackEvent } from "../../utils/tracking";
import { checkIfMobile } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import GeneralExchangePluses from "../../components/pluses/general-exchange-pluses";

export default function OkxScreen(): JSX.Element {
  const [isMobile, setIsMobile] = useState<boolean>(checkIfMobile());

  // any page
  useEffect(() => {
    trackEvent("OKXLanding", "PageView");
    deleteSeoData();
  }, []);
  //

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsMobile(checkIfMobile());
    }, RERENDER_DELAY)
  );
  return (
    <main className="main main--landing landing">
      <OkxLandingSeoSetting />
      <TrackTwitter />
      <div className="landing__wrapper">
        <OkxPromo isMobile={isMobile} />
        <GeneralExchangePluses />
        <OkxSteps />
        <OkxBriefly />
        <Bots />
        <OkxFaq />
      </div>
    </main>
  );
}
