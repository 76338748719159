import { localAmount } from "../../../utils/amount";
import { displayCompound } from "../../../utils/chart/calc-chart";
import { showNumericDayMonth } from "../../../utils/datetime";

export function BaseChartTooltip({ payload }: any): JSX.Element | null {
  if (payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <p>{showNumericDayMonth(payload[0].payload.timestamp)}</p>
        <p>{`${localAmount(payload[0].payload.amount)} USDT`}</p>
      </div>
    );
  }
  return null;
}

export function PercentChartTooltip({ payload }: any): JSX.Element | null {
  if (payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <p>{showNumericDayMonth(payload[0].payload.timestamp)}</p>
        <p>{`${localAmount(payload[0].payload.amount)}%`}</p>
      </div>
    );
  }
  return null;
}

export function CoinChartTooltip({ payload }: any): JSX.Element | null {
  if (payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <p>{showNumericDayMonth(payload[0].payload.timestamp)}</p>
        <p>{`${localAmount(payload[0].payload.benchmarkAmount)} USDT`}</p>
      </div>
    );
  }
  return null;
}

export function CalcChartTooltip({ payload }: any): JSX.Element | null {
  if (payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <p>{displayCompound(payload[0].payload.compound)}</p>
        <p>{`${localAmount(Math.round(payload[0].payload.amount))} USDT`}</p>
      </div>
    );
  }
  return null;
}
