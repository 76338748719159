import Button from "../../button/button";
import { createBybitConnectLink } from "../../../utils/id";
import {
  BYBIT_API_SETTINGS_PAGE_LINK,
  StrategyStatus,
} from "../../../const";

type BybitRenewKeysInfoProps = {
  status: string | undefined;
};

export default function BybitRenewKeysInfo({
  status,
}: BybitRenewKeysInfoProps): JSX.Element {
  const bybitConnectLink = createBybitConnectLink();
  const isDemo = status === StrategyStatus.Demo;

  return (
    <div className="renew-keys-info">
      <p className="renew-keys-info__paragraph">
        Click on the "Reconnect" button below to update your credentials using
        Fast API.
      </p>
      <Button
        classElement={`renew-keys-info__btn renew-keys-info__btn--reconnect ${
          isDemo ? "btn--disabled" : ""
        }`}
        pattern="white-blue"
        text="Reconnect"
        icon="arrow-right"
        type="link"
        linkAddress={bybitConnectLink}
        isSelfLink
      />
      <p className="renew-keys-info__paragraph">
        Or you can update the keys manually by following the instruction below:
        {/* You can update the keys manually by following the instruction below: */}
      </p>
      <ul className="renew-keys-info__list">
        <li className="renew-keys-info__item">
          1. Go to{" "}
          <a
            className="link"
            href={BYBIT_API_SETTINGS_PAGE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            the&nbsp;API&nbsp;section
          </a>{" "}
          on Bybit and click "Connect to Third-Party Applications" with the following settings:
        </li>
        <li className="renew-keys-info__item">
          <ul className="renew-keys-info__inner-list">
            <li className="renew-keys__inner-item">
              Application name: SMARD
            </li>
            <li className="renew-keys__inner-item">
              Permissions: Read-Write
            </li>
            <li className="renew-keys__inner-item">
              Checkbox: Unified Trading
            </li>
          </ul>
        </li>
        <li className="renew-keys-info__item">
          2. Enter API Key and API Secret below;
        </li>
        <li className="renew-keys-info__item">
          3. Click on the "Renew keys" button.
        </li>
      </ul>
    </div>
  );
}
