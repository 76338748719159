export default function DollarInCalendarUpIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.06 0c.37 0 .67.26.67.6v3.22c0 .33-.3.6-.67.6-.37 0-.67-.27-.67-.6V.6c0-.33.3-.59.67-.59ZM11.28 0c.37 0 .67.26.67.6v3.22c0 .33-.3.6-.67.6-.37 0-.68-.27-.68-.6V.6c0-.33.3-.59.68-.59Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M11.95 1.45v1.5h1.58c.39 0 .73.27 1.03.56.16.15.38.24.6.24h.13a.7.7 0 0 0 .69-.53.62.62 0 0 0-.07-.5 2.83 2.83 0 0 0-2.38-1.27h-1.58ZM5.73 1.45v1.5h4.87v-1.5H5.73ZM4.39 2.95v-1.5H2.82A2.78 2.78 0 0 0 0 4.2v8.05A2.78 2.78 0 0 0 2.82 15h1.25c.43 0 .77-.34.77-.75a.76.76 0 0 0-.77-.75H2.82c-.71 0-1.28-.56-1.28-1.25V4.2c0-.7.57-1.25 1.28-1.25h1.57ZM13.05 9.73l2.31-4.62c.04-.07.09-.11.14-.11.05 0 .1.04.14.11l2.3 4.62c.03.05.05.12.06.2l-.01.22a.44.44 0 0 1-.07.17c-.04.04-.07.07-.11.07h-.96v3.84c0 .2-.04.4-.12.54-.07.15-.17.23-.27.23h-1.92c-.1 0-.2-.08-.27-.23a1.25 1.25 0 0 1-.12-.54V10.4h-.96c-.04 0-.07-.03-.1-.07a.44.44 0 0 1-.08-.17.78.78 0 0 1 0-.22c0-.08.02-.15.04-.2ZM7.58 13.85v-1.2c-.49 0-.94-.05-1.36-.12a5.44 5.44 0 0 1-1.06-.32v-1.23a6.6 6.6 0 0 0 2.42.57V9.37a7.84 7.84 0 0 1-1.4-.59c-.35-.2-.61-.44-.78-.71-.17-.27-.25-.6-.25-.99s.1-.73.3-1.01c.2-.29.5-.51.86-.68.36-.16.79-.26 1.27-.3v-.94h.77v.93a6.17 6.17 0 0 1 2.29.49l-.44 1.07a6.55 6.55 0 0 0-1.85-.43v2.12c.48.16.91.32 1.28.5.38.18.68.4.9.67.21.27.32.62.32 1.05 0 .57-.21 1.04-.64 1.4-.43.38-1.05.6-1.86.67v1.23h-.77Zm.77-2.35c.38-.05.66-.14.85-.29a.72.72 0 0 0 .27-.58.7.7 0 0 0-.11-.42.98.98 0 0 0-.37-.3 3.68 3.68 0 0 0-.64-.26v1.85Zm-.77-3.45V6.23c-.23.03-.42.07-.58.14a.83.83 0 0 0-.36.27.68.68 0 0 0-.11.4c0 .17.03.32.1.44a.9.9 0 0 0 .34.31c.16.09.36.18.61.26Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.43 4H8.5v.93a6.18 6.18 0 0 1 2.2.5l.13.06-.54 1.35-.14-.06a6.4 6.4 0 0 0-1.65-.4v1.84c.45.15.84.3 1.2.47.4.19.71.42.94.72.24.3.36.68.36 1.14 0 .61-.23 1.12-.7 1.52-.43.38-1.03.6-1.8.69V14H7.43v-1.2a8.08 8.08 0 0 1-1.23-.12 5.58 5.58 0 0 1-1.1-.33l-.1-.04v-1.57l.22.1a6.46 6.46 0 0 0 2.2.55v-1.9a7.73 7.73 0 0 1-1.31-.58 2.3 2.3 0 0 1-.84-.76C5.1 7.85 5 7.49 5 7.08c0-.42.1-.79.33-1.1.22-.3.53-.55.91-.73a3.7 3.7 0 0 1 1.19-.3V4Zm.3.3v.94H7.6c-.47.04-.87.13-1.22.29-.34.16-.6.37-.8.63-.17.25-.27.56-.27.92s.08.66.23.91a2 2 0 0 0 .73.66c.34.2.8.4 1.37.58l.1.03v2.44h-.16a6.42 6.42 0 0 1-2.26-.5v.91c.27.1.58.2.94.27.4.07.85.12 1.33.12h.15v1.2h.47v-1.22l.14-.01a3.1 3.1 0 0 0 1.77-.63c.4-.34.59-.77.59-1.29 0-.4-.1-.72-.3-.95-.2-.25-.47-.46-.83-.63-.37-.18-.79-.34-1.27-.5l-.1-.03v-2.4l.16.02a7.1 7.1 0 0 1 1.76.39l.32-.8c-.29-.12-.6-.21-.9-.28h-.01a6.02 6.02 0 0 0-1.18-.14H8.2V4.3h-.47Zm0 1.76v2.2l-.2-.07a3.58 3.58 0 0 1-.63-.27c-.18-.1-.31-.22-.4-.36a.99.99 0 0 1-.13-.52c0-.18.05-.34.15-.48.1-.14.24-.25.42-.33.17-.07.38-.12.62-.15l.17-.02Zm-.3.35c-.15.02-.27.06-.37.1a.68.68 0 0 0-.3.22.53.53 0 0 0-.08.31c0 .15.03.27.08.36.06.1.15.18.28.26.1.06.24.12.39.17V6.41Zm.77 3.04.2.06c.27.08.5.17.67.27.18.1.32.21.41.35l-.12.08.12-.08c.1.14.15.3.15.5 0 .28-.12.52-.34.7-.22.17-.53.27-.92.32l-.17.02V9.45Zm.3.41v1.47c.28-.05.47-.14.6-.24a.57.57 0 0 0 .22-.46.55.55 0 0 0-.09-.33.83.83 0 0 0-.3-.25 2.96 2.96 0 0 0-.43-.19Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
