import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import Button from "../button/button";
import Message from "../message/message";
import TriangleDownIcon from "../icons/triangle/triangle-down-icon";
import { EMAILJS_WITHDRAW_TEMPLATE_ID, ErrorType } from "../../const";

type ReferralsWithdrawProps = {
  clientId: null | number;
  currentBalance: number;
};

export default function ReferralsWithdraw({
  clientId,
  currentBalance,
}: ReferralsWithdrawProps): JSX.Element {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sentFormStatus, setSentFormStatus] = useState<string>("");
  const [isInsufBalanceError, setInsufBalanceError] = useState<boolean>(false);
  const refBtn = useRef<null | HTMLButtonElement>(null);

  const MIN_AMOUNT = 100;
  const formattedBalance = Math.floor(currentBalance);
  const isInsufficientBalance = formattedBalance < MIN_AMOUNT;

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    formState: { isValid },
  } = useForm({ mode: "onChange" });

  const validAmount = (value: number) =>
    Number(value) >= MIN_AMOUNT && Number(value) <= formattedBalance;
  const validAddress = (value: string) => value.length >= 5;

  // onSubmit
  const onSubmit = () => {
    setLoading(true);
    const data = getValues(["address", "amount"]);
    const [address, amount] = data;

    const onSuccess = (response: any) => {
      setSentFormStatus("success");
      setLoading(false);
      reset();
      trigger("amount");

      if (refBtn.current) {
        refBtn.current.blur();
      }
    };

    const onError = (err: any) => {
      setSentFormStatus("error");
      setLoading(false);
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        EMAILJS_WITHDRAW_TEMPLATE_ID,
        {
          client_id: clientId ?? "",
          address: address,
          network: "ERC20",
          amount: amount,
        },
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY as string
      )
      .then(
        (response) => {
          onSuccess(response);
        },
        (err) => {
          onError(err);
        }
      );
  };

  const handleMaxClick = () => {
    setValue("amount", formattedBalance);
  };

  const handleAmountChange = () => {
    if (sentFormStatus) {
      setSentFormStatus("");
    }

    if (isInsufficientBalance) {
      setInsufBalanceError(true);
    }
  };

  const handleAddressChange = () => {
    if (sentFormStatus) {
      setSentFormStatus("");
    }
  };

  useEffect(() => {
    trigger("amount");
  }, [formattedBalance, trigger]);

  return (
    <section className="block referrals-withdraw">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Withdraw</h2>
      </div>
      <form
        className="referrals-withdraw__form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="referrals-withdraw__wrapper referrals-withdraw__wrapper--address shadow shadow--field">
          <label className="visually-hidden">
            Please enter address of your USDT wallet
          </label>
          <input
            className="field referrals-withdraw__field referrals-withdraw__field--address"
            type="text"
            placeholder="Please enter address of your USDT wallet"
            {...register("address", {
              required: true,
              onChange: handleAddressChange,
              validate: (value) => validAddress(value),
            })}
          />
        </div>

        <div className="referrals-withdraw__wrapper referrals-withdraw__wrapper--network-amount">
          {/* Amount field */}
          <div className="referrals-withdraw__wrapper referrals-withdraw__wrapper--amount-main">
            <div className="referrals-withdraw__wrapper referrals-withdraw__wrapper--amount-max">
              <label className="visually-hidden">Amount</label>
              <input
                className="field referrals-withdraw__field referrals-withdraw__field--amount"
                type="number"
                placeholder="Amount"
                autoComplete="off"
                {...register("amount", {
                  required: true,
                  onChange: handleAmountChange,
                  validate: (value) => validAmount(value),
                })}
              />
              <button
                className="max-btn"
                type="button"
                onClick={handleMaxClick}
              >
                MAX
              </button>
            </div>

            {/* amount message */}
            {formattedBalance > 100 ? (
              <Message
                classElement="referrals-withdraw__message-amount"
                status="neutral"
                message={`From ${MIN_AMOUNT} till ${formattedBalance}`}
              />
            ) : isInsufBalanceError ? (
              <Message
                classElement="referrals-withdraw__message-amount"
                status="error"
                message="Minimum 100 USDT required"
              />
            ) : (
              <Message status="" message="" />
            )}
          </div>

          {/* Network field */}
          <div className="field field--disabled field--select referrals-withdraw__field referrals-withdraw__field--network">
            <span className="referrals-withdraw__network-text">ERC20</span>
            <span className="field__icon">
              <TriangleDownIcon />
            </span>
          </div>
        </div>

        <div className="referrals-withdraw__wrapper referrals-withdraw__wrapper--submit">
          <Button
            classElement={`referrals-withdraw__submit-btn ${
              !isValid || isLoading ? "btn--disabled" : ""
            }`}
            pattern="white-green"
            text="Confirm"
            icon={isLoading ? "arrow-loader" : "credit-card"}
            type="submit"
            ref={refBtn}
          />

          {!sentFormStatus ? (
            <Message status="" message="" />
          ) : sentFormStatus === "success" ? (
            <Message
              status="success"
              message="Your withdrawal request has been successfully submitted"
            />
          ) : (
            <Message status="error" message={ErrorType.General} />
          )}
        </div>
      </form>
    </section>
  );
}
