import { useState } from "react";
import { AreaChart, XAxis, ResponsiveContainer } from "recharts";

import { displayChartGradient } from "./options/chart-gradient";
import { displayChartGrid } from "./options/chart-grid";
import { displayChartTooltip } from "./options/chart-tooltip";
import { displayChartMainYAxis } from "./options/chart-main-yaxis";
import { displayChartBenchYAxis } from "./options/chart-bench-yaxis";
import { displayChartAreas } from "./options/chart-areas";
import { displayChartSmardEndDot } from "./options/chart-smard-end-dot";
import { displayChartBenchEndDot } from "./options/chart-bench-end-dot";
import { ChartType } from "../../types/chart";
import { BaseChartTooltip } from "./tooltips/chart-tooltips";
import { showNumericDayMonth } from "../../utils/datetime";
import { RERENDER_DELAY } from "../../const";
import { checkIfChartMoreMobileWidth } from "../../utils/breakpoint";
import { formatDateIntoMonths } from "../../utils/chart/date";
import { checkColor } from "../../utils/chart/color";
import {
  checkMinDomain,
  collectDates,
  debounce,
  findTicks,
  formatTicks,
} from "../../utils/chart/chart";
import {
  findCorrelativeMinMax,
  findTimeTicks,
  formatDetails2ChartsData,
} from "../../utils/chart/details-chart";

type DemoChartProps = {
  type: string;
  main: ChartType[];
  btcBench: ChartType[];
  ethBench: ChartType[];
  isAllDays: boolean;
  isBitcoin: boolean;
};

export default function DemoChart({
  type,
  main,
  btcBench,
  ethBench,
  isAllDays,
  isBitcoin,
}: DemoChartProps): JSX.Element {
  const [isEndDotsShown, setEndDotsShown] = useState<boolean>(true);
  const [isMoreMobile, setMoreMobile] = useState<boolean>(
    checkIfChartMoreMobileWidth()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setMoreMobile(checkIfChartMoreMobileWidth());
    }, RERENDER_DELAY)
  );

  // Check period
  const checkPeriod = (data: ChartType[]) =>
    isAllDays ? data : data.slice(-32);
  const mainData = checkPeriod(main);
  const benchData = checkPeriod(
    isBitcoin ? checkPeriod(btcBench) : checkPeriod(ethBench)
  );

  const data = formatDetails2ChartsData(mainData, benchData);
  const minMaxMain = findCorrelativeMinMax(mainData, benchData).strategyRange;
  const minMaxBench = findCorrelativeMinMax(mainData, benchData).benchRange;
  const checkChartColor = () => checkColor(mainData);
  const chartType = `demo-${type}`;

  // For XAxis
  const timestamps = collectDates(benchData);
  const timestampTicks = findTimeTicks(timestamps);
  const formatTimestampTicks = (tick: number) =>
    isAllDays
      ? formatDateIntoMonths(timestampTicks, tick)
      : showNumericDayMonth(tick);

  // For YAxis props
  const isFewData = mainData.length < 5 || benchData.length < 5;
  const formatAmountTicks = (tick: number) => formatTicks(tick);

  const checkedMainMin = checkMinDomain(minMaxMain[0], minMaxBench[0]);
  const checkedBenchMin = checkMinDomain(minMaxBench[0], minMaxMain[0]);

  const mainDomain = [checkedMainMin, minMaxMain[1]];
  const mainTicks = findTicks(checkedMainMin, minMaxMain[1]);
  const benchDomain = [checkedBenchMin, minMaxBench[1]];
  const benchTicks = findTicks(checkedBenchMin, minMaxBench[1]);

  return (
    <div className="chart chart--demo">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: isMoreMobile ? 0 : 15,
          }}
        >
          {displayChartGradient(chartType, checkChartColor)}
          {displayChartGrid()}

          {/* Timestamps*/}
          <XAxis
            dataKey="timestamp"
            axisLine={false}
            tickLine={false}
            interval={0}
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={formatTimestampTicks}
            ticks={timestampTicks}
            tickSize={isMoreMobile ? 6 : 18}
            angle={isMoreMobile ? 0 : -90}
          />

          {/* Strategy*/}
          {displayChartMainYAxis(
            isFewData,
            formatAmountTicks,
            mainDomain,
            mainTicks
          )}

          {/* Benchmark*/}
          {displayChartBenchYAxis(
            isFewData,
            formatAmountTicks,
            benchDomain,
            benchTicks
          )}

          {displayChartAreas(
            chartType,
            isBitcoin,
            checkChartColor,
            setEndDotsShown
          )}

          {displayChartTooltip(<BaseChartTooltip payload />)}
          {displayChartBenchEndDot(data, isBitcoin, isEndDotsShown)}
          {displayChartSmardEndDot(data, isEndDotsShown)}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
