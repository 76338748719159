import { Dispatch, SetStateAction } from "react";
import { Area } from "recharts";

import {
  BitcoinActiveDot,
  CustomActiveDot,
  EthereumActiveDot,
  SmardActiveDot,
} from "../dots/active-dots";

export function displayChartAreas(
  type: string,
  isBitcoin: boolean,
  checkChartColor: () => string,
  setEndDotsShown: Dispatch<SetStateAction<boolean>>
): JSX.Element {
  return (
    <>
      <Area
        yAxisId="left"
        animationDuration={400}
        type="monotone"
        dataKey="amount"
        stroke={checkChartColor()}
        strokeWidth={2}
        fillOpacity={0.4}
        fill={`url(#${type}-chart-gradient)`}
      />
      <Area
        yAxisId="right"
        animationDuration={400}
        type="monotone"
        strokeWidth={1.5}
        dataKey="benchmarkAmount"
        stroke="rgba(92, 100, 199, 0.7)"
        fill="none"
        activeDot={isBitcoin ? <BitcoinActiveDot /> : <EthereumActiveDot />}
      />
      {/* Чтобы актив. точка SMARD всегда на перед. плане, хотя весь чарт SMARD позади чарта benchmarkа */}
      <Area
        yAxisId="left"
        type="monotone"
        dataKey="amount"
        strokeWidth={0}
        fill="transparent"
        activeDot={<SmardActiveDot setEndDotsShown={setEndDotsShown} />}
      />
    </>
  );
}

export function displayCoinChartAreas(
  type: string,
  checkChartColor: () => string,
  setEndDotsShown: Dispatch<SetStateAction<boolean>>,
  imgLink: string
): JSX.Element {
  return (
    <>
      <Area
        yAxisId="left"
        animationDuration={400}
        type="monotone"
        dataKey="benchmarkAmount"
        stroke={checkChartColor()}
        strokeWidth={2}
        fillOpacity={0.3}
        fill={`url(#${type}-chart-gradient)`}
      />
      <Area
        yAxisId="right"
        animationDuration={400}
        type="monotone"
        dataKey="amount"
        strokeWidth={1.5}
        stroke="rgba(92, 100, 199, 0.7)"
        fill="none"
        activeDot={<SmardActiveDot setEndDotsShown={setEndDotsShown} />}
      />
      {/* Чтобы актив. точка Coin всегда на перед. плане, хотя весь чарт Coin позади чарта SMARD */}
      <Area
        yAxisId="left"
        type="monotone"
        dataKey="benchmarkAmount"
        strokeWidth={0}
        fill="transparent"
        activeDot={
          <CustomActiveDot
            setEndDotsShown={setEndDotsShown}
            imgLink={imgLink}
          />
        }
      />
    </>
  );
}
