export default function EqualIcon(): JSX.Element {
  return (
    <svg
      width="9"
      height="5"
      viewBox="0 0 9 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="9" height="1.5" fill="currentColor" />
      <rect
        width="9"
        height="1.5"
        transform="matrix(1 0 0 -1 0 5)"
        fill="currentColor"
      />
    </svg>
  );
}
