import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";

import Button from "../button/button";
import CoinBanner from "../coin-banner/coin-banner";
import { useAppSelector } from "../../hooks";
import { CoinType } from "../../types/coins";

type CoinDescriptionProps = {
  coin: CoinType;
};

export default function CoinDescription({
  coin,
}: CoinDescriptionProps): JSX.Element {
  const { accountStatuses } = useAppSelector((state) => state);
  const [isExtraShown, setExtraShown] = useState<boolean>(false);
  const [truncated, setTruncated] = useState<string>("");

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);
  const isBannerHide = accountStatuses?.already_launched;
  const coinName = `${coin.title} (${coin.ticker})`;
  const restText = coin.description.slice(truncated.length);

  const handleShowClick = () => {
    setExtraShown(!isExtraShown);
  };


  return (
    <section className="coin-description">
      <h2 className="visually-hidden">Coin description</h2>

      {!isBannerHide && <CoinBanner />}

      <div
        className={`coin-description__info ${
          isBannerHide ? "coin-description__info--no-banner" : ""
        }`}
      >
        <h3 className="coin-description__heading">{`What Is  ${coinName}?`}</h3>

        <ResponsiveEllipsis
          className="coin-description__paragraph"
          text={coin.description}
          maxLine="16"
          ellipsis={isExtraShown ? "" : "..."}
          onReflow={({ clamped, text }) => setTruncated(text)}
        />

        <AnimatePresence initial={false}>
          {isExtraShown && (
            <motion.div
              initial={{
                height: 0,
                opacity: 0,
              }}
              animate={{
                height: "auto",
                opacity: 1,
              }}
              exit={{
                height: 0,
                opacity: 0,
              }}
              style={{ overflow: "hidden" }}
              transition={{ duration: 0.4 }}
            >
              <div className="coin-description__hidden">
                <p className="coin-description__paragraph">{restText}</p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {restText.length !== 0 && (
          <Button
            classElement="coin-description__info-btn"
            pattern="blue-white"
            text={isExtraShown ? "Show less" : "Show more"}
            icon={isExtraShown ? "triangle-up" : "triangle-down"}
            type="button"
            size="big"
            handleClick={handleShowClick}
          />
        )}
      </div>
    </section>
  );
}
