import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Copy from "../../components/copy/copy";
import { BitgetSetupSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import {
  AccountName,
  AppRoute,
  AuthStatus,
  BITGET_REGISTER_LINK,
  IP_ADDRESS_WITH_COMMA,
} from "../../const";

export default function BitgetSetupScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  const autoHeadingRef = useRef<null | HTMLHeadingElement>(null);
  const appHeadingRef = useRef<null | HTMLHeadingElement>(null);

  const handleAutoBtnClick = () => {
    if (autoHeadingRef.current) {
      const target = autoHeadingRef.current.getBoundingClientRect().top - 5;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    }
  };

  const handleAppBtnClick = () => {
    if (appHeadingRef.current) {
      const target = appHeadingRef.current.getBoundingClientRect().top - 5;

      window.scrollTo({
        top: target,
        behavior: "smooth",
      });
    }
  };

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main setup">
      <TrackTwitter />
      <BitgetSetupSeoSetting />
      <div className="setup__wrapper">
        <h1 className="setup__heading">Bitget Futures APIs Setup</h1>
        <h2 className="setup__heading-inner">Note</h2>
        <p className="setup__intro">
          If you do not already have an Bitget account, you can create the one
          using{" "}
          <a
            className="link"
            href={BITGET_REGISTER_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            this link
          </a>
          .
        </p>
        <ul className="setup__intro-list">
          <li className="setup__intro-item">
            <button
              className="setup__intro-scroll"
              onClick={handleAutoBtnClick}
            >
              Fast API
            </button>{" "}
            <span className="setup__intro-text">(Less than 1 minute)</span>
          </li>
          <li className="setup__intro-item">
            <button className="setup__intro-scroll" onClick={handleAppBtnClick}>
              Manual connection
            </button>{" "}
            <span className="setup__intro-text">(3-5 minutes)</span>
          </li>
        </ul>
        <h2 className="setup__heading-inner" ref={autoHeadingRef}>
          Fast API
        </h2>
        <p className="setup__intro">
          Connect your Bitget account to SMARD in less than 1 minute using
          OAuth.
        </p>
        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`}
              >
                Bitget details page
              </Link>{" "}
              and click on the <b>"Connect my account"</b> button.
            </p>

            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-auto-1.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              2. This will redirect you to the Bitget log in page. Here you can
              log in or create a new Bitget account.
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-auto-2.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">
              3. Complete the login or sign up process and click on the{" "}
              <b>"Next"</b> button.
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-auto-3.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              4. Return to the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`}
              >
                Bitget details page
              </Link>{" "}
              and specify the amount to be traded by SMARD and then press the{" "}
              <b>"Start"</b> button.
            </p>
            <img
              className="setup__img setup__img--big"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-auto-4.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>
        </ul>
        <p className="setup__post">
          Congratulations! You are all set for automated trading with SMARD!
        </p>
        <h2 className="setup__heading-inner" ref={appHeadingRef}>
          Manual connection
        </h2>
        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1. Go to the{" "}
              <a
                className="link"
                href="https://www.bitget.com/account/newapi"
                target="_blank"
                rel="noopener noreferrer"
              >
                API keys section
              </a>{" "}
              on Bitget and click the <b>"Create API Key"</b> button.
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-1.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">2. Choose API key type:</p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-2.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
            <ul className="setup__paragraph-list">
              <li className="setup__paragraph-item">
                <p className="setup__paragraph">
                  Choose <b>System-generated API key</b> if you prefer Bitget to
                  create both API key and API Secret for you. In the article
                  we'll consider this type.
                </p>
              </li>
              <li className="setup__paragraph-item">
                <p className="setup__paragraph">
                  Choose <b>User-generated API key</b> if you know how to
                  manually create API key and API Secret.
                </p>
              </li>
            </ul>
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">3. Create Note and Passphrase.</p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-3.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">4. Enable permissions.</p>
            <p className="setup__paragraph">
              Choose the <b>Read-write</b> option and enable permissions for{" "}
              <b>Futures</b> (<b>Orders</b> and <b>Holdings</b>) and <b>Spot</b>{" "}
              (<b>Trade</b>).
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-4-1.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-4-2.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              5. Enter the IP whitelist addresses.
            </p>
            <p className="setup__paragraph">
              Copy <Copy text={IP_ADDRESS_WITH_COMMA} /> the list of IPs on
              SMARD, paste it on Bitget and click the <b>"Next"</b> button.
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-5.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">6. Enter the verification codes.</p>
            <p className="setup__paragraph">
              After you enter the codes, please click the <b>"Confirm"</b>{" "}
              button:
            </p>
            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-6.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">7. Save the keys.</p>

            <img
              className="setup__img setup__img--medium"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-7.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              8. Finally, click on the <b>"Enter API keys manually"</b> button
              on the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.BitgetFutures}/`}
              >
                Bitget details page
              </Link>
              .
            </p>
            <img
              className="setup__img"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-8.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              9. Copy/paste the <b>API Key</b>, <b>Secret Key</b>,{" "}
              <b>Passphrase</b> and specify the amount to trade.
            </p>
            <img
              className="setup__img setup__img--big"
              src={`${process.env.PUBLIC_URL}/img/screens/bitget-setup/bitget-setup-manual-9.jpg`}
              alt="Bitget setup"
              width="100%"
              height="auto"
            />
          </li>
        </ul>
        <p className="setup__post">
          Congratulations! You are all set for automated trading with SMARD!
        </p>
      </div>
    </main>
  );
}
