import { createAction } from "@reduxjs/toolkit";
import { StateAction } from "../action-types";
import { DemoType, DiversifiedDemoType } from "../../types/general";

export const loadOkxDemo = createAction<DemoType>(StateAction.Demo.LoadOkxDemo);
export const setOkxDemoLoading = createAction<boolean>(
  StateAction.Demo.SetOkxLoading
);

export const loadBybitDemo = createAction<DemoType>(StateAction.Demo.LoadBybitDemo);
export const setBybitDemoLoading = createAction<boolean>(
  StateAction.Demo.SetBybitLoading
);

export const loadBinanceSpotDemo = createAction<DemoType>(
  StateAction.Demo.LoadBinanceSpotDemo
);
export const setBinanceSpotDemoLoading = createAction<boolean>(
  StateAction.Demo.SetBinanceSpotDemoLoading
);

export const loadBinanceFuturesDemo = createAction<DemoType>(
  StateAction.Demo.LoadBinanceFuturesDemo
);
export const setBinanceFuturesDemoLoading = createAction<boolean>(
  StateAction.Demo.SetBinanceFuturesDemoLoading
);

export const loadBitgetFuturesDemo = createAction<DemoType>(
  StateAction.Demo.LoadBitgetFuturesDemo
);
export const setBitgetFuturesDemoLoading = createAction<boolean>(
  StateAction.Demo.SetBitgetFuturesDemoLoading
);

export const loadDiversifiedDemo = createAction<DiversifiedDemoType>(
  StateAction.Demo.LoadDiversifiedDemo
);
