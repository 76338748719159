import { AreaChart, Area, XAxis, YAxis, ResponsiveContainer } from "recharts";

import { checkColor } from "../../utils/chart/color";
import { displayChartGradient } from "./options/chart-gradient";
import { displayChartGrid } from "./options/chart-grid";
import { displayChartTooltip } from "./options/chart-tooltip";
import { formatCalcDateIntoMonths } from "../../utils/chart/date";
import { SmardActiveDotNoEnd } from "./dots/active-dots";
import { CalcChartTooltip } from "./tooltips/chart-tooltips";
import { ChartType } from "../../types/chart";
import { findMinMaxForCalcChart } from "../../utils/chart/calc-chart";
import {
  collectAmounts,
  collectDates,
  findTicks,
  formatTicks,
} from "../../utils/chart/chart";

type CalcChartProps = {
  data: ChartType[];
};

export default function CalcChart({ data }: CalcChartProps): JSX.Element {
  const chartType = "calc";
  const checkChartColor = () => checkColor(data);
  const amounts = collectAmounts(data);
  const timestamps = collectDates(data);

  // For YAxis
  const amountDomain = findMinMaxForCalcChart(amounts);
  const formatAmountTicks = (tick: number) => formatTicks(tick);
  const amountTicks = findTicks(
    findMinMaxForCalcChart(amounts)[0],
    findMinMaxForCalcChart(amounts)[1]
  );

  const CustomizedAxisTick = ({
    x,
    y,
    payload,
    width,
    visibleTicksCount,
  }: any) => {
    const widthBetweenTicks = width / (visibleTicksCount - 1);
    const halfTickHeightInMobile = 6.5;
    return (
      <g
        transform={`translate(${
          x + widthBetweenTicks / 2 - halfTickHeightInMobile
        },${y}) rotate(${-90})`}
      >
        <text x={0} y={0} dy={10} textAnchor="middle" fill="#555555">
          {formatCalcDateIntoMonths(timestamps, payload.value)}
        </text>
      </g>
    );
  };

  return (
    <div className="chart chart--calc">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          // Здесь domain для сумм с отступами (полосы исключаются), и сверху поставили top: 10 для вертикальных тиков, иначе часть верхних закрывается и bottom: 10 для нижних тиков в мобилке
          margin={{
            top: 10,
            right: 5,
            left: 0,
            bottom: 15,
          }}
        >
          {displayChartGradient(chartType, checkChartColor)}
          {displayChartGrid()}

          <XAxis
            dataKey="timestamp"
            axisLine={false}
            tickLine={false}
            interval={0}
            tickSize={18}
            tick={<CustomizedAxisTick />}
          />

          <YAxis
            yAxisId="left"
            orientation="left"
            axisLine={false}
            tickLine={false}
            dataKey="amount"
            width={50}
            allowDecimals={false}
            interval={0}
            domain={amountDomain}
            tickFormatter={formatAmountTicks}
            ticks={amountTicks}
          />

          <Area
            yAxisId="left"
            animationDuration={400}
            type="monotone"
            dataKey="amount"
            stroke={checkChartColor()}
            strokeWidth={2}
            fillOpacity={0.4}
            fill="url(#calc-chart-gradient)"
            activeDot={<SmardActiveDotNoEnd />}
          />

          {displayChartTooltip(<CalcChartTooltip payload />)}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
