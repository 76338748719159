import FaqItem from "./faq-item";
import { createBitgetConnectLink } from "../../utils/id";
import { formatedMinNotional } from "../../utils/env";

export default function BitgetFaq(): JSX.Element {
  const bitgetConnectLink = createBitgetConnectLink();

  return (
    <div className="landing__item faq faq--landing">
      <h2 className="landing__title landing__title--faq">
        Frequently Asked Questions
      </h2>
      <ul className="faq__inner-list">
        <FaqItem
          question="Is Bitget a safe platform?"
          answer={
            <p className="faq-item__paragraph">
              Bitget claims to provide a secure trading environment with
              advanced encryption, multi-factor authentication and regular
              security audits to protect users' assets and information.
            </p>
          }
        />
        <FaqItem
          question="How do I connect my SMARD account to Bitget using the API?"
          answer={
            <p className="faq-item__paragraph">
              SMARD users can connect their Bitget account in just a few clicks
              using the{" "}
              <a
                className="link"
                href={bitgetConnectLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Fast API
              </a>
              .
            </p>
          }
        />
        <FaqItem
          question="What is the minimum amount to start trading with SMARD?"
          answer={
            <p className="faq-item__paragraph">
              {`The minimum amount for launching SMARD strategies is ${formatedMinNotional} USDT.`}
            </p>
          }
        />
        <FaqItem
          question="Is there a fully automated trading bot?"
          answer={
            <p className="faq-item__paragraph">
              Yes, SMARD trading bots are fully automated and require no trading
              or programming knowledge. All portfolio management technologies
              have already been implemented for you.
            </p>
          }
        />
        <FaqItem
          question="How much does Bitget trading bot by SMARD cost?"
          answer={
            <p className="faq-item__paragraph">
              SMARD has no fixed fees and does not require a credit card to
              start trading. The first payment after 30 days is 10% of the
              profit.
            </p>
          }
        />
      </ul>
    </div>
  );
}
