import Hint from "../hint/hint";
import DemoIcon from "../icons/demo-icon";
import { HINTS_TEXT } from "../../const";

export default function Demo(): JSX.Element {
  return (
    <div className="demo">
      <div className="demo__icon-wrapper">
        <span className="demo__icon">
          <DemoIcon />
        </span>
      </div>

      <Hint
        type="demo__hint"
        id="page-header/demo"
        hintText={HINTS_TEXT["page-header/demo"]}
      />
    </div>
  );
}
