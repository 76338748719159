import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

// DURATION

dayjs.extend(duration);

export const formatCountdownTime = (time: number) => {
  return dayjs.duration(time, "seconds").format("mm:ss");
};

export const getRangeOfMonths = (start: number, end: number) => {
  const dates = [];
  const startDate = new Date(start * 1000);
  const endDate = new Date(end * 1000);

  // avoids edge case where last month is skipped
  // например время стартовой даты позже чем время время конечной даты
  // при одинаковом дне месяца
  startDate.setDate(1);
  startDate.setHours(0);
  startDate.setMinutes(0);

  while (startDate < endDate) {
    dates.push(`${startDate.getFullYear()}-${startDate.getMonth() + 1}`);
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return dates;
};

// LOCAL
// TIME

export const showTime = (unixtime: number) => {
  const date = new Date(unixtime * 1000);
  return date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
  });
};

// YEARS

export const showFullCurrentYear = () => {
  return new Date().getFullYear();
};

export const showShortYear = (unixtime: number) => {
  const date = new Date(unixtime * 1000);
  return date.toLocaleString(undefined, {
    year: "2-digit",
  });
};

// REST

export const showFullDate = (unixtime: number) => {
  // Convert timestamp to milliseconds
  const date = new Date(unixtime * 1000);
  return date.toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
};

export const showDateWithShortYear = (unixtime: number) => {
  const date = new Date(unixtime * 1000);
  return date.toLocaleString(undefined, {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
};

export const showNumericDayMonth = (unixtime: number) => {
  const date = new Date(unixtime * 1000);
  return date.toLocaleString(undefined, {
    month: "numeric",
    day: "numeric",
  });
};

export const showShortMonthInEn = (unixtime: number) => {
  const date = new Date(unixtime * 1000);
  return date.toLocaleString("en", {
    month: "short",
  });
};

// BITGET EVENT
const START_BITGET_EVENT_UTC_TIME = Date.UTC(2024, 1, 26, 20, 0, 0); //26фев(20:00UTC)
const FINISH_BITGET_EVENT_UTC_TIME = Date.UTC(2024, 2, 18, 9, 0, 0); //18марта(09:00UTC)
const currentUtcTime = new Date(new Date().toUTCString()).getTime();

// TEST
// const TEST_START_BITGET_EVENT_UTC_TIME = Date.UTC(2024, 1, 26, 17, 59, 0);
// const TEST_FINISH_BITGET_EVENT_UTC_TIME = Date.UTC(2024, 1, 26, 18, 0, 0);
// const startBitgetEventTime = TEST_START_BITGET_EVENT_UTC_TIME;
// export const finishBitgetEventTime = TEST_FINISH_BITGET_EVENT_UTC_TIME;

// Comment while testing
const startBitgetEventTime = START_BITGET_EVENT_UTC_TIME;
export const finishBitgetEventTime = FINISH_BITGET_EVENT_UTC_TIME;

const isLaterThanBitgetStartTime = currentUtcTime >= startBitgetEventTime;
const isEarlierThanBitgetFinishTime = currentUtcTime < finishBitgetEventTime;
export const isBitgetEventTime =
  isLaterThanBitgetStartTime && isEarlierThanBitgetFinishTime;
