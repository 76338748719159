export default function RhombIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 15 15"
      fill="none"
    >
      <path
        fill="currentColor"
        d="m3.39 7.5-1.7 1.7L0 7.5l1.7-1.7 1.69 1.7ZM7.5 3.39l2.9 2.9 1.7-1.7L7.5 0 2.9 4.6l1.7 1.7 2.9-2.91Zm5.8 2.42L11.62 7.5l1.7 1.7L15 7.5l-1.7-1.7Zm-5.8 5.8-2.9-2.9-1.7 1.7L7.5 15l4.6-4.6-1.7-1.69-2.9 2.9Zm0-2.42L9.2 7.5 7.5 5.8 5.8 7.5l1.7 1.7Z"
      />
    </svg>
  );
}
