export default function PaymentsLastSkeleton(): JSX.Element {
  return (
    <div className="sk-payments-last animate-pulse">
      <div className="sk-payments-last__row">
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
      </div>
      <div className="sk-payments-last__row">
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
      </div>
      <div className="sk-payments-last__row">
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
      </div>
      <div className="sk-payments-last__row">
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
        <div className="sk-payments-last__text"></div>
      </div>
    </div>
  );
}
