type GridItemProps = {
  name: string;
  img: string;
  link?: string;
  isButton?: boolean;
};
export default function GridItem({
  name,
  img,
  link,
  isButton,
}: GridItemProps): JSX.Element {
    
  const imgTag = (
    <img
      className="grid__img"
      src={`${process.env.PUBLIC_URL}/img/${img}`}
      alt={name}
      width={135}
      height={50}
    />
  );

  return link ? (
    <li className="grid__item grid__item--with-link">
      <a
        className="grid__link"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {imgTag}
      </a>
    </li>
  ) : (
    <li className="grid__item">{imgTag}</li>
  );
}
