import { forwardRef } from "react";

import OverviewCurrent from "../elements/overview-current";
import OverviewFromStart from "../elements/overview-from-start";
import OverviewInitial from "../elements/overview-initial";
import OverviewSigns from "../elements/overview-signs";
import OverviewLastMonth from "../elements/overview-last-month";
import OverviewBtn from "../elements/overview-btn";
import OverviewDynamics from "../elements/overview-dynamics";
import { StrategyStatus } from "../../../const";
import { StrategyType } from "../../../types/strategy";

type OverviewStartedProps = {
  overview: StrategyType;
  isTabletAndMore: boolean;
  index: number;
  isNavigateAble: boolean;
};

function OverviewStarted(
  { overview, isTabletAndMore, index, isNavigateAble }: OverviewStartedProps,
  ref: any
): JSX.Element {
  return (
    <div className="overview-started">
      <div className="overview-board__total-wrapper overview-started__total-wrapper">
        <OverviewInitial
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
        <OverviewCurrent
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <div className="overview-board__total-wrapper overview-started__total-wrapper">
        <OverviewFromStart
          overview={overview}
          strategyStatus={overview.status}
          isTabletAndMore={isTabletAndMore}
        />
        <OverviewLastMonth
          overview={overview}
          strategyStatus={overview.status}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <OverviewSigns overview={overview} />
      <OverviewDynamics overview={overview} />
      <OverviewBtn
        overview={overview}
        index={index}
        ref={ref}
        isNavigateAble={isNavigateAble}
      />
    </div>
  );
}

export default forwardRef<
  {
    buttonRef: React.RefObject<HTMLButtonElement> | null;
    hintButtonRef: React.RefObject<HTMLDivElement> | null;
  },
  OverviewStartedProps
>(OverviewStarted);
