export default function LinkIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2872 1.23509C12.0609 1.00196 11.7921 0.817031 11.4963 0.690898C11.2004 0.564765 10.8833 0.499898 10.5631 0.500007C10.2431 0.499232 9.92604 0.56372 9.63033 0.689749C9.33463 0.815778 9.0661 1.00085 8.84027 1.23428L5.69293 4.47375C5.38708 4.78905 5.16821 5.1824 5.05877 5.61346C4.94933 6.04453 4.95326 6.4978 5.07016 6.92679C5.13984 7.18107 5.39661 7.32906 5.64366 7.25734C5.89071 7.18561 6.0345 6.92133 5.96481 6.66705C5.89274 6.40175 5.89043 6.1215 5.95813 5.85498C6.02583 5.58846 6.16109 5.34523 6.35009 5.15018L9.49743 1.91118C10.0859 1.30539 11.04 1.30528 11.6286 1.91096C12.2172 2.51661 12.2173 3.49868 11.6288 4.10447L8.48149 7.34392C8.27524 7.55649 8.01328 7.70261 7.72765 7.76441C7.47635 7.81825 7.31506 8.07158 7.36737 8.33023C7.38919 8.43811 7.44654 8.53493 7.52971 8.60433C7.61287 8.67372 7.71677 8.71143 7.82383 8.71109C7.85633 8.71111 7.88873 8.70759 7.92051 8.70058C8.38211 8.60039 8.80539 8.36402 9.13865 8.02034L12.286 4.78137C13.2378 3.80243 13.2383 2.21473 12.2872 1.23509Z"
        fill="currentColor"
      />
      <path
        d="M7.91643 5.08985C7.84674 4.83557 7.58997 4.68757 7.34292 4.7593C7.09587 4.83103 6.95209 5.09531 7.02177 5.34959C7.09385 5.61489 7.09615 5.89514 7.02846 6.16166C6.96076 6.42818 6.82549 6.67141 6.6365 6.86646L3.48913 10.1059C2.9007 10.7117 1.94654 10.7118 1.35797 10.1062C0.769408 9.50051 0.769299 8.51843 1.35773 7.91264L4.5051 4.67269C4.71102 4.46018 4.97266 4.31406 5.25801 4.2522C5.50972 4.20038 5.67294 3.94837 5.62259 3.68932C5.57224 3.43028 5.3274 3.26225 5.07572 3.31407C4.61052 3.41323 4.18371 3.65039 3.84793 3.9963L0.700565 7.23577C-0.243733 8.2227 -0.231914 9.81065 0.726953 10.7826C1.67536 11.7439 3.19774 11.744 4.14629 10.7828L7.29363 7.54286C7.59949 7.22758 7.81836 6.83423 7.92781 6.40317C8.03725 5.9721 8.03332 5.51883 7.91643 5.08985Z"
        fill="currentColor"
      />
    </svg>
  );
}
