import { useNavigate } from "react-router-dom";

import BlueGearsIcon from "../icons/blue-icon/blue-gears-icon";
import BlueSearchIcon from "../icons/blue-icon/blue-search-icon";
import BluePersonIcon from "../icons/blue-icon/blue-person-icon";
import BlueCalendarIcon from "../icons/blue-icon/blue-calendar-icon";
import BlueShieldIcon from "../icons/blue-icon/blue-shield-icon";
import BlueMoneyIcon from "../icons/blue-icon/blue-money-icon";
import BlueLockIcon from "../icons/blue-icon/blue-lock-icon";
import PlusItem from "./plus-item";
import Button from "../button/button";
import { AppRoute, AuthStatus } from "../../const";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";

export default function HowWorksSteps(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authStatus } = useAppSelector((state) => state);

  const handleConnectClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(AppRoute.Overview);
    } else {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("HIW", "Clicked");
  };

  return (
    <section className="landing__item how-works">
      <h2 className="landing__title">How the platform works</h2>
      <ul className="pluses pluses--how-works pluses--tablet-2-columns pluses--desktop-4-columns">
        <PlusItem
          type="automated"
          extraClass="top-less"
          icon={<BlueGearsIcon />}
          title="Fully automated"
          description={
            <p className="pluses__info">
              All trading bot parameters are already configured for you. You do
              not need to have knowledge of trading or programming.
            </p>
          }
        />

        <PlusItem
          type="risks"
          icon={<BlueShieldIcon />}
          title="No uncalculated risks"
          description={
            <p className="pluses__info">
              Operates without leverage, uses stop losses and trades highly
              liquid instruments only.
            </p>
          }
        />

        <PlusItem
          type="access"
          icon={<BlueMoneyIcon />}
          title="Keep access to your&nbsp;money"
          description={
            <p className="pluses__info">
              All assets remain on your side and under your full control. We
              only take access to make trades.
            </p>
          }
        />

        <PlusItem
          type="security"
          icon={<BlueLockIcon />}
          title="Security"
          description={
            <p className="pluses__info">
              Trading bots interact with exchanges using API keys only. The
              platform uses a whitelist of IP addresses to ensure strong
              security and do not ask for withdrawal rights.
            </p>
          }
        />

        <PlusItem
          type="transparency"
          icon={<BlueSearchIcon />}
          title="Transparency"
          description={
            <p className="pluses__info">
              You can see all details of current positions and activity history
              in your personal area.
            </p>
          }
        />

        <PlusItem
          type="billing"
          icon={<BlueCalendarIcon />}
          title="Monthly billing"
          description={
            <p className="pluses__info">
              Pay 10% of profits gained every 30 days.
            </p>
          }
        />

        <PlusItem
          type="flexibility"
          icon={<BluePersonIcon />}
          title="Flexibility"
          description={
            <p className="pluses__info">
              You may continue using your exchange account for other activities.
            </p>
          }
        />
      </ul>
      <div className="how-works__btn">
        <Button
          pattern="white-blue"
          text="Connect"
          icon="chain"
          type="button"
          handleClick={handleConnectClick}
        />
      </div>
    </section>
  );
}
