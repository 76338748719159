export default function PauseIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.11 9.36a8.5 8.5 0 1 0-17 0 8.5 8.5 0 0 0 17 0Zm-15.4 0a6.9 6.9 0 1 1 13.8 0 6.9 6.9 0 0 1-13.8 0ZM7 5.1c.6 0 1.07.38 1.07.85v6.8c0 .46-.48.85-1.06.85-.59 0-1.07-.39-1.07-.85v-6.8c0-.47.48-.85 1.07-.85Zm4.26.85c0-.47-.48-.85-1.07-.85-.58 0-1.06.38-1.06.85v6.8c0 .46.48.85 1.06.85.6 0 1.07-.39 1.07-.85v-6.8Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
