// import { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
import ErrorModal from "./error-modal";
import DeleteCardModal from "./delete-card-modal";
import StopModal from "./stop-modal";
import AuthModal from "./auth-modal";
import { useAppDispatch, useAppSelector } from "../../hooks";
// import { checkIfDesktop, checkIfMobile } from "../../utils/breakpoint";
import {
  // setBybiytModalOpened,
  // setBitgetModalOpened,
  // setOkxModalOpened,
} from "../../store/actions";
// import { debounce } from "../../utils/chart/chart";
import {
  // AppRoute,
  // IS_BYBIT_POPUP_SHOWN,
  // RERENDER_DELAY,
  // AccountName,
  // IS_OKX_POPUP_SHOWN,
  // IS_BITGET_POPUP_SHOWN,
} from "../../const";
// import BybitModal from "./bybit-modal";
// import { isBitgetEventTime } from "../../utils/datetime";
// import OkxModal from "./okx-modal";
// import IntegrationCalendar from "./integration-calender";
// import BitgetModal from "./bitget-modal";

export default function ModalsGroup(): JSX.Element {
  // const dispatch = useAppDispatch();
  // const { pathname } = useLocation();

  const {
    messages,
    isStopModalOpened,
    stopModalId,
    isDeleteCardModalOpened,
    deleteCardModalId,
    isAuthModalOpened,
    // accountStatuses,
    // isPersonalOpened,
    // isNotificationsOpened,
    // isErrorModalOpened,
    // isBybitModalOpened,
    // isIntegrationCalendarOpened,
    // isAccountStatusesLoading,
    // isOkxModalOpened,
    // isBitgetModalOpened,
  } = useAppSelector((state) => state);

  // const isBybitStarted = accountStatuses?.bybit_trading_started;
  // const isBybitLanding = pathname === AppRoute.Bybit;
  // const isOkxStarted = accountStatuses?.okx_trading_started;
  // const isBitgetStarted = accountStatuses?.bitget_futures_trading_started;
  // const isOkxLanding = pathname === AppRoute.Okx;
  // const isBitgetLanding = pathname === AppRoute.Bitget;

  // const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());
  // const [isMobile, setMobile] = useState<boolean>(checkIfMobile());
  // const [isCursorOnExitArea, setCursorOnExitArea] = useState<boolean>(false);
  // const [isMoreThan60Sec, setMoreThan60Sec] = useState<boolean>(false);
  // const [coords, setCoords] = useState({ x: 0, y: 0 });

  // const isBybitAlreadyShown = localStorage.getItem(IS_BYBIT_POPUP_SHOWN);
  // const currentPopup = isBitgetEventTime
  //   ? AccountName.BitgetFutures
  //   : AccountName.Okx;
  // const isOkxCurrent = currentPopup === AccountName.Okx;

  // const isOkxAlreadyShown = localStorage.getItem(IS_OKX_POPUP_SHOWN);
  // const isBitgetAlreadyShown = localStorage.getItem(IS_BITGET_POPUP_SHOWN);


  // useEffect(() => {
  //   const handleWindowMouseMove = (evt: MouseEvent) => {
  //     if (evt.clientY < 15 && evt.clientY !== 0) {
  //       setCursorOnExitArea(true);
  //     }
  //   };

  //   if (!isBybitAlreadyShown && isDesktop && !isAccountStatusesLoading) {
  //     window.addEventListener("mousemove", handleWindowMouseMove);
  //   }

    // if (isOkxCurrent) {
    // if (!isOkxAlreadyShown && isDesktop) {
    // window.addEventListener("mousemove", handleWindowMouseMove);
    // } else {
    //     if (!isBitgetAlreadyShown && isDesktop) {
    //       window.addEventListener("mousemove", handleWindowMouseMove);
    //     }
    //   }
    // }

    // return () => {
    //   if (!isBybitAlreadyShown && isDesktop && !isAccountStatusesLoading) {
    //     window.removeEventListener("mousemove", handleWindowMouseMove);
    //   }
      // if (isOkxCurrent) {
      //   if (!isOkxAlreadyShown && isDesktop) {
      //     window.removeEventListener("mousemove", handleWindowMouseMove);
      //   }
      // } else {
      //   if (!isBitgetAlreadyShown && isDesktop) {
      //     window.removeEventListener("mousemove", handleWindowMouseMove);
      //   }
      // }
    // };
  // }, [
    // coords.x,
    // coords.y,
    // isBitgetAlreadyShown,
    // isOkxCurrent,
    // isOkxAlreadyShown,
  //   isBybitAlreadyShown,
  //   isDesktop,
  //   isAccountStatusesLoading,
  // ]);

  // const isPersonalOpenedInMobile = isMobile && isPersonalOpened;
  // const isNotificationsOpenedInMobile = isMobile && isNotificationsOpened;
  // const isAnyModalOpened =
  //   isStopModalOpened ||
  //   isDeleteCardModalOpened ||
  //   isAuthModalOpened ||
  //   isIntegrationCalendarOpened ||
  //   isErrorModalOpened;

  // const commonCondition =
    // (isCursorOnExitArea || isMoreThan60Sec) &&
    // !isAnyModalOpened &&
    // !isPersonalOpenedInMobile &&
    // !isNotificationsOpenedInMobile;

  // const bybitCondition =
    // !isBybitStarted && !isBybitLanding && !isBybitAlreadyShown;
  // const okxCondition =
  // Именно проверка с false и здесь, тк !isOkxStarted может быть true при isOkxStarted undefined
  //   isOkxStarted === false && !isOkxLanding && !isOkxAlreadyShown;
  // const bitgetCondition =
  //   isBitgetStarted === false && !isBitgetLanding && !isBitgetAlreadyShown;
  // const strategyCondition = isOkxCurrent ? okxCondition : bitgetCondition;

  // if (
    // commonCondition &&
    // bybitCondition &&
    // !isAccountStatusesLoading
    //  && strategyCondition
  // ) {
    // dispatch(setBybiytModalOpened(true));
    //   if (isOkxCurrent) {
    //     dispatch(setOkxModalOpened(true));
    //   } else {
    //     dispatch(setBitgetModalOpened(true));
    //   }
  // }

  // setTimeout(() => {
    // setMoreThan60Sec(true);
  // }, 60000);

  // window.addEventListener(
    // "resize",
    // debounce(() => {
      // setDesktop(checkIfDesktop());
      // setMobile(checkIfMobile());
    // }, RERENDER_DELAY)
  // );

  return (
    <>
      {messages && messages.error && <ErrorModal message={messages.error} />}
      {isAuthModalOpened && <AuthModal />}
      {isStopModalOpened && stopModalId && <StopModal id={stopModalId} />}
      {isDeleteCardModalOpened && deleteCardModalId && (
        <DeleteCardModal id={deleteCardModalId} />
      )}
      {/* {isBybitModalOpened && <BybitModal />} */}
      {/* {isOkxModalOpened && <OkxModal />}
      {isBitgetModalOpened && <BitgetModal />} */}
      {/* {isIntegrationCalendarOpened && <IntegrationCalendar />} */}
    </>
  );
}
