export default function TradingResultsSkeleton(): JSX.Element {
  return (
    <div className="details__results details__item sk-trading-results animate-pulse">
      <div className="sk-trading-results__heading"></div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
      <div className="sk-trading-results__row">
        <div className="sk-trading-results__text"></div>
        <div className="sk-trading-results__text"></div>
      </div>
    </div>
  );
}
