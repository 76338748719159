import { useState } from "react";

import TradingResults from "../../components/trading-results/trading-results";
import DetailsCanvas from "../canvases/details-canvas";
import { useAppSelector } from "../../hooks";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";

export default function DetailsBlocks2Inner(): JSX.Element {
  const [isBitcoinMode, setBitcoinMode] = useState<boolean>(true);

  const { strategyDetails } = useAppSelector((state) => state);
  const quotes = strategyDetails?.quotes as QuotesItemsType;
  const tradingResults =
    strategyDetails?.strategy_results as StrategyResultsType;

  return (
    <>
      {/* TRADING RESULTS */}
      {tradingResults && (
        <TradingResults
          isBitcoin={isBitcoinMode}
          strategyDetails={strategyDetails}
        />
      )}
      {/* CHART */}
      {quotes && (
        <DetailsCanvas
          data={quotes}
          isBitcoin={isBitcoinMode}
          handleBitcoinMode={setBitcoinMode}
        />
      )}
    </>
  );
}
