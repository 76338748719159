import Button from "../button/button";
import DiskIcon from "../icons/decoration/disk-icon";

import { AuthStatus, BYBIT_REGISTER_LINK } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";
import { trackEvent } from "../../utils/tracking";
import { createBybitConnectLink } from "../../utils/id";
import useIsConnectButtonVisible from "../../hooks/useIsConnectButtonVisible";

export default function BybitSteps(): JSX.Element {
  const dispatch = useAppDispatch();

  const { authStatus, bybitDemo } = useAppSelector((state) => state);

  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const bybitConnectLink = createBybitConnectLink();

  const isConnectButtonVisible = useIsConnectButtonVisible(
    bybitDemo?.strategy_results.sharpe_ratio
  );

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
  };

  const handleRegisterClick = () => {
    trackEvent("BybitLandingRegisterAccount", "Clicked");
  };

  return (
    <section className="landing__item okx-steps">
      <h2 className="landing__title">How to start</h2>
      <ul className="okx-steps__list">
        <li className="okx-steps__item">
          <span className="okx-steps__icon">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">01</span>

          {isNoAuth ? (
            <button
              className="okx-steps__block okx-steps__block--register"
              onClick={handleConnectClick}
            >
              <p className="okx-steps__info">Register with SMARD and Bybit</p>
            </button>
          ) : (
            <div className="okx-steps__block">
              <p className="okx-steps__info">Register with SMARD and Bybit</p>
            </div>
          )}
        </li>

        <li className="okx-steps__item">
          <span className="okx-steps__icon">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">02</span>
          <div className="okx-steps__block">
            <p className="okx-steps__info">
              Automatically connect your Bybit account
            </p>
          </div>
        </li>

        <li className="okx-steps__item">
          <span className="okx-steps__icon  okx-steps__icon--disk">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">03</span>
          <div className="okx-steps__block">
            <p className="okx-steps__info">
              Specify the amount to be traded by SMARD
            </p>
          </div>
        </li>

        <li className="okx-steps__item">
          <span className="okx-steps__icon  okx-steps__icon--disk">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">04</span>
          <div className="okx-steps__block">
            <p className="okx-steps__info">Make profit</p>
          </div>
        </li>
      </ul>
      <div className="okx-steps__btn-wrapper1">
        <Button
          pattern="white-blue"
          text="Register a Bybit account"
          icon="arrow-right"
          type="link"
          size="super-big"
          linkAddress={BYBIT_REGISTER_LINK}
          handleClick={handleRegisterClick}
        />
        {isConnectButtonVisible ? (
          <Button
            size="huge"
            pattern="blue-white"
            text="Connect an existing account"
            icon="check"
            linkAddress={bybitConnectLink}
            isSelfLink
            type="link"
            // handleClick={handleConnectClick} TODO look on bitget
          />
        ) : null}
      </div>
    </section>
  );
}
