export default function DragDotsIcon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="24" fill="none">
      <rect
        width="3.3"
        height="2.9"
        x="6.7"
        y=".3"
        fill="currentColor"
        rx="1"
      />
      <rect
        width="3.3"
        height="2.9"
        x="6.7"
        y="20.8"
        fill="currentColor"
        rx="1"
      />
      <rect
        width="2.9"
        height="3.3"
        x="10"
        y="7.2"
        fill="currentColor"
        rx="1"
        transform="rotate(90 10 7.2)"
      />
      <rect
        width="2.9"
        height="3.3"
        x="10"
        y="14"
        fill="currentColor"
        rx="1"
        transform="rotate(90 10 14)"
      />
      <rect
        width="2.9"
        height="3.3"
        x="3.3"
        y=".3"
        fill="currentColor"
        rx="1"
        transform="rotate(90 3.3 .3)"
      />
      <rect
        width="2.9"
        height="3.3"
        x="3.3"
        y="7.2"
        fill="currentColor"
        rx="1"
        transform="rotate(90 3.3 7.2)"
      />
      <rect
        width="2.9"
        height="3.3"
        x="3.3"
        y="14"
        fill="currentColor"
        rx="1"
        transform="rotate(90 3.3 14)"
      />
      <rect
        width="2.9"
        height="3.3"
        x="3.3"
        y="20.8"
        fill="currentColor"
        rx="1"
        transform="rotate(90 3.3 20.8)"
      />
    </svg>
  );
}
