import { AccountName } from "../../const";
import { useAppSelector } from "../../hooks";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import GeneralPromoInsight from "../insights/general-exchange-insight";
import OkxPromoButtonGroup from "./okx-promo/okx-promo-button-group";

type OkxPromoProps = {
  isMobile: boolean;
};

export default function OkxPromo({ isMobile }: OkxPromoProps): JSX.Element {
  const { isOkxConnected, okxDemo } = useAppSelector((state) => state);

  const quotes = okxDemo?.quotes as QuotesItemsType;
  const results = okxDemo?.strategy_results as StrategyResultsType;

  return (
    <section className="landing__item exchange-promo">
      <div className="exchange-promo_container">
        <div className="exchange-promo__heading">
          <h1 className="landing__heading">
            <span className="landing__heading-large">
              SMARD AND OKX DELIVER CONSISTENT PERFORMANCE
            </span>
          </h1>
        </div>
        <p className="exchange-promo__description">
          SMARD is an official OKX API broker, which allows you to experience
          robust order execution and enjoy a convenient few clicks integration.
        </p>
        {isMobile ? null : <br />}
        {isOkxConnected ? null : (
          <p className="exchange-promo__description">
            Register your account with OKX or connect the existing one.
          </p>
        )}
        {isMobile ? null : <OkxPromoButtonGroup />}
      </div>
      {okxDemo ? (
        <GeneralPromoInsight
          isMobile={isMobile}
          quotes={quotes}
          results={results}
          type={AccountName.Okx}
        />
      ) : null}
      {isMobile ? <OkxPromoButtonGroup /> : null}
    </section>
  );
}
