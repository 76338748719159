export default function ArrowLoaderIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13 16"
      fill="none"
    >
      <path
        fill="currentColor"
        d="M5.71 2.5V0l3.72 3.71L5.7 7.34V4.15a4.9 4.9 0 0 0 0 9.65v1.65a6.52 6.52 0 0 1 0-12.94Zm4.82 11.63a6.47 6.47 0 0 1-3.19 1.32v-1.66c.71-.12 1.4-.4 2.01-.84l1.18 1.18Zm.82-4.34H13a6.45 6.45 0 0 1-1.32 3.18l-1.15-1.16c.42-.61.7-1.3.82-2.02Zm-.83-3.64 1.15-1.16c.73.94 1.2 2.04 1.33 3.17h-1.65c-.11-.7-.4-1.4-.83-2.01Z"
      />
    </svg>
  );
}
