import { Dispatch, SetStateAction, useRef, useEffect } from "react";
import { ConnectSteps, AccountName, IP_ADDRESS } from "../../../../const";
import Button from "../../../button/button";
import ConnectHelp from "../../general/connect-help";
import ConnectPoint1 from "../../points/connect-point1";
import ConnectApiLink from "../../general/connect-api-link";
import Copy from "../../../copy/copy";

type BinanceSpotConnectStep1Props = {
  pointId: string;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isStepsAnimationEnd?: boolean;
};

function BinanceSpotConnectStep1({
  pointId,
  activeStep,
  setActiveStep,
  isStepsAnimationEnd,
}: BinanceSpotConnectStep1Props) {
  const nextBtnRef = useRef<HTMLButtonElement | null>(null);

  const isStepActive = activeStep === ConnectSteps.STEP1;
  const blockClass = `${isStepActive ? "block--active" : "block--disabled"}`;

  const handleNextClick = () => {
    setActiveStep(ConnectSteps.STEP2);
  };

  useEffect(() => {
    if (nextBtnRef.current && isStepActive && isStepsAnimationEnd) {
      nextBtnRef.current.focus();
    }
  }, [isStepActive, isStepsAnimationEnd]);

  return (
    <div
      className={`details__item connect-step block block--step ${blockClass}`}
    >
      <h3 className="block__heading">
        Log in to Binance and&nbsp;create&nbsp;API&nbsp;keys
      </h3>
      <p className="connect-step__intro">
        Go to {<ConnectApiLink />} on Binance and create API with
        the&nbsp;following&nbsp;restrictions:
      </p>
      <ul className="connect-step__list">
        <li className="connect-step__item">Enable Spot & Margin Trading;</li>
        <li className="connect-step__item">
          <span className="connect-step__item-text">
            Restrict access to trusted IPs only:
          </span>
          <Copy text={IP_ADDRESS} />
        </li>
      </ul>
      <div className="connect-step__step1-btn-wrapper">
        <Button
          pattern="white-blue"
          text="Next"
          icon="arrow-right"
          type="button"
          handleClick={handleNextClick}
          ref={nextBtnRef}
        />
        <ConnectHelp accountName={AccountName.BinanceSpot} />
      </div>
      <ConnectPoint1 pointId={pointId} accountName={AccountName.BinanceSpot} />
    </div>
  );
}

export default BinanceSpotConnectStep1;
