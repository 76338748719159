export default function CopyIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6842 0H2.10526C0.947368 0 0 0.9 0 2V15C0 15.55 0.473684 16 1.05263 16C1.63158 16 2.10526 15.55 2.10526 15V3C2.10526 2.45 2.57895 2 3.15789 2H13.6842C14.2632 2 14.7368 1.55 14.7368 1C14.7368 0.45 14.2632 0 13.6842 0ZM17.8947 4H6.31579C5.1579 4 4.21053 4.9 4.21053 6V20C4.21053 21.1 5.1579 22 6.31579 22H17.8947C19.0526 22 20 21.1 20 20V6C20 4.9 19.0526 4 17.8947 4ZM16.8421 20H7.36842C6.78947 20 6.31579 19.55 6.31579 19V7C6.31579 6.45 6.78947 6 7.36842 6H16.8421C17.4211 6 17.8947 6.45 17.8947 7V19C17.8947 19.55 17.4211 20 16.8421 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
