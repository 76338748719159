import { Link, useLocation } from "react-router-dom";
import { AccountName } from "../../const";

export default function Bots(): JSX.Element {
  const { pathname } = useLocation();
  const bots = [
    AccountName.Okx,
    AccountName.Bitget,
    AccountName.Binance,
    AccountName.Bybit,
  ];
  const activeBot = pathname
    .split("/")
    .filter((item) => item !== "")
    .pop();

  const filteredBots = bots.filter((item) => item !== activeBot);
  const findRoute = (account: string) => `/exchanges/${account}/`;

  return (
    <div className="landing__item bots">
      <h2 className="landing__title">Other trading bots</h2>
      <ul className="bots__list">
        {filteredBots.map((item) => (
          <li className="bots__item" key={item}>
            <Link className="bots__link" to={findRoute(item)}>
              <img
                className="bots__img"
                srcSet={`${process.env.PUBLIC_URL}/img/strategy/centered/${item}.svg`}
                alt={item}
                width="135"
                height="50"
              />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
