import BitgetButtonGroup from "./bitget-promo/bitget-button-group";
import { useAppSelector } from "../../hooks";
import GeneralPromoInsight from "../insights/general-exchange-insight";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import { AccountName } from "../../const";

type BitgetPromoProps = {
  isMobile: boolean;
};

export default function BitgetPromo({
  isMobile,
}: BitgetPromoProps): JSX.Element {
  const { isBitgetConnected, bitgetFuturesDemo } = useAppSelector(
    (state) => state
  );

  const quotes = bitgetFuturesDemo?.quotes as QuotesItemsType;
  const results = bitgetFuturesDemo?.strategy_results as StrategyResultsType;

  return (
    <section className="landing__item exchange-promo">
      <div className="exchange-promo_container">
        <div className="exchange-promo__heading">
          <h1 className="landing__heading">
            MAXIMIZE YOUR TRADING EFFICIENCY ON BITGET WITH SMARD
          </h1>
        </div>
        <p className="exchange-promo__description">
          SMARD is an official Bitget API broker, offering reliable order
          execution and seamless integration.
        </p>
        {isMobile ? null : <br />}
        {isBitgetConnected ? null : (
          <p className="exchange-promo__description">
            Register a new account with Bitget or connect your existing one.
          </p>
        )}
        {isMobile ? null : <BitgetButtonGroup />}
      </div>
      {bitgetFuturesDemo ? (
        <GeneralPromoInsight
          isMobile={isMobile}
          quotes={quotes}
          results={results}
          type={AccountName.BitgetFutures}
        />
      ) : null}
      {isMobile ? <BitgetButtonGroup /> : null}
    </section>
  );
}
