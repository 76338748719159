import DetailsInitial from "../elements/details-initial";
import DetailsCurrent from "../elements/details-current";
import DetailsFromStart from "../elements/details-from-start";
import DetailsLastMonth from "../elements/details-last-month";
import DetailsSigns from "../elements/details-signs";
import DetailsStopNote from "../elements/details-stop-note";
import { StrategyOverviewType } from "../../../types/details";
import { StrategyStatus } from "../../../const";

type BlockStoppingProps = {
  overview: StrategyOverviewType;
  isTabletAndMore: boolean;
};

export default function BlockStopping({
  overview,
  isTabletAndMore,
}: BlockStoppingProps): JSX.Element {
  return (
    <div className="block-stopping">
      <div className="block-stopping__total-wrapper">
        <DetailsInitial
          overview={overview}
          strategyStatus={StrategyStatus.Stopping}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsCurrent
          overview={overview}
          strategyStatus={StrategyStatus.Stopping}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <div className="block-stopping__total-wrapper">
        <DetailsFromStart
          overview={overview}
          strategyStatus={StrategyStatus.Stopping}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsLastMonth
          overview={overview}
          strategyStatus={StrategyStatus.Stopping}
          isTabletAndMore={isTabletAndMore}
        />
      </div>

      <DetailsSigns overview={overview} />

      <div className="block-stopping__note-wrapper">
        <DetailsStopNote />
      </div>
    </div>
  );
}
