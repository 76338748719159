export default function KeyIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.04.5h-4.2L4.88 2.46V6.2c0 .21-.1.42-.24.57L0 11.4v2.1h2.1l4.64-4.64a.81.81 0 0 1 .57-.23h3.73L13 6.66v-4.2L11.04.5ZM9.75 2.12a1.63 1.63 0 1 1 0 3.25 1.63 1.63 0 0 1 0-3.25Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
