export default function MoneyWithCoinsIcon(): JSX.Element {
  return (
    <svg
      width="33"
      height="25"
      viewBox="0 0 33 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6363 3.92466C27.1363 3.92466 25.9091 2.74777 25.9091 1.30931H15L15.0005 2.02598L15.0008 2.61621C18.0133 2.61621 20.4554 5.25083 20.4554 8.5008C20.4554 11.7508 18.0133 14.3854 15.0008 14.3854L15 15.6938H25.9091C25.9091 14.2554 27.1363 13.0785 28.6363 13.0785V3.92466ZM23.1834 9.81033C23.9365 9.81033 24.547 9.22485 24.547 8.50263C24.547 7.7804 23.9365 7.19492 23.1834 7.19492C22.4303 7.19492 21.8197 7.7804 21.8197 8.50263C21.8197 9.22485 22.4303 9.81033 23.1834 9.81033Z"
        fill="#60A5FA"
      />
      <path
        d="M30.0004 1.30771C30.0004 0.588476 29.3867 0 28.6367 0H15.0004L15 1.30931H25.9091C25.9091 2.74777 27.1363 3.92466 28.6363 3.92466V13.0785C27.1363 13.0785 25.9091 14.2554 25.9091 15.6938H15L15.0004 17H28.6367C29.3867 17 30.0003 16.4115 30.0004 15.6923V1.30771Z"
        fill="#2563EB"
      />
      <path
        d="M15.0008 14.3854C18.0133 14.3854 20.4554 11.7508 20.4554 8.5008C20.4554 5.25083 18.0133 2.61621 15.0008 2.61621V8.5008L15.0007 10.5977L15.0008 14.3854Z"
        fill="#2563EB"
      />
      <path
        d="M24.547 8.50263C24.547 9.22485 23.9365 9.81033 23.1834 9.81033C22.4303 9.81033 21.8197 9.22485 21.8197 8.50263C21.8197 7.7804 22.4303 7.19492 23.1834 7.19492C23.9365 7.19492 24.547 7.7804 24.547 8.50263Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.36368 3.92466C2.86368 3.92466 4.09092 2.74777 4.09092 1.30931H15L14.9995 2.02598L14.9992 2.61621C11.9867 2.61621 9.54462 5.25083 9.54462 8.5008C9.54462 11.7508 11.9867 14.3854 14.9992 14.3854L15 15.6938H4.09092C4.09092 14.2554 2.86368 13.0785 1.36368 13.0785V3.92466ZM6.81662 9.81033C6.0635 9.81033 5.45297 9.22485 5.45297 8.50263C5.45297 7.7804 6.0635 7.19492 6.81662 7.19492C7.56974 7.19492 8.18027 7.7804 8.18027 8.50263C8.18027 9.22485 7.56974 9.81033 6.81662 9.81033Z"
        fill="#A0CBFF"
      />
      <path
        d="M-0.000396729 1.30771C-0.000396729 0.588476 0.613254 0 1.36325 0H14.9996L15 1.30931H4.09092C4.09092 2.74777 2.86368 3.92466 1.36368 3.92466V13.0785C2.86368 13.0785 4.09092 14.2554 4.09092 15.6938H15L14.9996 17H1.36331C0.613313 17 -0.000338135 16.4115 -0.000396729 15.6923V1.30771Z"
        fill="#60A5FA"
      />
      <path
        d="M14.9992 14.3854C11.9867 14.3854 9.54462 11.7508 9.54462 8.5008C9.54462 5.25083 11.9867 2.61621 14.9992 2.61621V8.5008L14.9993 10.5977L14.9992 14.3854Z"
        fill="#60A5FA"
      />
      <path
        d="M5.45297 8.50263C5.45297 9.22485 6.0635 9.81033 6.81662 9.81033C7.56974 9.81033 8.18027 9.22485 8.18027 8.50263C8.18027 7.7804 7.56974 7.19492 6.81662 7.19492C6.0635 7.19492 5.45297 7.7804 5.45297 8.50263Z"
        fill="#60A5FA"
      />
      <path
        d="M16.0733 8.33492C15.7704 8.17572 15.4484 8.05441 15.1295 7.92857C14.9446 7.85578 14.7676 7.77087 14.6114 7.65261C14.3037 7.4191 14.3627 7.04004 14.723 6.88993C14.825 6.84747 14.9318 6.83383 15.0402 6.82776C15.4579 6.80654 15.8549 6.87932 16.2327 7.05217C16.4208 7.1386 16.483 7.1113 16.5468 6.9248C16.6137 6.72769 16.6695 6.52754 16.7317 6.32891C16.7731 6.19548 16.7221 6.10754 16.5898 6.05144C16.3475 5.94985 16.0988 5.87707 15.8373 5.83765C15.4962 5.78761 15.4962 5.78609 15.4946 5.4601C15.493 5.00067 15.493 5.00067 15.0084 5.00067C14.9382 5.00067 14.8681 4.99916 14.7979 5.00067C14.5715 5.00674 14.5333 5.04465 14.5269 5.26147C14.5237 5.35851 14.5269 5.45555 14.5253 5.55411C14.5237 5.8422 14.5221 5.83765 14.232 5.93772C13.5305 6.18032 13.0969 6.6352 13.0507 7.363C13.0092 8.00741 13.3631 8.44258 13.9195 8.75947C14.2623 8.95507 14.6417 9.07031 15.0052 9.22345C15.1471 9.28258 15.2826 9.35081 15.4005 9.44482C15.7497 9.71926 15.6859 10.1757 15.2714 10.3485C15.0498 10.441 14.8155 10.4637 14.5747 10.4349C14.2033 10.391 13.8478 10.2985 13.513 10.1332C13.3169 10.0362 13.2595 10.0619 13.1925 10.2636C13.1352 10.438 13.0841 10.6139 13.0331 10.7897C12.9646 11.0263 12.9901 11.0824 13.2276 11.1931C13.5305 11.3326 13.8541 11.4038 14.1841 11.4539C14.4424 11.4933 14.4504 11.5039 14.4536 11.7586C14.4552 11.8739 14.4552 11.9906 14.4568 12.1059C14.4584 12.2514 14.5317 12.3363 14.6895 12.3394C14.8681 12.3424 15.0482 12.3424 15.2268 12.3378C15.3734 12.3348 15.4484 12.259 15.4484 12.118C15.4484 11.9603 15.4563 11.8011 15.45 11.6434C15.442 11.4827 15.5153 11.4008 15.6779 11.3583C16.0526 11.2613 16.3714 11.0702 16.6169 10.7852C17.2992 9.99674 17.0394 8.84287 16.0733 8.33492Z"
        fill="#DCE7FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2718 7.97121C15.5494 8.08238 15.8274 8.19375 16.0908 8.33492C17.038 8.84287 17.2927 9.99674 16.6238 10.7852C16.3831 11.0702 16.0705 11.2613 15.7032 11.3583C15.5437 11.4008 15.4718 11.4827 15.4796 11.6434C15.4837 11.7448 15.4819 11.8469 15.4801 11.9488C15.4791 12.0053 15.4781 12.0617 15.4781 12.118C15.4781 12.259 15.4046 12.3348 15.2608 12.3378C15.1868 12.3398 15.1126 12.3409 15.0383 12.3413V10.4272C15.1288 10.4113 15.2177 10.3855 15.3046 10.3485C15.711 10.1757 15.7735 9.71926 15.4312 9.44482C15.3155 9.35081 15.1827 9.28258 15.0436 9.22345C15.0418 9.22269 15.04 9.22194 15.0383 9.22118V7.8746C15.0801 7.89345 15.1226 7.91135 15.1655 7.92857C15.2009 7.94282 15.2363 7.95701 15.2718 7.97121ZM15.0383 6.83043C15.0515 6.82939 15.0647 6.82852 15.0779 6.82776C15.4875 6.80654 15.8766 6.87932 16.2471 7.05217C16.4315 7.1386 16.4925 7.1113 16.555 6.9248C16.5969 6.79894 16.6344 6.67183 16.6719 6.54467C16.6931 6.4727 16.7143 6.40071 16.7363 6.32891C16.777 6.19548 16.7269 6.10754 16.5972 6.05144C16.3596 5.94985 16.1158 5.87707 15.8595 5.83765C15.525 5.78761 15.525 5.78609 15.5234 5.46012L15.5234 5.4601C15.5226 5.23297 15.5222 5.11813 15.4646 5.06006C15.4057 5.00067 15.2869 5.00067 15.0467 5.00067C15.0439 5.00067 15.0411 5.00067 15.0383 5.00067V6.83043ZM14.9172 5.00001C14.9119 5 14.9066 5 14.9014 5.00001H14.9172Z"
        fill="#60A5FA"
      />
      <path
        d="M23.7552 24.1289C26.2352 24.1289 28.2457 22.2751 28.2457 19.9883C28.2457 17.7015 26.2352 15.8477 23.7552 15.8477C21.2751 15.8477 19.2646 17.7015 19.2646 19.9883C19.2646 22.2751 21.2751 24.1289 23.7552 24.1289Z"
        fill="#A0CBFF"
      />
      <path
        d="M23.7538 22.9081C25.5044 22.9081 26.9236 21.5995 26.9236 19.9853C26.9236 18.3711 25.5044 17.0625 23.7538 17.0625C22.0031 17.0625 20.584 18.3711 20.584 19.9853C20.584 21.5995 22.0031 22.9081 23.7538 22.9081Z"
        fill="#60A5FA"
      />
      <path
        d="M23.4905 18.2813C25.9706 18.2813 27.9811 16.4274 27.9811 14.1406C27.9811 11.8538 25.9706 10 23.4905 10C21.0105 10 19 11.8538 19 14.1406C19 16.4274 21.0105 18.2813 23.4905 18.2813Z"
        fill="#A0CBFF"
      />
      <path
        d="M23.4901 17.0643C25.2407 17.0643 26.6599 15.7558 26.6599 14.1415C26.6599 12.5273 25.2407 11.2188 23.4901 11.2188C21.7395 11.2188 20.3203 12.5273 20.3203 14.1415C20.3203 15.7558 21.7395 17.0643 23.4901 17.0643Z"
        fill="#60A5FA"
      />
      <path
        d="M23.4912 18.2852H24.0195V21.6951H23.4912V18.2852Z"
        fill="#2563EB"
      />
      <path
        d="M23.2266 12.4336H23.7549V15.8435H23.2266V12.4336Z"
        fill="#2563EB"
      />
      <path
        d="M28.5091 21.9336C30.9892 21.9336 32.9996 20.0798 32.9996 17.793C32.9996 15.5062 30.9892 13.6523 28.5091 13.6523C26.029 13.6523 24.0186 15.5062 24.0186 17.793C24.0186 20.0798 26.029 21.9336 28.5091 21.9336Z"
        fill="#DCE7FA"
      />
      <path
        d="M28.5096 20.7167C30.2603 20.7167 31.6794 19.4081 31.6794 17.7939C31.6794 16.1797 30.2603 14.8711 28.5096 14.8711C26.759 14.8711 25.3398 16.1797 25.3398 17.7939C25.3398 19.4081 26.759 20.7167 28.5096 20.7167Z"
        fill="#A0CBFF"
      />
      <path
        d="M28.2461 16.0898H28.7744V19.4998H28.2461V16.0898Z"
        fill="#2563EB"
        fillOpacity="0.83"
      />
    </svg>
  );
}
