export const pricingFaqData = [
  {
    question: "How is the service fee calculated?",
    answer:
      "The service fee is 10% of your profit. If you make a profit, we charge 10% of that amount. If you incur a loss, you only pay the minimum access fee of $1.",
  },
  {
    question: "How do I pay the service fee?",
    answer:
      "The service fee is calculated at the end of each month based on your profits. You can link your credit card to enable automatic payments. If you prefer to pay manually, you can do so using cryptocurrency.",
  },
  {
    question: "Why do I need to authorize payments from my side?",
    answer:
      "For security and privacy reasons, we do not have access to withdraw funds directly from your exchange account. You need to authorize payments from your side, either by linking your credit card for automatic payments or by manually paying using cryptocurrency.",
  },
  {
    question: "What happens if I don't pay a fee on time?",
    answer:
      "If a fee is not paid, trading on all connected accounts will be paused until the payment is made. Each account has its own payment schedule, so it's important to ensure timely payment for uninterrupted service.",
  },
  {
    question: "Can I use multiple exchanges with SMARD?",
    answer:
      "Yes, you can connect multiple exchange accounts to SMARD. Each account will have its own payment schedule, and the service fee will be calculated separately for each account.",
  },
  {
    question: "Can I pay a fee from my exchange account?",
    answer:
      "Yes, you can pay a fee from your exchange account, just do not touch the position that SMARD has formed otherwise the trading will stop.",
  },
];
