import { useEffect } from "react";

import OkxRenewKeys from "./okx-renew-keys";
import RenewKeys from "./renew-keys";
import BitgetRenewKeys from "./bitget-renew-keys";
import BybitRenewKeys from "./bybit-renew-keys";
import { AccountName } from "../../const";

type RenewKeysWrapperProps = {
  accountName: string;
  isAnimationEnd: boolean;
  clearAnimationEnd: () => void;
};

export default function RenewKeysWrapper({
  accountName,
  // эти пропы нужны, так как фокусировка в мобилке при выезде блоков на первой кнопке ломала плавную анимацию, а вследствие с этим, и пунктирные линии
  isAnimationEnd,
  clearAnimationEnd,
}: RenewKeysWrapperProps): JSX.Element {
  useEffect(() => {
    return () => {
      if (clearAnimationEnd) {
        clearAnimationEnd();
      }
    };
    // eslint-disable-next-line
  }, []);

  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;

  return isOkx ? (
    <OkxRenewKeys isAnimationEnd={isAnimationEnd} />
  ) : isBitget ? (
    <BitgetRenewKeys isAnimationEnd={isAnimationEnd} />
  ): isBybit ? (
    <BybitRenewKeys isAnimationEnd={isAnimationEnd} />
  ) : (
    <RenewKeys isAnimationEnd={isAnimationEnd} />
  );
}
