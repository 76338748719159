import FiveStarsIcon from "../icons/five-stars-icon";

export default function Reviews(): JSX.Element {
  return (
    <section className="landing__item reviews">
      <h2 className="landing__title">
        What clients<span className="visually-hidden"> traders</span> say about
        us
      </h2>
      <ul className="reviews__list">
        <li className="reviews__item reviews__item--andrew">
          <div className="reviews__icon">
            <FiveStarsIcon />
          </div>
          <p className="reviews__inner-heading">
            So far I had a positive experience
          </p>
          <p className="reviews__opinion">
            They help me make money with pay-for-performance, while I don't
            waste any time, effort or nerves on it. Thank You.
          </p>
          <p className="reviews__author">
            Maya, SMARD User Reviews on{" "}
            <a
              className="link"
              href="https://www.trustpilot.com/users/6677b5785f04c572efc3a0df"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot.com
            </a>
          </p>
        </li>

        <li className="reviews__item reviews__item--sb">
          <div className="reviews__icon">
            <FiveStarsIcon />
          </div>
          <p className="reviews__inner-heading">
            Good algorithmic trading strategy, if you are tolerant to some risks
          </p>
          <p className="reviews__opinion">
            Started using it from Jan 8 2023. Since that it earned 24%. Nice
            thing is that it takes commission only from profit, and doesn't
            require much attention from your side.Definitely good thing to
            diversify conservative portfolio, if you you are looking like to add
            risks/high returns.
          </p>
          <p className="reviews__author">
            Sergey Bachurin, SMARD User Reviews on{" "}
            <a
              className="link"
              href="https://www.trustpilot.com/reviews/642027078ff2777bbf0d03d7"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot.com
            </a>
          </p>
        </li>

        <li className="reviews__item reviews__item--valentin">
          <div className="reviews__icon">
            <FiveStarsIcon />
          </div>
          <p className="reviews__inner-heading">
            I have been using the strategy on Binance Spot since January 2024.
          </p>
          <p className="reviews__opinion">
            In the first month I had a small drawdown (5-7%), but in the long
            term it shows good results... smard is definitely on my list of
            tools for investing.
          </p>
          <p className="reviews__author">
            Leonie, SMARD User Reviews on{" "}
            <a
              className="link"
              href="https://www.trustpilot.com/users/666c56740ac5c40af73a1cee"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot.com
            </a>
          </p>
        </li>

        <li className="reviews__item reviews__item--a">
          <div className="reviews__icon">
            <FiveStarsIcon />
          </div>
          <p className="reviews__inner-heading">
            In 1 year and 3 months of use…
          </p>
          <p className="reviews__opinion">
            In 1 year and 3 months of use my avg monthly profit 5.46% and Sharpe
            1.61 Decent!
          </p>
          <p className="reviews__author">
            Walter, SMARD User Reviews on{" "}
            <a
              className="link"
              href="https://www.trustpilot.com/users/666ffd2a290ae6fde31053d1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot.com
            </a>
          </p>
        </li>

        <li className="reviews__item reviews__item--mz">
          <div className="reviews__icon">
            <FiveStarsIcon />
          </div>
          <p className="reviews__inner-heading">Nice profit</p>
          <p className="reviews__opinion">
            Nice profit: about 8% a month on average for the 3 months. The
            creators and the community are really cool guys always ready to help
            and constantly improving their product.
          </p>
          <p className="reviews__author">
            Mikhail Zakharov, SMARD User Reviews on{" "}
            <a
              className="link"
              href="https://www.trustpilot.com/reviews/641639b660752bb97d5b9f14"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot.com
            </a>
          </p>
        </li>

        <li className="reviews__item reviews__item--vg">
          <div className="reviews__icon">
            <FiveStarsIcon />
          </div>
          <p className="reviews__inner-heading">My favorite</p>
          <p className="reviews__opinion">
            At first the bot didn't work but it was my fault because I didn't
            connect correctly, I got some help from the support team and
            everything worked. Otherwise there were no more difficulties
          </p>
          <p className="reviews__author">
            Luke, SMARD User Reviews on{" "}
            <a
              className="link"
              href="https://www.trustpilot.com/users/664cf504d11b2965a42a7d5b"
              target="_blank"
              rel="noopener noreferrer"
            >
              Trustpilot.com
            </a>
          </p>
        </li>
      </ul>
    </section>
  );
}
