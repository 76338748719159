export default function UsdtIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 15 14"
    >
      <path
        fill="#50AF95"
        fillRule="evenodd"
        d="M2.75.07 0 6.23a.13.13 0 0 0 .03.14l7.38 7.6a.1.1 0 0 0 .08.03.1.1 0 0 0 .08-.03l7.38-7.6a.13.13 0 0 0 .02-.14L12.26.07a.12.12 0 0 0-.04-.05.1.1 0 0 0-.06-.02h-9.3a.1.1 0 0 0-.06.02.12.12 0 0 0-.04.05Z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.45 6.87a13.66 13.66 0 0 1-1.9 0C4.69 6.78 3.29 6.43 3.29 6s1.4-.77 3.28-.86v1.37a13.19 13.19 0 0 0 1.89 0V5.15c1.87.09 3.27.44 3.27.86s-1.4.77-3.27.86Zm0-1.86V3.78h2.62V1.91H3.94v1.87h2.62v1.23c-2.13.1-3.73.55-3.73 1.1 0 .54 1.6.99 3.73 1.1v3.92h1.89V7.2c2.12-.1 3.72-.55 3.72-1.1 0-.54-1.6-.99-3.72-1.1Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
