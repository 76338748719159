import { useLocation } from "react-router-dom";

import LoginGoogle from "../login/login-google";
import LoginTelegram from "../login/login-telegram";
import AuthDescription from "../auth-description/auth-description";
import LoginAppleModal from "../login/login-apple-modal";
import { AppRoute, ParamsSource } from "../../const";
import { useAppSelector } from "../../hooks";

export default function AuthBlockModal(): JSX.Element {
  const { pathname } = useLocation();
  const { isAuthModalOpened } = useAppSelector((state) => state);
  const isOkxLanding = pathname === AppRoute.Okx;
  const isBitgetLanding = pathname === AppRoute.Bitget;
  const isOverview = pathname === AppRoute.Overview;

  const isAuthInShortHeader = false;
  const getAuthUrl = (link: string) =>
    isOkxLanding
      ? `${link}${ParamsSource.EXCHANGES_OKX_LANDING}`
      : isBitgetLanding
      ? `${link}${ParamsSource.EXCHANGES_BITGET_LANDING}`
      : link;

  return (
    <div className="auth">
      <div className="auth__wrapper">
        <p className="auth__heading">Start with</p>
        <ul className="auth__list">
          <li className="auth__item">
            <LoginGoogle getAuthUrl={getAuthUrl} />
          </li>
          <li className="auth__item">
            <LoginTelegram
              isAuthInShortHeader={isAuthInShortHeader}
              isAuthModalOpened={isAuthModalOpened}
              getAuthUrl={getAuthUrl}
            />
          </li>
          <li className="auth__item">
            <LoginAppleModal
              isRedirectToOkx={isOkxLanding}
              isRedirectToBitget={isBitgetLanding}
              isOverview={isOverview}
            />
          </li>
        </ul>
      </div>
      <AuthDescription />
    </div>
  );
}
