import { SyntheticEvent } from "react";

import Button from "../../button/button";
import DetailsInitial from "../elements/details-initial";
import DetailsCurrent from "../elements/details-current";
import DetailsFromStart from "../elements/details-from-start";
import DetailsLastMonth from "../elements/details-last-month";
import DetailsSigns from "../elements/details-signs";
import DetailsLink from "../elements/details-link";
import { useAppDispatch } from "../../../hooks";
import { setRenewKeysOpened, setStopModalOpened } from "../../../store/actions";
import { StrategyOverviewType } from "../../../types/details";
import { StrategyStatus } from "../../../const";

type BlockStartedProps = {
  overview: StrategyOverviewType;
  isTabletAndMore: boolean;
  isRenewKeysFormOpened: boolean;
  shouldRenewKeysFormShow: boolean;
};

export default function BlockStarted({
  overview,
  isTabletAndMore,
  isRenewKeysFormOpened,
  shouldRenewKeysFormShow,
}: BlockStartedProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleRenewKeysClick = (evt: SyntheticEvent) => {
    const element = evt.currentTarget as HTMLButtonElement;
    dispatch(setRenewKeysOpened(true));
    element.blur();
  };

  const handleStopClick = () => {
    dispatch(setStopModalOpened(true));
  };

  return (
    <div className="block-started">
      <div className="block-started__total-wrapper">
        <DetailsInitial
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsCurrent
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <div className="block-started__total-wrapper">
        <DetailsFromStart
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
        <DetailsLastMonth
          overview={overview}
          strategyStatus={StrategyStatus.Started}
          isTabletAndMore={isTabletAndMore}
        />
      </div>

      <DetailsSigns overview={overview} />

      <div
        className={`block-started__btns-wrapper ${
          !shouldRenewKeysFormShow
            ? "block-started__btns-wrapper--with-renew"
            : ""
        }`}
      >
        {!shouldRenewKeysFormShow && (
          <div className="block-started__renew-btn-wrapper">
            <Button
              classElement={`block-started__renew-btn ${
                isRenewKeysFormOpened ? "btn--disabled" : ""
              }`}
              pattern="white-blue"
              text="Renew keys"
              icon="key-in-circle"
              type="button"
              handleClick={handleRenewKeysClick}
            />
            <div className="block-started__link">
              <DetailsLink accountName={overview.code} />
            </div>
          </div>
        )}

        <Button
          classElement="block-started__stop-btn"
          pattern="blue-transparent"
          text="Stop"
          icon="error"
          type="button"
          size="small"
          handleClick={handleStopClick}
        />
      </div>
    </div>
  );
}
