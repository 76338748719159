export default function DetailsCanvasSkeleton(): JSX.Element {
  return (
    <div className="details__canvas details__item sk-details-canvas animate-pulse">
      <div className="sk-details-canvas__chart"></div>
      <div className="sk-details-canvas__toggle-wrapper">
        <div className="sk-details-canvas__toggle"></div>
        <div className="sk-details-canvas__toggle"></div>
      </div>
    </div>
  );
}
