export default function BlueClockIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#60A5FA"
        d="M12.5 4A8.55 8.55 0 0 0 4 12.5c0 4.65 3.85 8.5 8.5 8.5s8.5-3.85 8.5-8.5S17.15 4 12.5 4Z"
      />
      <path
        fill="#2563EB"
        d="M22 12.5c0 4.65-4.07 8.5-9 8.5V4c4.93 0 9 3.85 9 8.5Z"
      />
      <path
        fill="#60A5FA"
        d="M20 13c0 3.83-3.17 7-7 7s-7-3.17-7-7 3.17-7 7-7 7 3.17 7 7Z"
      />
      <path fill="#2563EB" d="M20 13c0 3.83-3.17 7-7 7V6c3.83 0 7 3.17 7 7Z" />
      <path
        fill="#60A5FA"
        d="M26 13c0 2.92-1 5.86-2.85 8.14l-1.19-.94A11.52 11.52 0 0 0 13 1.53C6.7 1.52 1.52 6.68 1.52 13c0 6.3 5.17 11.48 11.48 11.48 2.01 0 3.98-.54 5.71-1.54l.82 1.32A13.04 13.04 0 0 1 0 13C0 5.86 5.86 0 13 0s13 5.86 13 13Z"
      />
      <path fill="#A0CBFF" d="M18 12.51V14h-6V8h1.5v4.51H18Z" />
      <path
        fill="#2563EB"
        d="m19.13 23 .87 1.3a15.07 15.07 0 0 1-7 1.7v-1.5c2.16 0 4.27-.51 6.13-1.5ZM26 12.91c0 2.9-1 5.82-2.85 8.09l-1.19-.94a11.34 11.34 0 0 0 2.52-7.15c0-6.26-5.17-11.4-11.48-11.4V0a13 13 0 0 1 13 12.91Z"
      />
      <path fill="#60A5FA" d="M18 12.51V14h-5V8h.71v4.51H18Z" />
      <path fill="#2563EB" d="M26 22h-5v-5h1.67v3.33H26V22Z" />
    </svg>
  );
}
