import { localAmount } from "../../../utils/amount";
import { formatedMinNotional } from "../../../utils/env";

type AmoutRangeNoteProps = {
  availableAmount: number;
};

export default function AmountRangeNote({
  availableAmount,
}: AmoutRangeNoteProps): JSX.Element {
  return (
    <span className="message__note">
      {`From ${formatedMinNotional} till ${localAmount(availableAmount)}`}
    </span>
  );
}
