import { forwardRef } from "react";

import OverviewFromStart from "../elements/overview-from-start";
import OverviewLastMonth from "../elements/overview-last-month";
import OverviewBtn from "../elements/overview-btn";
import OverviewDynamics from "../elements/overview-dynamics";
import { StrategyStatus } from "../../../const";
import { StrategyType } from "../../../types/strategy";

type OverviewNotStartedProps = {
  overview: StrategyType;
  isTabletAndMore: boolean;
  index: number;
  isNavigateAble: boolean;
};

function OverviewNotStarted(
  { overview, isTabletAndMore, index, isNavigateAble }: OverviewNotStartedProps,
  ref: any
): JSX.Element {
  return (
    <div className="overview-not-started">
      <p className="overview-not-started__historical">
        <span className="overview-not-started__historical-big">Historical</span>
        <span className="overview-not-started__historical-small">results</span>
      </p>

      <div className="overview-board__total-wrapper overview-not-started__total-wrapper">
        <OverviewFromStart
          overview={overview}
          strategyStatus={StrategyStatus.NotStarted}
          isTabletAndMore={isTabletAndMore}
        />
        <OverviewLastMonth
          overview={overview}
          strategyStatus={StrategyStatus.NotStarted}
          isTabletAndMore={isTabletAndMore}
        />
      </div>
      <OverviewDynamics overview={overview} />
      <OverviewBtn
        overview={overview}
        index={index}
        ref={ref}
        isNavigateAble={isNavigateAble}
      />
    </div>
  );
}

export default forwardRef<
  {
    buttonRef: React.RefObject<HTMLButtonElement> | null;
    hintButtonRef: React.RefObject<HTMLDivElement> | null;
  },
  OverviewNotStartedProps
>(OverviewNotStarted);
