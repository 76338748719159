export default function TriangleUpIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.330314 2.51062L3.21591 0.254763C3.65042 -0.0849209 4.35232 -0.0849209 4.78683 0.254763L7.67243 2.51062C8.37433 3.05934 7.87298 4 6.8814 4H1.11021C0.118629 4 -0.371588 3.05934 0.330314 2.51062Z"
        fill="currentColor"
      />
    </svg>
  );
}
