type ReferralsHeadingProps = {
  commission: string;
  isAffiliate: boolean;
};

export default function ReferralsHeading({
  commission,
  isAffiliate,
}: ReferralsHeadingProps): JSX.Element {
  return isAffiliate ? (
    <h2 className="referrals__heading">
      Monetize your traffic and earn{" "}
      <em className="referrals__emphasis">{`${commission} commission`}</em>{" "}
      from&nbsp;invited&nbsp;users!
    </h2>
  ) : (
    <h2 className="referrals__heading">
      Invite friends to earn{" "}
      <em className="referrals__emphasis">{`${commission} of their commission!`}</em>
    </h2>
  );
}
