import TriangleDownIcon from "../../components/icons/triangle/triangle-down-icon";
import TriangleUpIcon from "../../components/icons/triangle/triangle-up-icon";
import { ChartType } from "../../types/chart";
import { formatTrending, addNullIfNeeded, localAmount } from "../amount";

export const checkPercentClass = (amount: number) => {
  const formatted = formatTrending(amount).amount;
  if (formatted === 0) {
    return "";
  }

  if (formatted > 0) {
    return "coin-canvas__info-percent--plus";
  }
  return "coin-canvas__info-percent--minus";
};

export const displayPercent = (amount: number) => {
  const finalDisplay = addNullIfNeeded(
    amount,
    localAmount(Math.abs(formatTrending(amount).amount))
  );

  return `${finalDisplay}${formatTrending(amount).short}%`;
};

export const countChartPercent = (data: ChartType[]) => {
  const first = data[0].amount;
  const last = data[data.length - 1].amount;
  const count = (last / first - 1) * 100;

  return count;
};

export const displayArrow = (amount: number) => {
  const formatted = formatTrending(amount).amount;
  if (formatted === 0) {
    return <></>;
  }

  return (
    <span className="coin-canvas__info-percent-icon">
      {formatted > 0 ? <TriangleUpIcon /> : <TriangleDownIcon />}
    </span>
  );
};
