import { useState } from "react";

import CopyIcon from "../icons/action/copy-icon";
import ShareIcon from "../icons/action/share-icon";

type ActionBtnProps = {
  link: string;
  action: "copy" | "share";
};

export default function ActionBtn({
  link,
  action,
}: ActionBtnProps): JSX.Element {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = () => {
    if (navigator.clipboard && link) {
      navigator.clipboard.writeText(link);
      setIsCopied(true);
    }
  };

  const handleSharing = () => {
    if (navigator.share && link) {
      navigator.share({
        text: "Please join me using a trading enhancement tool",
        url: link,
      });
    }
  };

  setTimeout(() => {
    if (isCopied) {
      setIsCopied(false);
    }
  }, 500);

  const checkBtn = () => {
    switch (action) {
      case "copy":
        return (
          <button
            className={`action-btn ${isCopied ? "action-btn--copied" : ""}
          ${link ? "" : "action-btn--disabled"}`}
            type="button"
            aria-label="Copy"
            onClick={copyToClipboard}
          >
            <span className="action-btn__icon action-btn__icon--copy">
              <CopyIcon />
            </span>
          </button>
        );
      case "share":
        return (
          <button
            className={`action-btn action-btn--share ${
              link ? "" : "action-btn--disabled"
            }`}
            type="button"
            aria-label="Share"
            onClick={handleSharing}
          >
            <span className="action-btn__icon action-btn__icon--share">
              <ShareIcon />
            </span>
          </button>
        );
      default:
        return <></>;
    }
  };

  return checkBtn();
}
