import { Dispatch, SetStateAction, useEffect, useRef } from "react";

import Button from "../../../button/button";
import ConnectPoint1 from "../../points/connect-point1";
import Copy from "../../../copy/copy";
import ConnectHelp from "../../general/connect-help";
import {
  AccountName,
  BITGET_API_SETTINGS_PAGE_LINK,
  ConnectSteps,
  IP_ADDRESS_WITH_COMMA,
} from "../../../../const";

type BitgetConnectStep1Props = {
  pointId: string;
  activeStep: string;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isStepsAnimationEnd?: boolean;
};

export default function BitgetConnectStep1({
  pointId,
  activeStep,
  setActiveStep,
  isStepsAnimationEnd,
}: BitgetConnectStep1Props): JSX.Element {
  const nextBtnRef = useRef<HTMLButtonElement | null>(null);

  const isStepActive = activeStep === ConnectSteps.STEP1;
  const blockClass = `${isStepActive ? "block--active" : "block--disabled"}`;

  const handleNextClick = () => {
    setActiveStep(ConnectSteps.STEP2);
  };

  useEffect(() => {
    if (nextBtnRef.current && isStepActive && isStepsAnimationEnd) {
      nextBtnRef.current.focus();
    }
  }, [isStepActive, isStepsAnimationEnd]);

  return (
    <div
      className={`details__item connect-step block block--step ${blockClass}`}
    >
      <h3 className="block__heading">
        Log in to Bitget and&nbsp;create&nbsp;API&nbsp;keys{" "}
      </h3>
      <ul className="connect-step__list connect-step__list--ordered">
        <li className="connect-step__item">
          1. Go to the{" "}
          <a
            className="link"
            href={BITGET_API_SETTINGS_PAGE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            API keys section
          </a>{" "}
          on Bitget and click "Create API";
        </li>
        <li className="connect-step__item">2. Create Note and Passphrase;</li>
        <li className="connect-step__item">
          3. Enable permissions:
          <ul className="connect-step__inner-list">
            <li className="connect-step__inner-item">
              Choose the Read-write option
            </li>
            <li className="connect-step__inner-item">
              Enable permissions for Futures (Orders and Holdings)
              and&nbsp;Spot&nbsp;(Trade)
            </li>
          </ul>
        </li>
        <li className="connect-step__item connect-step__item--with-copy">
          4. Enter the IP whitelist addresses:{" "}
          <Copy text={IP_ADDRESS_WITH_COMMA} />
        </li>
      </ul>
      <div className="connect-step__step1-btn-wrapper">
        <Button
          pattern="white-blue"
          text="Next"
          icon="arrow-right"
          type="button"
          handleClick={handleNextClick}
          ref={nextBtnRef}
        />
        <ConnectHelp accountName={AccountName.BitgetFutures} />
      </div>
      <ConnectPoint1
        pointId={pointId}
        accountName={AccountName.BitgetFutures}
      />
    </div>
  );
}
