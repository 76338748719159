import BlueClockIcon from "../../icons/blue/blue-clock-icon";
import BlueDollarIcon from "../../icons/blue/blue-dollar";
import BlueGearsIcon from "../icons/blue-icon/blue-gears-icon";
import PlusItem from "./plus-item";

export default function BinancePluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">Why SMARD?</h2>
      <ul className="pluses pluses--binance pluses--tablet-3-colums">
        <PlusItem
          type="automated"
          icon={<BlueGearsIcon />}
          extraClass="top-less"
          title="Fully automated"
          description={
            <p className="pluses__info">
              The strategies are fully automated and require no manual
              intervention.
            </p>
          }
        />

        <PlusItem
          type="prosperity"
          icon={<BlueClockIcon />}
          title="Establish long-term financial prosperity"
          description={
            <p className="pluses__info">
              Strategies are designed to generate high profits over the long
              run.
            </p>
          }
        />

        <PlusItem
          type="payments"
          icon={<BlueDollarIcon />}
          title="No upfront payments"
          description={
            <p className="pluses__info">
              The first payment is 10% of the profit, due only after 30 days.
            </p>
          }
        />
      </ul>
    </section>
  );
}
