import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { AccountName, AppRoute, AuthStatus, RERENDER_DELAY } from "../../const";
import { OkxChangeModeSeoSetting } from "../../components/seo-setting/seo-setting";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { checkIfDesktop } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";

export default function OkxChangeModeScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main setup">
      <TrackTwitter />
      <OkxChangeModeSeoSetting />
      <div className="setup__wrapper">
        <h1 className="setup__heading">Change Account Mode in OKX</h1>
        <p className="setup__intro">
          In order for SMARD strategies to work correctly, please follow the
          steps below to configure the account mode in your OKX account.
        </p>
        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1.{" "}
              <a
                className="link"
                href="https://www.okx.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Log in
              </a>{" "}
              to your OKX account and go to{" "}
              {isDesktop ? "Perpetual swaps" : "Perpetual"} by clicking{" "}
              <a
                className="link"
                href="https://www.okx.com/trade-swap/btc-usdt-swap"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
              <br />
              Or follow the instructions in the screenshots below:
            </p>
            {isDesktop ? (
              <>
                <img
                  className="setup__img"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/okx-change-mode-1-1.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
                <img
                  className="setup__img"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/okx-change-mode-1-2.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
              </>
            ) : (
              <>
                <img
                  className="setup__img setup__img--medium"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-1-1.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
                <img
                  className="setup__img setup__img--medium"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-1-2.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
              </>
            )}
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">
              2. Open <b>settings</b> by clicking on the gear icon.
            </p>
            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/okx-change-mode-2.jpg`}
                alt="Okx change mode"
                width="100%"
                height="auto"
              />
            ) : (
              <>
                <img
                  className="setup__img setup__img--medium"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-2-1.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
                <img
                  className="setup__img setup__img--medium"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-2-2.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
              </>
            )}
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">
              3. Click on <b>"Account mode"</b> and select{" "}
              <b>"Single-currency margin"</b>.
            </p>
            {isDesktop ? (
              <>
                <img
                  className="setup__img setup__img--short"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/okx-change-mode-3-1.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
                <img
                  className="setup__img"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/okx-change-mode-3-2.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
              </>
            ) : (
              <>
                <img
                  className="setup__img setup__img--medium"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-3-1.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
                <img
                  className="setup__img setup__img--medium"
                  src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-3-2.jpg`}
                  alt="Okx change mode"
                  width="100%"
                  height="auto"
                />
              </>
            )}
          </li>
          <li className="setup__item">
            <p className="setup__paragraph">
              4. Done! Now go back to the{" "}
              <Link
                className="link"
                to={`${AppRoute.Accounts}/${AccountName.Okx}/`}
              >
                OKX details page
              </Link>
              , specify the amount, and start trading by clicking the{" "}
              <b>"Start"</b> button.
            </p>
            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/okx-change-mode-4.jpg`}
                alt="Okx change mode"
                width="100%"
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--medium"
                src={`${process.env.PUBLIC_URL}/img/screens/okx-change-mode/mobile/okx-change-mode-mobile-4.jpg`}
                alt="Okx change mode"
                width="100%"
                height="auto"
              />
            )}
          </li>
        </ul>
      </div>
    </main>
  );
}
