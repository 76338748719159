import Message from "../../message/message";
import { ResponseStatusType } from "../../../types/details";
import BybitChangeUtaNote from "../notes/bybit/bybit-change-uta-note";

import {
  ErrorType,
  BYBIT_COMMON_ERROR,
  BYBIT_INVALID_API_KEY,
  BYBIT_INVALID_SECRET_KEY,
  BYBIT_ACCOUNT_NOT_UNIFIED_ERROR,
  BYBIT_WRONG_PERMISSIONS,
  BYBIT_WRONG_MARGIN_MODE,
} from "../../../const";

type BybitStep2MessageProps = {
  lengthFieldError: string;
  addKeysStatus: null | ResponseStatusType;
};

export default function BybitStep2Message({
  lengthFieldError,
  addKeysStatus,
}: BybitStep2MessageProps): JSX.Element {
  const isAddKeysFail = addKeysStatus?.isSuccess === false;
  const isAddKeysSuccess = addKeysStatus?.isSuccess;
  const addKeysCode = addKeysStatus?.code;
  const isNoError = !lengthFieldError && (!addKeysStatus || isAddKeysSuccess);

  const isCommonError = (code: number) => BYBIT_COMMON_ERROR === code;
  const isKeyInvalid = (code: number) =>
    code === BYBIT_INVALID_SECRET_KEY ||
    code === BYBIT_INVALID_API_KEY ||
    code === BYBIT_WRONG_PERMISSIONS ||
    code === BYBIT_WRONG_MARGIN_MODE;
  const isAccountNotUtaError = (code: number) => BYBIT_ACCOUNT_NOT_UNIFIED_ERROR === code;

  return isNoError ? (
    <Message message="" />
  ) : lengthFieldError ? (
    <Message status="error" message={`Invalid ${lengthFieldError}`} />
  ): addKeysCode && isAccountNotUtaError(addKeysCode) ? (
    <Message status="error" message={<BybitChangeUtaNote />} />
  ) : addKeysStatus &&
    isAddKeysFail &&
    addKeysCode &&
    (isKeyInvalid(addKeysCode) || isCommonError(addKeysCode)) ? (
    <Message status="error" message={addKeysStatus?.message} />
  ): (
    <Message status="error" message={ErrorType.General} />
  );
}
