import { useEffect } from "react";

import Close from "../close/close";
import RenewKeysInfo from "./info/renew-keys-info";
import RenewKeysForm from "./form/renew-keys-form";
import { setRenewKeysOpened } from "../../store/actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setSendRenewKeysToCheckStatus,
  setSendRenewKeysToUpdateStatus,
} from "../../store/actions/keys";

type RenewKeysProps = {
  isAnimationEnd: boolean;
};


export default function RenewKeys({isAnimationEnd}: RenewKeysProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { strategyDetails } = useAppSelector((state) => state);
  const accountName = strategyDetails?.overview?.code as string;
  const isToggle = !strategyDetails?.renew_keys_form;

  const handleCloseClick = () => {
    dispatch(setRenewKeysOpened(false));
  };

  useEffect(() => {
    return () => {
      dispatch(setSendRenewKeysToCheckStatus(null));
      dispatch(setSendRenewKeysToUpdateStatus(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <section className="renew-keys block block--more-bottom">
      <h2 className="block__heading">Renew keys</h2>
      <div className="renew-keys__wrapper">
        <RenewKeysInfo accountName={accountName} />
        <RenewKeysForm isAnimationEnd={isAnimationEnd}/>
      </div>
      {isToggle && <Close handleClick={handleCloseClick} />}
    </section>
  );
}
