export default function BinanceSpotIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 135 27"
    >
      <path
        fill="#F0B90B"
        d="m6.13 13.5-3.07 3.05L0 13.5l3.06-3.05 3.07 3.05Zm7.44-7.4 5.25 5.22 3.06-3.05L13.57 0 5.25 8.27l3.07 3.05 5.25-5.22Zm10.5 4.35-3.06 3.05 3.06 3.05 3.07-3.05-3.07-3.05ZM13.57 20.9l-5.25-5.22-3.07 3.05L13.57 27l8.31-8.27-3.06-3.05-5.25 5.22Zm0-4.35 3.06-3.05-3.06-3.05-3.07 3.05 3.07 3.05Zm32.34.3v-.05c0-1.99-1.06-2.98-2.79-3.64 1.06-.6 1.96-1.52 1.96-3.2v-.03c0-2.33-1.87-3.83-4.91-3.83h-6.9v14.8h7.07c3.36 0 5.57-1.35 5.57-4.06Zm-4.08-6.34c0 1.1-.92 1.57-2.36 1.57h-3.02V8.95h3.23c1.39 0 2.15.55 2.15 1.52v.04Zm.83 5.93c0 1.1-.88 1.6-2.32 1.6h-3.89V14.8h3.79c1.68 0 2.42.61 2.42 1.6v.05Zm10.13 4.46V6.1H49.5v14.8h3.29Zm17.6 0V6.1h-3.24v9.11L60.17 6.1h-3.02v14.8h3.24V11.5l7.2 9.41h2.8Zm18.13 0L82.13 6h-3.01L72.74 20.9h3.34l1.36-3.32h6.3l1.36 3.32h3.42Zm-5.95-6.2h-3.96l1.97-4.8 2 4.8Zm21.56 6.2V6.1h-3.24v9.11L93.9 6.1H90.9v14.8h3.22V11.5l7.21 9.41h2.8Zm16.9-2.39-2.08-2.09c-1.17 1.06-2.21 1.73-3.91 1.73-2.56 0-4.32-2.1-4.32-4.65v-.04c0-2.54 1.8-4.61 4.32-4.61 1.48 0 2.65.63 3.8 1.67l2.08-2.4a7.7 7.7 0 0 0-5.87-2.28 7.54 7.54 0 0 0-7.76 7.65v.04a7.5 7.5 0 0 0 7.63 7.62c2.87 0 4.57-1 6.1-2.64Zm13.97 2.4V18h-8.1v-3.12h7.03v-2.9h-7.02v-3h7.97V6.1h-11.22V20.9H135Z"
      />
    </svg>
  );
}
