import { Link } from "react-router-dom";

import Button from "../../button/button";
import { createOkxConnectLink } from "../../../utils/id";
import {
  AppRoute,
  OKX_API_SETTINGS_PAGE_LINK,
  StrategyStatus,
} from "../../../const";

type OkxRenewKeysInfoProps = {
  status: string | undefined;
};

export default function OkxRenewKeysInfo({
  status,
}: OkxRenewKeysInfoProps): JSX.Element {
  const okxConnectLink = createOkxConnectLink();
  const isDemo = status === StrategyStatus.Demo;

  return (
    <div className="renew-keys-info">
      <p className="renew-keys-info__paragraph">
        Click on the "Reconnect" button below to update your credentials using
        Fast API.
      </p>
      <Button
        classElement={`renew-keys-info__btn renew-keys-info__btn--reconnect ${
          isDemo ? "btn--disabled" : ""
        }`}
        pattern="white-blue"
        text="Reconnect"
        icon="arrow-right"
        type="link"
        linkAddress={okxConnectLink}
        isSelfLink
      />
      <p className="renew-keys-info__paragraph">
        Or you can update the keys manually by following the instruction below:
      </p>
      <ul className="renew-keys-info__list">
        <li className="renew-keys-info__item">
          1. Go to{" "}
          <a
            className="link"
            href={OKX_API_SETTINGS_PAGE_LINK}
            target="_blank"
            rel="noopener noreferrer"
          >
            the&nbsp;API&nbsp;keys&nbsp;section
          </a>{" "}
          on OKX and click "Linking third-party apps" with the following
          settings{" "}
          <Link className="link" to={AppRoute.OkxSetup}>
            (Full&nbsp;guide)
          </Link>
          :
          <ul className="renew-keys-info__inner-list">
            <li className="renew-keys__inner-item">
              Choose "SMARD" from the App name list
            </li>
            <li className="renew-keys__inner-item">
              Permissions: Read and Trade
            </li>
          </ul>
        </li>
        <li className="renew-keys-info__item">
          2. Enter API key, Secret key and Passphrase below;
        </li>
        <li className="renew-keys-info__item">
          3. Click on the "Renew keys" button.
        </li>
      </ul>
    </div>
  );
}
