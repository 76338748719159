import { useLocation } from "react-router-dom";

import { useAppDispatch } from "../../hooks";
import { sendLogsToServer } from "../../store/api-actions/interface";

export default function CatchError() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  if (Number(process.env.REACT_APP_SEND_LOGS_TO_SERVER)) {
    window.onerror = (message, url, lineNumber, columnNo, error) => {
      // является строкой
      let stack = error?.stack;

      if (stack) {
        const splitted = stack?.split(" ");
        const filtered = splitted.filter(
          (item) => item !== "" && !item.startsWith("(")
        );
        const firstAtIndex = filtered.findIndex((item) => item === "at");
        const sliced = filtered.slice(firstAtIndex);
        const slicedTo10 = sliced.slice(0, 10);
        const joined = slicedTo10.join(" ");
        stack = joined;
      }

      dispatch(
        sendLogsToServer({
          message,
          pathname,
          error: stack ?? "",
        })
      );
    };
  }

  return null;
}
