export default function BitcoinCoinIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path fill="#FFC107" d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z" />
      <path
        fill="#FFF8E1"
        d="M6 15.5v-10h4.1c1.2 0 2.12.23 2.74.7.62.46.93 1.14.93 2.04a2.17 2.17 0 0 1-1.42 2.12c.51.13.91.38 1.2.77.3.4.45.87.45 1.43 0 .96-.3 1.69-.91 2.18a4.1 4.1 0 0 1-2.6.76H6Zm2-6h2.18c1.03-.02 1.55-.41 1.55-1.22 0-.46-.13-.78-.4-.98-.25-.2-.67-.3-1.23-.3H8v2.5ZM8 11v3h2.42c.48 0 1.58-.23 1.58-1.3S11.44 11 10.5 11H8Z"
      />
      <path
        fill="#FFF8E1"
        d="M7 3.5h1.5V6H7V3.5Zm2.5 0H11V6H9.5V3.5ZM7 14h1.5v2.5H7V14Zm2.5 0H11v2.5H9.5V14Z"
      />
    </svg>
  );
}
