export default function ReferralsBalanceSkeleton(): JSX.Element {
  return (
    <div className="sk-referrals-balance animate-pulse">
      <div className="sk-referrals-balance__item">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>

      <div className="sk-referrals-balance__item">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>
      <div className="sk-referrals-balance__item">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>
      <div className="sk-referrals-balance__item sk-referrals-balance__item--big">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>
      <div className="sk-referrals-balance__item sk-referrals-balance__item--big">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>
      <div className="sk-referrals-balance__item">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>
      <div className="sk-referrals-balance__item">
        <div className="sk-referrals-balance__value-wrapper">
          <div className="sk-referrals-balance__icon"></div>
          <div className="sk-referrals-balance__value"></div>
        </div>
        <div className="sk-referrals-balance__title"></div>
      </div>
    </div>
  );
}
