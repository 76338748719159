import Button from "../button/button";
import PartnershipIncome from "../partnership-income/partnership-income";
import { useAppDispatch } from "../../hooks";
import { setPartnershipFormFocused } from "../../store/actions";

export default function PartnershipPromo(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleBtnClick = () => {
    dispatch(setPartnershipFormFocused(true));
  };

  return (
    <section className="landing__item partnership-promo">
      <div className="partnership-promo__heading">
        <h1 className="landing__heading">
          <span className="landing__heading-large">
            JOIN&nbsp;SMARD AFFILIATE&nbsp;PROGRAM
          </span>
        </h1>
      </div>

      <p className="partnership-promo__description">
        Monetize your traffic and earn commissions when you share SMARD with
        your audience. Content creators, influencers, and platforms can earn
        from&nbsp;30% to&nbsp;50% of&nbsp;the commission from invited users
        indefinitely.
      </p>
      <div className="partnership-promo__income">
        <PartnershipIncome />
      </div>
      <Button
        classElement="partnership-promo__btn"
        pattern="white-blue"
        text="Become an affiliate"
        icon="arrow-right"
        type="button"
        size="super"
        handleClick={handleBtnClick}
      />
    </section>
  );
}
