import { Tooltip } from "recharts";

export function displayChartTooltip(content: JSX.Element): JSX.Element {
  return (
    <Tooltip
      wrapperStyle={{ outline: "none" }}
      animationDuration={150}
      content={content}
    />
  );
}
