export default function HamburgerIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 27 11"
    >
      <path
        fill="currentColor"
        d="m18.16 10.67 7.44-5.34c.42-.31.68-.8.68-1.33 0-1.33-1.5-2.12-2.57-1.35l-5.55 4L10.03.8 1.06 6.33c-.49.32-.78.84-.78 1.42a1.62 1.62 0 0 0 2.47 1.4L9.9 4.73l8.26 5.93Z"
      />
    </svg>
  );
}
