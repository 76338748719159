import { trackEvent } from "./tracking";

export const checkAuthAndRegistration = (
  successAuth: string | null,
  successRegistration: string | null
) => {
  if (successAuth) {
    trackEvent("User", "SuccessAuth");
  }

  if (successRegistration) {
    trackEvent("User", "SuccessRegistration");
  }
};

