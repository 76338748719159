import ReferralsBalanceSkeleton from "../skeletons/referrals-balance-skeleton";
import BoardSign from "../board-sign/board-sign";
import PercentInCircleIcon from "../../icons/percent-in-circle-icon";
import WalletIcon from "../../icons/payment/wallet-icon";
import DollarInCircleIcon from "../../icons/payment/dollar-in-circle-icon";
import CardWithDollarIcon from "../../icons/payment/card-with-dollar-icon";
import PersonIcon from "../../icons/person/person-icon";
import PersonActiveIcon from "../../icons/person/person-active-icon";
import { BalanceDetailsType } from "../../types/referrals";
import { formatStrategyWithDeciminals, localAmount } from "../../utils/amount";
import LinkWithDollarIcon from "../../icons/payment/link-with-dollar-icon";

type ReferralsBalanceProps = {
  isLoading: boolean;
  isAffiliate: boolean;
  balance: BalanceDetailsType;
  commission: string;
};

export default function ReferralsBalance({
  isLoading,
  isAffiliate,
  balance,
  commission,
}: ReferralsBalanceProps): JSX.Element {
  const checkColorInBalance = (amount: number) => {
    const formatted = Math.floor(amount);
    if (formatted === 0) {
      return "";
    }
    if (formatted > 0) {
      return "referrals-balance__amount--plus";
    }
    return "referrals-balance__amount--minus";
  };

  const checkColorInInvested = (amount: number) => {
    const formatted = formatStrategyWithDeciminals(amount).amount;
    if (formatted === 0) {
      return "";
    }
    if (formatted > 0) {
      return "referrals-balance__amount--plus";
    }
    return "referrals-balance__amount--minus";
  };

  const displayAmount = (amount: number) =>
    `$${localAmount(formatStrategyWithDeciminals(amount).amount)}${
      formatStrategyWithDeciminals(amount).short
    }`;

  const displayColoredInvested = (amount: number) =>
    `$${localAmount(Math.abs(formatStrategyWithDeciminals(amount).amount))}${
      formatStrategyWithDeciminals(amount).short
    }`;

  const displayColoredBalance = (amount: number) =>
    `$${localAmount(Math.abs(Math.floor(amount)))}`;

  const displayReferrals = (amount: number) => `${localAmount(amount)}`;

  const addColorSignInBalance = (amount: number) => {
    const formatted = Math.floor(amount);
    if (formatted < 0) {
      return <BoardSign sign="minus" isColored />;
    }
    return <></>;
  };

  return (
    <section className="referrals-balance block block--more-bottom">
      {isLoading ? (
        <ReferralsBalanceSkeleton />
      ) : (
        balance && (
          <>
            <ul className="refferals-summary__list">
              {/* COMMISSION */}
              <li className="referrals-balance__item referrals-balance__item--commission">
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--commission">
                      <PercentInCircleIcon />
                    </span>
                  </p>
                  <span className="referrals-balance__amount">
                    {commission}
                  </span>
                </div>
                <span className="referrals-balance__title">
                  Your profit share
                </span>
              </li>

              {/* CURRENT BALANCE */}
              <li className="referrals-balance__item referrals-balance__item--balance">
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--balance">
                      <WalletIcon />
                    </span>
                  </p>
                  <span
                    className={`referrals-balance__amount ${checkColorInBalance(
                      balance.current_balance
                    )}`}
                  >
                    {addColorSignInBalance(balance.current_balance)}
                    {displayColoredBalance(balance.current_balance)}
                  </span>
                </div>
                <span className="referrals-balance__title">
                  Current balance
                </span>
              </li>

              {/* TOTAL EARNINGS */}
              <li className="referrals-balance__item referrals-balance__item--earnings">
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--earnings">
                      <DollarInCircleIcon />
                    </span>
                  </p>
                  <span className="referrals-balance__amount">
                    {displayAmount(balance.earned)}
                  </span>
                </div>
                <span className="referrals-balance__title">Total earnings</span>
              </li>

              {/* ALREADY SPENT */}
              <li className="referrals-balance__item referrals-balance__item--spent">
                {/* <li className="referrals-balance__item referrals-balance__item--withdrawn"> */}
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--spent">
                      <CardWithDollarIcon />
                    </span>
                  </p>
                  <span className="referrals-balance__amount">
                    {isAffiliate
                      ? displayAmount(balance.withdrawn)
                      : displayAmount(balance.spent)}
                  </span>
                </div>
                <span className="referrals-balance__title">
                  {isAffiliate ? "Already withdrawn" : "Already spent"}
                </span>
              </li>
            </ul>
            <ul className="refferals-summary__list">
              {/* CAPITAL INVESTED */}
              <li className="referrals-balance__item referrals-balance__item--invested">
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--invested">
                      <LinkWithDollarIcon />
                    </span>
                  </p>
                  <span
                    className={`referrals-balance__amount ${checkColorInInvested(
                      balance.referrals_capital_invested
                    )}`}
                  >
                    {displayColoredInvested(balance.referrals_capital_invested)}
                  </span>
                </div>
                <span className="referrals-balance__title">
                  {isAffiliate
                    ? "Total affiliate capital invested"
                    : "Total referral capital invested"}
                </span>
              </li>
              {/* REFERRALS */}
              <li className="referrals-balance__item referrals-balance__item--referrals">
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--referrals">
                      <PersonIcon />
                    </span>
                  </p>
                  <span className="referrals-balance__amount">
                    {displayReferrals(balance.referrals)}
                  </span>
                </div>
                <span className="referrals-balance__title">
                  {isAffiliate ? "Affiliates" : "Referrals"}
                </span>
              </li>

              {/* STARTED */}
              <li className="referrals-balance__item referrals-balance__item--started">
                <div className="referrals-balance__wrapper">
                  <p className="referrals-balance__icon-wrapper">
                    <span className="referrals-balance__icon referrals-balance__icon--started">
                      <PersonActiveIcon />
                    </span>
                  </p>
                  <span className="referrals-balance__amount">
                    {displayReferrals(balance.active_referrals)}
                  </span>
                </div>
                <span className="referrals-balance__title">
                  {isAffiliate
                    ? "Affiliates who started trading"
                    : "Referrals who started trading"}
                </span>
              </li>
            </ul>
          </>
        )
      )}
    </section>
  );
}
