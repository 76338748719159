import GoogleIcon from "../icons/socials/google-icon";
import { AUTH_GOOGLE_URL } from "../../const";

export default function LoginGooglePersonal(): JSX.Element {
  return (
    <a className="personal__link" href={AUTH_GOOGLE_URL}>
      <p className="personal__icon-wrapper">
        <span className="personal__icon personal__icon--google">
          <GoogleIcon />
        </span>
      </p>
      <p className="personal__heading">Connect to Google</p>
    </a>
  );
}
