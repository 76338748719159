import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import { AppRoute } from "../../const";

type SummaryItemProps = {
  title: string;
  type: string;
  amount: string | number | JSX.Element;
  icon: JSX.Element;
  amountModificator?: string;
};

export default function SummaryItem({
  title,
  amount,
  type,
  amountModificator,
  icon,
}: SummaryItemProps): JSX.Element {
  const navigate = useNavigate();

  const handlePayClick = () => {
    navigate(AppRoute.Payments);
  };

  return type === "pay-button" ? (
    <li className="overview-summary__item overview-summary__item--btn">
      <Button
        pattern="green-white"
        text="Pay"
        icon="credit-card"
        type="button"
        handleClick={handlePayClick}
      />
    </li>
  ) : (
    <li className={`overview-summary__item overview-summary__item--${type}`}>
      <p className="overview-summary__icon-wrapper">
        <span
          className={`overview-summary__icon overview-summary__icon--${type}`}
        >
          {icon}
        </span>
      </p>
      <span
        className={`overview-summary__amount ${
          amountModificator
            ? `overview-summary__amount--${amountModificator}`
            : ""
        }`}
      >
        {amount}
      </span>
      <span className="overview-summary__title">{title}</span>
    </li>
  );
}
