import { createAction } from "@reduxjs/toolkit";
import { StateAction } from "../action-types";
import { PaymentsInfoType } from "../../types/payment";

export const loadPaymentsInfo = createAction<PaymentsInfoType>(
  StateAction.Payment.LoadPaymentsInfo
);

export const setLoadPaymentsInfoLoading = createAction<boolean>(
  StateAction.Payment.SetLoadPaymentsInfoLoading
);
