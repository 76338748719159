import ExitIcon from "../../icons/exit-icon";
import LoginApplePersonal from "../../login/login-apple-personal";
import LoginGooglePersonal from "../../login/login-google-personal";
import LoginTelegramPersonal from "../../login/login-telegram-personal";
import { UserAuthAccountsType } from "../../../types/general";
import { AUTH_LOGOUT } from "../../../const";

type PersonalLoginProps = {
  userAuthAccounts: UserAuthAccountsType;
  isOverview: boolean;
};

export default function PersonalLogin({
  userAuthAccounts,
  isOverview,
}: PersonalLoginProps): JSX.Element {
  return (
    <ul className="personal__login-list">
      {userAuthAccounts && !userAuthAccounts.apple && (
        <li className="personal__item">
          <LoginApplePersonal isOverview={isOverview} />
          <p className="personal__description">
            Connect your Apple ID to simplify logging&nbsp;in
          </p>
        </li>
      )}

      {userAuthAccounts && !userAuthAccounts.google && (
        <li className="personal__item">
          <LoginGooglePersonal />
          <p className="personal__description">
            Connect your Google account to streamline your login experience
          </p>
        </li>
      )}

      {userAuthAccounts && !userAuthAccounts.telegram && (
        <li className="personal__item">
          <LoginTelegramPersonal />
          <p className="personal__description">
            Connect Telegram to enable notifications
          </p>
        </li>
      )}

      <li className="personal__item">
        <a className="personal__link" href={AUTH_LOGOUT}>
          <p className="personal__icon-wrapper">
            <span className="personal__icon personal__icon--exit">
              <ExitIcon />
            </span>
          </p>
          <p className="personal__heading">Log out</p>
        </a>
      </li>
    </ul>
  );
}
