import GridItem from "./grid-item";

export default function Publishers(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">Featured on</h2>
      <ul className="grid">
        <GridItem
          name="Coin Market Cap"
          img="publisher/coin-market-cap.svg"
          link="https://coinmarketcap.com/community/articles/652004f5f567de67583d7bee/"
        />
        <GridItem
          name="Yahoo finance"
          img="publisher/yahoo-finance.svg"
          link="https://finance.yahoo.com/news/smard-launches-services-automated-cryptocurrency-022600081.html?fr=sycsrp_catchall&guccounter=1"
        />
        <GridItem
          name="Investing.com"
          img="publisher/investing-com.svg"
          link="https://www.investing.com/news/cryptocurrency-news/smard-launches-fully-automated-crypto-trading-software-3033348"
        />
        <GridItem
          name="Benzinga"
          img="publisher/benzinga.svg"
          link="https://www.benzinga.com/pressreleases/23/03/g31468526/smard-launches-new-services-in-automated-cryptocurrency-trading"
        />
        <GridItem
          name="Digital journal"
          img="publisher/digital-journal.svg"
          link="https://www.digitaljournal.com/pr/news/smard-launches-new-services-in-automated-cryptocurrency-trading"
        />
        <GridItem
          name="Coin edition"
          img="publisher/coin-edition.svg"
          link="https://coinedition.com/smard-launches-fully-automated-crypto-trading-software/"
        />
        <GridItem
          name="Coin Market Cal"
          img="publisher/coin-market-cal.svg"
          link="https://coinmarketcal.com/en/news/simple-method-to-make-37-profit-in-a-month-with-automated-trading-software-smard-june-trading-results"
        />
        <GridItem
          name="Coinpaper"
          img="publisher/coinpaper.svg"
          link="https://coinpaper.com/2092/from-zero-to-crypto-hero-new-automated-strategy-for-binance-futures-with-a-5-average-monthly-return"
        />
      </ul>
    </section>
  );
}
