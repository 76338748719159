import axios, { AxiosError, AxiosInstance } from "axios";

import { store } from "../store";
import { sendLogsToServer } from "../store/api-actions/interface";
import { ApiRoute } from "../const";

// const REQUEST_TIMEOUT = 5000;

export const createAPI = (): AxiosInstance => {
  const api = axios.create({
    // timeout: REQUEST_TIMEOUT,
    baseURL: process.env.REACT_APP_SITE_ORIGIN,
  });

  api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
      if (
        Number(process.env.REACT_APP_SEND_LOGS_TO_SERVER) &&
        error.response &&
        error.response.status === 500 &&
        // Чтобы предотвратить бесконечный цикл запросов на ApiRoute.LogsToServer
        //  если будет приходить 500 ошибка
        error.config &&
        error.config.url !== ApiRoute.LogsToServer
      ) {
        store.dispatch(
          sendLogsToServer({
            message: error.message,
            pathname: error.response.config.url ?? "",
          })
        );
      }
      throw error;
    }
  );

  return api;
};
