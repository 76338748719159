export default function BanIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="currentColor"
        d="M6.1 1.2a5 5 0 1 0 0 10 5 5 0 0 0 0-10Zm0 9a4 4 0 0 1-3.2-6.4l5.7 5.6a4 4 0 0 1-2.5.8Zm3.2-1.5L3.7 3a4 4 0 0 1 5.6 5.6Z"
      />
    </svg>
  );
}
