import { FaqData } from "../../../types/general";

export const FaqScreenSecurityData: FaqData[] = [
  {
    question: "Who can trade on my account?",
    answer: "Only you. Either on your own, or using SMARD software.",
  },
  {
    question: "Can SMARD withdraw money from my account?",
    answer:
      "No, if the platform settings allow access to withdraw funds, do not grant such permissions to the keys issued for use by SMARD. In any case, SMARD does not have this functionality.",
  },
  {
    question: "Will I have access to my money?",
    answer:
      "Yes, you will have full access to your account at all times and can see which assets the strategy has purchased. You can also sell these assets and withdraw money from the account if needed. If SMARD does not detect the expected instruments in the account, it will pause trading activities.",
  },
  {
    question: "Is SMARD a regulated entity?",
    answer:
      "No. SMARD is a technology provider and trading enhancement software that trades on your account on your behalf. SMARD does not take or withdraw money itself; the money is always in the complete control of the client. The client makes the decision to use SMARD.",
  },
  {
    question: "Privacy",
    answer:
      "SMARD is serious about privacy. We do not report user data anywhere; we use cookies only for advertisements and information pages, and we do not place any cookies in personal sections of the site.",
  },
];
