import BlueChartIcon from "../icons/blue-icon/blue-chart-icon";
import BlueClockIcon from "../icons/blue-icon/blue-clock-icon";
import BlueTrendingUpIcon from "../icons/blue-icon/blue-trending-up-icon";
import BlueWarningIcon from "../icons/blue-icon/blue-warning-icon";
import PlusItem from "./plus-item";

export default function WhyWorksSteps(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">Why it works</h2>
      <ul className="pluses pluses--why-works pluses--tablet-2-columns">
        <PlusItem
          type="momentum"
          icon={<BlueTrendingUpIcon />}
          title="Momentum effect"
          description={
            <p className="pluses__info">
              Our algorithms employs a momentum effect verified by{" "}
              <a
                className="link"
                href="https://research.cbs.dk/en/publications/time-series-momentum"
                target="_blank"
                rel="noopener noreferrer"
              >
                fundamental literature
              </a>
              , as well as a number of tried-and-tested techniques to increase
              trading performance, which allows us to systematically identify
              future market winners.
            </p>
          }
        />

        <PlusItem
          type="risk"
          icon={<BlueWarningIcon />}
          title="Risk management via&nbsp;stop&nbsp;losses"
          description={
            <p className="pluses__info">
              Depending on market conditions, SMARD trading bots use regular
              stop-losses, trailing stops or a combination of both.
            </p>
          }
        />

        <PlusItem
          type="position"
          icon={<BlueChartIcon />}
          title="Variable position size"
          description={
            <p className="pluses__info">
              Trading bots flexibly manage the size of the position by
              controlling the amount of risk taken in each trade.
            </p>
          }
        />

        <PlusItem
          type="long"
          icon={<BlueClockIcon />}
          title="Long term"
          description={
            <p className="pluses__info">
              Focused on long-term usage over the course of months or years.
            </p>
          }
        />
      </ul>
    </section>
  );
}
