import { Link } from "react-router-dom";

import { AppRoute } from "../../const";

type FooterList1Props = {
  isNoAuth: boolean;
};

export default function FooterList1({
  isNoAuth,
}: FooterList1Props): JSX.Element {
  return (
    <ul className="footer-list">
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Manual}>
          Home
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Overview}>
          {isNoAuth ? "Demo" : "Personal area"}
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Pricing}>
          Pricing
        </Link>
      </li>
      <li className="footer-list__item">
        <Link className="footer-list__link" to={AppRoute.Coins}>
          Tradable coins
        </Link>
      </li>
      <li className="footer-list__item">
        <a
          className="footer-list__link"
          href="https://medium.com/@smard.club"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blog
        </a>
      </li>
    </ul>
  );
}
