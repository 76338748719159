import { FaqData } from "../../../types/general";

export const FaqScreenAccountManagmentData: FaqData[] = [
  {
    question: "Can I trade on my account while it is being traded by SMARD?",
    answer:
      "Yes, you can continue to use your account to invest or trade; just do not touch the position that the bot has formed. If, at the time of trading, SMARD does not see the expected position, then it will pause and not continue until you recover the expected position in the assets.",
  },
  {
    question: "Why doesn’t the algorithm trade on spot strategy?",
    answer:
      "Spot strategies trade long only, so it either makes few or no trades when the market is falling or consolidating. But rest assured, the algorithm is constantly analyzing the market and looking for the right conditions to make a trade.",
  },
  {
    question: "How can I change the amount under SMARD management?",
    answer:
      "To change the amount under management, you need to stop the strategy and start it again with the new amount. We realize that this is not very convenient and will add a function to change the portfolio without reconnection soon",
  },
  {
    question: "What happens after the strategy is stopped?",
    answer:
      "After the strategy is stopped, the algorithm will automatically close all open positions within 10-15 minutes.",
  },
];
