type TooltipErrorProps = {
  type: string;
  message: string;
};

export default function TooltipError({
  type,
  message,
}: TooltipErrorProps): JSX.Element {
  return (
    <div className={`tooltip-error ${type}`}>
      <button
        className="tooltip-error__btn"
        type="button"
        aria-label="Error tooltip"
      ></button>

      <p className="tooltip-error__message">{message}</p>
    </div>
  );
}
