import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

import PlusIcon from "../../icons/sign/plus-icon";

type FaqItemProps = {
  question: string;
  answer: JSX.Element | string;
  isWithChart?: boolean;
};

export default function FaqItem({
  question,
  answer,
  isWithChart,
}: FaqItemProps): JSX.Element {
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleClick = () => {
    setOpen(!isOpen);
  };

  return (
    <li className="faq-item">
      <h3 className="faq-item__question" onClick={handleClick}>
        <motion.div
          animate={{
            rotate: isOpen ? 45 : 0,
          }}
          transition={{ duration: 0.2 }}
          className="faq-item__icon"
        >
          <PlusIcon />
        </motion.div>

        {question}
      </h3>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: "auto",
              opacity: 1,
            }}
            exit={{
              height: 0,
              opacity: 0,
            }}
            style={{ overflow: "hidden" }}
            transition={{ duration: isWithChart ? 0.5 : 0.2 }}
            className={`faq-item__answer ${
              isWithChart ? "faq-item__answer--with-chart" : ""
            }`}
          >
            {answer}
          </motion.div>
        )}
      </AnimatePresence>
    </li>
  );
}
