import { useLocation } from "react-router-dom";

import Nav from "../nav/nav";
import AuthBlock from "../../auth-block/auth-block";
import Logo from "../logo";
import Demo from "../demo";
import NavBtns from "../nav-btns/nav-btns";
import NavToggle from "../nav-toggle";
import { AppRoute, AuthStatus } from "../../../const";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setNavOpened } from "../../../store/actions/interface";

export default function PageHeader(): JSX.Element {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isNavOpened, authStatus } = useAppSelector((state) => state);

  const isAuth = authStatus === AuthStatus.Auth;
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const isDemoShown =
    pathname === AppRoute.Overview ||
    pathname === AppRoute.Referrals ||
    pathname === AppRoute.Payments ||
    pathname.startsWith(AppRoute.Accounts);

  const handleToggleClick = () => {
    dispatch(setNavOpened(!isNavOpened));
  };

  return (
    <header className="page-header">
      <Logo />
      <Nav />

      <div className="page-header__nav-toggle">
        <NavToggle isOpened={isNavOpened} handleOpened={handleToggleClick} />
      </div>

      {isAuth && (
        <div className="page-header__nav-btns">
          <NavBtns />
        </div>
      )}

      {isNoAuth && (
        <>
          <AuthBlock type="page-header__auth" />
          {isDemoShown && (
            <div className="page-header__demo">
              <Demo />
            </div>
          )}
        </>
      )}
    </header>
  );
}
