import CloseIcon from "../icons/close-icon";

type CloseProps = {
  handleClick: () => void;
};

export default function Close({ handleClick }: CloseProps): JSX.Element {
  return (
    <button
      className="close"
      aria-label="Close block."
      type="button"
      onClick={handleClick}
    >
      <CloseIcon />
    </button>
  );
}
