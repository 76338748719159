export default function NotificationsSkeleton(): JSX.Element {
  return (
    <div className="sk-notifications animate-pulse">
      <div className="sk-notifications__item">
        <div className="sk-notifications__icon"></div>
        <div className="sk-notifications__text-wrapper">
          <div className="sk-notifications__message"></div>
          <div className="sk-notifications__date"></div>
        </div>
      </div>
      <div className="sk-notifications__item">
        <div className="sk-notifications__icon"></div>
        <div className="sk-notifications__text-wrapper">
          <div className="sk-notifications__message"></div>
          <div className="sk-notifications__date"></div>
        </div>
      </div>
      <div className="sk-notifications__item">
        <div className="sk-notifications__icon"></div>
        <div className="sk-notifications__text-wrapper">
          <div className="sk-notifications__message"></div>
          <div className="sk-notifications__date"></div>
        </div>
      </div>
      <div className="sk-notifications__item">
        <div className="sk-notifications__icon"></div>
        <div className="sk-notifications__text-wrapper">
          <div className="sk-notifications__message"></div>
          <div className="sk-notifications__date"></div>
        </div>
      </div>
      <div className="sk-notifications__item">
        <div className="sk-notifications__icon"></div>
        <div className="sk-notifications__text-wrapper">
          <div className="sk-notifications__message"></div>
          <div className="sk-notifications__date"></div>
        </div>
      </div>
    </div>
  );
}
