import { StrategyOverviewType } from "../../../types/details";
import { displayStrategyAmount } from "../../../utils/component/overview";

type DetailsInitialProps = {
  strategyStatus: string;
  isTabletAndMore: boolean;
  overview: StrategyOverviewType;
};

export default function DetailsInitial({
  strategyStatus,
  isTabletAndMore,
  overview,
}: DetailsInitialProps): JSX.Element {
  const amount = overview.start_amount;
  const currency = overview.currency;

  return currency && amount !== null ? (
    <div className="details-board__total details-board__total--initial">
      <p className="details-board__amount">
        {displayStrategyAmount(
          "initial",
          strategyStatus,
          isTabletAndMore,
          amount,
          currency
        )}
      </p>
      <span className="details-board__text">Initial amount</span>
    </div>
  ) : (
    <></>
  );
}
