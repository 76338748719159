export default function CardIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 19 14"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 2c0-1 1-2 2-2h15c1 0 2 1 2 2v10c0 1-1 2-2 2H2c-1 0-2-1-2-2V2Zm16.5-.5h-14c-.5 0-1 .5-1 1v9c0 .5.5 1 1 1h14c.5 0 1-.5 1-1v-9c0-.5-.5-1-1-1Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M1.5 3.5h16v3h-16v-3Z" />
    </svg>
  );
}
