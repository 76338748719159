import { YAxis } from "recharts";

export function displayChartMainYAxis(
  isFewData: boolean,
  formatTicks: (tick: number) => string,
  domain: number[],
  ticks: number[]
): JSX.Element {
  return isFewData ? (
    <YAxis
      yAxisId="left"
      orientation="left"
      axisLine={false}
      tickLine={false}
      dataKey="amount"
      width={50}
      allowDecimals={false}
      interval={0}
      domain={domain}
      tickFormatter={(tick: number) => formatTicks(tick)}
    />
  ) : (
    <YAxis
      yAxisId="left"
      orientation="left"
      axisLine={false}
      tickLine={false}
      dataKey="amount"
      width={50}
      allowDecimals={false}
      interval={0}
      domain={domain}
      tickFormatter={(tick: number) => formatTicks(tick)}
      ticks={ticks}
    />
  );
}

export function displayCoinChartMainYAxis(
  isFewData: boolean,
  formatTicks: (tick: number) => string,
  domain: number[],
  ticks: number[],
  width: number,
  isAllowDiciminals: boolean
): JSX.Element {
  return isFewData ? (
    <YAxis
      yAxisId="left"
      orientation="left"
      axisLine={false}
      tickLine={false}
      dataKey="benchmarkAmount"
      width={width}
      allowDecimals={isAllowDiciminals}
      interval={0}
      domain={domain}
      tickFormatter={formatTicks}
    />
  ) : (
    <YAxis
      yAxisId="left"
      orientation="left"
      axisLine={false}
      tickLine={false}
      dataKey="benchmarkAmount"
      width={width}
      allowDecimals={isAllowDiciminals}
      interval={0}
      domain={domain}
      tickFormatter={formatTicks}
      ticks={ticks}
    />
  );
}
