import Hint from "../hint/hint";
import TableDate from "../table-items/table-date";
import PersonIcon from "../../icons/person/person-icon";
import MinusIcon from "../../icons/sign/minus-icon";
import WalletIcon from "../../icons/payment/wallet-icon";
import { AuthStatus, HINTS_TEXT } from "../../const";
import { ReferralsActivityType } from "../../types/referrals";
import { localInvoiceAmount } from "../../utils/amount";

type ReferralsActivityProps = {
  authStatus: string;
  isAffiliate: boolean;
  activityList: ReferralsActivityType[];
};

export default function ReferralsActivity({
  authStatus,
  isAffiliate,
  activityList,
}: ReferralsActivityProps): JSX.Element {
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const hint = (
    <Hint
      block="referrals-activity"
      type="block__hint"
      id="referrals/activity"
      hintText={HINTS_TEXT["referrals/activity"]}
    />
  );

  const titleRow = (
    <li className="referrals-activity__row">
      <p className="referrals-activity__title referrals-activity__title--name">
        Date
      </p>
      <p className="referrals-activity__title referrals-activity__title--event">
        Event
      </p>
      <p className="referrals-activity__title referrals-activity__title--amount">
        Amount
      </p>
    </li>
  );

  const showAmount = (amount: number) =>
    `$${localInvoiceAmount(Math.abs(amount))}`;

  const checkSignIcon = (amount: number) => {
    const formatted = amount;

    if (formatted >= 0) {
      return "";
    }

    return (
      <span className="referrals-activity__amount-icon referrals-activity__amount-icon--minus">
        <MinusIcon />
      </span>
    );
  };

  return (
    <section className="referrals-activity block">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Activity</h2>
        {isNoAuth && hint}
      </div>

      {activityList?.length ? (
        <ul className="referrals-activity__table">
          {titleRow}
          {activityList.map((item, index) => {
            return (
              <li className="referrals-activity__row" key={index}>
                {/* DATE */}
                <TableDate date={item.date} />

                {/* EVENT */}
                <div className="referrals-activity__event">
                  {item.type === "Withdrawal" ? (
                    <>
                      <p className="referrals-activity__event-wrapper">
                        <span className="referrals-activity__event-icon referrals-activity__event-icon--wallet">
                          <WalletIcon />
                        </span>
                      </p>

                      <p className="referrals-activity__event-text">
                        Withdrawn to external wallet
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="referrals-activity__event-wrapper">
                        <span className="referrals-activity__event-icon referrals-activity__event-icon--user">
                          <PersonIcon />
                        </span>
                      </p>
                      <p className="referrals-activity__event-text">
                        {`Earned from ${item.referral_name}`}
                      </p>
                    </>
                  )}
                </div>

                {/* AMOUNT */}
                <div className="referrals-activity__amount">
                  {checkSignIcon(item.amount)}
                  <p className="referrals-activity__absolute">
                    {showAmount(item.amount)}
                  </p>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="table-note">
          {`You will see your earnings through ${
            isAffiliate ? "affiliate" : "referral"
          } program here.`}
        </p>
      )}
    </section>
  );
}
