import { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

import DollarIcon from "../../icons/nav/dollar-icon";
import ChatIcon from "../../icons/nav/chat-icon";
import OverviewIcon from "../../icons/nav/overview-icon";
import PersonalLogin from "./personal-login";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { AppRoute } from "../../../const";
import { setPersonalOpened } from "../../../store/actions";

type PersonalProps = {
  userBtnRef: React.RefObject<HTMLButtonElement>;
};

export default function Personal({ userBtnRef }: PersonalProps): JSX.Element {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { userAuthAccounts } = useAppSelector((state) => state);
  const personalRef = useRef<HTMLDivElement | null>(null);
  const isManual = pathname === AppRoute.Manual;
  const isOkx = pathname === AppRoute.Okx;
  const isBitget = pathname === AppRoute.Bitget;
  const isBinance = pathname === AppRoute.Binance;
  const isAffiliates = pathname === AppRoute.Affiliates;
  const isLanding = isManual || isOkx || isBitget || isBinance || isAffiliates;
  const isOverview = pathname === AppRoute.Overview;

  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      if (
        !personalRef?.current?.contains(evt.target as Element) &&
        !userBtnRef?.current?.contains(evt.target as Element)
      ) {
        dispatch(setPersonalOpened(false));
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [personalRef, userBtnRef, dispatch]);

  return (
    <div
      ref={personalRef}
      className="personal private-window shadow shadow--personal"
    >
      <div className="personal__lists private-window__list">
        {isLanding && (
          <ul className="personal__link-list">
            <li className="personal__item personal__item--overview">
              <Link className="personal__link" to={AppRoute.Overview}>
                <p className="personal__icon-wrapper">
                  <span className="personal__icon personal__icon--overview">
                    <OverviewIcon />
                  </span>
                </p>
                <p className="personal__heading">Go to personal area</p>
              </Link>
            </li>
            <li className="personal__item personal__item--pricing">
              <Link className="personal__link" to={AppRoute.Pricing}>
                <p className="personal__icon-wrapper">
                  <span className="personal__icon personal__icon--pricing">
                    <DollarIcon />
                  </span>
                </p>
                <p className="personal__heading">Win-win pricing</p>
              </Link>
            </li>
            <li className="personal__item  personal__item--faq">
              <Link className="personal__link" to={AppRoute.Faq}>
                <p className="personal__icon-wrapper">
                  <span className="personal__icon personal__icon--faq">
                    <ChatIcon />
                  </span>
                </p>
                <p className="personal__heading">FAQ</p>
              </Link>
            </li>
          </ul>
        )}

        <PersonalLogin
          userAuthAccounts={userAuthAccounts}
          isOverview={isOverview}
        />
      </div>

      <button
        className="personal__close"
        onClick={() => {
          dispatch(setPersonalOpened(false));
        }}
      >
        <span className="visually-hidden">Close personal block</span>
      </button>
    </div>
  );
}
