export default function ChainIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 32 16"
    >
      <path
        fill="currentColor"
        d="M23.94.1h-4.76c-.88 0-1.6.72-1.6 1.58 0 .87.72 1.58 1.6 1.58h4.76A4.76 4.76 0 0 1 28.71 8c0 2.6-2.15 4.74-4.77 4.74h-4.76c-.88 0-1.6.7-1.6 1.58 0 .86.72 1.57 1.6 1.57h4.76A7.92 7.92 0 0 0 31.88 8c0-4.36-3.56-7.9-7.94-7.9ZM9.64 8c0 .87.72 1.58 1.6 1.58h9.52c.88 0 1.6-.71 1.6-1.58 0-.87-.72-1.58-1.6-1.58h-9.53c-.87 0-1.58.71-1.58 1.58Zm3.18 4.74H8.06A4.76 4.76 0 0 1 3.29 8c0-2.6 2.15-4.74 4.77-4.74h4.76c.88 0 1.6-.7 1.6-1.58 0-.86-.72-1.57-1.6-1.57H8.06A7.92 7.92 0 0 0 .12 8c0 4.36 3.55 7.9 7.94 7.9h4.76c.88 0 1.6-.72 1.6-1.58 0-.87-.72-1.58-1.6-1.58Z"
      />
    </svg>
  );
}
