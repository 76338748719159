import Message from "../../message/message";
import { ResponseStatusType } from "../../../types/details";

import {
  ErrorType,
  BINANCE_INVALID_SECRET_KEY,
  BINANCE_INVALID_API_KEY,
  BINANCE_COMMON_ERROR,
} from "../../../const";

type BinanceSpotStep2MessageProps = {
  lengthFieldError: string;
  addKeysStatus: null | ResponseStatusType;
};

export default function BinanceSpotStep2Message({
  lengthFieldError,
  addKeysStatus,
}: BinanceSpotStep2MessageProps): JSX.Element {
  const isAddKeysFail = addKeysStatus?.isSuccess === false;
  const isAddKeysSuccess = addKeysStatus?.isSuccess;
  const addKeysCode = addKeysStatus?.code;
  const isNoError = !lengthFieldError && (!addKeysStatus || isAddKeysSuccess);

  const isCommonError = (code: number) => BINANCE_COMMON_ERROR === code;
  const isKeyInvalid = (code: number) =>
    code === BINANCE_INVALID_SECRET_KEY || code === BINANCE_INVALID_API_KEY;

  return isNoError ? (
    <Message message="" />
  ) : lengthFieldError ? (
    <Message status="error" message={`Invalid ${lengthFieldError}`} />
  ) : addKeysStatus &&
    isAddKeysFail &&
    addKeysCode &&
    (isKeyInvalid(addKeysCode) || isCommonError(addKeysCode)) ? (
    <Message status="error" message={addKeysStatus?.message} />
  ) : (
    <Message status="error" message={ErrorType.General} />
  );
}
