import { useEffect, useState } from "react";
import { PricingSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { AuthStatus, RERENDER_DELAY } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { checkIfMobile } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import PricingFaqSection from "../../components/faq/pricing-faq-section";
import PricingSummarySection from "../../components/pricing/pricing-summary-sections";
import PricingCalculatorSection from "../../components/pricing/pricing-calculator-sections";
import PricingFeesSection from "../../components/pricing/pricing-fees-section";

export default function PricingScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const [isMobile, setIsMobile] = useState<boolean>(checkIfMobile());

  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
  }, [authStatus]);

  window.addEventListener(
    "resize",
    debounce(() => {
      setIsMobile(checkIfMobile());
    }, RERENDER_DELAY)
  );

  return (
    <main className="main main--landing landing">
      <div className="landing__wrapper">
        <PricingSeoSetting />
        <TrackTwitter />
        <PricingSummarySection isMobile={isMobile} />
        <PricingFeesSection isMobile={isMobile} />
        <PricingCalculatorSection isMobile={isMobile} />
        <PricingFaqSection />
      </div>
    </main>
  );
}
