import { CartesianGrid } from "recharts";

// creating a wrapped component does not work
// рендеринг происходит только при вызове функции
export function displayChartGrid(): JSX.Element {
  return (
    <CartesianGrid
      horizontal={true}
      vertical={true}
      stroke="lightgray"
      strokeDasharray="2 2"
    />
  );
}

export function displayChartNoGrid(): JSX.Element {
  return <CartesianGrid horizontal={false} vertical={false} />;
}
