import { useState } from "react";
import { AreaChart, XAxis, ResponsiveContainer } from "recharts";

import { displayChartGradient } from "./options/chart-gradient";
import { displayChartGrid } from "./options/chart-grid";
import { checkColor } from "../../utils/chart/color";
import { formatDateIntoMonthsLastTickYear } from "../../utils/chart/date";
import { displayCoinChartMainYAxis } from "./options/chart-main-yaxis";
import { displayChartTooltip } from "./options/chart-tooltip";
import { displayCoinChartSecondYAxis } from "./options/chart-bench-yaxis";
import { displayCoinChartSmardEndDot } from "./options/chart-smard-end-dot";
import { displayCoinChartCustomEndDot } from "./options/chart-bench-end-dot";
import { displayCoinChartAreas } from "./options/chart-areas";
import { ChartType } from "../../types/chart";
import { showNumericDayMonth } from "../../utils/datetime";
import { CoinChartTooltip } from "./tooltips/chart-tooltips";
import { checkIfChartMoreMobileWidth } from "../../utils/breakpoint";
import { RERENDER_DELAY } from "../../const";
import {
  checkMinDomain,
  collectAmounts,
  collectDates,
  debounce,
  findSmallAmountTicks,
  findTicks,
  formatSmallAmountTicks,
  formatTicks,
} from "../../utils/chart/chart";
import {
  findCorrelativeMinMax,
  findTimeTicks,
  formatDetails2ChartsData,
} from "../../utils/chart/details-chart";

type CoinChartProps = {
  strategyData: ChartType[];
  coinData: ChartType[];
  coinImgLink: string;
  isMonth: boolean;
};

export default function CoinChart({
  strategyData,
  coinData,
  coinImgLink,
  isMonth,
}: CoinChartProps): JSX.Element {
  const [isEndDotsShown, setEndDotsShown] = useState<boolean>(true);
  const [isMoreMobile, setMoreMobile] = useState<boolean>(
    checkIfChartMoreMobileWidth()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setMoreMobile(checkIfChartMoreMobileWidth());
    }, RERENDER_DELAY)
  );

  const main = formatDetails2ChartsData(strategyData, coinData);
  const minMaxStrategy = findCorrelativeMinMax(
    strategyData,
    coinData
  ).strategyRange;
  const minMaxCoin = findCorrelativeMinMax(strategyData, coinData).benchRange;

  const maxCoinPrice = Math.max(...collectAmounts(coinData));
  const checkCoinTickWidth = () => {
    if (maxCoinPrice < 0.001) {
      return 75;
    }
    return 45;
  };

  const checkChartColor = () => checkColor(coinData);
  const chartType = "coin";

  // For XAxis
  const timestamps = collectDates(strategyData);
  const timestampTicks = findTimeTicks(timestamps);
  const formatTimestampTicks = (tick: number) =>
    !isMonth
      ? formatDateIntoMonthsLastTickYear(timestampTicks, tick)
      : showNumericDayMonth(tick);

  // For YAxis props
  const isFewData = strategyData.length < 5 || coinData.length < 5;
  const isCoinPriceSmall = maxCoinPrice < 200;
  const checkedCoinMin = checkMinDomain(minMaxCoin[0], minMaxStrategy[0]);
  const checkedStrategyMin = checkMinDomain(minMaxStrategy[0], minMaxCoin[0]);

  // Coin
  const formatCoinTicks = isCoinPriceSmall
    ? (tick: number) => formatSmallAmountTicks(tick)
    : (tick: number) => formatTicks(tick);
  const coinDomain = [checkedCoinMin, minMaxCoin[1]];
  const coinTicks = isCoinPriceSmall
    ? findSmallAmountTicks(checkedCoinMin, minMaxCoin[1])
    : findTicks(checkedCoinMin, minMaxCoin[1]);
  const coinTickWidth = checkCoinTickWidth();
  const isAllowDecimals = isCoinPriceSmall;

  // Strategy
  const strategyDomain = [checkedStrategyMin, minMaxStrategy[1]];
  const formatStrategyTicks = (tick: number) => formatTicks(tick);
  const strategyTicks = findTicks(checkedStrategyMin, minMaxStrategy[1]);

  return (
    <div className="chart chart--coin">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={main}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: isMoreMobile ? 0 : 15,
          }}
        >
          {displayChartGradient(chartType, checkChartColor)}
          {displayChartGrid()}
          {/* Timestamps*/}
          <XAxis
            dataKey="timestamp"
            axisLine={false}
            tickLine={false}
            interval={0}
            type="number"
            domain={["dataMin", "dataMax"]}
            tickFormatter={formatTimestampTicks}
            ticks={timestampTicks}
            tickSize={isMoreMobile ? 6 : 18}
            angle={isMoreMobile ? 0 : -90}
          />
          {/* Strategy первее Coin!
          Иначе когда isCoinPriceSmall, то пропадает гориз. grid */}

          {/* Strategy*/}
          {displayCoinChartSecondYAxis(
            isFewData,
            formatStrategyTicks,
            strategyDomain,
            strategyTicks
          )}

          {/* Coin*/}
          {displayCoinChartMainYAxis(
            isFewData,
            formatCoinTicks,
            coinDomain,
            coinTicks,
            coinTickWidth,
            isAllowDecimals
          )}
          {displayCoinChartAreas(
            chartType,
            checkChartColor,
            setEndDotsShown,
            coinImgLink
          )}
          {displayChartTooltip(<CoinChartTooltip payload />)}
          {displayCoinChartSmardEndDot(main, isEndDotsShown)}
          {displayCoinChartCustomEndDot(main, isEndDotsShown, coinImgLink)}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
