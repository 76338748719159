import { showShortMonthInEn, showShortYear } from "../datetime";

export const formatDateIntoMonths = (ticks: number[], tick: number) => {
  if (tick === ticks[ticks.length - 1] || tick === ticks[0]) {
    return `${showShortMonthInEn(tick)}/${showShortYear(tick)}`;
  }
  return showShortMonthInEn(tick);
};

export const formatDateIntoMonthsLastTickYear = (
  ticks: number[],
  tick: number
) => {
  if (tick === ticks[ticks.length - 1]) {
    return `${showShortMonthInEn(tick)}/${showShortYear(tick)}`;
  }

  return showShortMonthInEn(tick);
};

export const formatCalcDateIntoMonths = (ticks: number[], tick: number) => {
  if (tick === ticks[ticks.length - 2]) {
    return `${showShortMonthInEn(tick)}/${showShortYear(tick)}`;
  }

  if (tick === ticks[ticks.length - 1]) {
    return "";
  }
  return showShortMonthInEn(tick);
};
