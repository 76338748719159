import { ReferenceDot } from "recharts";
import { ChartWithBenchmarkType } from "../../../types/chart";
import { BitcoinEndDot, CustomEndDot, EthereumEndDot } from "../dots/end-dots";

export function displayChartBenchEndDot(
  data: ChartWithBenchmarkType[],
  isBitcoin: boolean,
  isEndDotsShown: boolean
): JSX.Element {
  return (
    <ReferenceDot
      yAxisId="right"
      x={data[data.length - 1].timestamp}
      y={data[data.length - 1].benchmarkAmount}
      shape={
        isBitcoin ? (
          <BitcoinEndDot isEndDotsShown={isEndDotsShown} />
        ) : (
          <EthereumEndDot isEndDotsShown={isEndDotsShown} />
        )
      }
    />
  );
}

export function displayCoinChartCustomEndDot(
  data: ChartWithBenchmarkType[],
  isEndDotsShown: boolean,
  imgLink: string
): JSX.Element {
  return (
    <ReferenceDot
      yAxisId="left"
      x={data[data.length - 1].timestamp}
      y={data[data.length - 1].benchmarkAmount}
      shape={<CustomEndDot isEndDotsShown={isEndDotsShown} imgLink={imgLink} />}
    />
  );
}
