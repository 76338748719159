export default function ChainIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 18 19"
    >
      <g fill="currentColor">
        <path d="M16.8 1.23a4.06 4.06 0 0 0-5.83 0l-2.55 2.6 1.18 1.2 2.54-2.6a2.43 2.43 0 0 1 3.48 0c.96.99.96 2.58 0 3.57L11.8 9.9a2.42 2.42 0 0 1-3.48 0l-1.17 1.2a4.05 4.05 0 0 0 5.83 0l3.81-3.9a4.3 4.3 0 0 0 0-5.97Z" />
        <path d="M5.86 15.98c-.96.99-2.52.99-3.48 0a2.56 2.56 0 0 1 0-3.56l3.81-3.9a2.43 2.43 0 0 1 3.49 0l1.17-1.2a4.06 4.06 0 0 0-5.83 0l-3.81 3.9a4.3 4.3 0 0 0 0 5.97 4.06 4.06 0 0 0 5.82 0l2.55-2.6-1.18-1.2-2.54 2.6Z" />
      </g>
    </svg>
  );
}
