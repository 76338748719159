type FaqItemProps = {
  type: string;
  icon: JSX.Element;
  title: string;
  description: string | JSX.Element;
  extraClass?: string;
  extraHeadingClass?: string;
};

export default function PlusItem({
  type,
  icon,
  title,
  description,
  extraClass,
  extraHeadingClass,
}: FaqItemProps): JSX.Element {
  return (
    <li
      className={`pluses__item pluses__item--${type}
      ${extraClass ? `pluses__item--${extraClass}` : ""}`}
    >
      <h3
          className={`pluses__heading ${
              extraHeadingClass ? `pluses__heading--${extraHeadingClass}` : ""
          }`}
      >
        <span className={`pluses__icon pluses__icon--${type}`}>{icon}</span>
        {title}
      </h3>
      {description}
    </li>
  );
}
