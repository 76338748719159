import { useEffect } from "react";
import AppleLogin from "react-apple-login";

import AppleIcon from "../icons/socials/apple-icon";
import { useAppDispatch } from "../../hooks";
import { sendUserAppleInfo } from "../../store/api-actions/user";
import { AUTH_APPLE_CLIENT_ID, AUTH_APPLE_URI } from "../../const";

type LoginApplePersonalProps = {
  isOverview: boolean;
};

export default function LoginApplePersonal({
  isOverview,
}: LoginApplePersonalProps): JSX.Element {
  const dispatch = useAppDispatch();

  const appleLoginOnSuccess = (event: any) => {
    dispatch(
      sendUserAppleInfo({
        info: event.detail,
        isOverview: isOverview,
      })
    );
  };

  //   const appleLoginOnError = (event: any) => {};

  useEffect(() => {
    document.addEventListener("AppleIDSignInOnSuccess", appleLoginOnSuccess);

    return () => {
      document.removeEventListener(
        "AppleIDSignInOnSuccess",
        appleLoginOnSuccess
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <AppleLogin
      clientId={AUTH_APPLE_CLIENT_ID}
      redirectURI={AUTH_APPLE_URI}
      usePopup={true}
      scope="name email"
      state="web"
      render={(renderProps) => (
        <button
          className="personal__link personal__link--apple"
          type="button"
          onClick={() => {
            renderProps.onClick();
          }}
        >
          <p className="personal__icon-wrapper">
            <span className="personal__icon personal__icon--apple">
              <AppleIcon />
            </span>
          </p>

          <p className="personal__heading">Connect to Apple</p>
        </button>
      )}
    />
  );
}
