import { useEffect, useRef, useState } from "react";
import {
  AnimatePresence,
  motion,
  Reorder,
  useDragControls,
} from "framer-motion";

import CryptoForm from "../crypto-form/crypto-form";
import DragDotsIcon from "../icons/drag-dots-icon";
import PaymentsCard from "./payments-card";
import { AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchCrytoWallet } from "../../store/api-actions";
import {
  loadCryptoWallet,
  setCryptoFormChosen,
  setSendDepositInLastPaymentsStatus,
} from "../../store/actions";
import {
  CreditCardType,
  CryptoWalletType,
  DepositType,
} from "../../types/payment";
import Button from "../button/button";
import WalletIcon from "../../icons/payment/wallet-icon";

type PaymentsMethodType = {
  type: string;
  method: CreditCardType | DepositType;
  cardsLength: number;
  containerRef?: React.RefObject<HTMLLIElement>;
  isFirstValidCard: boolean;
};

export default function PaymentsMethod({
  type,
  method,
  cardsLength,
  containerRef,
  isFirstValidCard,
}: PaymentsMethodType): JSX.Element {
  const dispatch = useAppDispatch();

  const { authStatus, isCryptoFormChosen } = useAppSelector((state) => state);
  const cryptoWallet = useAppSelector(
    (state) => state.cryptoWallet as CryptoWalletType
  );

  // DeleteCardModal
  const depositBtnRef = useRef<HTMLButtonElement | null>(null);

  // CryptoForm
  const [isCryptoFormOpened, setCryptoFormOpened] = useState<boolean>(false);
  const [isAnimationLaunched, setAnimationLaunched] = useState<boolean>(false);

  const closeCryptoForm = () => {
    setCryptoFormOpened(false);

    if (authStatus === AuthStatus.Auth) {
      setAnimationLaunched(true);
    }
  };

  const handleCryptoFormOnClick = () => {
    setCryptoFormOpened(!isCryptoFormOpened);

    if (authStatus === AuthStatus.Auth) {
      setAnimationLaunched(true);
    }
  };

  const handleAnimationComplete = (definition: any) => {
    setAnimationLaunched(false);

    // условие с isCryptoFormOpened не работает
    if (definition.height === 0) {
      dispatch(setSendDepositInLastPaymentsStatus(null));
      dispatch(loadCryptoWallet(null));
    }
  };

  useEffect(() => {
    if (authStatus === AuthStatus.Auth && isCryptoFormOpened) {
      dispatch(fetchCrytoWallet());
    }
    // eslint-disable-next-line
  }, [isCryptoFormOpened]);

  useEffect(() => {
    if (isCryptoFormChosen) {
      depositBtnRef.current?.focus();
      dispatch(setCryptoFormChosen(false));

      if (!isCryptoFormOpened) {
        setCryptoFormOpened(true);
        setAnimationLaunched(true);
      }
    }
    // eslint-disable-next-line
  }, [isCryptoFormChosen]);

  // utils

  const controls = useDragControls();

  const renderDeposit = () => (
    <>
      <div
        className={`payments-method__row ${
          cardsLength > 1 ? "payments-method__row--with-cards" : ""
        }`}
      >
        <span className="payments-method__icon">
          <WalletIcon />
        </span>
        <span className="payments-method__note">SMARD wallet</span>
        <Button
          classElement={`payments-method__btn-deposit btn--disabled ${
            isAnimationLaunched ? "btn--disabled" : ""
          }`}
          pattern="green-white"
          text={isCryptoFormOpened ? "Hide" : "Add crypto"}
          icon={isCryptoFormOpened ? "arrow-up" : "plus-in-circle"}
          type="button"
          handleClick={handleCryptoFormOnClick}
          ref={depositBtnRef}
        ></Button>
      </div>

      <AnimatePresence>
        {authStatus === AuthStatus.Auth
          ? isCryptoFormOpened &&
            cryptoWallet && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                style={{ overflow: "hidden" }}
                transition={{ duration: 0.6 }}
                onAnimationComplete={(definition) =>
                  handleAnimationComplete(definition)
                }
              >
                <CryptoForm
                  closeCryptoForm={closeCryptoForm}
                  isAnimationLaunched={isAnimationLaunched}
                />
              </motion.div>
            )
          : isCryptoFormOpened && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                style={{ overflow: "hidden" }}
                transition={{ duration: 0.3 }}
              >
                <CryptoForm closeCryptoForm={closeCryptoForm} />
              </motion.div>
            )}
      </AnimatePresence>
    </>
  );

  return type === "deposit" ? (
    renderDeposit()
  ) : (
    <Reorder.Item
      className="payments-method"
      value={method}
      whileDrag={{
        boxShadow: "0px 1px 10px rgba(37, 99, 235, 0.15)",
      }}
      dragListener={false}
      dragControls={controls}
      dragConstraints={containerRef}
      dragElastic={0.01}
    >
      {cardsLength > 1 && (
        <button
          className="payments-method__handle"
          aria-label="Change order"
          onPointerDown={(event) => controls.start(event)}
        >
          <span className="payments-method__drag-icon">
            <DragDotsIcon />
          </span>
        </button>
      )}
      <PaymentsCard
        method={method as CreditCardType}
        cardsLength={cardsLength}
        isFirstValidCard={isFirstValidCard}
      />
    </Reorder.Item>
  );
}
