import Tooltip from "../tooltip/tooltip";
import { formatQuantity, localAmount } from "../../utils/amount";

type TableQuantityProps = {
  amount: number;
};

export default function TableQuantity({
  amount,
}: TableQuantityProps): JSX.Element {
  const checkedAmount = formatQuantity(amount).amount;
  const checkedShort = formatQuantity(amount).short;
  const isTooltip = formatQuantity(amount).isQuantityTooltip;

  return (
    <div className="table-quantity">
      <p className="table-quantity__amount">
        {checkedAmount === null
          ? checkedShort
          : // не выносить отдельно в переменную! localAmount(checkedAmount as number), тк checkedAmount может = null
            `${localAmount(checkedAmount as number)}${checkedShort}`}
      </p>
      {isTooltip && (
        <div className="table-quantity__tooltip">
          <Tooltip amount={localAmount(amount)} />
        </div>
      )}
    </div>
  );
}
