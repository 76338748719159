import { CoinsChartType } from "../../types/chart";
import { sortAmounts } from "./chart";

export const findOnlyNumbers = (data: (null | number)[]) =>
  data.filter((item) => item !== null) as number[];

export const convertBigInts = (data: (null | number | BigInt)[]) =>
  data.map((item) => {
    if (item !== null) {
      return Number(item);
    }
    return null;
  });

export const findMinMaxForCoinChart = (amounts: number[]) => {
  const sortedAmounts = [...amounts].sort(sortAmounts);
  const least = sortedAmounts[0];
  const biggest = sortedAmounts[sortedAmounts.length - 1];

  return [least * 0.9995, biggest * 1.0005];
};

export const formatDataToCoinsChart = (data: (null | number)[]) => {
  const formattedData: CoinsChartType[] = [];

  const firstAmountIndex = data.findIndex((item) => item !== null);
  const lastAmountIndex = data.findLastIndex((item) => item !== null);

  data.forEach((amount, index) => {
    if (
      amount === null &&
      index > firstAmountIndex &&
      index < lastAmountIndex
    ) {
      const lastAmount = formattedData[formattedData.length - 1].amount;
      const objWithAmongNulls = {
        timestamp: index,
        amount: lastAmount,
      };
      formattedData.push(objWithAmongNulls);
    } else {
      const obj = {
        timestamp: index,
        amount: amount,
      };

      formattedData.push(obj);
    }
  });

  //  if less days then in month for short chart
  if (data.length < 24) {
    for (let i = data.length; i <= 24; i++) {
      const obj = {
        timestamp: i,
        amount: null,
      };
      formattedData.push(obj);
    }
  }

  return formattedData;
};
