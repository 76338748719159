import BagWithMoneyIcon from "../../icons/blue/bag-with-money-icon";
import MoneyWithCoinsIcon from "../../icons/blue/money-with-coins-icon";
import PercentInCircleWithArrowUpIcon from "../../icons/blue/percent-in-circle-with-arrow-icon";
import WatchStopIcon from "../../icons/blue/stop-watch-icon";
import PlusItem from "./plus-item";

export default function SmardBestPluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">
        What makes SMARD the best crypto affiliate program?
      </h2>
      <ul className="pluses pluses--smard-best pluses--tablet-2-columns">
        <PlusItem
          type="rate"
          icon={<PercentInCircleWithArrowUpIcon />}
          title="High conversion rate"
          description={
            <p className="pluses__info">
              We offer a first-class trading automation solution that is
              available to traders and investors of all levels.
            </p>
          }
        />

        <PlusItem
          type="commissions"
          icon={<BagWithMoneyIcon />}
          title="Massive commissions"
          description={
            <p className="pluses__info">
              Our base commissions start from 30% and depend directly on the
              amount of capital referred by the affiliate. Even a few referrals
              with a large initial amount can generate stable passive income.
            </p>
          }
        />

        <PlusItem
          type="payouts"
          icon={<MoneyWithCoinsIcon />}
          title="Lifetime payouts"
          description={
            <p className="pluses__info">
              All SMARD algorithms are designed for long-term use. Clients use
              the service longer = the partner receives payments longer.
            </p>
          }
        />

        <PlusItem
          type="tracking"
          icon={<WatchStopIcon />}
          title="Real-time tracking"
          description={
            <p className="pluses__info">
              As a SMARD affiliate, you will have access to our affiliate
              dashboard. The dashboard provides real-time tracking, so you can
              see how many people are signing up and converting into active
              customers.
            </p>
          }
        />
      </ul>
    </section>
  );
}
