import { useEffect } from "react";
import { Link } from "react-router-dom";

import { PolicySeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { AppRoute, AuthStatus } from "../../const";
import { setFirstScreenRendered } from "../../store/actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteSeoData } from "../../utils/general";

export default function PolicyScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main policy">
      <PolicySeoSetting />
      <TrackTwitter />
      <h1 className="policy__heading">Privacy Policy</h1>
      <p className="policy__intro">
        This Privacy Policy describes how the Website{" "}
        <a
          className="link"
          href="https://smard.club"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://smard.club
        </a>{" "}
        and any Webpages thereof (collectively the "Website"), operated Smard
        LLC, registered under the laws of the United States of America, the
        state of Wyoming, collects and processes Your personal data. In this
        Privacy Policy "You" or "Your" refers to You personally and any other
        person who accesses on Your behalf, and/or on whose behalf You are
        accessing the Website. By accessing Our Website and using its services
        You confirm that You have read, understood and agreed to the terms
        contained in this Privacy Policy as amended from time to time.
      </p>
      <ul className="policy__list">
        {/* 1. What information We collect */}
        <li className="policy__item">
          <h2 className="policy__question">1. What information We collect</h2>

          <p className="policy__paragraph">
            1.1. During Your usage of the Website as described in Our{" "}
            <Link className="link" to={AppRoute.Terms}>
              Terms of Service
            </Link>{" "}
            we might collect some personal data which is limited to what You
            have provided during the registration process and the information
            You have submitted and provided through Your usage of the Website
            and its services.
          </p>
          <p className="policy__paragraph">
            1.2. We always make sure that all personal data We collect is
            adequate, relevant and limited to what is necessary in relation to
            the purposes for which it is collected and processed.
          </p>
          <p className="policy__paragraph">
            1.3. The information We collect includes:
          </p>
          <ul className="policy__sked">
            <li className="policy__sked-item">
              Your name and contact details;
            </li>
            <li className="policy__sked-item">
              Information on trading activity performed by Website’s trading
              enhancement tool;
            </li>
            <li className="policy__sked-item">
              Information and details on payment methods You use for Website
              services;
            </li>
            <li className="policy__sked-item">
              Cookies (including pixel tags, local storage and other tracking
              technologies).
            </li>
          </ul>
        </li>

        {/* 2. Why do We need this information? */}
        <li className="policy__item">
          <h2 className="policy__question">
            2. Why do We need this information?
          </h2>
          <p className="policy__paragraph">
            2.1. We need the information described above:
          </p>
          <ul className="policy__sked">
            <li className="policy__sked-item">
              To be able to identify You and provide You with access to the
              Website’s services;
            </li>
            <li className="policy__sked-item">To contact You;</li>
            <li className="policy__sked-item">
              To use it for Our analytics, direct marketing and market analysis
              as Well as to send You promotional materials;
            </li>
            <li className="policy__sked-item">
              To charge You for the usage of the Website’s services;
            </li>
            <li className="policy__sked-item">
              To comply with Our obligations under the applicable laws and
              regulations We are subject to.
            </li>
          </ul>
        </li>

        {/* 3. Cookies */}
        <li className="policy__item">
          <h2 className="policy__question">3. Cookies</h2>
          <p className="policy__paragraph">
            3.1. When You use and access the Website, We may store and use
            cookies, local storage, pixel tags and other tracking technologies
            to automatically collect and store the information on Your usage of
            the Website and its features. Third-party marketing cookies such as
            pixel tags are stored only on the main pages of the Website and not
            from Your personal account section. The only first-party cookies We
            collect from Your personal usage of the Website through Your
            personal account are:
          </p>
          <ul className="policy__table">
            <li className="policy__row">
              <p className="policy__cell">#</p>
              <p className="policy__cell">Name</p>
              <p className="policy__cell">Provider</p>
              <p className="policy__cell">Expiry</p>
              <p className="policy__cell">Purpose</p>
            </li>
            <li className="policy__row">
              <p className="policy__cell">1</p>
              <p className="policy__cell">Crsftoken</p>
              <p className="policy__cell">smard.club</p>
              <p className="policy__cell">Session</p>
              <p className="policy__cell">
                This cookie is associated with the Web development platform for
                Python. It is designed to help us to protect Our Website against
                a particular type of software attack on Web forms.
              </p>
            </li>
            <li className="policy__row">
              <p className="policy__cell">2</p>
              <p className="policy__cell">Session</p>
              <p className="policy__cell">Smard.club</p>
              <p className="policy__cell">Session</p>
              <p className="policy__cell">
                This cookie saves Your authentication data from providers such
                as Google and Telegram.
              </p>
            </li>
          </ul>
          <p className="policy__paragraph">3.2. This is done:</p>
          <ul className="policy__sked">
            <li className="policy__sked-item">
              To enhance and customize Your user experiences;
            </li>
            <li className="policy__sked-item">
              To perform Our analytics to measure the effectiveness of marketing
              strategies We use;
            </li>
            <li className="policy__sked-item">
              To mitigate risks and potential fraudulent activity.
            </li>
          </ul>
        </li>

        {/* 4. How long We will store it */}
        <li className="policy__item">
          <h2 className="policy__question">4. How long We will store it</h2>
          <p className="policy__paragraph">
            4.1. We will keep the information You provided as long as You use
            the Website and its services. You can always request us to delete
            all Your data by clicking "Delete my data" button in Your personal
            account on the Website.
          </p>
        </li>

        {/* 5. Our safeguarding measures */}
        <li className="policy__item">
          <h2 className="policy__question">5. Our safeguarding measures</h2>
          <p className="policy__paragraph">
            5.1. We take all reasonable and appropriate steps to protect Your
            Personal Data from misuse, loss or unauthorized disclosure. We are
            doing this by having a range of appropriate technical and
            organizational measures such as firewalls, data encryption and
            information access authorization controls.
          </p>
          <p className="policy__paragraph">
            5.2. We do Our best to safeguard the provided information, but We
            cannot guarantee that any misuse, unauthorized acquisition, loss or
            alteration of Your data will not occur and You agree to such risk by
            accessing the Website and using its services.
          </p>
        </li>

        {/* 6.	Necessary sharing  */}
        <li className="policy__item">
          <h2 className="policy__question">6. Necessary sharing</h2>
          <p className="policy__paragraph">
            6.1. We can share the information provided by You with Our
            affiliated parties and authorized persons, as Well as with third
            parties who are working with Our Website to enable us to provide its
            services to You. The sharing will be limited only to the data that
            is needed in order for You to access the Website and use its
            features.
          </p>
          <p className="policy__paragraph">
            6.2. Your information can be transferred to and stored in other
            countries anywhere in the world depending on Our business needs and
            these countries can have different laws and regulations on the
            protection of personal data. We will protect Your data as described
            in this Privacy Policy through these transfers.
          </p>
        </li>

        {/* 7. Rights You can exercise  */}
        <li className="policy__item">
          <h2 className="policy__question">7. Rights You can exercise</h2>
          <p className="policy__paragraph">
            7.1. You can always exercise the following rights in relation to
            information provided:
          </p>
          <ul className="policy__sked">
            <li className="policy__sked-item">
              The right to object the processing of personal data;
            </li>
            <li className="policy__sked-item">
              The right to restrict processing of personal data;
            </li>
            <li className="policy__sked-item">
              The right of access to Your personal data We have;
            </li>

            <li className="policy__sked-item">
              The right to request a correction of the wrong personal data;
            </li>
            <li className="policy__sked-item">
              The right for data portability;
            </li>
            <li className="policy__sked-item">
              The right to required us to delete Your personal data.
            </li>
          </ul>

          <p className="policy__note">
            The provision of services of the Website can be limited or suspended
            if You choose any of the rights above (i.e., if You will request us
            to erase Your personal data We will not be able to provide You with
            any services).
          </p>

          <p className="policy__paragraph">
            7.2. Please feel free to exercise the above rights by sending an
            email to{" "}
            <a className="link" href="mailto:info@smard.club">
              info@smard.club
            </a>
            . You also have a right to lodge a complaint regarding the
            processing of Your personal data by sending a complaint to the same
            email, and Our officer in charge will investigate the matter.
          </p>
        </li>

        {/* 8.	Personal Data breach notification  */}
        <li className="policy__item">
          <h2 className="policy__question">
            8. Personal Data breach notification
          </h2>
          <p className="policy__paragraph">
            8.1. In case We become aware of Personal Data breach We will,
            without undue delay, notify You of such breach and provide the
            following information:
          </p>
          <ul className="policy__sked">
            <li className="policy__sked-item">
              What information was affected;
            </li>
            <li className="policy__sked-item">What kind of breach is it;</li>
            <li className="policy__sked-item">
              What We will do in order to solve such issue;
            </li>
          </ul>
          <p className="policy__paragraph">
            8.2. You should inform us immediately by sending an email to{" "}
            <a className="link" href="mailto:info@smard.club">
              info@smard.club
            </a>{" "}
            of any possible damage or loss such breach may cause to You so We
            can cooperate together to solve this matter.
          </p>
        </li>

        {/* 9. Amendments  */}
        <li className="policy__item">
          <h2 className="policy__question">9. Amendments</h2>
          <p className="policy__paragraph">
            9.1. We reserve the right to unilaterally amend and modify this
            Privacy Policy from time to time by posting an updated version on
            Our Website without any specific notice served to You to that effect
            in advance. You are solely responsible for regular assessment of the
            Website on any updated Privacy Policy. Your usage of the Website and
            its services after publication of any updated Privacy Policy on the
            Website constitutes Your acceptance of these updated terms. If You
            do not agree to the updated Privacy Policy, You can terminate Your
            usage of the Website and services available therein.
          </p>
        </li>
      </ul>
    </main>
  );
}
