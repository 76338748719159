import BlueBigGearsIcon from "../icons/blue-icon/blue-big-gears-icon";
import BlueCrossedDollarIcon from "../icons/blue-icon/blue-crossed-dollar-icon";
import BlueDollarIcon from "../icons/blue-icon/blue-dollar";
import BlueLockWithCrossIcon from "../icons/blue-icon/blue-lock-with-cross-icon";
import PlusItem from "./plus-item";

export default function GeneralExchangePluses(): JSX.Element {
  return (
    <section className="landing__item">
      <h2 className="landing__title">Why SMARD?</h2>
      <ul className="pluses pluses--why-works pluses--tablet-2-columns">
        <PlusItem
          type="automated"
          icon={<BlueBigGearsIcon />}
          title="Fully automated"
          description={
            <p className="pluses__info">
              Trading algorithms are based on fundamental scientific research
              and incorporate all principles of portfolio management and risk
              control.
            </p>
          }
        />
        <PlusItem
          type="transfer"
          icon={<BlueCrossedDollarIcon />}
          title="No fund transfer"
          description={
            <p className="pluses__info">
              Connect in a few clicks via fast API without access to
              withdrawals.
            </p>
          }
        />
        <PlusItem
          type="long"
          icon={<BlueDollarIcon />}
          title="Win-win pricing"
          description={
            <p className="pluses__info">
              Get access to our trading software for just $1 per month, and pay
              10% of your profits only after SMARD earns for you.
            </p>
          }
        />
        <PlusItem
          type="lock"
          icon={<BlueLockWithCrossIcon />}
          title="No lock up"
          description={
            <p className="pluses__info">
              All funds remain under your full control on the exchange account.
              You can stop using SMARD anytime you want. There are no withdrawal
              limitations or minimal constraints to use the service.
            </p>
          }
        />
      </ul>
    </section>
  );
}
