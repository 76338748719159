import { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

import TooltipError from "../tooltip-error/tooltip-error";
import Message from "../message/message";
import Button from "../button/button";
import CardCheckedIcon from "../../icons/payment/card-checked-icon";
import { fetchAddedCard } from "../../store/api-actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  setAddCardLoading,
  setSendAddedCardStatus,
  setCardFormChosen,
} from "../../store/actions";

type CardFormType = {
  cardsLength: number;
};

export default function CardForm({ cardsLength }: CardFormType): JSX.Element {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const {
    isAddedCardLoading,
    isCardFormChosen,
    addedCardStatus,
    clientSecret,
  } = useAppSelector((state) => state);

  const submitRef = useRef<null | HTMLButtonElement>(null);

  const [numberError, setNumberError] = useState<null | string>(null);
  const [expiryError, setExpiryError] = useState<null | string>(null);
  const [cvcError, setCvcError] = useState<null | string>(null);

  const [isNumberValid, setNumberValid] = useState(false);
  const [isExpiryValid, setExpiryValid] = useState(false);
  const [isCvcValid, setCvcValid] = useState(false);

  const [submitStripeError, setSubmitStripeError] = useState<null | string>(
    null
  );

  const cardInputStyle = {
    style: {
      base: {
        color: "#555555",
        fontFamily: "Open Sans, Arial, sans-serif",
        fontWeight: 100,
        fontSize: "12px",
        "::placeholder": {
          color: "#555555",
        },
        // ":focus": {
        //   doesn`t work with outline border-color box-shadow
        // },
      },
      invalid: {
        fontFamily: "Open Sans, Arial, sans-serif",
        color: "#fa755a",
      },
    },
  };

  const handleSubmit = async (evt: SyntheticEvent) => {
    evt.preventDefault();
    dispatch(setAddCardLoading(true));

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardNumberElement);

    if (card === null) {
      return;
    }

    const { error, setupIntent } = await stripe.confirmCardSetup(
      clientSecret as string,
      {
        payment_method: {
          card,
        },
      }
    );

    if (error?.message) {
      setSubmitStripeError(error.message);
      dispatch(setAddCardLoading(false));
    } else {
      dispatch(fetchAddedCard(setupIntent));
    }
  };

  const validField = (
    evt:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
    setError: (arg0: string | null) => void,
    setValid: (arg0: boolean) => void
  ) => {
    if (evt.error) {
      setError(evt.error.message);
      setValid(false);
    } else {
      setError(null);
    }

    if (evt.complete) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  const changeFocusIfCompleted = (
    evt:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    if (!stripe || !elements) {
      return;
    }

    const number = elements.getElement(CardNumberElement);
    const expiry = elements.getElement(CardExpiryElement);
    const cvc = elements.getElement(CardCvcElement);

    if (number === null || expiry === null || cvc === null) {
      return;
    }

    if (evt.complete) {
      if (evt.elementType !== "cardNumber" && !isNumberValid) {
        number.focus();
        return;
      }
      if (evt.elementType !== "cardExpiry" && !isExpiryValid) {
        expiry.focus();
        return;
      }
      if (evt.elementType !== "cardCvc" && !isCvcValid) {
        cvc.focus();
        return;
      }
    }
  };

  const deleteError = () => {
    if (addedCardStatus && addedCardStatus.message) {
      dispatch(setSendAddedCardStatus(null));
    }
    if (submitStripeError) {
      setSubmitStripeError(null);
    }
  };

  const handleNumberChange = (evt: StripeCardNumberElementChangeEvent) => {
    validField(evt, setNumberError, setNumberValid);
    deleteError();
    changeFocusIfCompleted(evt);
  };

  const handleExpiryChange = (evt: StripeCardExpiryElementChangeEvent) => {
    validField(evt, setExpiryError, setExpiryValid);
    deleteError();
    changeFocusIfCompleted(evt);
  };

  const handleCvcChange = (evt: StripeCardCvcElementChangeEvent) => {
    validField(evt, setCvcError, setCvcValid);
    deleteError();
    changeFocusIfCompleted(evt);
  };

  useEffect(() => {
    const resetForm = () => {
      if (!stripe || !elements) {
        return;
      }

      const number = elements.getElement(CardNumberElement);
      const expiry = elements.getElement(CardExpiryElement);
      const cvc = elements.getElement(CardCvcElement);

      if (number === null || expiry === null || cvc === null) {
        return;
      }

      number.clear();
      expiry.clear();
      cvc.clear();
    };

    if (addedCardStatus && addedCardStatus.isSucceed) {
      resetForm();
    }
  }, [addedCardStatus, elements, stripe]);

  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }
    const number = elements.getElement(CardNumberElement);
    if (number === null) {
      return;
    }

    if (isCardFormChosen) {
      number.focus();
      dispatch(setCardFormChosen(false));
    }

    // eslint-disable-next-line
  }, [elements, isCardFormChosen, stripe]);

  if (isNumberValid && isExpiryValid && isCvcValid && submitRef.current) {
    submitRef.current.focus();
  }

  return (
    <form className="card-form">
      <div
        className={`card-form__wrapper ${
          cardsLength > 1 ? "card-form__wrapper--with-cards" : ""
        }`}
      >
        <span className="card-form__icon">
          <CardCheckedIcon />
        </span>
        <div className="card-form__input-wrapper card-form__input-wrapper--number">
          <CardNumberElement
            className={`card-form__input card-form__input--number ${
              numberError ? "card-form__input--invalid" : ""
            }`}
            options={{ ...cardInputStyle, placeholder: "Number" }}
            onChange={handleNumberChange}
          />
          {numberError && (
            <TooltipError
              type={"card-form__input-error"}
              message={numberError}
            />
          )}
        </div>

        <div className="card-form__fields-wrapper">
          <div className="card-form__input-wrapper card-form__input-wrapper--expiry">
            <CardExpiryElement
              className={`card-form__input card-form__input--expiry ${
                expiryError ? "card-form__input--invalid" : ""
              }`}
              options={cardInputStyle}
              onChange={handleExpiryChange}
            />
            {expiryError && (
              <TooltipError
                type="card-form__input-error"
                message={expiryError}
              />
            )}
          </div>
          <div className="card-form__input-wrapper card-form__input-wrapper--cvc">
            <CardCvcElement
              className={`card-form__input card-form__input--cvc ${
                cvcError ? "card-form__input--invalid" : ""
              }`}
              options={cardInputStyle}
              onChange={handleCvcChange}
            />
            {cvcError && (
              <TooltipError
                type={"card-form__input-error"}
                message={cvcError}
              />
            )}
          </div>
        </div>

        <Button
          classElement={`card-form__btn-add-card ${
            isNumberValid && isExpiryValid && isCvcValid && !isAddedCardLoading
              ? ""
              : "btn--disabled"
          }`}
          pattern="green-white"
          text="Add card"
          icon="credit-card"
          type="submit"
          handleClick={handleSubmit}
          ref={submitRef}
        />
      </div>

      {/* Error */}
      {submitStripeError && (
        <Message
          classElement="card-form__message"
          status="error"
          message={submitStripeError}
        />
      )}

      {addedCardStatus && !addedCardStatus.isSucceed && (
        <Message
          classElement="card-form__message"
          status="error"
          message={addedCardStatus.message}
        />
      )}
    </form>
  );
}
