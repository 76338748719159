import CardCheckedIcon from "../../icons/payment/card-checked-icon";
import Button from "../button/button";

type FakeCardFormType = {
  cardsLength: number;
};

export default function FakeCardForm({
  cardsLength,
}: FakeCardFormType): JSX.Element {
  return (
    <form
      className={`fake-card-form ${
        cardsLength > 1 ? "fake-card-form--with-cards" : ""
      }`}
    >
      <span className="fake-card-form__icon">
        <CardCheckedIcon />
      </span>
      <input
        className="fake-card-form__input fake-card-form__input--number"
        placeholder="Number"
      />

      <div className="fake-card-form__fields-wrapper">
        <input
          className="fake-card-form__input fake-card-form__input--expiry"
          placeholder="MM / YY"
        />

        <input
          className="fake-card-form__input fake-card-form__input--cvc"
          placeholder="CVC"
        />
      </div>

      <Button
        classElement="fake-card-form__btn-add-card"
        pattern="green-white"
        text="Add card"
        icon="credit-card"
        type="submit"
      />
    </form>
  );
}
