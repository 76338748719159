import { Dispatch, SetStateAction } from "react";
import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { useAppDispatch } from "../../../hooks";
import { ResponseStatusType } from "../../../types/details";
import { setAddKeysStatus } from "../../../store/actions/keys";
import { addKeysBinanceFutures } from "../../../store/api-actions/keys";
import { AccountName, KEY_LENGTH } from "../../../const";

type BinanceFuturesStep2FieldsProps = {
  register: UseFormRegister<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  addKeysStatus: null | ResponseStatusType;
  lengthFieldError: string;
  setLengthFieldError: Dispatch<SetStateAction<string>>;
};

export default function BinanceFuturesStep2Fields({
  register,
  getValues,
  addKeysStatus,
  lengthFieldError,
  setLengthFieldError,
}: BinanceFuturesStep2FieldsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const isAddKeysSuccess = addKeysStatus?.isSuccess;
  const keyDisabledClass = isAddKeysSuccess ? "field--disabled" : "";
  const isFieldsValid = (keyLength: number, secretLength: number) =>
    // ПЕРЕМЕННЫЕ ДЛЯ BINANCE
    keyLength === KEY_LENGTH &&
    secretLength === KEY_LENGTH;

  // handleKeyChange
  const handleKeyChange = () => {
    const data = getValues(["key", "secret"]);
    const [key, secret] = data;

    if (addKeysStatus) {
      dispatch(setAddKeysStatus(null));
    }

    if (isFieldsValid(key.length, secret.length)) {
      if (lengthFieldError) {
        setLengthFieldError("");
      }

      setTimeout(() => {
        dispatch(
          addKeysBinanceFutures({
            id: AccountName.BinanceFutures,
            key,
            secret,
          })
        );
      }, 500);
      return;
    }

    if (key && secret) {
      if (key.length !== KEY_LENGTH) {
        setLengthFieldError("API key");
        return;
      }

      if (secret.length !== KEY_LENGTH) {
        setLengthFieldError("secret key");
        return;
      }
    }

    if (lengthFieldError) {
      setLengthFieldError("");
    }
  };

  return (
    <div className="connect-step__fields-inner-wrapper connect-step__fields-inner-wrapper--triple">
      {/* api-key */}
      <label className="visually-hidden" htmlFor="api-key">
        Api key
      </label>
      <input
        className={`connect-step__field connect-step__field--triple field
            ${keyDisabledClass}`}
        type="text"
        id="api-key"
        placeholder="API Key"
        autoComplete="off"
        {...register("key", { required: true, onChange: handleKeyChange })}
      />

      {/* secret-key */}
      <label className="visually-hidden" htmlFor="secret-key">
        API Secret
      </label>
      <input
        className={`connect-step__field connect-step__field--triple field
            ${keyDisabledClass}`}
        type="text"
        id="secret-key"
        placeholder="API Secret"
        autoComplete="off"
        {...register("secret", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
    </div>
  );
}
