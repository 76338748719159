export default function DollarInCircleIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8 14.5a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M7.58 12.8v-1.18c-.5-.01-.96-.05-1.38-.13a5.57 5.57 0 0 1-1.07-.3V9.95a6.77 6.77 0 0 0 2.45.56V8.37a8.02 8.02 0 0 1-1.42-.59c-.36-.2-.62-.43-.79-.7-.17-.27-.25-.6-.25-.98 0-.39.1-.72.3-1 .21-.28.5-.5.87-.67.37-.17.8-.26 1.29-.3V3.2h.77v.92a6.36 6.36 0 0 1 2.31.48l-.43 1.07a6.73 6.73 0 0 0-1.88-.43v2.1c.49.15.92.31 1.3.49.39.18.69.4.9.67.22.26.33.6.33 1.03 0 .57-.22 1.03-.65 1.4-.43.36-1.05.58-1.88.65v1.22h-.77Zm.77-2.32c.39-.05.67-.15.86-.3a.7.7 0 0 0 .28-.57c0-.16-.04-.3-.12-.4a.98.98 0 0 0-.36-.3 3.77 3.77 0 0 0-.66-.27v1.84Zm-.77-3.42v-1.8c-.24.02-.43.07-.6.14a.84.84 0 0 0-.35.27.66.66 0 0 0-.12.4c0 .16.04.3.1.42a.9.9 0 0 0 .35.31c.16.1.36.17.62.26Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.42 3.05H8.5v.92a6.37 6.37 0 0 1 2.22.5l.14.05-.55 1.34-.14-.05a6.58 6.58 0 0 0-1.67-.4v1.82c.45.14.86.3 1.22.46.4.19.71.42.95.71.24.3.36.68.36 1.13 0 .61-.23 1.12-.7 1.51-.44.37-1.05.6-1.83.68v1.23H7.42v-1.19a8.33 8.33 0 0 1-1.24-.12c-.43-.08-.8-.19-1.11-.32l-.1-.04V9.73l.22.1a6.62 6.62 0 0 0 2.23.53V8.48a7.9 7.9 0 0 1-1.33-.56 2.3 2.3 0 0 1-.85-.76c-.18-.3-.27-.66-.27-1.06 0-.41.11-.78.33-1.09.23-.3.54-.55.93-.72a3.8 3.8 0 0 1 1.2-.3v-.94Zm.3.3v.93h-.14a3.6 3.6 0 0 0-1.23.28c-.35.16-.61.37-.8.63a1.5 1.5 0 0 0-.28.91c0 .36.08.66.23.9a2 2 0 0 0 .74.65c.35.2.8.4 1.38.58l.1.03v2.42h-.15a6.63 6.63 0 0 1-2.3-.5v.9c.28.1.6.2.96.26.41.08.86.12 1.35.13h.14v1.18h.48v-1.2l.14-.02c.8-.07 1.4-.28 1.8-.62.4-.33.59-.76.59-1.28 0-.4-.1-.7-.3-.94-.2-.25-.47-.46-.84-.62-.37-.18-.8-.34-1.28-.49l-.1-.03V5.07l.16.02a7.31 7.31 0 0 1 1.77.39l.33-.8a6.21 6.21 0 0 0-2.12-.41H8.2v-.92h-.47Zm0 1.74v2.17l-.2-.06a3.66 3.66 0 0 1-.63-.27c-.18-.1-.31-.21-.4-.36a.96.96 0 0 1-.13-.5.8.8 0 0 1 .15-.5c.1-.13.24-.23.42-.31.18-.08.39-.13.63-.15l.17-.02Zm-.3.34c-.14.02-.27.06-.37.1a.69.69 0 0 0-.3.22.51.51 0 0 0-.1.31c0 .15.04.26.1.36.05.09.14.17.28.25.1.06.24.12.4.18V5.43Zm.78 3.01.2.06c.27.09.5.18.68.27.18.1.32.2.41.35.1.14.15.3.15.49 0 .28-.11.52-.34.7-.22.17-.53.27-.93.31l-.17.02v-2.2Zm.3.41v1.45c.28-.05.49-.13.62-.23a.56.56 0 0 0 .22-.46.53.53 0 0 0-.09-.32.84.84 0 0 0-.31-.25 3.05 3.05 0 0 0-.44-.19Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
