export const renderSmardDot = (xPosition: number, yPosition: number) => (
  <svg x={xPosition - 15} y={yPosition - 15} width="30" height="30" fill="none">
    <g filter="url(#smardDot)">
      <circle cx="15" cy="15" r="10" fill="#EFF3FB" />
      <path
        fill="#F93332"
        d="m11.68 17.18-.24.41-.73 1.23-2.21-3.75h1.93l1.25 2.11Z"
      />
      <path
        fill="#1A1A1A"
        d="m16.3 9-.7 1.25-.23.42H12.5l-1.9 3.4-.24.42H8.5L11.57 9h4.73ZM21.5 14.84l-3.32 5.72H13.4l.7-1.22.24-.4h2.89l2.38-4.1-1.44-2.49.23-.4.7-1.22 2.39 4.1Z"
      />
      <path
        fill="#32E532"
        d="m18.61 9.82-.93 1.63-4.26 7.47-.46.82-.47.82h-.93l-.46-.82.93-1.63 4.26-7.48.93-1.63h.93l.46.82Z"
      />
    </g>
    <filter id="smardDot">
      <feDropShadow
        dx="0"
        dy="0"
        stdDeviation="2"
        floodColor="blue"
        floodOpacity="0.2"
      />
    </filter>
  </svg>
);

export const renderBitcoinDot = (xPosition: number, yPosition: number) => (
  <svg x={xPosition - 15} y={yPosition - 15} width="30" height="30" fill="none">
    <g filter="url(#bitcoinDot)">
      <path fill="#FFC107" d="M25 15a10 10 0 1 1-20 0 10 10 0 0 1 20 0Z" />
      <path
        fill="#FFF8E1"
        d="M11.4 20.2v-9.6h4.1c1.2 0 2.12.22 2.74.67.62.45.93 1.1.93 1.96 0 .47-.12.89-.37 1.24-.25.36-.6.62-1.04.8.5.12.9.36 1.2.74.3.37.44.83.44 1.37 0 .92-.3 1.62-.91 2.1-.61.47-1.48.71-2.6.72H11.4Zm2-5.76h2.18c1.03-.02 1.55-.4 1.55-1.17 0-.44-.13-.75-.4-.94-.25-.2-.67-.29-1.23-.29h-2.1v2.4Zm0 1.44v2.88h2.42c.48 0 1.58-.23 1.58-1.25 0-1.03-.56-1.62-1.5-1.63h-2.5Z"
      />
      <path
        fill="#FFF8E1"
        d="M13 9h1.5v2.46H13V9Zm2.5 0H17v2.46h-1.5V9ZM13 19.34h1.5v2.46H13v-2.46Zm2.5 0H17v2.46h-1.5v-2.46Z"
      />
    </g>
    <filter id="bitcoinDot">
      <feDropShadow
        dx="0"
        dy="0"
        stdDeviation="2"
        floodColor="brown"
        floodOpacity="0.3"
      />
    </filter>
  </svg>
);

export const renderEthereumDot = (xPosition: number, yPosition: number) => (
  <svg x={xPosition - 15} y={yPosition - 15} width="30" height="30" fill="none">
    <g filter="url(#ethereumDot)">
      <circle cx="15" cy="15" r="10" fill="#D9DBEE" />
      <path
        fill="#7880E7"
        d="M9.95 15.03 15 7l5.05 8.03L15 17.95l-5.05-2.92Z"
      />
      <path fill="#5C64C7" d="m15 7 5.05 8.03L15 17.95V7Z" />
      <path
        fill="#7880E7"
        d="M9.95 16.26 15 19.1l5.05-2.84L15 23l-5.05-6.74Z"
      />
      <path
        fill="#5C64C7"
        d="m15 19.03 5.05-2.89L15 23v-3.97Zm-5.05-3.97L15 12.89l5.05 2.17L15 17.95l-5.05-2.89Z"
      />
      <path fill="#2A3192" d="m15 12.9 5.05 2.16L15 17.95v-5.06Z" />
    </g>{" "}
    <filter id="ethereumDot">
      <feDropShadow
        dx="0"
        dy="0"
        stdDeviation="2"
        floodColor="blue"
        floodOpacity="0.2"
      />
    </filter>
  </svg>
);

export const renderOkxDot = (xPosition: number, yPosition: number) => (
  <svg
    x={xPosition - 15}
    y={yPosition - 15}
    width="30"
    height="30"
    fill="black"
  >
    <g filter="url(#okxDot)">
      <circle cx="15" cy="15" r="10" fill="#EFF3FB" />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="14"
        fill="none"
        viewBox="0 0 13 14"
        x={8.3}
        y={8.3}
      >
        <path
          fill="#000"
          d="M8.38 4.83H4.63a.29.29 0 0 0-.3.3v3.75c0 .16.14.29.3.29h3.75c.16 0 .29-.13.29-.3V5.13a.3.3 0 0 0-.29-.29ZM4.04.5H.3A.29.29 0 0 0 0 .79v3.75c0 .16.13.3.29.3h3.75c.16 0 .3-.14.3-.3V.8a.29.29 0 0 0-.3-.29ZM12.71.5H8.96a.29.29 0 0 0-.3.29v3.75c0 .16.14.3.3.3h3.75c.16 0 .29-.14.29-.3V.8a.29.29 0 0 0-.29-.29ZM4.04 9.17H.3a.29.29 0 0 0-.29.29v3.75c0 .16.13.29.29.29h3.75c.16 0 .3-.13.3-.29V9.46a.29.29 0 0 0-.3-.3ZM12.71 9.17H8.96a.29.29 0 0 0-.3.29v3.75c0 .16.14.29.3.29h3.75c.16 0 .29-.13.29-.29V9.46a.29.29 0 0 0-.29-.3Z"
        />
      </svg>
    </g>

    <filter id="okxDot">
      <feDropShadow
        dx="0"
        dy="0"
        stdDeviation="2"
        floodColor="gray"
        floodOpacity="0.6"
      />
    </filter>
  </svg>
);

export const renderCustomDot = (
  xPosition: number,
  yPosition: number,
  imgLink: string
) => (
  <svg
    x={xPosition - 15}
    y={yPosition - 15}
    width="30"
    height="30"
    fill="black"
  >
    <g filter="url(#customDot)">
      <circle cx="15" cy="15" r="10" fill="#EFF3FB" />
      <image href={imgLink} width="20" height="20" x={5} y={5} />
    </g>

    <filter id="customDot">
      <feDropShadow
        dx="0"
        dy="0"
        stdDeviation="2"
        floodColor="gray"
        floodOpacity="0.6"
      />
    </filter>
  </svg>
);
