import { useRef, useEffect } from "react";
import { useAppSelector } from "../../hooks";
import ChangeBalanceForm from "./change-balance-form";

function AnimatedChangeBalanceForm() {
  const { isChangeBalanceFormOpened, changeAmountStatus, useAllAmountStatus } =
    useAppSelector((state) => state);

  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (!contentElement) return;

    const recalculateHeight = () => {
      const contentHeight = contentElement.scrollHeight;
      contentElement.style.maxHeight = isChangeBalanceFormOpened
        ? `${contentHeight}px`
        : "0";
    };

    recalculateHeight();

    const observer = new MutationObserver(recalculateHeight);
    observer.observe(contentElement, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [isChangeBalanceFormOpened, changeAmountStatus, useAllAmountStatus]);

  return (
    <div>
      <div
        ref={contentRef}
        className={`details__change-balance-animation ${
          isChangeBalanceFormOpened ? "open" : "closed"
        }`}
      >
        {isChangeBalanceFormOpened ? (
          <ChangeBalanceForm />
        ) : (
          <div style={{ height: "160px" }} />
        )}
      </div>
    </div>
  );
}

export default AnimatedChangeBalanceForm;
