import CoinStartegyToggles from "../toggles/coin-strategy-toggles";
import SmardIcon from "../icons/coins/smard-icon";
import CoinChart from "../charts/coin-chart";
import CoinPeriodToggles from "../toggles/coin-period-toggles";
import { CoinType } from "../../types/coins";
import { ChartType } from "../../types/chart";
import { useAppSelector } from "../../hooks";
import {
  checkPercentClass,
  countChartPercent,
  displayArrow,
  displayPercent,
} from "../../utils/component/coin-canvas";

type CoinCanvasProps = {
  coin: CoinType;
};

export default function CoinCanvas({ coin }: CoinCanvasProps): JSX.Element {
  const { coinToggles } = useAppSelector((state) => state);

  const isMonth = coinToggles.isMonth;
  const isBinanceSpot = coinToggles.isBinanceSpot;

  const coinData = coin.quotes?.coin as ChartType[];
  const spotData = coin.quotes?.spot as ChartType[];
  const futuresData = coin.quotes?.futures as ChartType[];
  const strategyData = isBinanceSpot ? spotData : futuresData;

  const checkPeriod = (data: ChartType[]) =>
    isMonth ? data.slice(-32) : data.slice(-365);
  const checkedStrategyData = checkPeriod(strategyData);
  const checkedCoinData = checkPeriod(coinData);

  const strategyPercent = countChartPercent(checkedStrategyData);
  const coinPercent = countChartPercent(checkedCoinData);

  return (
    <section className="coin__item coin-canvas">
      <h2 className="visually-hidden">Coin chart</h2>

      <div className="coin-canvas__top">
        <div className="coin-canvas__info">
          <p className="coin-canvas__info-item">
            <img
              className="coin-canvas__info-coin"
              width={18}
              height={18}
              src={coin.logo_url}
              alt={`${coin.title} logo`}
            />
            <span
              className={`coin-canvas__info-percent ${checkPercentClass(
                coinPercent
              )}`}
            >
              {displayArrow(coinPercent)}
              {displayPercent(coinPercent)}
            </span>
          </p>

          <p className="coin-canvas__info-item">
            <span className="coin-canvas__info-coin">
              <SmardIcon />
            </span>

            <span
              className={`coin-canvas__info-percent ${checkPercentClass(
                strategyPercent
              )}`}
            >
              {displayArrow(strategyPercent)}
              {displayPercent(strategyPercent)}
            </span>
          </p>
        </div>
        <CoinStartegyToggles />
      </div>

      {coin.quotes && (
        <CoinChart
          strategyData={checkedStrategyData}
          coinData={checkedCoinData}
          coinImgLink={coin.logo_url}
          isMonth={isMonth}
        />
      )}

      <div className="coin-canvas__toggles-wrapper">
        <CoinPeriodToggles />
      </div>
    </section>
  );
}
