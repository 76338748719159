import SkeletonChartIcon from "../icons/skeleton-chart-icon";

import { useAppSelector } from "../../hooks";
import { StrategyDetailsType } from "../../types/details";
import { formatTicks } from "../../utils/chart/chart";

export default function EmptyChartBlock(): JSX.Element {
  const strategyDetails = useAppSelector(
    (state) => state.strategyDetails as StrategyDetailsType
  );
  const startAmount = strategyDetails?.overview.under_management as number;

  return (
    <div className="chart chart--empty">
      <div className="chart__block">
        <span className="chart__start">{formatTicks(startAmount)}</span>
        <SkeletonChartIcon isForEmptyChart />
        <p className="chart__info">
          <b>Thank you for your trust!</b>
          SMARD will start the trading activity on your account soon. You will
          see your trading results&nbsp;here.
        </p>
      </div>
    </div>
  );
}
