import Tooltip from "../tooltip/tooltip";
import { formatAmount, localAmount } from "../../utils/amount";

type TableAbsoluteProps = {
  amount: number;
};

export default function TableAbsolute({
  amount,
}: TableAbsoluteProps): JSX.Element {
  const checkedAmount = formatAmount(amount).amount;
  const checkedShort = formatAmount(amount).short;
  const localedAmount = localAmount(checkedAmount as number);
  const isTooltip = formatAmount(amount).isAmountTooltip;

  return (
    <div className="table-absolute">
      <p className="table-absolute__amount">
        {checkedAmount === null
          ? checkedShort
          : `${localedAmount}${checkedShort}`}
      </p>
      {isTooltip && (
        <div className="table-absolute__tooltip">
          <Tooltip amount={localAmount(amount)} />
        </div>
      )}
    </div>
  );
}
