import { NavLink } from "react-router-dom";
import { AppRoute, AuthStatus } from "../../../const";
import { useAppSelector } from "../../../hooks";

type NavExtraProps = {
  type: string;
};

export default function NavExtraLanding({ type }: NavExtraProps): JSX.Element {
  const { authStatus } = useAppSelector((state) => state);

  const isNoAuth = authStatus === AuthStatus.NoAuth;

  return (
    <ul className={`nav-extra-landing ${type}`}>
      <li className="nav-extra-landing__item">
        <NavLink className="nav-extra-landing__link" to={AppRoute.Pricing}>
          Win-win pricing
        </NavLink>
      </li>
      <li className="nav-extra-landing__item">
        <NavLink className="nav-extra-landing__link" to={AppRoute.Faq}>
          FAQ
        </NavLink>
      </li>
      <li className="nav-extra-landing__item">
        <NavLink className="nav-extra-landing__link" to={AppRoute.Overview}>
          {isNoAuth ? "Demo" : "Personal area"}
        </NavLink>
      </li>
    </ul>
  );
}
