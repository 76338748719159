import ShortHeaderMain from "./short-header-main";
import ShortHeaderExtra from "./short-header-extra";

export default function ShortHeader(): JSX.Element {
  return (
    <header className="short-header">
      <ShortHeaderMain />
      <ShortHeaderExtra />
    </header>
  );
}
