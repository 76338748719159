export default function ArrowRightIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M0 7a6.5 6.5 0 1 1 13 0A6.5 6.5 0 0 1 0 7Zm1.3 0a5.2 5.2 0 1 0 10.4 0A5.2 5.2 0 0 0 1.3 7Zm1.95-.65a.65.65 0 0 0 0 1.3h4.93l-.84.84-.05.06a.65.65 0 0 0 .97.86l1.95-1.95.05-.05.04-.07.04-.06.03-.08.02-.07v-.08L10.4 7v-.08l-.02-.1-.03-.06-.03-.07-.05-.08-.03-.04-.03-.03-1.95-1.95-.06-.05a.65.65 0 0 0-.86.05l-.05.06-.05.07a.65.65 0 0 0 .1.79l.84.84H3.25Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
