import DetailsLink from "../elements/details-link";
import Button from "../../button/button";
import DetailsBtnsWithRegister from "../elements/details-btns-with-register";
import { setIntegrationOpened } from "../../../store/actions";
import { useAppDispatch } from "../../../hooks";
import { AccountName, AuthStatus } from "../../../const";
import { StrategyOverviewType } from "../../../types/details";

type BlockEmptyDataProps = {
  overview: StrategyOverviewType;
  isIntegrationOpened: boolean;
  isConnected: boolean;
  authStatus: string;
};

export default function BlockEmptyData({
  overview,
  isIntegrationOpened,
  authStatus,
  isConnected,
}: BlockEmptyDataProps): JSX.Element {
  const dispatch = useAppDispatch();
  const isAuthed = authStatus === AuthStatus.Auth;
  const accountName = overview.code;
  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;
  const isBtnsWithRegisterShown = isOkx || isBitget || isBybit;

  const handleConnectClick = () => {
    dispatch(setIntegrationOpened(!isIntegrationOpened));
  };

  return (
    <div className="block-empty-data">
      {isAuthed &&
        (isBtnsWithRegisterShown ? (
          <div className="block-empty-data__btns-wrapper block-empty-data__btns-wrapper--with-register">
            <DetailsBtnsWithRegister
              isIntegrationOpened={isIntegrationOpened}
              isConnected={isConnected}
              accountName={overview.code}
            />
          </div>
        ) : (
          <div className="block-empty-data__btns-wrapper">
            <Button
              classElement="block-empty-data__connect-btn"
              size="super"
              pattern={`${isIntegrationOpened ? "blue-white" : "white-blue"}`}
              text={`${isIntegrationOpened ? "Hide" : "Connect my account"}`}
              icon={`${isIntegrationOpened ? "arrow-up" : "arrow-right"}`}
              type="button"
              handleClick={handleConnectClick}
            />
            <div className="block-empty-data__link">
              <DetailsLink accountName={accountName} />
            </div>
          </div>
        ))}
    </div>
  );
}
