import { useEffect, useState } from "react";

import Copy from "../../components/copy/copy";
import { BinanceSetupSeoSetting } from "../../components/seo-setting/seo-setting";
import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { AuthStatus, IP_ADDRESS, RERENDER_DELAY } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setFirstScreenRendered } from "../../store/actions";
import { debounce } from "../../utils/chart/chart";
import { deleteSeoData } from "../../utils/general";
import { checkIfDesktop } from "../../utils/breakpoint";

export default function BinanceSetupScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );
  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main setup">
      <TrackTwitter />
      <BinanceSetupSeoSetting />
      <div className="setup__wrapper">
        <h1 className="setup__heading">Binance APIs Setup</h1>
        <p className="setup__intro">
          These are 6 easy steps to connect SMARD to your Binance account in the
          secured way.
        </p>
        <ul className="setup__list">
          <li className="setup__item">
            <p className="setup__paragraph">
              1. As a first step, log in to{" "}
              <a
                className="link"
                href="https://www.binance.com/en"
                target="_blank"
                rel="noopener noreferrer"
              >
                Binance using your credentials
              </a>{" "}
              and go to your account settings in the top right and click on{" "}
              <a
                className="link"
                href="https://www.binance.com/en/my/settings/api-management"
                target="_blank"
                rel="noopener noreferrer"
              >
                API Management
              </a>
              .
            </p>

            {isDesktop ? (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-1.jpg`}
                alt="Binance setup"
                width={258}
                height={388}
              />
            ) : (
              <>
                <img
                  className="setup__img setup__img--short"
                  src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-1-mobile.jpg`}
                  alt="Binance setup"
                  width={258}
                  height="auto"
                />
                <img
                  className="setup__img setup__img--short"
                  src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-1-2-mobile.jpg`}
                  alt="Binance setup"
                  width={258}
                  height="auto"
                />
              </>
            )}
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              2. Then, click the <b>"Create API"</b> button to start creating
              the API Key. Make sure you have enabled the Two-Factor
              Authentication (2FA) since it is required to go ahead in the
              process. Give a name to the new API first.
            </p>
            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-2.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-2-mobile.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            )}
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              3. Binance will ask to confirm the API creation according to your
              security settings. Once the API is created, click on{" "}
              <b>"Edit Restrictions"</b>.
            </p>
            <p className="setup__paragraph">
              For enhanced security, select{" "}
              <b>Restrict access to trusted IPs only</b>. Copy{" "}
              <Copy text={IP_ADDRESS} /> the list of IPs on SMARD and paste it
              on Binance.
            </p>

            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-3.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-3-mobile.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            )}
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              4. Click on the <b>"Enable Reading"</b> checkbox and "Enable Spot
              & Margin Trading" one if you plan to use SMARD in your Spot
              account.
            </p>

            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-4-1.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-4-1-mobile.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            )}

            <p className="setup__paragraph">
              Click on the <b>"Enable Reading"</b> checkbox and{" "}
              <b>"Enable Futures"</b> one if you plan to use SMARD in your
              Futures account.
            </p>

            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-4-2.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-4-2-mobile.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            )}

            <p className="setup__paragraph">
              Or select all three checkboxes if you wish to enable both Spot and
              Futures.
            </p>

            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-4-3.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-4-3-mobile.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            )}
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              5. Now save and confirm once again with the authenticator code.
              Remember, SMARD doesn’t need any withdrawal permissions, so for
              your own security you don’t select this option.
            </p>
          </li>

          <li className="setup__item">
            <p className="setup__paragraph">
              6. Finally, you have to copy/paste both the API Key and the Secret
              Key in the "Accounts" section of your SMARD account.
            </p>

            {isDesktop ? (
              <img
                className="setup__img"
                src={`${process.env.PUBLIC_URL}/img/screens/binance-setup-6.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            ) : (
              <img
                className="setup__img setup__img--short"
                src={`${process.env.PUBLIC_URL}/img/screens/mobile/binance-setup-6-mobile.jpg`}
                alt="Binance setup"
                width={258}
                height="auto"
              />
            )}
          </li>
        </ul>
        <p className="setup__post">
          You are all set for automated trading with SMARD!
        </p>
      </div>
    </main>
  );
}
