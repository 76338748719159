import PricingButtonGroup from "./pricing-button-group";

type PricingSummarySectionProps = {
  isMobile: boolean;
};

function PricingSummarySection({ isMobile }: PricingSummarySectionProps) {
  return (
    <section className="landing__item--pricing-fees pricing-block">
      <div>
        <div className="pricing__heading">
          <h1 className="landing__heading">
            <span className="landing__heading-large">Summary</span>
          </h1>
        </div>
        <p className="pricing__main-description">
          SMARD’s pricing is based on principles of transparency and mutual
          benefit. Use our trading software for $1 access fee per month and pay
          10% service fee after SMARD earns for you. Your success = our success.
        </p>
        {!isMobile ? <PricingButtonGroup /> : null}
      </div>
      <div className="pricing__img" />
      {isMobile ? <PricingButtonGroup /> : null}
    </section>
  );
}

export default PricingSummarySection;
