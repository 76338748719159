import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { AuthStatus } from "../../const";
import { checkMessagesAction } from "../../store/api-actions";

export default function CheckMessages() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { authStatus } = useAppSelector((state) => state);

  useEffect(() => {
    if (authStatus === AuthStatus.Auth) {
      dispatch(checkMessagesAction());
    }
  }, [pathname, authStatus, dispatch]);

  return null;
}
