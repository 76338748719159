import GoogleIcon from "../icons/socials/google-icon";
import { trackEvent } from "../../utils/tracking";
import { AUTH_GOOGLE_URL } from "../../const";

type LoginGoogleProps = {
  getAuthUrl: (link: string) => string;
};

export default function LoginGoogle({
  getAuthUrl,
}: LoginGoogleProps): JSX.Element {
  const handleGoogleClick = () => {
    trackEvent("GoogleAuthButton", "Clicked");
    trackEvent("AuthButton", "Clicked");
  };

  return (
    <a
      href={getAuthUrl(AUTH_GOOGLE_URL)}
      className="login login--google"
      onClick={handleGoogleClick}
    >
      <span className="login__icon login__icon--google">
        <GoogleIcon />
      </span>
      <span className="visually-hidden">Login with google</span>
    </a>
  );
}
