import { useState } from "react";

import NavExtraLanding from "../nav-extra/nav-extra-landing";
import AuthBlock from "../../auth-block/auth-block";

import Logo from "../logo";
import NavBtns from "../nav-btns/nav-btns";
import NavToggle from "../nav-toggle";
import { AuthStatus, RERENDER_DELAY } from "../../../const";
import { useAppSelector } from "../../../hooks";
import { debounce } from "../../../utils/chart/chart";
import { checkIfTabletAndMore } from "../../../utils/breakpoint";

export default function ShortHeaderMain(): JSX.Element {
  const [isLandingNavOpened, setLandingNavOpened] = useState<boolean>(false);
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  const { authStatus } = useAppSelector((state) => state);
  const isAuth = authStatus === AuthStatus.Auth;
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const handleToggleClick = () => {
    setLandingNavOpened(!isLandingNavOpened);
  };

  return (
    <div className="short-header-main">
      <div className="short-header__container">
        <div className="short-header-main__wrapper">
          <Logo />

          {(isLandingNavOpened || isTabletAndMore) && (
            <NavExtraLanding type="" />
          )}

          {isAuth && (
            <div className="short-header-main__nav-btns">
              <NavBtns />
            </div>
          )}

          {isNoAuth && (
            <>
              <div className="short-header-main__toggle">
                <NavToggle
                  isOpened={isLandingNavOpened}
                  handleOpened={handleToggleClick}
                />
              </div>

              {(isLandingNavOpened || isTabletAndMore) && (
                // Передача short-header__auth, чтобы блокировать телеграм при открытии модального окна на страница HIW
                <AuthBlock type="short-header__auth" />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
