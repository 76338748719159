export default function BlueMoneyIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9863 5.47559C9.32577 5.47559 5.47559 9.32577 5.47559 13.9863C5.47559 18.6467 9.32577 22.4971 13.9863 22.4971C18.6469 22.4971 22.4971 18.6467 22.4971 13.9863C22.4971 9.32577 18.6469 5.47559 13.9863 5.47559Z"
        fill="#60A5FA"
      />
      <path
        d="M22.4971 13.9863C22.4971 18.6467 18.6469 22.4971 13.9863 22.4971V5.47559C18.6469 5.47559 22.4971 9.32577 22.4971 13.9863Z"
        fill="#2563EB"
      />
      <path
        d="M20.959 13.9863C20.959 17.8008 17.8008 20.959 13.9863 20.959C10.1719 20.959 7.01367 17.8008 7.01367 13.9863C7.01367 10.1717 10.1719 7.01367 13.9863 7.01367C17.8008 7.01367 20.959 10.1717 20.959 13.9863Z"
        fill="#60A5FA"
      />
      <path
        d="M20.959 13.9863C20.959 17.8008 17.8008 20.959 13.9863 20.959V7.01367C17.8008 7.01367 20.959 10.1717 20.959 13.9863Z"
        fill="#2563EB"
      />
      <path
        d="M27.1113 13.9863C27.1113 16.9393 26.0962 19.8977 24.2351 22.2048L23.0354 21.2512C24.6965 19.1748 25.5732 16.6317 25.5732 13.9863C25.5732 7.61865 20.354 2.39941 13.9863 2.39941C7.61865 2.39941 2.39941 7.61865 2.39941 13.9863C2.39941 20.354 7.61865 25.5732 13.9863 25.5732C16.0166 25.5732 18.0007 25.0349 19.7542 24.0198L20.5745 25.3579C18.5903 26.5115 16.2781 27.1113 13.9863 27.1113C6.77271 27.1113 0.861328 21.2 0.861328 13.9863C0.861328 6.77271 6.77271 0.861328 13.9863 0.861328C21.2 0.861328 27.1113 6.77271 27.1113 13.9863Z"
        fill="#60A5FA"
      />
      <path
        d="M19.7542 24.0195L20.5745 25.3577C18.5903 26.5112 16.2781 27.1111 13.9863 27.1111V25.573C16.0166 25.573 18.0007 25.0347 19.7542 24.0195Z"
        fill="#2563EB"
      />
      <path
        d="M27.1113 13.9863C27.1113 16.9393 26.0962 19.8977 24.2351 22.2048L23.0354 21.2512C24.6965 19.1748 25.5732 16.6317 25.5732 13.9863C25.5732 7.61865 20.354 2.39941 13.9863 2.39941V0.861328C21.2 0.861328 27.1113 6.77271 27.1113 13.9863Z"
        fill="#2563EB"
      />
      <path
        d="M27.1113 22.4971H22.4971V17.8828H24.0352V20.959H27.1113V22.4971Z"
        fill="#2563EB"
      />
      <path
        d="M13.5166 19.6103V18.1748C12.9925 18.1656 12.4912 18.1201 12.0127 18.038C11.5387 17.956 11.1514 17.8489 10.8506 17.7168V16.6093C11.165 16.7552 11.5684 16.8828 12.0605 16.9921C12.5527 17.1015 13.0381 17.1608 13.5166 17.1699V14.1621C12.915 13.9934 12.416 13.802 12.0195 13.5878C11.6276 13.3691 11.3337 13.1025 11.1377 12.788C10.9417 12.4736 10.8438 12.0931 10.8438 11.6464C10.8438 11.1816 10.9531 10.7851 11.1719 10.457C11.3952 10.1243 11.7074 9.86454 12.1084 9.67769C12.5094 9.49084 12.9788 9.38374 13.5166 9.3564V8.17378H14.3506V9.34273C14.8382 9.3564 15.2826 9.41109 15.6836 9.50679C16.0846 9.59794 16.4561 9.71643 16.7979 9.86226L16.4424 10.8125C16.1325 10.6894 15.7975 10.5846 15.4375 10.498C15.0775 10.4114 14.7152 10.3522 14.3506 10.3203V13.3076C14.9613 13.4853 15.4717 13.6744 15.8818 13.875C16.2965 14.0755 16.6087 14.3238 16.8184 14.6201C17.028 14.9163 17.1328 15.2991 17.1328 15.7685C17.1328 16.4339 16.889 16.9716 16.4014 17.3818C15.9137 17.7874 15.2301 18.0335 14.3506 18.1201V19.6103H13.5166ZM14.3506 17.1084C14.9111 17.0537 15.3281 16.9192 15.6016 16.705C15.8796 16.4863 16.0186 16.1992 16.0186 15.8437C16.0186 15.5885 15.9639 15.3789 15.8545 15.2148C15.7497 15.0507 15.5765 14.9095 15.335 14.791C15.0934 14.6679 14.7653 14.5517 14.3506 14.4423V17.1084ZM13.5166 13.0205V10.3476C13.1748 10.3704 12.8877 10.4342 12.6553 10.539C12.4229 10.6438 12.2474 10.7806 12.1289 10.9492C12.015 11.1178 11.958 11.3183 11.958 11.5507C11.958 11.8196 12.0081 12.0452 12.1084 12.2275C12.2132 12.4052 12.3796 12.5579 12.6074 12.6855C12.8398 12.8085 13.1429 12.9202 13.5166 13.0205Z"
        fill="#60A5FA"
      />
      <mask
        id="mask0_1107_1335"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="10"
        y="8"
        width="8"
        height="12"
      >
        <path
          d="M13.5166 19.6104V18.1748C12.9925 18.1657 12.4912 18.1201 12.0127 18.0381C11.5387 17.9561 11.1514 17.849 10.8506 17.7168V16.6094C11.165 16.7552 11.5684 16.8828 12.0605 16.9922C12.5527 17.1016 13.0381 17.1608 13.5166 17.1699V14.1621C12.915 13.9935 12.416 13.8021 12.0195 13.5879C11.6276 13.3691 11.3337 13.1025 11.1377 12.7881C10.9417 12.4736 10.8438 12.0931 10.8438 11.6465C10.8438 11.1816 10.9531 10.7852 11.1719 10.457C11.3952 10.1243 11.7074 9.86458 12.1084 9.67773C12.5094 9.49089 12.9788 9.38379 13.5166 9.35645V8.17383H14.3506V9.34277C14.8382 9.35645 15.2826 9.41113 15.6836 9.50684C16.0846 9.59798 16.4561 9.71647 16.7979 9.8623L16.4424 10.8125C16.1325 10.6895 15.7975 10.5846 15.4375 10.498C15.0775 10.4115 14.7152 10.3522 14.3506 10.3203V13.3076C14.9613 13.4854 15.4717 13.6745 15.8818 13.875C16.2965 14.0755 16.6087 14.3239 16.8184 14.6201C17.028 14.9163 17.1328 15.2992 17.1328 15.7686C17.1328 16.4339 16.889 16.9717 16.4014 17.3818C15.9137 17.7874 15.2301 18.0335 14.3506 18.1201V19.6104H13.5166ZM14.3506 17.1084C14.9111 17.0537 15.3281 16.9193 15.6016 16.7051C15.8796 16.4863 16.0186 16.1992 16.0186 15.8438C16.0186 15.5885 15.9639 15.3789 15.8545 15.2148C15.7497 15.0508 15.5765 14.9095 15.335 14.791C15.0934 14.668 14.7653 14.5518 14.3506 14.4424V17.1084ZM13.5166 13.0205V10.3477C13.1748 10.3704 12.8877 10.4342 12.6553 10.5391C12.4229 10.6439 12.2474 10.7806 12.1289 10.9492C12.015 11.1178 11.958 11.3184 11.958 11.5508C11.958 11.8197 12.0081 12.0452 12.1084 12.2275C12.2132 12.4053 12.3796 12.5579 12.6074 12.6855C12.8398 12.8086 13.1429 12.9202 13.5166 13.0205Z"
          fill="#60A5FA"
        />
      </mask>
      <g mask="url(#mask0_1107_1335)">
        <rect
          x="9.74023"
          y="7.57227"
          width="4.24634"
          height="12.6179"
          fill="#A0CBFF"
        />
      </g>
    </svg>
  );
}
