type PeriodToggleProps = {
  type: string;
  isAllDays: boolean;
  setAllDays: (isAllDays: boolean) => void;
};

export default function PeriodToggle({
  type,
  isAllDays,
  setAllDays,
}: PeriodToggleProps): JSX.Element {
  return (
    <div className="toggles-base__item">
      <span className="toggles-base__text">All time</span>
      <input
        className="visually-hidden"
        id={`${type}-controls-time`}
        type="checkbox"
        name="time-type"
        value="all-time"
        onChange={() => setAllDays(!isAllDays)}
      />

      <label
        className="toggles-base__label toggles-base__label--period"
        htmlFor={`${type}-controls-time`}
      >
        <span className="toggles-base__toggle toggles-base__toggle--period"></span>
      </label>
      <span className="toggles-base__text">Last 30 days</span>
    </div>
  );
}
