import { EMPTY_DATA } from "../const";
import { formatTradingResults, localAmount } from "./amount";

export const displayResultsAmount = (amount: number | undefined | null) => {
  if (amount === null || amount === undefined) {
    return EMPTY_DATA;
  }

  return `${localAmount(formatTradingResults(amount).amount)}${
    formatTradingResults(amount).short
  }`;
};

export const displayResultsPercent = (value: number | undefined | null) => {
  if (value === null || value === undefined) {
    return EMPTY_DATA;
  }

  return `${displayResultsAmount(value)}%`;
};

export const displayResultsAbsolute = (
  value: number | undefined | null,
  currency: string | undefined | null
) => {
  if (
    value === null ||
    value === undefined ||
    currency === null ||
    currency === undefined
  ) {
    return EMPTY_DATA;
  }

  return `${displayResultsAmount(value)} ${currency}`;
};
