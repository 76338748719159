export default function BlueDollarIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1569_6178)">
        <g clipPath="url(#clip1_1569_6178)">
          <path
            d="M17.7214 12.7192C16.5661 12.112 15.3379 11.6494 14.1219 11.1694C13.4166 10.8918 12.7416 10.568 12.1458 10.1169C10.9723 9.22634 11.1973 7.7806 12.5714 7.2081C12.9605 7.04617 13.3679 6.99413 13.7814 6.97099C15.3744 6.89003 16.8884 7.16761 18.3294 7.82687C19.0469 8.15649 19.284 8.0524 19.5272 7.3411C19.7826 6.58932 19.9954 5.82598 20.2325 5.06841C20.3906 4.55951 20.196 4.22411 19.6914 4.01014C18.7672 3.62268 17.8186 3.3451 16.8215 3.19475C15.5203 3.00391 15.5203 2.99813 15.5142 1.7548C15.5082 0.00257022 15.5082 0.00257019 13.6598 0.00257019C13.3922 0.00257019 13.1247 -0.00321274 12.8572 0.00257019C11.9938 0.0257019 11.8479 0.170275 11.8235 0.997233C11.8114 1.36734 11.8235 1.73745 11.8175 2.11334C11.8114 3.21209 11.8053 3.19475 10.6987 3.57642C8.02338 4.50169 6.36956 6.23656 6.19323 9.01237C6.03514 11.4701 7.38496 13.1298 9.50696 14.3384C10.8142 15.0844 12.2613 15.5239 13.6476 16.108C14.1887 16.3336 14.7056 16.5938 15.1555 16.9523C16.4871 17.999 16.2439 19.7397 14.663 20.3989C13.8179 20.7517 12.9241 20.8385 12.0059 20.7286C10.5892 20.5609 9.23335 20.2081 7.9565 19.5778C7.20863 19.2077 6.98974 19.306 6.73437 20.0751C6.51548 20.7401 6.32092 21.411 6.12635 22.0818C5.8649 22.9839 5.96218 23.1979 6.86814 23.62C8.02338 24.1521 9.25767 24.4239 10.5163 24.6147C11.5013 24.7651 11.5317 24.8055 11.5438 25.7771C11.5499 26.2166 11.5499 26.6619 11.556 27.1014C11.5621 27.6565 11.8418 27.9804 12.4437 27.9919C13.1247 28.0035 13.8118 28.0035 14.4928 27.9861C15.0521 27.9746 15.3379 27.6854 15.3379 27.1476C15.3379 26.5462 15.3683 25.939 15.344 25.3376C15.3136 24.7246 15.5933 24.4123 16.2135 24.2504C17.6423 23.8803 18.8584 23.1516 19.7947 22.0644C22.3971 19.0573 21.406 14.6565 17.7214 12.7192Z"
            fill="#60A5FA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.6676 11.332C15.7263 11.756 16.7869 12.1808 17.7913 12.7192C21.4038 14.6565 22.3755 19.0573 19.8241 22.0644C18.906 23.1516 17.7138 23.8802 16.3129 24.2503C15.7048 24.4123 15.4306 24.7245 15.4604 25.3375C15.4757 25.7244 15.4689 26.1136 15.462 26.5022C15.4582 26.7177 15.4544 26.933 15.4544 27.1476C15.4544 27.6854 15.1742 27.9746 14.6258 27.9861C14.3437 27.9934 14.0604 27.9977 13.777 27.9993V20.699C14.1222 20.6386 14.4613 20.54 14.7927 20.3989C16.3427 19.7397 16.5811 17.999 15.2756 16.9523C14.8345 16.5938 14.3277 16.3335 13.7972 16.108C13.7905 16.1051 13.7838 16.1022 13.777 16.0993V10.9635C13.9367 11.0354 14.0986 11.1037 14.2622 11.1694C14.3972 11.2237 14.5324 11.2779 14.6676 11.332ZM13.777 6.98112C13.8274 6.97718 13.8778 6.97385 13.9283 6.97096C15.4902 6.89 16.9746 7.16758 18.3874 7.82684C19.0908 8.15646 19.3233 8.05237 19.5618 7.34107C19.7216 6.86102 19.8645 6.37625 20.0074 5.89128C20.0883 5.61677 20.1692 5.3422 20.2533 5.06838C20.4083 4.55948 20.2175 4.22408 19.7227 4.01011C18.8166 3.62265 17.8866 3.34507 16.909 3.19471C15.6333 3.00388 15.6333 2.9981 15.6273 1.75485L15.6273 1.75477C15.6244 0.888495 15.6229 0.450494 15.4031 0.229033C15.1784 0.00253968 14.7254 0.00253967 13.8091 0.00253967C13.7984 0.00253967 13.7877 0.00253006 13.777 0.00251203V6.98112ZM13.3153 -4.98195e-06C13.2952 -3.7707e-05 13.275 -4.03267e-05 13.2549 -4.98195e-06H13.3153Z"
            fill="#2563EB"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1569_6178">
          <rect width="28" height="28" fill="white" />
        </clipPath>
        <clipPath id="clip1_1569_6178">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
