import { FaqList } from "../../../types/general";
import { FaqScreenAccountManagmentData } from "./faq-screen-account-managment-data";
import { FaqScreenConnectionData } from "./faq-screen-connection-data";
import { FaqScreenMyMoneyData } from "./faq-screen-my-money-data";
import { FaqScreenPaymentsData } from "./faq-screen-payments-data";
import { FaqScreenSecurityData } from "./faq-screen-security-data";
import { FaqScreenTradingResults } from "./faq-screen-trading-results-data";

export const faqScreenListData: FaqList[] = [
  {
    label: "My money",
    faqData: FaqScreenMyMoneyData,
  },
  {
    label: "Connection",
    faqData: FaqScreenConnectionData,
  },
  {
    label: "Account management",
    faqData: FaqScreenAccountManagmentData,
  },
  {
    label: "Payments",
    faqData: FaqScreenPaymentsData,
  },
  {
    label: "Security",
    faqData: FaqScreenSecurityData,
  },
  {
    label: "Trading results explained",
    faqData: FaqScreenTradingResults,
  },
];
