import { createAsyncThunk } from "@reduxjs/toolkit";

import { ThunkApiConfigType } from "../../types/api";
import { StateAction } from "../action-types";
import { AccountName, ApiRoute } from "../../const";
import {
  loadBinanceFuturesDemo,
  loadBinanceSpotDemo,
  loadBitgetFuturesDemo,
  loadDiversifiedDemo,
  loadOkxDemo,
  loadBybitDemo,
  setOkxDemoLoading,
  setBybitDemoLoading,
  setBinanceFuturesDemoLoading,
  setBinanceSpotDemoLoading,
  setBitgetFuturesDemoLoading,
} from "../actions/demo";

export const fetchOkxDemo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.Demo.LoadOkxDemo, async (_arg, { dispatch, extra: api }) => {
  dispatch(setOkxDemoLoading(true));
  try {
    const { data } = await api.get(`${ApiRoute.Demo}${AccountName.Okx}/`);
    dispatch(loadOkxDemo(data));
  } catch {}
  dispatch(setOkxDemoLoading(false));
});

export const fetchBybitDemo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(StateAction.Demo.LoadBybitDemo, async (_arg, { dispatch, extra: api }) => {
  dispatch(setBybitDemoLoading(true));
  try {
    const { data } = await api.get(`${ApiRoute.Demo}${AccountName.Bybit}/`);
    dispatch(loadBybitDemo(data));
  } catch {}
  dispatch(setBybitDemoLoading(false));
});

export const fetchBinanceSpotDemo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Demo.LoadBinanceSpotDemo,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setBinanceSpotDemoLoading(true));
    try {
      const { data } = await api.get(
        `${ApiRoute.Demo}${AccountName.BinanceSpot}/`
      );
      dispatch(loadBinanceSpotDemo(data));
    } catch {}
    dispatch(setBinanceSpotDemoLoading(false));
  }
);

export const fetchBinanceFuturesDemo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Demo.LoadBinanceFuturesDemo,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setBinanceFuturesDemoLoading(true));
    try {
      const { data } = await api.get(
        `${ApiRoute.Demo}${AccountName.BinanceFutures}/`
      );
      dispatch(loadBinanceFuturesDemo(data));
    } catch {}
    dispatch(setBinanceFuturesDemoLoading(false));
  }
);

export const fetchBitgetFuturesDemo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Demo.LoadBitgetFuturesDemo,
  async (_arg, { dispatch, extra: api }) => {
    dispatch(setBitgetFuturesDemoLoading(true));
    try {
      const { data } = await api.get(
        `${ApiRoute.Demo}${AccountName.BitgetFutures}/`
      );
      dispatch(loadBitgetFuturesDemo(data));
    } catch {}
    dispatch(setBitgetFuturesDemoLoading(false));
  }
);

export const fetchDiversifiedDemo = createAsyncThunk<
  void,
  undefined,
  ThunkApiConfigType
>(
  StateAction.Demo.LoadDiversifiedDemo,
  async (_arg, { dispatch, extra: api }) => {
    try {
      const { data } = await api.get(
        `${ApiRoute.Demo}${AccountName.DiversifiedBinanceSpotBinanceFutures}/`
      );
      dispatch(loadDiversifiedDemo(data));
    } catch {}
  }
);
