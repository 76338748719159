import { formatedMinNotional } from "../../../../utils/env";

export default function BitgetMinNotionalError(): JSX.Element {
  return (
    <span className="message__note message__note--error">
      {`Minimum ${formatedMinNotional} USDT required in the `}
      <b>Futures trading</b> account
    </span>
  );
}
