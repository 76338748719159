import ErrorIcon from "../icons/action/error-icon";
import SuccessIcon from "../icons/action/success-icon";

type MessageProps = {
  classElement?: string;
  status?: string;
  message: string | JSX.Element;
};

export default function Message({
  classElement,
  status,
  message,
}: MessageProps): JSX.Element {
  const checkIcon = () => {
    switch (status) {
      case "success":
        return <SuccessIcon />;
      case "error":
        return <ErrorIcon />;
      default:
        return null;
    }
  };
  return (
    <p
      className={`message 
      ${status ? `message--${status}` : ""} 
      ${classElement ?? ""}`}
    >
      {status && <span className="message__icon">{checkIcon()}</span>}
      {message && message}
    </p>
  );
}
