import { useState } from "react";

import Button from "../../button/button";
import LinkIcon from "../../icons/link-icon";
import ArrowUpIcon from "../../icons/btn/arrow-up-icon";
import { checkIfDesktop } from "../../../utils/breakpoint";
import { debounce } from "../../../utils/chart/chart";
import { useAppDispatch } from "../../../hooks";
import { setIntegrationOpened } from "../../../store/actions";
import { trackEvent } from "../../../utils/tracking";
import {
  createBitgetConnectLink,
  createOkxConnectLink,
  createBybitConnectLink,
} from "../../../utils/id";
import {
  AccountName,
  BITGET_REGISTER_LINK,
  BYBIT_REGISTER_LINK,
  OKX_REGISTER_LINK,
  RERENDER_DELAY,
} from "../../../const";

type DetailsBtnsWithRegisterProps = {
  accountName: string;
  isIntegrationOpened: boolean;
  isConnected: boolean;
};

export default function DetailsBtnsWithRegister({
  accountName,
  isIntegrationOpened,
  isConnected,
}: DetailsBtnsWithRegisterProps): JSX.Element {
  const dispatch = useAppDispatch();
  const okxConnectLink = createOkxConnectLink();
  const bitgetConnectLink = createBitgetConnectLink();
  const bybitConnectLink = createBybitConnectLink();
  const isOkx = accountName === AccountName.Okx;
  const isBitget = accountName === AccountName.BitgetFutures;
  const isBybit = accountName === AccountName.Bybit;

  const [isDesktop, setDesktop] = useState<boolean>(checkIfDesktop());

  window.addEventListener(
    "resize",
    debounce(() => {
      setDesktop(checkIfDesktop());
    }, RERENDER_DELAY)
  );

  const handleConnectClick = () => {
    dispatch(setIntegrationOpened(!isIntegrationOpened));
  };

  const handleRegisterClick = () => {
    if (isOkx) {
      trackEvent("OKXDetailsRegisterAccount", "Clicked");
      return;
    }
    if (isBitget) {
      trackEvent("BitgetDetailsRegisterAccount", "Clicked");
      return;
    }
    if (isBybit) {
      trackEvent("BybitDetailsRegisterAccount", "Clicked");
      return;
    }
  };

  // const name = isOkx ? "OKX" : isBitget ? "Bitget" : isBybit ? "Bybit" : "Binance";

  const registerNewAccountLinkAddress = isOkx ? OKX_REGISTER_LINK : isBitget ? BITGET_REGISTER_LINK : BYBIT_REGISTER_LINK
  const connectAccountLinkAddress = isOkx ? okxConnectLink : isBitget ? bitgetConnectLink : bybitConnectLink

  return (
    <div className="details-btns-with-register">
      {isConnected ? "" :
       <Button
        pattern="blue-white"
        text="Register a new account"
        icon="arrow-right"
        type="link"
        size={isDesktop ? "190" : "200"}
        linkAddress={registerNewAccountLinkAddress}
        handleClick={handleRegisterClick}
       />
      }
      <div className="details-btns-with-register__wrapper">
        <Button
          classElement={isConnected ? "btn--disabled" : ""}
          size={isDesktop ? "175" : "200"}
          pattern="white-blue"
          text="Connect my account"
          icon="arrow-right"
          type="link"
          linkAddress={connectAccountLinkAddress}
          isSelfLink
        />
        <button
          className={`details-btns-with-register__link link ${
            isConnected ? "link--disabled" : ""
          }`}
          onClick={handleConnectClick}
        >
          {/* COMMON */}
          {isIntegrationOpened ? (
            <span className="details-btns-with-register__icon details-btns-with-register__icon--up">
              <ArrowUpIcon />
            </span>
          ) : (
            <span className="details-btns-with-register__icon details-btns-with-register__icon--chain">
              <LinkIcon />
            </span>
          )}
          {isIntegrationOpened ? "Hide" : "Enter API keys manually"}
        </button>
      </div>
    </div>
  );
}
