export default function CreditCardIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7052 0.425781H3.4412C1.96601 0.425781 0.796493 1.60859 0.796493 3.08378L0.783203 19.0318C0.783203 20.507 1.96601 21.6898 3.4412 21.6898H24.7052C26.1804 21.6898 27.3632 20.507 27.3632 19.0318V3.08378C27.3632 1.60859 26.1804 0.425781 24.7052 0.425781ZM23.3762 19.0318H4.7702C4.03925 19.0318 3.4412 18.4337 3.4412 17.7028V11.0578H24.7052V17.7028C24.7052 18.4337 24.1071 19.0318 23.3762 19.0318ZM24.7052 5.74178H3.4412V3.08378H24.7052V5.74178Z"
        fill="currentColor"
      />
    </svg>
  );
}
