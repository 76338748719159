import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { ResponseStatusType } from "../../../../types/details";
import { useForm } from "react-hook-form";
import { ConnectSteps } from "../../../../const";
import { useAppDispatch } from "../../../../hooks";
import { setAddKeysStatus } from "../../../../store/actions/keys";
import { fetchAmountToStartBinanceSpot } from "../../../../store/api-actions/start";
import Button from "../../../button/button";
import ConnectPoint2 from "../../points/connect-point2";
import BinanceSpotStep2Message from "../../messages/binance-spot-step2-message";
import BinanceSpotStep2Fields from "../../fields/binance-spot-step2-fields";

type BinanceSpotConnectStep2Props = {
  pointId: string;
  activeStep: string;
  addKeysStatus: null | ResponseStatusType;
  setActiveStep: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  loadAmountToStartStatus: null | ResponseStatusType;
  checkPositionModeStatus: null | ResponseStatusType;
};

function BinanceSpotConnectStep2({
  pointId,
  activeStep,
  addKeysStatus,
  setActiveStep,
  isLoading,
  loadAmountToStartStatus,
  checkPositionModeStatus,
}: BinanceSpotConnectStep2Props) {
  const dispatch = useAppDispatch();

  const [lengthFieldError, setLengthFieldError] = useState<string>("");
  const [isApiKeyFocused, setApiKeyFocused] = useState<boolean>(false);
  const nextBtnRef = useRef<HTMLButtonElement | null>(null);

  const { register, getValues, setFocus, setValue } = useForm({
    mode: "onChange",
  });

  const isStepActive = activeStep === ConnectSteps.STEP2;
  const isStepDone = activeStep === ConnectSteps.STEP3;
  const isAddKeysSuccess = addKeysStatus?.isSuccess;
  const isLoadAmountFailed = loadAmountToStartStatus?.isSuccess === false;
  const blockClass = isStepActive ? "block--active" : "block--disabled";
  const nextDisabledClass = isAddKeysSuccess ? "" : "btn--disabled";
  const backBlockedClass = isLoading ? "btn--blocked" : "";
  const isPointActive = isStepActive || isStepDone;

  const clearKeys = () => {
    setValue("key", "");
    setValue("secret", "");
  };

  const handleBackClick = () => {
    setActiveStep(ConnectSteps.STEP1);
    setApiKeyFocused(false);

    if (lengthFieldError) {
      setLengthFieldError("");
      clearKeys();
    }

    if (!isAddKeysSuccess) {
      dispatch(setAddKeysStatus(null));
      clearKeys();
    }
  };

  const handleNextClick = () => {
    dispatch(fetchAmountToStartBinanceSpot());
    setActiveStep(ConnectSteps.STEP3);

  };

  // Фокус при переходе на блок на поле "key" до запроса успешного "add-keys"
  useEffect(() => {
    if (isStepActive && !addKeysStatus && !isApiKeyFocused) {
      setFocus("key");
      setApiKeyFocused(true);
    }
  }, [
    addKeysStatus,
    setApiKeyFocused,
    isStepActive,
    setFocus,
    isApiKeyFocused,
  ]);

  // Фокус на 3й блок при ошибке на "coin-info" или любом ответе на "check-mode"
  // useEffect(() => {
  //   if (isLoadAmountFailed || checkPositionModeStatus) {
  //     setActiveStep(ConnectSteps.STEP3);
  //   }
  // }, [checkPositionModeStatus, setActiveStep, isLoadAmountFailed]);

  // Фокус при переходе на блок на кнопку "next" уже при успешном ответе на "add-keys"
  // (был возврат на 1й блок)
  useEffect(() => {
    if (isAddKeysSuccess && nextBtnRef.current && isStepActive) {
      nextBtnRef.current.focus();
    }
  }, [isAddKeysSuccess, isStepActive]);

  return (
    <form
      className={`details__item connect-step block block--step ${blockClass}`}
    >
      <h3 className="block__heading">Enter API key and API Secret below</h3>
      <p className="connect-step__intro">
        Go back to Binance, copy both API keys and paste them into the fields
        below.
      </p>
      <div className="connect-step__fields-wrapper">
        <BinanceSpotStep2Fields
          register={register}
          getValues={getValues}
          addKeysStatus={addKeysStatus}
          lengthFieldError={lengthFieldError}
          setLengthFieldError={setLengthFieldError}
        />

        {/* key message */}
        <BinanceSpotStep2Message
          lengthFieldError={lengthFieldError}
          addKeysStatus={addKeysStatus}
        />
      </div>

      {/* buttons */}
      <div className="connect-step__btn-wrapper">
        <Button
          classElement={backBlockedClass}
          pattern="blue-white"
          text="Back"
          icon="arrow-left"
          type="button"
          handleClick={handleBackClick}
        />
        <Button
          classElement={nextDisabledClass}
          pattern="white-blue"
          text="Next"
          icon={isLoading ? "arrow-loader" : "arrow-right"}
          type="button"
          handleClick={handleNextClick}
          ref={nextBtnRef}
        />
      </div>
      <ConnectPoint2 pointId={pointId} isPointActive={isPointActive} />
    </form>
  );
}

export default BinanceSpotConnectStep2;
