import { useEffect, useState } from "react";

import { TrackTwitter } from "../../components/track-twitter/track-twitter";
import { FaqSeoSetting } from "../../components/seo-setting/seo-setting";
import { useAppDispatch, useAppSelector } from "../../hooks/index";
import { fetchFaqChart } from "../../store/api-actions";
import { AuthStatus } from "../../const";
import { loadFaqChart, setFirstScreenRendered } from "../../store/actions";
import { deleteSeoData } from "../../utils/general";
import { faqScreenListData } from "../../components/faq/faq-data/faq-screen-list-data";
import FaqScreenList from "../../components/faq/faq-screen-list";

export default function FaqScreen(): JSX.Element {
  const dispatch = useAppDispatch();
  const { authStatus, isFirstScreenRendered } = useAppSelector(
    (state) => state
  );

  useEffect(() => {
    dispatch(fetchFaqChart());
    return () => {
      dispatch(loadFaqChart(null));
    };
  }, [dispatch]);

  // any page
  useEffect(() => {
    deleteSeoData();
  }, []);

  useEffect(() => {
    if (!isFirstScreenRendered && authStatus === AuthStatus.Auth) {
      dispatch(setFirstScreenRendered(true));
    }
    // eslint-disable-next-line
  }, [authStatus]);
  //

  return (
    <main className="main faq-screen">
      <FaqSeoSetting />
      <TrackTwitter />
      <div className="faq">
        <h1 className="faq__heading">Frequently Asked Questions</h1>
        <ul className="faq__list">
          {faqScreenListData.map((list) => {
            return <FaqScreenList label={list.label} faqData={list.faqData} />;
          })}
        </ul>
      </div>
    </main>
  );
}
