export default function ErrorIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.36689 3.66304C9.18942 3.48432 8.90273 3.48432 8.72526 3.66304L6.5 5.89938L4.27474 3.65846C4.09727 3.47973 3.81058 3.47973 3.63311 3.65846C3.45563 3.83718 3.45563 4.12589 3.63311 4.30461L5.85836 6.54554L3.63311 8.78647C3.45563 8.96519 3.45563 9.2539 3.63311 9.43262C3.81058 9.61135 4.09727 9.61135 4.27474 9.43262L6.5 7.1917L8.72526 9.43262C8.90273 9.61135 9.18942 9.61135 9.36689 9.43262C9.54437 9.2539 9.54437 8.96519 9.36689 8.78647L7.14164 6.54554L9.36689 4.30461C9.53982 4.13047 9.53982 3.83718 9.36689 3.66304Z"
        fill="currentColor"
      />
      <circle
        cx="6.5"
        cy="6.5"
        r="5.75"
        stroke="currentColor"
        strokeWidth={1.5}
      />
    </svg>
  );
}
