import { FaqList } from "../../types/general";
import FaqItem from "./faq-item";

function FaqScreenList({ label, faqData }: FaqList) {
  return (
    <li className="faq__item">
      <h2 className="faq__inner-heading">{label}</h2>
      <ul className="faq__inner-list">
        {faqData.map((faq) => {
          return (
            <FaqItem
              key={faq.question}
              question={faq.question}
              answer={
                  <p className="faq-item__paragraph">{faq.answer}</p>
              }
            />
          );
        })}
      </ul>
    </li>
  );
}

export default FaqScreenList;
