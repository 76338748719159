export default function PaymentApprovedIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 20 19"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2 5C1 5 0 6 0 7v10c0 1 1 2 2 2h15c1 0 2-1 2-2v-5c0-1-1.5-1-1.5 0v4.5c0 .5-.5 1-1 1h-14c-.5 0-1-.5-1-1v-9c0-.5.5-1 1-1H8C9 6.5 9 5 8 5H2Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="M8 9H1.5v1.5H8C9 10.5 9 9 8 9Z" />
      <path
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M10.34 2.67 14.36 1l4.35 1.67a8.25 8.25 0 0 1-4.35 9.72C9.54 9.98 9.67 4.9 10.34 2.67Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="m12.5 6.73 1.54 1.54L17 5M3 14h3"
      />
    </svg>
  );
}
