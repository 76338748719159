import FaqItem from "./faq-item";
import { OKX_REGISTER_LINK } from "../../const";
import { useAppSelector } from "../../hooks";
import { StrategyResultsType } from "../../types/details";
import {
  displayResultsAmount,
  displayResultsPercent,
} from "../../utils/results";

export default function OkxFaq(): JSX.Element {
  const { okxDemo } = useAppSelector((state) => state);
  const results = okxDemo?.strategy_results as StrategyResultsType;

  const statistics =
    results &&
    results?.sharpe_ratio !== null &&
    results?.annualized_return !== null &&
    results?.avg_monthly_return !== null &&
    ` Since 01.01.2021, SMARD strategies on OKX have shown the following
performance: Sharpe ratio is ${displayResultsAmount(
      results.sharpe_ratio
    )}, annualized return is ${displayResultsPercent(
      results.annualized_return
    )}, and
average monthly return is ${displayResultsPercent(
      results.avg_monthly_return
    )}.`;

  return (
    <div className="landing__item faq faq--landing">
      <h2 className="landing__title landing__title--faq">
        Frequently Asked Questions
      </h2>
      <ul className="faq__inner-list">
        <FaqItem
          question="Is OKX safe?"
          answer={
            <p className="faq-item__paragraph">
              OKX is one of the top five rated cryptocurrency exchanges and has
              a good reputation for its enhanced security and user-friendliness.
            </p>
          }
        />
        <FaqItem
          question="Should I trust trading bots?"
          answer={
            <p className="faq-item__paragraph">
              Trust in trading bots depends on your risk tolerance and the
              reliability of a particular bot. Perform a thorough check, study
              the algorithm mechanism, historical trading results, and user
              reviews, and then use trading bots as one of many possible asset
              management strategies rather than investing all your money in it.
            </p>
          }
        />
        <FaqItem
          question="Are crypto trading bots profitable?"
          answer={
            <p className="faq-item__paragraph">
              Trading bots are profitable, however, success depends on a variety
              of factors, including the type of strategy and market conditions.
              {statistics}
            </p>
          }
        />
        <FaqItem
          question="How much money can I make by a trading bot?"
          answer={
            <p className="faq-item__paragraph">
              Trading bots generate profits, but the amount is unpredictable.
              Your profit or loss depends on market conditions and the
              efficiency of algorithms. Past performance does not guarantee
              future results, so always assess risks and diversify your
              portfolio.
            </p>
          }
        />
        <FaqItem
          question="Can I trade with OKX?"
          answer={
            <p className="faq-item__paragraph">
              Yes, you can trade with{" "}
              <a
                className="link"
                href={OKX_REGISTER_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                OKX
              </a>
              . It is a cryptocurrency exchange platform that allows users to
              buy and sell various digital assets. If you use SMARD with OKX you
              can trade manually on the same account as well; just don’t touch
              the position formed by SMARD.
            </p>
          }
        />
        <FaqItem
          question="Is OKX a Chinese company?"
          answer={
            <p className="faq-item__paragraph">
              According to official information on the OKX website, as of
              December 2023, the exchange has legal addresses in the Bahamas,
              Hong Kong, Brazil, and other countries. For accurate, up-to-date
              information, please contact{" "}
              <a
                className="link"
                href="https://www.okx.com/help/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                OKX
              </a>{" "}
              directly.
            </p>
          }
        />
      </ul>
    </div>
  );
}
