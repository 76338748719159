import { Link } from "react-router-dom";
import { AppRoute } from "../../../const";

export default function OkxChangeModeAgainNote(): JSX.Element {
  return (
    <span className="message__note">
      Your account has an invalid account mode. Please follow this{" "}
      <Link className="link link--underline" to={AppRoute.OkxChangeMode}>
        instruction
      </Link>{" "}
      to change your account settings manually.
    </span>
  );
}
