export default function DollarIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0ZM8.5 1.69972C4.74446 1.69972 1.7 4.74419 1.7 8.49972C1.7 12.2553 4.74446 15.2997 8.5 15.2997C12.2555 15.2997 15.3 12.2553 15.3 8.49972C15.3 4.74419 12.2555 1.69972 8.5 1.69972Z"
        fill="currentColor"
      />
      <path
        d="M8.03516 13.3027V12.0957C7.55078 12.0879 7.10352 12.0449 6.69336 11.9668C6.28711 11.8848 5.93555 11.7793 5.63867 11.6504V10.4023C5.93945 10.543 6.31055 10.6699 6.75195 10.7832C7.19336 10.8965 7.62109 10.9609 8.03516 10.9766V8.7793C7.46875 8.58789 7.00781 8.38867 6.65234 8.18164C6.30078 7.97461 6.04297 7.73438 5.87891 7.46094C5.71484 7.18359 5.63281 6.85156 5.63281 6.46484C5.63281 6.06641 5.73242 5.72461 5.93164 5.43945C6.13477 5.15039 6.41602 4.92188 6.77539 4.75391C7.13867 4.58594 7.55859 4.48633 8.03516 4.45508V3.5H8.79688V4.4375C9.23047 4.44922 9.63086 4.49805 9.99805 4.58398C10.3691 4.66602 10.7227 4.78125 11.0586 4.92969L10.6309 6.01953C10.3379 5.90234 10.0332 5.80664 9.7168 5.73242C9.40039 5.6582 9.09375 5.60742 8.79688 5.58008V7.72461C9.27344 7.88086 9.69727 8.04883 10.0684 8.22852C10.4434 8.4082 10.7363 8.63477 10.9473 8.9082C11.1621 9.17773 11.2695 9.53125 11.2695 9.96875C11.2695 10.543 11.0586 11.0176 10.6367 11.3926C10.2148 11.7637 9.60156 11.9863 8.79688 12.0605V13.3027H8.03516ZM8.79688 10.9297C9.17188 10.8828 9.45117 10.7852 9.63477 10.6367C9.81836 10.4844 9.91016 10.2871 9.91016 10.0449C9.91016 9.88086 9.87109 9.74219 9.79297 9.62891C9.71875 9.51562 9.59961 9.41406 9.43555 9.32422C9.27539 9.23438 9.0625 9.14648 8.79688 9.06055V10.9297ZM8.03516 7.4375V5.60352C7.80859 5.62695 7.61719 5.67383 7.46094 5.74414C7.30469 5.81445 7.1875 5.90625 7.10938 6.01953C7.03125 6.13281 6.99219 6.26758 6.99219 6.42383C6.99219 6.5957 7.02734 6.74219 7.09766 6.86328C7.16797 6.98438 7.2793 7.08984 7.43164 7.17969C7.58789 7.26953 7.78906 7.35547 8.03516 7.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}
