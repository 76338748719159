import { useEffect, useRef, useState } from "react";
import { Reorder } from "framer-motion";

import CardFormWrapper from "../card-form-wrapper/card-form-wrapper";
import FakeCardForm from "../fake-card-form/fake-card-form";
import Hint from "../hint/hint";
import PaymentsOrderSkeleton from "../skeletons/payments-order-skeleton";
import PaymentsMethod from "../payments-method/payments-method";
import { AuthStatus, HINTS_TEXT } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CreditCardType } from "../../types/payment";
import { setSendMethodsOrderStatus } from "../../store/actions";
import {
  fetchClientSecret,
  fetchLastPayments,
  fetchPaymentMethods,
  sendMethodsOrder,
} from "../../store/api-actions";

export default function PaymentsOrder(): JSX.Element {
  const dispatch = useAppDispatch();

  const {
    isPaymentMethodsLoading,
    isLastPaymentsLoading,
    isFailedTransactionsAgainLoading,
    authStatus,
    methodsOrderStatus,
    clientSecret,
    isFailedTransactionsLoading,
    paymentMethods,
  } = useAppSelector((state) => state);

  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const depositObj = paymentMethods.find((item) => item.type === "deposit");
  const cardsArr = paymentMethods.filter((item) => item.type === "card");
  const firstValidCard = cardsArr.find((item) => item.data?.status !== "charge_rejected");

  const [currentItems, setCurrentItems] = useState(cardsArr);
  const [backUpItems, setBackUpItems] = useState(cardsArr);
  const [methodsOrderTimerId, setMethodsOrderTimerId] = useState<any>();
  const cardsLength = currentItems.length;
  const constraintsRef = useRef(null);

  useEffect(() => {
    dispatch(fetchPaymentMethods());
    dispatch(fetchClientSecret());
    dispatch(fetchLastPayments());
  }, [dispatch]);

  useEffect(() => {
    if (paymentMethods.length !== 0) {
      setCurrentItems(cardsArr);
      setBackUpItems(cardsArr);
    }
    // eslint-disable-next-line
  }, [paymentMethods]);

  useEffect(() => {
    if (methodsOrderStatus) {
      // If success (true)
      setBackUpItems(currentItems);
    } else if (methodsOrderStatus !== null) {
      // If error (false, не true и не null)
      setCurrentItems(backUpItems);
    }

    // Обнуляет после каждой смены порядка, чтобы понять новый ответ сервера
    dispatch(setSendMethodsOrderStatus(null));
    // eslint-disable-next-line
  }, [methodsOrderStatus]);

  const handleItems = (cards: CreditCardType[]) => {
    setCurrentItems(cards);

    if (methodsOrderTimerId) {
      clearTimeout(methodsOrderTimerId);
    }

    const timerId = setTimeout(() => {
      changeOrder(cards);
    }, 1000);

    setMethodsOrderTimerId(timerId);
  };

  const changeOrder = (cards: CreditCardType[]) => {
    dispatch(
      sendMethodsOrder({
        order: ["deposit", ...cards.map((card) => card.data.code)],
      })
    );
  };

  return (
    <section className="block payments__item payments__order payments-order">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Payment methods</h2>

        {isNoAuth && (
          <Hint
            block="payments-order"
            type="block__hint"
            id="payments/order"
            hintText={HINTS_TEXT["payments/order"]}
          />
        )}
      </div>

      <p className="payments-order__intro">
        Payment is charged automatically at the end of the calendar month from
        the moment of connection. <br /> If the service is not paid, trading on
        all accounts will be paused.
      </p>

      {(isPaymentMethodsLoading ||
        isLastPaymentsLoading ||
        isFailedTransactionsLoading) &&
      !isFailedTransactionsAgainLoading ? (
        <PaymentsOrderSkeleton />
      ) : (
        <>
          {depositObj && (
            <PaymentsMethod
              type="deposit"
              method={depositObj}
              cardsLength={cardsLength}
            />
          )}

          <Reorder.Group
            className="payments-order__table"
            values={currentItems}
            axis="y"
            onReorder={handleItems}
            ref={constraintsRef}
          >
            {currentItems.map((item, i) => (
              <PaymentsMethod
                key={item.data.code}
                type="card"
                method={item}
                cardsLength={cardsLength}
                containerRef={constraintsRef}
                isFirstValidCard={item === firstValidCard}
              />
            ))}
          </Reorder.Group>

          {clientSecret &&
            paymentMethods.length !== 0 &&
            authStatus === AuthStatus.Auth && (
              <div className="payments-order__form">
                <CardFormWrapper cardsLength={cardsLength} />
              </div>
            )}

          {(clientSecret === "" || authStatus === AuthStatus.NoAuth) && (
            <div className="payments-order__fake-form">
              <FakeCardForm cardsLength={cardsLength} />
            </div>
          )}
        </>
      )}
    </section>
  );
}
