export default function BlueWarningIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 26 26"
    >
      <path
        fill="#60A5FA"
        fillRule="evenodd"
        d="M13 26H9.46v-3.07a11.23 11.23 0 0 1-3.35-1.91L3.54 22.5 0 16.38l2.6-1.49a9.48 9.48 0 0 1-.22-1.93c0-.61.07-1.25.22-1.93L0 9.54l3.54-6.12L6.1 4.9c1-.82 2.12-1.46 3.35-1.9V0H13v26Z"
        clipRule="evenodd"
      />
      <path
        fill="#2563EB"
        d="M23.62 12.96a9.5 9.5 0 0 0-.22-1.93L26 9.54l-3.54-6.12L19.9 4.9c-1-.82-2.12-1.46-3.35-1.9V0H13v26h3.54v-3.07a11.23 11.23 0 0 0 3.35-1.91l2.57 1.48L26 16.38l-2.6-1.49c.15-.68.22-1.32.22-1.93Z"
      />
      <path
        fill="#A0CBFF"
        fillRule="evenodd"
        d="M13 6.16c-.38 0-.75.19-.97.56L6.66 15.8c-.45.76.1 1.7.97 1.7H13V6.17Z"
        clipRule="evenodd"
      />
      <path
        fill="#60A5FA"
        d="m19.34 15.8-5.37-9.08c-.22-.37-.6-.56-.97-.56v11.35h5.37c.87 0 1.42-.95.97-1.7Z"
      />
      <path
        fill="#2563EB"
        d="M13.58 14.09h-1.14l-.24-4.58h1.62l-.24 4.58Zm-1.4 1.6c0-.29.08-.5.24-.61a.97.97 0 0 1 .58-.18c.23 0 .42.06.58.18.16.11.24.32.24.61s-.08.49-.24.61a.9.9 0 0 1-.58.2.93.93 0 0 1-.58-.2c-.16-.12-.24-.32-.24-.6Z"
      />
    </svg>
  );
}
