import { useEffect, useRef, useState } from "react";

import { AuthStatus } from "../../const";
import { useAppSelector } from "../../hooks";
import { trackTwt60Sec, trackTwt80Percent } from "../../utils/tracking";

export function TrackTwitter() {
  const { authStatus } = useAppSelector((state) => state);
  const [timerId, setTimerId] = useState<NodeJS.Timer | null>(null);
  const is60secRef = useRef<boolean>(false);
  const is80PercentRef = useRef<boolean>(false);
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const trackScroll = () => {
    // window.pageYOffset - прокрученная высота, меньше чем сама высота
    // scrollY вместо pageYOffset
    // window.innerHeight - высота вью порта
    // document.body.clientHeight - высота самой страницы

    const percent80 =
      ((document.body.clientHeight - window.innerHeight) / 100) * 80;
    if (window.scrollY >= percent80 && !is80PercentRef.current) {
      trackTwt80Percent();
      is80PercentRef.current = true;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", trackScroll);

    return () => {
      window.removeEventListener("scroll", trackScroll);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isNoAuth && !is60secRef.current && !timerId) {
      const timerId = setTimeout(() => {
        trackTwt60Sec();
        is60secRef.current = true;
      }, 60000);
      setTimerId(timerId);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [is60secRef, isNoAuth, timerId]);

  return null;
}
