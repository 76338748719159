import TriangleDownIcon from "../../components/icons/triangle/triangle-down-icon";
import TriangleUpIcon from "../../components/icons/triangle/triangle-up-icon";
import MinusIcon from "../../components/icons/sign/minus-icon";
import {
  addNullIfNeeded,
  formatAmount,
  formatTrending,
  localAmount,
} from "../amount";

export const displayMinus = (amount: number | BigInt) => {
  const number = Number(amount);
  const formatted = formatAmount(number).amount;

  if (formatted === null) {
    return "";
  }

  if (formatted < 0) {
    return (
      <span className="coin-board__minus-icon">
        <MinusIcon />
      </span>
    );
  }
};

export const displayPrice = (amount: number | BigInt) => {
  const number = Number(amount);

  if (Math.abs(number) <= 0.001) {
    return `$${localAmount(Math.abs(number))}`;
  }

  return `$${localAmount(formatAmount(Math.abs(number)).amount as number)}${
    formatAmount(number).short
  }`;
};

export const displayAmount = (amount: number | BigInt) => {
  const number = Number(amount);

  if (formatAmount(Math.abs(number)).amount === null) {
    return "$0";
  }

  return `$${localAmount(formatAmount(Math.abs(number)).amount as number)}${
    formatAmount(number).short
  }`;
};

export const checkPercentClass = (amount: number) => {
  const formatted = formatTrending(amount).amount;
  if (formatted === 0) {
    return "";
  }

  if (formatted > 0) {
    return "coin-board__percent--plus";
  }
  return "coin-board__percent--minus";
};

export const displayArrow = (amount: number) => {
  const formatted = formatTrending(amount).amount;
  if (formatted === 0) {
    return <></>;
  }

  return (
    <span className="coin-board__percent-icon">
      {formatted > 0 ? <TriangleUpIcon /> : <TriangleDownIcon />}
    </span>
  );
};

export const displayPercent = (amount: number) => {
  const finalDisplay = addNullIfNeeded(
    amount,
    localAmount(Math.abs(formatTrending(amount).amount))
  );

  return `${finalDisplay}${formatTrending(amount).short}%`;
};
