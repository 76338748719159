import { FaqData } from "../../../types/general";

export const FaqScreenPaymentsData: FaqData[] = [
  {
    question: "How is the service's fee calculated?",
    answer:
      "Fees are based on the performance of the strategy over a 30-day period. If no profit is generated, a $1 access fee is charged. If a profit is made, the fee is 10% of that profit.",
  },
  {
    question: "How to pay for the service?",
    answer: (
      <>
        <p className="faq-item__paragraph">
          You can make a payment for the service automatically using a card or
          make a manual payment via crypto.
        </p>
        <p className="faq-item__paragraph">
          Note that the payment takes place on the client side. SMARD cannot
          automatically charge your exchange account because it doesn't have and
          doesn't want to have the right to do so.
        </p>
      </>
    ),
  },
  {
    question: "Can I pay from the same wallet that is connected to SMARD ?",
    answer:
      "You can make payments from the same wallet linked to SMARD on the exchange, provided it does not impact the expected position considering compound interest. If your exchange balance falls below the expected amount, the strategy will automatically stop.",
  },
];
