import BitgetIcon from "../../icons/account/bitget-icon";
import TransparentDoubleDiskIcon from "../../icons/decor/transparent-double-disk-icon";

export default function BitgetBriefly(): JSX.Element {
  return (
    <section className="landing__item okx-briefly">
      <h2 className="landing__title">Briefly about Bitget</h2>
      <div className="okx-briefly__wrapper">
        <div className="okx-briefly__intro1">
          <p className="okx-briefly__paragraph">
            Bitget is a cryptocurrency exchange platform that offers a wide
            range of trading solutions for users around the world. Whether you
            want to trade Bitcoin (BTC), Ethereum (ETH) or other well-known
            cryptocurrencies, Bitget provides a seamless and user-centered
            experience.
          </p>
          <p className="okx-briefly__paragraph">
            Featuring an intuitive interface, Bitget simplifies the trading
            process for beginners and experienced traders alike. With a
            user-friendly interface, users can easily access their desired
            cryptocurrencies and make trades.
          </p>
          <p className="okx-briefly__paragraph">
            Bitget promises to{" "}
            <a
              className="link"
              href="https://www.bitget.com/proof-of-reserves"
              target="_blank"
              rel="noopener noreferrer"
            >
              reserve
            </a>{" "}
            100% of its users' assets and shares Merkle Tree proof of Merkle
            Tree, platform reserves, and platform reserve ratio on a monthly
            basis.
          </p>
        </div>
        <div className="okx-briefly__intro1__media">
          <picture>
            <source
              media="(min-width: 1080px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/bitget/laptop-desktop.png`}
              width="431"
              height="251"
            />
            <source
              media="(min-width: 678px)"
              srcSet={`${process.env.PUBLIC_URL}/img/laptop/bitget/laptop-tablet.png`}
              width="354"
              height="206"
            />
            <img
              className="okx-briefly__img"
              src={`${process.env.PUBLIC_URL}/img/laptop/bitget/laptop-mobile.png`}
              alt="Bitget account in laptop"
              width="290"
              height="169"
            />
          </picture>
        </div>
        <div className="okx-briefly__intro2">
          <p className="okx-briefly__paragraph">
            Bitget also equips traders with robust market analysis tools,
            allowing them to gain valuable insights and make data-driven
            decisions. From technical analysis indicators to real-time market
            updates, Bitget provides the resources needed to make informed
            trading decisions.
          </p>
          <h3 className="okx-briefly__heading-inner">
            Bitget trading bots by SMARD
          </h3>
          <p className="okx-briefly__paragraph">
            Designed for both newcomers and traders, Bitget trading bots by
            SMARD offer an intuitive interface that simplifies the trading
            process.
          </p>
          <p className="okx-briefly__paragraph">
            The platform provides efficient automated strategies for
            cryptocurrency trading that do not require the client's trading
            knowledge and constant market monitoring. Connecting to Bitget is
            done in a few clicks via Fast API without access to withdrawal.
          </p>
        </div>
        <div className="okx-briefly__intro2__media">
          <span className="okx-briefly__disk">
            <TransparentDoubleDiskIcon />
          </span>
          <span className="okx-briefly__logo">
            <BitgetIcon />
          </span>
          <span className="okx-briefly__note okx-briefly__note--tools">
            Quick access via API
          </span>
          <span className="okx-briefly__note okx-briefly__note--speed">
            Fast order execution
          </span>
          <span className="okx-briefly__note okx-briefly__note--interface">
            Multi-language support
          </span>
        </div>
      </div>
    </section>
  );
}
