import { Link } from "react-router-dom";

import { ResponseStatusType } from "../../../types/details";
import { AppRoute } from "../../../const";

type ConnectNoteProps = {
  status: null | ResponseStatusType;
  isOkx: boolean;
};

// применяется на данный момент только с Binance
export default function ConnectNote({
  status,
  isOkx,
}: ConnectNoteProps): JSX.Element {
  return (
    <>
      {status && status.message}
      <span className="message__note">
        See our manual on how to successfully
      </span>
      <Link
        className="link link--underline"
        to={isOkx ? AppRoute.Okx : AppRoute.BinanceSetup}
      >
        connect to {isOkx ? "OKX" : "Binance"}
      </Link>
    </>
  );
}
