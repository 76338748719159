import AlertIcon from "../icons/alert/alert-icon";
import { useAppDispatch } from "../../hooks";
import { resetErrorInMessages, setErrorModalOpened } from "../../store/actions";

type ErrorModalProps = {
  message: string;
};

export default function ErrorModal({ message }: ErrorModalProps): JSX.Element {
  const dispatch = useAppDispatch();
  dispatch(setErrorModalOpened(true));

  const handleClick = () => {
    dispatch(setErrorModalOpened(false));
    dispatch(resetErrorInMessages(null));
  };

  return (
    <div className="modal">
      <div className="modal__wrapper">
        <h2 className="modal__heading">
          <span className="modal__heading-icon">
            <AlertIcon />
          </span>
          Warning
        </h2>
        <p className="modal__text">{message}</p>
        <button
          className="modal__btn modal__btn--ok"
          type="button"
          onClick={handleClick}
        >
          OK
        </button>
      </div>
    </div>
  );
}
