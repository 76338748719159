import { ChartType } from "../../types/chart";
import { collectAmounts } from "./chart";

export const checkColor = (
  data: ChartType[] | number[],
  isNumbers?: boolean
) => {
  const amounts = isNumbers ? data : collectAmounts(data as ChartType[]);
  if (amounts[0] > amounts[amounts.length - 1]) {
    return "#F93332";
  }

  return "#30E733";
};

export const checkResultColor = (result: number) => {
  if (result < 0) {
    return "#F93332";
  }
  return "#30E733";
};
