import { forwardRef, memo, useEffect, useRef, useState } from "react";

import HintIcon from "../icons/help/hint-icon";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addReadHintId, setOpenedHint } from "../../store/actions";

type HintProps = {
  type: string;
  id: string;
  hintText: string;
  block?: string;
};

function Hint({ type, id, hintText, block }: HintProps, ref: any): JSX.Element {
  const dispatch = useAppDispatch();
  const { openedHint, readHintIds } = useAppSelector((state) => state);

  const [closingHint, setClosingHint] = useState<string>("");
  const blockRef = useRef<HTMLDivElement | null>(null);
  const questionRef = useRef<HTMLButtonElement | null>(null);

  const isHintRead = readHintIds.some((item) => item === id);
  const isOpenedHintRead = readHintIds.some((item) => item === openedHint);
  const openedHintClass = openedHint === id ? "hint--opened" : "";
  const animatedQuestionClass = !isHintRead ? "hint__question--animated" : "";
  const stoppedQuestionClass =
    closingHint === id ? "hint__question--closed" : "";

  const addClosing = () => {
    if (openedHint && !isOpenedHintRead) {
      setClosingHint(openedHint);
      dispatch(addReadHintId(openedHint));
    }
  };

  const handleQuestionClick = () => {
    // Условие при котором кликаешь на вопрос открытого хинта
    if (openedHint && openedHint === id) {
      addClosing();
      dispatch(setOpenedHint(null));
      return;
    }

    // Условие при котором кликаешь на вопрос одного хинта, а другой открыт
    if (openedHint && openedHint !== id) {
      addClosing();
      setTimeout(() => {
        // setTimeout тк перезаписывается openedHint в setClosingHint
        dispatch(setOpenedHint(id));
      }, 50);
      return;
    }

    // Условие при котором кликаешь на вопрос хинта, и все закрыты
    dispatch(setOpenedHint(id));
  };

  const handleGotItClick = () => {
    addClosing();
    dispatch(setOpenedHint(null));
  };

  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      const target = evt.target as Element;
      const isClickInBlock = blockRef?.current?.contains(target);
      const isClickInQuestion = questionRef?.current?.contains(target);

      if (
        // openedHint === id чтобы сработал единожды у открытого хинта
        openedHint &&
        openedHint === id &&
        !isClickInBlock &&
        !isClickInQuestion
      ) {
        addClosing();
        dispatch(setOpenedHint(null));
      }
    };

    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("click", handleClick);
    };
    // eslint-disable-next-line
  }, [openedHint, id]);

  setTimeout(() => {
    if (closingHint) {
      setClosingHint("");
    }
    // 1150 - время closing animation  1.15
  }, 1150);

  return (
    <div className={`${type} hint ${openedHintClass}`} ref={ref}>
      <button
        className={`hint__question ${animatedQuestionClass}
        ${stoppedQuestionClass}`}
        type="button"
        aria-label="Hint"
        ref={questionRef}
        onClick={handleQuestionClick}
      >
        <HintIcon />
      </button>
      {hintText && (
        <div
          className={`hint__message ${block ? `hint__message--${block}` : ""}`}
          ref={blockRef}
        >
          <span className="hint__text">{hintText}</span>
          <button
            className="hint__got-it"
            type="button"
            aria-label="Got it"
            onClick={handleGotItClick}
          >
            Got it
          </button>
        </div>
      )}
    </div>
  );
}

export default memo(forwardRef<HTMLDivElement | null, HintProps>(Hint));
