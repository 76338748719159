import BitcoinCoinIcon from "../../icons/coin/bitcoin-coin-icon";
import EthereumCoinIcon from "../../icons/coin/ethereum-coin-icon";

type CoinToggleProps = {
  type: string;
  isBitcoinMode: boolean;
  setBitcoinMode: (mode: boolean) => void;
};

export default function CoinToggle({
  type,
  isBitcoinMode,
  setBitcoinMode,
}: CoinToggleProps): JSX.Element {
  return (
    <div className="toggles-base__item">
      <span className="toggles-base__text">vs</span>
      <span className="toggles-base__icon">
        <BitcoinCoinIcon />
      </span>
      <span className="toggles-base__text">Bitcoin</span>
      <input
        className="visually-hidden"
        id={`${type}-controls-instrument`}
        type="checkbox"
        name="instrument-type"
        value="bitcoin"
        onChange={() => {
          setBitcoinMode(!isBitcoinMode);
        }}
      />
      <label
        className="toggles-base__label toggles-base__label--coin"
        htmlFor={`${type}-controls-instrument`}
      >
        <span className="toggles-base__toggle toggles-base__toggle--coin"></span>
      </label>
      <span className="toggles-base__icon">
        <EthereumCoinIcon />
      </span>
      <span className="toggles-base__text">Ethereum</span>
    </div>
  );
}
