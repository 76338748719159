import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
} from "react-hook-form";

import { useAppDispatch } from "../../../hooks";
import { KEY_LENGTH } from "../../../const";
import { Dispatch, SetStateAction } from "react";
import { ResponseStatusType } from "../../../types/details";
import { checkRenewKeys } from "../../../store/api-actions/keys";
import { setSendRenewKeysToCheckStatus } from "../../../store/actions/keys";

type RenewKeysFieldsProps = {
  accountName: string;
  isFieldsDisabled: boolean | undefined;
  isKeyLengthError: boolean;
  setKeyLengthError: Dispatch<SetStateAction<boolean>>;
  getValues: UseFormGetValues<FieldValues>;
  register: UseFormRegister<FieldValues>;
  checkRenewKeysStatus: null | ResponseStatusType;
};

export default function RenewKeysFields({
  accountName,
  isFieldsDisabled,
  isKeyLengthError,
  setKeyLengthError,
  getValues,
  register,
  checkRenewKeysStatus,
}: RenewKeysFieldsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const fieldClass = `${isFieldsDisabled ? "field--disabled" : ""} `;
  const isFieldsValid = (keyLength: number, secretLength: number) =>
    keyLength === KEY_LENGTH && secretLength === KEY_LENGTH;

  // handleKeyChange
  const handleKeyChange = () => {
    const data = getValues(["key", "secret"]);
    const [key, secret] = data;

    if (isFieldsValid(key.length, secret.length)) {
      if (isKeyLengthError) {
        setKeyLengthError(false);
      }

      setTimeout(() => {
        dispatch(checkRenewKeys({ id: accountName, key, secret }));
      }, 500);

      return;
    }

    if (key && secret) {
      setKeyLengthError(true);

      if (checkRenewKeysStatus) {
        dispatch(setSendRenewKeysToCheckStatus(null));
      }
      return;
    }
  };

  return (
    <div className="renew-keys-form__inner-wrapper">
      {/* api-key */}
      <label className="visually-hidden" htmlFor="api-key">
        Api key
      </label>
      <input
        className={`renew-keys-form__field field ${fieldClass}`}
        type="text"
        id="api-key"
        placeholder="API Key"
        autoComplete="off"
        {...register("key", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
      {/* secret-key */}
      <label className="visually-hidden" htmlFor="secret-key">
        Secret key
      </label>
      <input
        className={`renew-keys-form__field field ${fieldClass}`}
        type="text"
        id="secret-key"
        placeholder="Secret Key"
        autoComplete="off"
        {...register("secret", {
          required: true,
          onChange: handleKeyChange,
        })}
      />
    </div>
  );
}
