export default function DiskIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70 98.3333C85.6481 98.3333 98.3333 85.6481 98.3333 70C98.3333 54.3519 85.6481 41.6667 70 41.6667C54.3519 41.6667 41.6667 54.3519 41.6667 70C41.6667 85.6481 54.3519 98.3333 70 98.3333ZM70 140C108.66 140 140 108.66 140 70C140 31.3401 108.66 0 70 0C31.3401 0 0 31.3401 0 70C0 108.66 31.3401 140 70 140Z"
        fill="url(#paint0_linear_1330_4887)"
        fillOpacity="0.2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1330_4887"
          x1="22.4085"
          y1="18.5054"
          x2="118.491"
          y2="118.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2563EB" />
          <stop offset="1" stopColor="#2563EB" stopOpacity="0.2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
