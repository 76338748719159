import { useRef, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import Personal from "./personal";
import Notifications from "./notifications";
import PersonIcon from "../../../icons/person/person-icon";
import BellIcon from "../../../icons/bell";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { fecthNotifications } from "../../../store/api-actions";
import { checkNotificationsAmount } from "../../../utils/general";
import {
  loadNotifications,
  setNotificationsOpened,
  setPersonalOpened,
} from "../../../store/actions";

export default function NavBtns(): JSX.Element {
  const dispatch = useAppDispatch();
  const {
    messages,
    notifications,
    isPersonalOpened,
    isNotificationsOpened,
    isNotificationsLoading,
  } = useAppSelector((state) => state);

  const userBtnRef = useRef<HTMLButtonElement | null>(null);
  const notificationsBtnRef = useRef<HTMLButtonElement | null>(null);

  const handleNotificationsClick = () => {
    if (!isNotificationsOpened) {
      dispatch(setNotificationsOpened(true));
      dispatch(fecthNotifications());
      return;
    }
    closeNotifications();
  };

  const handleUserClick = () => {
    dispatch(setPersonalOpened(!isPersonalOpened));
  };

  const closeNotifications = () => {
    dispatch(setNotificationsOpened(false));
    dispatch(loadNotifications(null));
  };

  useEffect(() => {
    // Для случая когда закрыли окно раньше чем загрузились данные
    if (notifications && !isNotificationsOpened) {
      dispatch(loadNotifications(null));
    }
  }, [dispatch, isNotificationsOpened, notifications]);

  return (
    <div className="nav-btns">
      <button
        className={`nav-btns__item ${
          isNotificationsLoading ? "nav-btns__item--disabled" : ""
        }`}
        type="button"
        aria-label="Show notifications."
        onClick={handleNotificationsClick}
        ref={notificationsBtnRef}
      >
        <span className="nav-btns__icon nav-btns__icon--bell">
          <BellIcon />
        </span>
        <AnimatePresence initial={false}>
          {messages && messages.bell !== null && messages.bell > 0 && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <span className="nav-btns__notifications">
                {checkNotificationsAmount(messages.bell)}
              </span>
            </motion.div>
          )}
        </AnimatePresence>
      </button>

      <button
        className="nav-btns__item"
        type="button"
        aria-label="Open user block."
        onClick={handleUserClick}
        ref={userBtnRef}
      >
        <span className="nav-btns__icon nav-btns__icon--user">
          <PersonIcon isNav />
        </span>
      </button>

      {isNotificationsOpened && (
        <Notifications
          closeNotifications={closeNotifications}
          notificationsBtnRef={notificationsBtnRef}
        />
      )}
      {isPersonalOpened && <Personal userBtnRef={userBtnRef} />}
    </div>
  );
}
