export default function DollarIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 9 16"
      fill="none"
    >
      <path
        fill="currentColor"
        d="M3.84 15.5v-1.85c-.78 0-1.5-.07-2.15-.2a8.72 8.72 0 0 1-1.68-.48v-1.9c.48.2 1.07.4 1.78.57.7.18 1.38.28 2.05.3V8.58c-.9-.3-1.64-.6-2.21-.92a3.36 3.36 0 0 1-1.24-1.1C.13 6.14 0 5.63 0 5.04c0-.61.16-1.14.48-1.57.32-.44.77-.8 1.34-1.05.58-.26 1.25-.41 2.02-.46V.5h1.21v1.43a9.94 9.94 0 0 1 3.61.76l-.68 1.67a10.51 10.51 0 0 0-2.93-.68v3.28c.76.24 1.44.5 2.03.78.6.27 1.07.62 1.4 1.04.35.4.52.95.52 1.62 0 .88-.34 1.6-1.01 2.18-.67.56-1.65.9-2.94 1.02v1.9H3.84Zm1.21-3.63c.6-.07 1.05-.22 1.34-.45.3-.23.44-.53.44-.9 0-.26-.06-.47-.19-.64-.12-.17-.3-.33-.57-.47-.25-.14-.6-.27-1.02-.4v2.86ZM3.84 6.53V3.72c-.37.03-.67.1-.92.21-.25.11-.44.25-.56.43-.13.17-.19.37-.19.61 0 .27.06.5.17.68.11.18.29.34.53.48.25.14.57.27.97.4Z"
      />
    </svg>
  );
}
