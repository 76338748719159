import { useState } from "react";

import CopyIcon from "../icons/action/copy-icon";

type CopyProps = {
  text: string;
};

export default function Copy({ text }: CopyProps): JSX.Element {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text);
      setIsCopied(true);
    }
  };

  setTimeout(() => {
    if (isCopied) {
      setIsCopied(false);
    }
  }, 500);

  return (
    <div className="copy">
      <button
        className={`copy__btn ${isCopied ? "copy__btn--copied" : ""}`}
        type="button"
        onClick={copyToClipboard}
      >
        <span className="copy__text">{text}</span>
        <span className="copy__icon">
          <CopyIcon />
        </span>
      </button>
    </div>
  );
}
