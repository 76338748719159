export default function DetailsStrategySkeleton(): JSX.Element {
  return (
    <div className="details__board details__item sk-details-strategy animate-pulse">
      <div className="sk-details-strategy__img"></div>
      <div className="sk-details-strategy__big-wrapper">
        <div className="sk-details-strategy__small-wrapper">
          <div className="sk-details-strategy__amount-wrapper">
            <div className="sk-details-strategy__amount"></div>
            <div className="sk-details-strategy__note"></div>
          </div>
          <div className="sk-details-strategy__amount-wrapper">
            <div className="sk-details-strategy__amount"></div>
            <div className="sk-details-strategy__note"></div>
          </div>
        </div>
        <div className="sk-details-strategy__small-wrapper">
          <div className="sk-details-strategy__amount-wrapper sk-details-strategy__amount-wrapper--third">
            <div className="sk-details-strategy__amount"></div>
            <div className="sk-details-strategy__note"></div>
          </div>
          <div className="sk-details-strategy__amount-wrapper">
            <div className="sk-details-strategy__amount"></div>
            <div className="sk-details-strategy__note"></div>
          </div>
        </div>
      </div>
      <div className="sk-details-strategy__btn"></div>
    </div>
  );
}
