import { Dispatch, SetStateAction, SyntheticEvent } from "react";

import TriangleDownIcon from "../../components/icons/triangle/triangle-down-icon";
import TriangleUpIcon from "../../components/icons/triangle/triangle-up-icon";
import MinusIcon from "../../components/icons/sign/minus-icon";
import { TradableCoinType } from "../../types/coins";
import { CoinsSorting } from "../../const";
import {
  addNullIfNeeded,
  formatAmount,
  formatTrending,
  localAmount,
} from "../amount";

export const sortByV24HToLess = (a: TradableCoinType, b: TradableCoinType) => {
  if (a.volume_24h === null && b.volume_24h !== null) {
    return 1;
  }

  if (a.volume_24h !== null && b.volume_24h === null) {
    return -1;
  }

  if (a.volume_24h !== null && b.volume_24h !== null) {
    return b.volume_24h - a.volume_24h;
  }

  // оба null
  return 0;
};

export const sortByV24HToMore = (a: TradableCoinType, b: TradableCoinType) => {
  if (a.volume_24h === null && b.volume_24h !== null) {
    return 1;
  }

  if (a.volume_24h !== null && b.volume_24h === null) {
    return -1;
  }

  if (a.volume_24h !== null && b.volume_24h !== null) {
    return a.volume_24h - b.volume_24h;
  }

  // оба null
  return 0;
};

export const sortByMCToLess = (a: TradableCoinType, b: TradableCoinType) => {
  if (a.market_cap === null && b.market_cap !== null) {
    return 1;
  }

  if (a.market_cap !== null && b.market_cap === null) {
    return -1;
  }

  if (a.market_cap !== null && b.market_cap !== null) {
    return b.market_cap - a.market_cap;
  }

  // оба null
  return 0;
};

export const sortByMCToMore = (a: TradableCoinType, b: TradableCoinType) => {
  if (a.market_cap === null && b.market_cap !== null) {
    return 1;
  }

  if (a.market_cap !== null && b.market_cap === null) {
    return -1;
  }

  if (a.market_cap !== null && b.market_cap !== null) {
    return a.market_cap - b.market_cap;
  }

  // оба null
  return 0;
};

export const sortByC24ToLess = (a: TradableCoinType, b: TradableCoinType) => {
  return b.change_24h - a.change_24h;
};

export const displayPrice = (amount: number | BigInt) => {
  const number = Number(amount);

  if (Math.abs(number) <= 0.001) {
    return `$${localAmount(Math.abs(number))}`;
  }

  return `$${localAmount(formatAmount(Math.abs(number)).amount as number)}${
    formatAmount(number).short
  }`;
};

export const displayAmount = (amount: null | number | BigInt) => {
  if (amount !== null) {
    const number = Number(amount);

    if (formatAmount(Math.abs(number)).amount === null) {
      return "$0";
    }

    return `$${localAmount(formatAmount(Math.abs(number)).amount as number)}${
      formatAmount(number).short
    }`;
  }
  return "...";
};

export const displayPercent = (amount: null | number) => {
  if (amount === null) {
    return "...";
  }

  const finalDisplay = addNullIfNeeded(
    amount,
    localAmount(Math.abs(formatTrending(amount).amount))
  );

  return `${finalDisplay}${formatTrending(amount).short}%`;
};

export const checkPercentClass = (amount: null | number) => {
  if (amount === null) {
    return "";
  }

  const formatted = formatTrending(amount).amount;
  if (formatted === 0) {
    return "";
  }

  if (formatted > 0) {
    return "coins__change-amount--plus";
  }
  return "coins__change-amount--minus";
};

export const displaySortArrows = (
  activeSort: string,
  IncSort: string,
  DescSort: string
) => {
  const checkActiveIcon = (sorting: string) =>
    activeSort === sorting ? "coins__arrow-icon--active" : "";

  return (
    <p className="coins__arrow-wrapper">
      <span
        className={`coins__arrow-icon
        ${checkActiveIcon(IncSort)}`}
      >
        <TriangleUpIcon />
      </span>
      <span
        className={`coins__arrow-icon
        ${checkActiveIcon(DescSort)}`}
      >
        <TriangleDownIcon />
      </span>
    </p>
  );
};

export const displayArrow = (amount: null | number) => {
  if (amount === null) {
    return <></>;
  }

  const formatted = formatTrending(amount).amount;
  if (formatted === 0) {
    return <></>;
  }

  return (
    <span className="coins__percent-icon">
      {formatted > 0 ? <TriangleUpIcon /> : <TriangleDownIcon />}
    </span>
  );
};

export const displayMinus = (amount: null | number | BigInt) => {
  const number = Number(amount);
  const formatted = formatAmount(number).amount;

  if (amount === null || formatted === null) {
    return "";
  }

  if (formatted < 0) {
    return (
      <span className="coins__minus-icon">
        <MinusIcon />
      </span>
    );
  }
};

export const handleSorting = (
  evt: SyntheticEvent,
  DescSort: string,
  activeSort: string,
  setActiveSorting: Dispatch<SetStateAction<string>>,
  coinsList: TradableCoinType[],
  setCoinsList: Dispatch<SetStateAction<TradableCoinType[]>>
) => {
  const target = evt.currentTarget as Element;

  if (target.className.includes("coins__row-heading--change")) {
    if (activeSort !== DescSort) {
      setActiveSorting(CoinsSorting.DESC_C24H);
      setCoinsList(coinsList.sort(sortByC24ToLess));
    } else {
      setActiveSorting(CoinsSorting.INC_C24H);
      setCoinsList(coinsList.sort(sortByC24ToLess).reverse());
    }
  }

  if (target.className.includes("coins__row-heading--market-cap")) {
    if (activeSort !== DescSort) {
      setActiveSorting(CoinsSorting.DESC_MC);
      setCoinsList(coinsList.sort(sortByMCToLess));
    } else {
      setActiveSorting(CoinsSorting.INC_MC);
      setCoinsList(coinsList.sort(sortByMCToMore));
    }
  }

  if (target.className.includes("coins__row-heading--volume")) {
    if (activeSort !== DescSort) {
      setActiveSorting(CoinsSorting.DESC_V24H);
      setCoinsList(coinsList.sort(sortByV24HToLess));
    } else {
      setActiveSorting(CoinsSorting.INC_V24H);
      setCoinsList(coinsList.sort(sortByV24HToMore));
    }
  }
};
