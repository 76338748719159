import { AccountName } from "../../const";
import {
  fetchAmountToStartBinanceFutures,
  fetchAmountToStartBinanceSpot,
  fetchAmountToStartBitget,
  fetchAmountToStartBybit,
  fetchAmountToStartOkx,
} from "../../store/api-actions/start";

export const fetchAmountToStartForCurrentAccount = {
  [AccountName.Okx]: fetchAmountToStartOkx(),
  [AccountName.Bybit]: fetchAmountToStartBybit(),
  [AccountName.BitgetFutures]: fetchAmountToStartBitget(),
  [AccountName.BinanceFutures]: fetchAmountToStartBinanceFutures(),
  [AccountName.BinanceSpot]: fetchAmountToStartBinanceSpot(),
};
