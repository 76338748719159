export default function ArrowUpIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.39258 13.5C3.80273 13.5 0.892578 10.5899 0.892578 7C0.892578 3.41015 3.80273 0.5 7.39258 0.5C10.9824 0.5 13.8926 3.41015 13.8926 7C13.8926 10.5899 10.9824 13.5 7.39258 13.5ZM7.39263 12.2002C10.2645 12.2002 12.5926 9.87208 12.5926 7.0002C12.5926 4.12831 10.2645 1.8002 7.39263 1.8002C4.52075 1.8002 2.19263 4.12831 2.19263 7.0002C2.19263 9.87208 4.52075 12.2002 7.39263 12.2002ZM6.74248 10.2504C6.74248 10.6094 7.03349 10.9004 7.39248 10.9004C7.75146 10.9004 8.04248 10.6094 8.04248 10.2504V5.32079L8.88286 6.16001L8.9441 6.21408C9.19909 6.41235 9.56779 6.39433 9.8021 6.16001C10.0559 5.90617 10.0559 5.49461 9.8021 5.24077L7.8521 3.29077L7.79902 3.24319L7.73094 3.19529L7.66757 3.16128L7.59537 3.13272L7.52295 3.11355L7.44139 3.1022L7.39248 3.10039L7.31572 3.10488L7.21958 3.12369L7.15111 3.14669L7.07784 3.18143L7.00553 3.22801L6.96143 3.26386L6.93286 3.29077L4.98286 5.24077L4.92879 5.30201C4.73052 5.557 4.74855 5.9257 4.98286 6.16001L5.0441 6.21408L5.11007 6.25914C5.3596 6.40784 5.68731 6.3748 5.9021 6.16001L6.74248 5.31949L6.74248 10.2504Z"
        fill="currentColor"
      />
    </svg>
  );
}
