import FaqItem from "./faq-item";
import {createBybitConnectLink} from "../../utils/id";
import {BYBIT_REGISTER_LINK} from "../../const";

export default function BybitFaq(): JSX.Element {
  const bybitConnectLink = createBybitConnectLink();

  return (
    <div className="landing__item faq faq--landing">
      <h2 className="landing__title landing__title--faq">
        Frequently Asked Questions
      </h2>
      <ul className="faq__inner-list">
        <FaqItem
          question="What is Bybit?"
          answer={
            <p className="faq-item__paragraph">
              Bybit is a cryptocurrency exchange platform that allows users to
              trade a variety of digital assets, including Bitcoin, Ethereum,
              Ripple, and more.
            </p>
          }
        />
        <FaqItem
          question=" Is Bybit safe to use?"
          answer={
            <p className="faq-item__paragraph">
              Bybit prioritizes the security of its users' funds. The platform
              employs industry-leading security measures, including cold storage
              and multi-signature wallets, to ensure the safety of funds.
            </p>
          }
        />

        <FaqItem
          question="How can I get started with Bybit?"
          answer={
            <p className="faq-item__paragraph">
              To get started with Bybit, simply{" "}
              <a
                className="link"
                href={BYBIT_REGISTER_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                create an account
              </a>{" "}
              on the platform and go through the verification process. After
              verification, you will be able to fund your account and start
              connecting to SMARD.
            </p>
          }
        />
        <FaqItem
          question="How much does Bybit trading bot by SMARD cost?"
          answer={
            <p className="faq-item__paragraph">
              SMARD has no fixed fees and does not require a credit card to
              start trading. The first payment is due after 30 days and is 10%
              of profits only.
            </p>
          }
        />
        <FaqItem
          question="How do I connect my SMARD account to Bybit using the API?"
          answer={
            <>
              <p className="faq-item__paragraph">
                SMARD users can connect their Bybit account in just a few clicks
                using the{" "}
                <a
                  className="link"
                  href={bybitConnectLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Fast API
                </a>
                .
              </p>
            </>
          }
        />
      </ul>
    </div>
  );
}