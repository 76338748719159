import AlertIcon from "../icons/alert/alert-icon";
import { useAppDispatch } from "../../hooks";
import { deleteAddedCard } from "../../store/api-actions";
import {
  setDeleteCardModalId,
  setDeleteCardModalOpened,
} from "../../store/actions";

type DeleteCardModalProps = {
  id: string;
};

export default function DeleteCardModal({
  id,
}: DeleteCardModalProps): JSX.Element {
  const dispatch = useAppDispatch();

  const handleCancelClick = () => {
    dispatch(setDeleteCardModalOpened(false));
    dispatch(setDeleteCardModalId(null));
  };

  const handleDeleteClick = () => {
    dispatch(deleteAddedCard(id));
  };

  return (
    <div className="modal">
      <div className="modal__wrapper ">
        <h2 className="modal__heading">
          <span className="modal__heading-icon">
            <AlertIcon />
          </span>
          Warning
        </h2>
        <p className="modal__text">Are you sure you want to delete the card?</p>
        <div className="modal__stop-wrapper">
          <button
            className="modal__btn modal__btn--cancel"
            type="button"
            onClick={handleCancelClick}
          >
            Cancel
          </button>
          <button
            className="modal__btn modal__btn--delete"
            type="button"
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
