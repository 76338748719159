import { useAppDispatch } from "../../../../hooks";
import { changePositionModeBybit } from "../../../../store/api-actions/start";

export default function BybitChangeMarginModeNote(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleLinkClick = () => {
    dispatch(changePositionModeBybit());
  };

  return (
    <span className="message__note">
      Click{" "}
      <button
        className="message__link link link--underline"
        onClick={handleLinkClick}
        type="button"
      >
        here
      </button>{" "}
      to automatically change margin mode to cross margin and enable trading in
      your account.
    </span>
  );
}
