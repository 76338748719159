import { useState } from "react";
import { AnimatePresence } from "framer-motion";

import Hint from "../hint/hint";
import TableToggle from "../table-items/table-toggle";
import TableInstrument from "../table-items/table-instrument";
import TableAbsolute from "../table-items/table-result";
import TableTrending from "../table-items/table-trending";
import AnimatedTableChart from "../table-items/animated-table-chart";
import TableQuantity from "../table-items/table-quantity";
import { AuthStatus, HINTS_TEXT, START_CURRENCY } from "../../const";
import { useAppSelector } from "../../hooks";
import { CurrentPositionType } from "../../types/details";

export default function CurrentPosition(): JSX.Element {
  const { authStatus, strategyDetails } = useAppSelector((state) => state);
  const positionList =
    strategyDetails?.current_position as CurrentPositionType[];
  const currency = strategyDetails?.overview.currency as string;
  const isNoAuth = authStatus === AuthStatus.NoAuth;

  const [openedChart, setOpenedChart] = useState<null | number>(null);

  const handleChartToggle = (index: number) => {
    if (index === openedChart) {
      setOpenedChart(null);
      return;
    }
    setOpenedChart(index);
  };

  const titleRow = (
    <li className="current-position__title-row">
      <div className="current-position__row-wrapper">
        <p className="current-position__title current-position__title--instrument">
          Instrument
        </p>
        <p className="current-position__title current-position__title--quantity">
          Quantity
        </p>
        <p className="current-position__title current-position__title--amount">
          <span className="current-position__title-title">Amount</span>
          <span className="current-position__title-gap">{currency}</span>
        </p>
      </div>
    </li>
  );

  const hint = (
    <Hint
      type="block__hint"
      id="details/current-position"
      hintText={HINTS_TEXT["details/current-position"]}
      block="current-position"
    />
  );

  return (
    <section className="details__item details__position current-position block">
      <div className="block__heading-wrapper">
        <h2 className="block__heading">Current position</h2>
        {isNoAuth && hint}
      </div>
      <ul className="current-position__table">
        {titleRow}
        {/* ROWS */}
        {positionList &&
          positionList.map((item, index) => {
            return (
              <li
                className="current-position__row current-position__row--without-chart"
                // className={`current-position__row ${
                //   item.chart_data ? "" : "current-position__row--without-chart"
                // }`}
                key={index}
                onClick={() => {
                  if (item.chart_data) {
                    handleChartToggle(index);
                  }
                }}
              >
                <div className="current-position__row-wrapper">
                  <TableInstrument
                    url={item.logo_url}
                    title={item.title}
                    ticker={item.ticker}
                  />

                  <div className="current-position__quantity">
                    {item.code !== START_CURRENCY && (
                      <TableQuantity amount={item.quantity} />
                    )}
                  </div>

                  <div className="current-position__amount">
                    <div className="current-position__amount-absolute">
                      <TableAbsolute amount={item.amount.absolute} />
                    </div>
                    {item.code !== START_CURRENCY && (
                      <div className="current-position__amount-relative">
                        <TableTrending amount={item.amount.relative} />
                      </div>
                    )}
                  </div>

                  {/* {item.chart_data && (
                    <div className="recent-activity__toggle">
                      <TableToggle isActive={openedChart === index} />
                    </div>
                  )} */}
                </div>

                {/* {item.chart_data && (
                  <AnimatePresence initial={false}>
                    {openedChart === index && (
                      <AnimatedTableChart
                        data={item.chart_data.quotes}
                        points={item.chart_data.entry_points}
                        result={item.amount.relative}
                      />
                    )}
                  </AnimatePresence>
                )} */}
              </li>
            );
          })}
      </ul>
    </section>
  );
}
