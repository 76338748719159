export default function BlueCrossedDollarIcon(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.8047 14C22.8047 12.5441 22.4492 11.1698 21.8209 9.95876L16.4034 15.3762C16.4124 15.46 16.4172 15.5451 16.4172 15.6313C16.4172 16.6698 15.7491 17.5547 14.8204 17.8802V18.7906C14.8204 19.2437 14.4531 19.6109 14.0001 19.6109C13.547 19.6109 13.1797 19.2437 13.1797 18.7906V18.5999L9.95881 21.8208C11.1698 22.4491 12.5441 22.8047 14 22.8047C18.8549 22.8047 22.8047 18.8549 22.8047 14ZM5.19531 14C5.19531 15.4559 5.55084 16.8302 6.17914 18.0412L11.5863 12.6341C11.5816 12.5591 11.583 12.4451 11.5828 12.3687C11.5828 11.3302 12.2509 10.4453 13.1797 10.1198V9.20937C13.1797 8.75634 13.547 8.38906 14 8.38906C14.453 8.38906 14.8203 8.75634 14.8203 9.20937V9.40013L18.0412 6.1792C16.8302 5.55089 15.4559 5.19531 14 5.19531C9.14512 5.19531 5.19531 9.14506 5.19531 14Z"
        fill="#60A5FA"
      />
      <path
        d="M14 0C6.28037 0 0 6.28037 0 14C0 21.7196 6.28037 28 14 28C21.7196 28 28 21.7196 28 14C28 6.28037 21.7196 0 14 0ZM4.24337 20.9458C2.79158 18.911 2.0242 16.5092 2.0242 14C2.0242 10.801 3.26982 7.79357 5.53175 5.5317C7.79357 3.26988 10.801 2.0242 14 2.0242C16.5093 2.0242 18.9111 2.79158 20.9458 4.24342L21.7345 4.80616L4.8061 21.7345L4.24337 20.9458ZM22.4683 22.4683C20.2065 24.7302 17.199 25.9759 14 25.9759C11.4907 25.9759 9.0889 25.2085 7.0542 23.7566L6.26549 23.1939L23.1939 6.26555L23.7566 7.0542C25.2084 9.08895 25.9758 11.4908 25.9758 14C25.9758 17.199 24.7302 20.2064 22.4683 22.4683Z"
        fill="#60A5FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.417 27.9939C21.9445 27.7726 28 21.5801 28 14C28 6.41983 21.9445 0.227396 14.417 0.00610352V2.03127C16.7749 2.11152 19.0241 2.87218 20.9458 4.2434L21.7345 4.80614L14.417 12.1236V15.0424L23.1939 6.26553L23.7566 7.05418C25.2084 9.08893 25.9758 11.4908 25.9758 14C25.9758 17.199 24.7302 20.2064 22.4683 22.4683C20.305 24.6316 17.4596 25.8654 14.417 25.9688V27.9939Z"
        fill="#2563EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.417 22.7949C19.079 22.5765 22.8047 18.7152 22.8047 14C22.8047 12.5441 22.4492 11.1698 21.8209 9.95877L16.4034 15.3762C16.4124 15.4601 16.4172 15.5451 16.4172 15.6313C16.4172 16.6698 15.7491 17.5548 14.8204 17.8802V18.7906C14.8204 19.0914 14.6584 19.3544 14.417 19.4972V22.7949ZM14.417 8.50282C14.6584 8.64561 14.8203 8.90859 14.8203 9.20938V9.40013L18.0412 6.1792C16.9465 5.61122 15.7183 5.26611 14.417 5.20508V8.50282Z"
        fill="#2563EB"
      />
    </svg>
  );
}
