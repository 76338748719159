import { useState } from "react";

import RelativeBlockSkeleton from "../skeletons/relative-block-skeleton";
import RelativeBlockSliders from "./relative-block-sliders";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import { RERENDER_DELAY } from "../../const";
import { useAppSelector } from "../../hooks";

export default function RelativeBlock(): JSX.Element {
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, RERENDER_DELAY)
  );

  const {
    okxDemo,
    binanceSpotDemo,
    binanceFuturesDemo,
    bitgetFuturesDemo,
    bybitDemo,
    isOkxDemoLoading,
    isBinanceSpotDemoLoading,
    isBinanceFuturesDemoLoading,
    isBitgetFuturesDemoLoading,
    isBybitDemoLoading,
    isCalcChartLoading,
  } = useAppSelector((state) => state);

  const isDemoLoading =
    isOkxDemoLoading ||
    isBinanceSpotDemoLoading ||
    isBinanceFuturesDemoLoading ||
    isBybitDemoLoading ||
    isBitgetFuturesDemoLoading;

  // Если шире мобилки - ожидания и demo и calc
  // Если мобилка - то только demo
  const isLoading = (isTabletAndMore && isCalcChartLoading) || isDemoLoading;

  return (
    <section className="relative-block">
      <h2 className="landing__title">Connected to major exchanges</h2>
      {isLoading ? (
        <RelativeBlockSkeleton />
      ) : (
        <div>
          {okxDemo &&
            binanceSpotDemo &&
            binanceFuturesDemo &&
            bitgetFuturesDemo &&
            bybitDemo &&(
              <RelativeBlockSliders
                binanceSpotData={binanceSpotDemo}
                binanceFuturesData={binanceFuturesDemo}
                bitgetFuturesData={bitgetFuturesDemo}
                okxData={okxDemo}
                bybitData={bybitDemo}
              />
            )}
        </div>
      )}
    </section>
  );
}
