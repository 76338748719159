import PaymentsAd from "../../components/payments-ad/payments-ad";
import PaymentsOrder from "../../components/payments-order/payments-order";
import PaymentsBalance from "../payments-balance/payments-balance";

export default function PaymentsWrapper(): JSX.Element {
  return (
    <div className="payments-wrapper">
      <div className="payments-wrapper__item payments-wrapper__item--balance">
        <PaymentsBalance />
      </div>
      <div className="payments-wrapper__item payments-wrapper__item--order">
        <PaymentsOrder />
      </div>
      <div className="payments-wrapper__item payments-wrapper__item--ad">
        <PaymentsAd />
      </div>
    </div>
  );
}
