import { Dispatch, SetStateAction } from "react";
import ReactSlider from "react-slider";

import { CalcChartType } from "../../types/chart";

type CalcSlidersProps = {
  calcChart: CalcChartType[];
  initialAmount: number;
  setInitialAmount: Dispatch<SetStateAction<number>>;
  period: number;
  setPeriod: Dispatch<SetStateAction<number>>;
};

export default function CalcSliders({
  calcChart,
  initialAmount,
  setInitialAmount,
  period,
  setPeriod,
}: CalcSlidersProps): JSX.Element {
  const checkMonths = (amount: number) => {
    if (amount === 1) {
      return "month";
    }
    return "months";
  };

  const handleInitialAmountChange = (valueNow: number) => {
    setInitialAmount(valueNow);
  };

  const handlePeriodChange = (valueNow: number) => {
    setPeriod(valueNow);
  };

  return (
    <div className="calc-sliders">
      <div className="calc-sliders__item">
        <span className="calc-sliders__label">Initial amount</span>
        <ReactSlider
          className="calc-sliders__slider"
          ariaLabel="Initial amount"
          thumbClassName="calc-sliders__thumb"
          trackClassName="calc-sliders__track"
          min={1000}
          max={20000}
          defaultValue={initialAmount}
          onAfterChange={handleInitialAmountChange}
          renderThumb={(props, state) => (
            <div {...props}>
              <span className="calc-sliders__mark">$</span>
              <span className="calc-sliders__value">{`$${state.valueNow}`}</span>
            </div>
          )}
        />
      </div>

      <div className="calc-sliders__item">
        <span className="calc-sliders__label">Length of investment</span>
        {calcChart && (
          <ReactSlider
            className="calc-sliders__slider"
            ariaLabel="Length of investment"
            thumbClassName="calc-sliders__thumb"
            trackClassName="calc-sliders__track"
            min={1}
            max={calcChart.length - 1}
            defaultValue={period}
            onAfterChange={handlePeriodChange}
            renderThumb={(props, state) => (
              <div {...props}>
                <span className="calc-sliders__mark">M</span>
                <span className="calc-sliders__value">{`${
                  state.valueNow
                } ${checkMonths(state.valueNow)}`}</span>
              </div>
            )}
          />
        )}
      </div>
    </div>
  );
}
