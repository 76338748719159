import { createAsyncThunk } from "@reduxjs/toolkit";

import { ThunkApiConfigType } from "../../types/api";
import { StateAction } from "../action-types";
import { ApiRoute } from "../../const";
import { LogsToServerType } from "../../types/interface";

export const sendLogsToServer = createAsyncThunk<
  void,
  LogsToServerType,
  ThunkApiConfigType
>(
  StateAction.Interface.SendLogsToServer,
  async ({ message, pathname, error }, { dispatch, extra: api }) => {
    try {
      api.post(ApiRoute.LogsToServer, {
        message,
        pathname,
        error,
      });
    } catch {}
  }
);
