export default function PlusInCircleIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.9 7.17a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0Zm7.09-.58h2.69v1.2h-2.7v2.7H6.8V7.8H4.11v-1.2h2.68V3.84H8V6.6Zm-.6 5.78a5.2 5.2 0 1 1 0-10.4 5.2 5.2 0 0 1 0 10.4Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
