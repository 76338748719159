export default function TwitterNewIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L14.8077 21H21L6.19231 0H0ZM2.59637 1.34615L15.5056 19.6538H18.4036L5.49436 1.34615H2.59637Z"
        fill="currentColor"
      />
      <path
        d="M20.4615 0H18.5769L11.3129 8.21154L12.1257 9.42308L20.4615 0Z"
        fill="currentColor"
      />
      <path
        d="M0 21H1.88462L9.625 12.25L8.69305 11.1731L0 21Z"
        fill="currentColor"
      />
    </svg>
  );
}
