import { AccountName } from "../../const";
import { useAppSelector } from "../../hooks";
import { QuotesItemsType, StrategyResultsType } from "../../types/details";
import GeneralPromoInsight from "../insights/general-exchange-insight";
import BybitButtonGroup from "./bybit-promo/bybit-button-group";

type BybitPromoProps = {
  isMobile: boolean;
};

export default function BybitPromo({ isMobile }: BybitPromoProps): JSX.Element {
  const { isBybitConnected, bybitDemo } = useAppSelector((state) => state);

  const quotes = bybitDemo?.quotes as QuotesItemsType;
  const results = bybitDemo?.strategy_results as StrategyResultsType;

  return (
    <section className="landing__item exchange-promo">
      <div className="exchange-promo_container">
        <div className="exchange-promo__heading">
          <h1 className="landing__heading">
            AUTOMATE YOUR PORTFOLIO MANAGEMET ON BYBIT
          </h1>
        </div>

        <p className="exchange-promo__description">
          Integrated with Bybit, SMARD enables seamless trading on a highly
          liquid exchange. Advanced algorithms, automated portfolio management
          and risk controls, allow SMARD to benefit from market opportunities
          without any unnecessary risk.
        </p>

        {isMobile ? null : <br />}
        {isBybitConnected ? null : (
          <p className="exchange-promo__description">
            Still don't have a Bybit account? Create one now and get access to
            our top-performing strategies.
          </p>
        )}
        {isMobile ? null : <BybitButtonGroup />}
      </div>
      {bybitDemo ? (
        <GeneralPromoInsight
          isMobile={isMobile}
          quotes={quotes}
          results={results}
          type={AccountName.Bybit}
        />
      ) : null}
      {isMobile ? <BybitButtonGroup /> : null}
    </section>
  );
}
