import { motion } from "framer-motion";

import PositionDiscrepancy from "./position-discrepancy";
import { DivergenceType } from "../../types/details";

type AnimatedPositionDiscrepancyProps = {
  divergence: DivergenceType[];
};

export default function AnimatedPositionDiscrepancy({
  divergence,
}: AnimatedPositionDiscrepancyProps): JSX.Element {
  return (
    <motion.div
      initial={{
        height: 0,
        opacity: 0,
      }}
      animate={{
        height: "auto",
        opacity: 1,
      }}
      exit={{
        height: 0,
        opacity: 0,
      }}
      style={{ overflow: "hidden" }}
      transition={{ duration: 0.4 }}
      className="details__discrepancy"
    >
      <div className="details__item">
        <PositionDiscrepancy divergence={divergence} />
      </div>
    </motion.div>
  );
}
