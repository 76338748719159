import Message from "../../message/message";
import OkxChangeModeNote from "../notes/okx-change-mode-note";
import OkxMinNotionalError from "../notes/okx-min-notional-error";
import OkxChangeModeAgainNote from "../notes/okx-change-mode-again-note";
import AmountRangeNote from "../notes/amount-rage-note";
import { minNotional } from "../../../utils/env";
import { ResponseStatusType } from "../../../types/details";
import {
  ErrorType,
  OKX_BITGET_CHANGE_MODE_ERROR,
  OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE,
} from "../../../const";

type OkxStep3MessageProps = {
  availableAmount: number;
  sendAmountToStartStatus: null | ResponseStatusType;
  changePositionModeStatus: null | ResponseStatusType;
  loadAmountToStartStatus: null | ResponseStatusType;
  checkPositionModeStatus: null | ResponseStatusType;
};

export default function OkxStep3Message({
  availableAmount,
  sendAmountToStartStatus,
  changePositionModeStatus,
  loadAmountToStartStatus,
  checkPositionModeStatus,
}: OkxStep3MessageProps): JSX.Element {
  const isCodeEqualToUnexpectedMode = (code: number) =>
    code === OKX_BITGET_UNEXPECTED_ACCOUNT_LEVEL_OR_POSITION_MODE;
  const isCodeEqualToChangeModeError = (code: number) =>
    code === OKX_BITGET_CHANGE_MODE_ERROR;

  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isStartStatusFail = sendAmountToStartStatus?.isSuccess === false;
  const startStatus = sendAmountToStartStatus;
  const checkModeStatus = checkPositionModeStatus;

  const shouldChangeModeNote =
    (startStatus &&
      isStartStatusFail &&
      startStatus.code &&
      isCodeEqualToUnexpectedMode(startStatus.code)) ||
    (checkModeStatus &&
      checkPositionModeStatus &&
      checkModeStatus.code &&
      isCodeEqualToUnexpectedMode(checkModeStatus.code));

  const shouldChangeModeAgainNote =
    changePositionModeStatus &&
    isChangeModeFail &&
    changePositionModeStatus.code &&
    isCodeEqualToChangeModeError(changePositionModeStatus.code);

  return shouldChangeModeAgainNote ? (
    <Message status="error" message={<OkxChangeModeAgainNote />} />
  ) : shouldChangeModeNote ? (
    <Message status="error" message={<OkxChangeModeNote />} />
  ) : isStartStatusFail ? (
    <Message status="error" message={sendAmountToStartStatus.message} />
  ) : isLoadAmountFail || isCheckModeFail || isChangeModeFail ? (
    <Message status="error" message={ErrorType.General} />
  ) : availableAmount >= minNotional ? (
    <Message
      status="neutral"
      message={<AmountRangeNote availableAmount={availableAmount} />}
    />
  ) : (
    <Message status="error" message={<OkxMinNotionalError />} />
  );
}
