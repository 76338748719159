export default function PercentInCircleWithArrowUpIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4981_11094)">
        <path
          d="M14 0C6.33347 0 0 6.33347 0 14C0 21.6663 6.33347 28 14 28C21.6665 28 28 21.6663 28 14C28 6.33347 21.6665 0 14 0Z"
          fill="#60A5FA"
        />
        <path
          d="M28 14C28 21.6663 21.6665 28 14 28V0C21.6665 0 28 6.33347 28 14Z"
          fill="#2563EB"
        />
        <path
          d="M9.70068 7C10.6409 7 11.3523 7.37259 11.835 8.11776C12.3239 8.85673 12.5684 9.90308 12.5684 11.2568C12.5684 12.6106 12.3333 13.6662 11.8632 14.4238C11.3931 15.1752 10.6723 15.5509 9.70068 15.5509C8.79806 15.5509 8.10543 15.1752 7.62278 14.4238C7.14639 13.6662 6.9082 12.6106 6.9082 11.2568C6.9082 9.90308 7.13699 8.85673 7.59457 8.11776C8.05215 7.37259 8.75418 7 9.70068 7ZM9.70068 8.10845C9.19296 8.10845 8.82 8.37237 8.58181 8.9002C8.34362 9.42182 8.22452 10.2074 8.22452 11.2568C8.22452 12.3063 8.34362 13.098 8.58181 13.6321C8.82 14.1661 9.19296 14.4331 9.70068 14.4331C10.2272 14.4331 10.619 14.1661 10.876 13.6321C11.133 13.098 11.2615 12.3063 11.2615 11.2568C11.2615 10.2136 11.133 9.42803 10.876 8.9002C10.6252 8.37237 10.2335 8.10845 9.70068 8.10845ZM18.4072 7.19561L10.7819 20.8137H9.40921L17.0345 7.19561H18.4072ZM18.0405 12.4584C18.9745 12.4584 19.6859 12.831 20.1748 13.5762C20.6637 14.3151 20.9082 15.3615 20.9082 16.7152C20.9082 18.0628 20.6731 19.1153 20.203 19.8729C19.7329 20.6243 19.0121 21 18.0405 21C17.1316 21 16.439 20.6243 15.9626 19.8729C15.4862 19.1153 15.248 18.0628 15.248 16.7152C15.248 15.3615 15.4737 14.3151 15.925 13.5762C16.3826 12.831 17.0877 12.4584 18.0405 12.4584ZM18.0405 13.5762C17.5328 13.5762 17.1598 13.837 16.9216 14.3586C16.6834 14.8802 16.5643 15.6658 16.5643 16.7152C16.5643 17.7647 16.6834 18.5564 16.9216 19.0905C17.1598 19.6183 17.5328 19.8822 18.0405 19.8822C18.567 19.8822 18.9588 19.6214 19.2158 19.0998C19.4728 18.572 19.6013 17.7771 19.6013 16.7152C19.6013 15.672 19.4728 14.8896 19.2158 14.3679C18.9651 13.8401 18.5733 13.5762 18.0405 13.5762Z"
          fill="#60A5FA"
        />
        <mask
          id="mask0_4981_11094"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="6"
          y="7"
          width="15"
          height="14"
        >
          <path
            d="M9.70068 7C10.6409 7 11.3523 7.37259 11.835 8.11776C12.3239 8.85673 12.5684 9.90308 12.5684 11.2568C12.5684 12.6106 12.3333 13.6662 11.8632 14.4238C11.3931 15.1752 10.6723 15.5509 9.70068 15.5509C8.79806 15.5509 8.10543 15.1752 7.62278 14.4238C7.14639 13.6662 6.9082 12.6106 6.9082 11.2568C6.9082 9.90308 7.13699 8.85673 7.59457 8.11776C8.05215 7.37259 8.75418 7 9.70068 7ZM9.70068 8.10845C9.19296 8.10845 8.82 8.37237 8.58181 8.9002C8.34362 9.42182 8.22452 10.2074 8.22452 11.2568C8.22452 12.3063 8.34362 13.098 8.58181 13.6321C8.82 14.1661 9.19296 14.4331 9.70068 14.4331C10.2272 14.4331 10.619 14.1661 10.876 13.6321C11.133 13.098 11.2615 12.3063 11.2615 11.2568C11.2615 10.2136 11.133 9.42803 10.876 8.9002C10.6252 8.37237 10.2335 8.10845 9.70068 8.10845ZM18.4072 7.19561L10.7819 20.8137H9.40921L17.0345 7.19561H18.4072ZM18.0405 12.4584C18.9745 12.4584 19.6859 12.831 20.1748 13.5762C20.6637 14.3151 20.9082 15.3615 20.9082 16.7152C20.9082 18.0628 20.6731 19.1153 20.203 19.8729C19.7329 20.6243 19.0121 21 18.0405 21C17.1316 21 16.439 20.6243 15.9626 19.8729C15.4862 19.1153 15.248 18.0628 15.248 16.7152C15.248 15.3615 15.4737 14.3151 15.925 13.5762C16.3826 12.831 17.0877 12.4584 18.0405 12.4584ZM18.0405 13.5762C17.5328 13.5762 17.1598 13.837 16.9216 14.3586C16.6834 14.8802 16.5643 15.6658 16.5643 16.7152C16.5643 17.7647 16.6834 18.5564 16.9216 19.0905C17.1598 19.6183 17.5328 19.8822 18.0405 19.8822C18.567 19.8822 18.9588 19.6214 19.2158 19.0998C19.4728 18.572 19.6013 17.7771 19.6013 16.7152C19.6013 15.672 19.4728 14.8896 19.2158 14.3679C18.9651 13.8401 18.5733 13.5762 18.0405 13.5762Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4981_11094)">
          <rect
            x="5.50781"
            y="4.19922"
            width="8.4"
            height="18.2"
            fill="#A0CBFF"
          />
        </g>
        <path
          d="M21.25 28H25.75V22.2857H28L23.5 18L19 22.2857H21.25V28Z"
          fill="#60A5FA"
        />
      </g>
      <defs>
        <clipPath id="clip0_4981_11094">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
