export default function CalendarIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.5 6.56V12c0 .83.67 1.5 1.5 1.5h10c.83 0 1.5-.67 1.5-1.5V6.56h-13Zm13-1.5h-13V4.6c0-.83.67-1.5 1.5-1.5h10c.83 0 1.5.67 1.5 1.5v.47ZM16 4.6a3 3 0 0 0-3-3H3a3 3 0 0 0-3 3V12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V4.6Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.97 0c.36 0 .65.26.65.6v3.22c0 .33-.3.6-.65.6-.37 0-.66-.27-.66-.6V.6c0-.33.3-.59.66-.59ZM11.04 0c.36 0 .65.26.65.6v3.22c0 .33-.3.6-.65.6-.37 0-.66-.27-.66-.6V.6c0-.33.3-.59.66-.59Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M3.43 8c0-.28.22-.5.5-.5h1.29c.27 0 .5.22.5.5v1.05a.5.5 0 0 1-.5.5H3.93a.5.5 0 0 1-.5-.5V7.99ZM3.43 11.08c0-.28.22-.5.5-.5h1.29c.27 0 .5.22.5.5v1.06a.5.5 0 0 1-.5.5H3.93a.5.5 0 0 1-.5-.5v-1.06ZM6.86 8c0-.28.22-.5.5-.5h1.28c.28 0 .5.22.5.5v1.05a.5.5 0 0 1-.5.5H7.36a.5.5 0 0 1-.5-.5V7.99ZM6.86 11.08c0-.28.22-.5.5-.5h1.28c.28 0 .5.22.5.5v1.06a.5.5 0 0 1-.5.5H7.36a.5.5 0 0 1-.5-.5v-1.06ZM10.29 8c0-.28.22-.5.5-.5h1.28c.28 0 .5.22.5.5v1.05a.5.5 0 0 1-.5.5H10.8a.5.5 0 0 1-.5-.5V7.99Z"
      />
    </svg>
  );
}
