import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import DiskIcon from "../icons/decoration/disk-icon";
import { AccountName, AppRoute, AuthStatus } from "../../const";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";
import { createOkxConnectLink } from "../../utils/id";
import { trackEvent } from "../../utils/tracking";
import useIsConnectButtonVisible from "../../hooks/useIsConnectButtonVisible";

export default function OkxSteps(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus, isOkxConnected, okxDemo } = useAppSelector(
    (state) => state
  );
  const isNoAuth = authStatus === AuthStatus.NoAuth;
  const okxConnectLink = createOkxConnectLink();
  const isConnectButtonVisible = useIsConnectButtonVisible(
    okxDemo?.strategy_results.sharpe_ratio
  );

  const handleConnectClick = () => {
    if (isNoAuth) {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("OKXLandingFastConnect", "Clicked");
  };

  const handleViewClick = () => {
    navigate(`${AppRoute.Accounts}/${AccountName.Okx}/`);
  };

  return (
    <section className="landing__item okx-steps">
      <h2 className="landing__title">How to start</h2>
      <ul className="okx-steps__list">
        <li className="okx-steps__item">
          <span className="okx-steps__icon">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">01</span>

          {isOkxConnected ? (
            <div className="okx-steps__block">
              <p className="okx-steps__info">Register with SMARD and OKX</p>
            </div>
          ) : isNoAuth ? (
            <button
              className="okx-steps__block okx-steps__block--register"
              onClick={handleConnectClick}
            >
              <p className="okx-steps__info">Register with SMARD and OKX</p>
            </button>
          ) : (
            <a
              className="okx-steps__block okx-steps__block--register"
              href={okxConnectLink}
              onClick={handleConnectClick}
            >
              <p className="okx-steps__info">Register with SMARD and OKX</p>
            </a>
          )}
        </li>

        <li className="okx-steps__item">
          <span className="okx-steps__icon">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">02</span>
          <div className="okx-steps__block">
            <p className="okx-steps__info">
              Automatically connect your OKX account
            </p>
          </div>
        </li>

        <li className="okx-steps__item">
          <span className="okx-steps__icon  okx-steps__icon--disk">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">03</span>
          <div className="okx-steps__block">
            <p className="okx-steps__info">
              Specify the amount to be traded by SMARD
            </p>
          </div>
        </li>

        <li className="okx-steps__item">
          <span className="okx-steps__icon  okx-steps__icon--disk">
            <DiskIcon />
          </span>
          <span className="okx-steps__number">04</span>
          <div className="okx-steps__block">
            <p className="okx-steps__info">Make profit</p>
          </div>
        </li>
      </ul>
      <div className="okx-steps__btn-wrapper">
        {isOkxConnected === null ? (
          <div className="landing__btn-gap"></div>
        ) : isOkxConnected ? (
          <Button
            pattern="white-blue"
            text="Learn more"
            type="button"
            icon="arrow-right"
            handleClick={handleViewClick}
          />
        ) : isConnectButtonVisible ? (
          <Button
            pattern="white-blue"
            text="Get started"
            icon="arrow-right"
            type={isNoAuth ? "button" : "link"}
            isSelfLink
            linkAddress={okxConnectLink}
            handleClick={handleConnectClick}
          />
        ) : null}
      </div>
    </section>
  );
}
