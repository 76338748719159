import Message from "../../message/message";
import AmountRangeNote from "../notes/amount-rage-note";
import { minNotional } from "../../../utils/env";
import { ResponseStatusType } from "../../../types/details";
import { ErrorType } from "../../../const";
import MinNotionalError from "../notes/min-notional-error";

type BinanceSpotStep3MessageProps = {
  availableAmount: number;
  sendAmountToStartStatus: null | ResponseStatusType;
  changePositionModeStatus: null | ResponseStatusType;
  loadAmountToStartStatus: null | ResponseStatusType;
  checkPositionModeStatus: null | ResponseStatusType;
};

export default function BinanceSpotStep3Message({
  availableAmount,
  sendAmountToStartStatus,
  changePositionModeStatus,
  loadAmountToStartStatus,
  checkPositionModeStatus,
}: BinanceSpotStep3MessageProps): JSX.Element {
  const isLoadAmountFail = loadAmountToStartStatus?.isSuccess === false;
  const isCheckModeFail = checkPositionModeStatus?.isSuccess === false;
  const isChangeModeFail = changePositionModeStatus?.isSuccess === false;
  const isStartStatusFail = sendAmountToStartStatus?.isSuccess === false;
  //   const startStatus = sendAmountToStartStatus;
  //   const checkModeStatus = checkPositionModeStatus;

  //   const shouldChangeModeNote =
  //     (startStatus && isStartStatusFail && startStatus.code) ||
  //     (checkModeStatus && checkPositionModeStatus && checkModeStatus.code);

  //   const shouldChangeModeAgainNote =
  //     changePositionModeStatus && isChangeModeFail;

  return isStartStatusFail ? (
    <Message status="error" message={sendAmountToStartStatus.message} />
  ) : isLoadAmountFail || isCheckModeFail || isChangeModeFail ? (
    <Message status="error" message={ErrorType.General} />
  ) : availableAmount >= minNotional ? (
    <Message
      status="neutral"
      message={<AmountRangeNote availableAmount={availableAmount} />}
    />
  ) : (
    <Message status="error" message={<MinNotionalError />} />
  );
}
