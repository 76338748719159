import Button from "../button/button";
import DiskIcon from "../icons/decoration/disk-icon";
import { useAppDispatch } from "../../hooks";
import { setPartnershipFormFocused } from "../../store/actions";

export default function HowToEarn(): JSX.Element {
  const dispatch = useAppDispatch();

  const handleBtnClick = () => {
    dispatch(setPartnershipFormFocused(true));
  };

  return (
    <section className="landing__item how-to-earn">
      <h2 className="landing__title">How to earn commission</h2>
      <ul className="how-to-earn__list">
        <li className="how-to-earn__item">
          <span className="how-to-earn__icon">
            <DiskIcon />
          </span>
          <span className="how-to-earn__number">01</span>

          <button
            className="how-to-earn__block how-to-earn__block--btn-to-form"
            aria-label="Scroll to the affiliate form"
            onClick={handleBtnClick}
          >
            <h3 className="how-to-earn__title">Apply to become an affiliate</h3>
            <p className="how-to-earn__info">
              Describe in detail your methods for ensuring customer engagement.
              We may contact you for more information.
            </p>
          </button>
        </li>

        <li className="how-to-earn__item">
          <span className="how-to-earn__icon">
            <DiskIcon />
          </span>
          <span className="how-to-earn__number">02</span>
          <div className="how-to-earn__block">
            <h3 className="how-to-earn__title">Share your affiliate link</h3>
            <p className="how-to-earn__info">
              Help your audience learn more about crypto and empower them to
              earn more with SMARD!
            </p>
          </div>
        </li>

        <li className="how-to-earn__item">
          <span className="how-to-earn__icon  how-to-earn__icon--disk">
            <DiskIcon />
          </span>
          <span className="how-to-earn__number">03</span>
          <div className="how-to-earn__block">
            <h3 className="how-to-earn__title">Earn commission</h3>
            <p className="how-to-earn__info">
              You will earn 30% to 50% of the commissions earned from the users
              you refer.
            </p>
          </div>
        </li>
      </ul>

      <Button
        classElement="how-to-earn__btn"
        pattern="white-blue"
        text="Join now"
        icon="arrow-right"
        type="button"
        handleClick={handleBtnClick}
      />
    </section>
  );
}
