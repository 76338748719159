export default function BlueChartIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox=" 0 0 22 22"
    >
      <path fill="#60A5FA" d="M3 0a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h8V0H3Z" />
      <path fill="#2563EB" d="M19 22a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3h-8v22h8Z" />
      <path
        fill="#A0CBFF"
        d="M8 18.5a.8.8 0 0 0 1.6 0H8ZM9.7 4A.8.8 0 0 0 8 4h1.5ZM3.3 18.5a.8.8 0 0 0 1.5 0H3.3ZM4.8 15a.8.8 0 0 0-1.5 0h1.5Zm8.1 3.6a.8.8 0 0 0 1.5 0H13Zm1.5-10.9a.8.8 0 0 0-1.5 0h1.5Zm3.4 11a.8.8 0 0 0 1.5 0h-1.5Zm1.5-7.3a.8.8 0 0 0-1.5 0h1.5Zm-9.7 7.2V4H8v14.5h1.5Zm-4.8 0V15H3.3v3.6h1.5Zm9.6 0V7.6H13v11h1.5Zm4.9 0v-7.2h-1.5v7.2h1.5Z"
      />
      <path
        fill="#60A5FA"
        d="M13 18.5a.8.8 0 0 0 1.4 0H13Zm1.4-10.9a.8.8 0 0 0-1.5 0h1.5Zm3.4 11a.8.8 0 0 0 1.5 0h-1.5Zm1.5-7.3a.8.8 0 0 0-1.5 0h1.5Zm-4.9 7.2V7.6H13v11h1.5Zm4.9 0v-7.2h-1.5v7.2h1.5Z"
      />
    </svg>
  );
}
