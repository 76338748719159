import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import { useAppDispatch } from "../../hooks";
import { setSendAmountToStartStatus } from "../../store/actions/start";
import { minNotional } from "../../utils/env";
import TriangleDownIcon from "../icons/triangle/triangle-down-icon";

type ChangeBalanceFieldsProps = {
  availableAmount: number;
  isSendAmountForStartLoading: boolean;
  sendAmountFailed: boolean;
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  isUseAllAmount: boolean;
  currentBalance: number;
};

export default function ChangeBalanceFields({
  availableAmount,
  isSendAmountForStartLoading,
  sendAmountFailed,
  setValue,
  trigger,
  register,
  isUseAllAmount,
  currentBalance,
}: ChangeBalanceFieldsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const validAmount = (value: number) =>
    Number(value) <= availableAmount &&
    Number(value) >= minNotional &&
    Number(value) !== currentBalance;

  const amountDisabledClass =
    isSendAmountForStartLoading || isUseAllAmount ? "field--disabled" : "";
  const maxDisabledClass =
    isSendAmountForStartLoading || isUseAllAmount ? "field--disabled" : "";

  const handleAmountChange = () => {
    if (sendAmountFailed) {
      dispatch(setSendAmountToStartStatus(null));
    }
  };

  const handleMaxClick = () => {
    setValue("amount", availableAmount);
    trigger("amount");
  };

  return (
    <div className="change-balance__fields-amount-inner-wrapper">
      <div className="change-balance__amount-wrapper">
        <label className="visually-hidden" htmlFor="amount">
          Amount
        </label>

        <input
          className={`connect-step__field connect-step__field--amount field ${amountDisabledClass}`}
          type="number"
          id="amount"
          placeholder="Amount"
          autoComplete="off"
          {...register("amount", {
            required: true,
            onChange: handleAmountChange,
            validate: (value) => validAmount(value),
          })}
        />

        <button
          className={`connect-step__amount-btn
                ${maxDisabledClass}`}
          type="button"
          onClick={handleMaxClick}
        >
          MAX
        </button>
      </div>

      {/* currency field */}
      <div className="change-balance__currency-field field field--disabled">
        USDT
        <span className="connect-step__currency-icon">
          <TriangleDownIcon />
        </span>
      </div>
    </div>
  );
}
