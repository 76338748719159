import { useState } from "react";
import Xarrow, { Xwrapper } from "react-xarrows";

import ChainIcon from "../icons/nav/chain-icon";
import CreditCardIcon from "../icons/nav/credit-card-icon";
import { checkIfTabletAndMore } from "../../utils/breakpoint";
import { debounce } from "../../utils/chart/chart";
import PersonIcon from "../../icons/person/person-icon";

type ReferralsIntroProps = {
  commission: string;
  isAffiliate: boolean;
};

export default function ReferralsIntro({
  commission,
  isAffiliate,
}: ReferralsIntroProps): JSX.Element {
  const [isTabletAndMore, setTabletAndMore] = useState<boolean>(
    checkIfTabletAndMore()
  );

  window.addEventListener(
    "resize",
    debounce(() => {
      setTabletAndMore(checkIfTabletAndMore());
    }, 0)
  );

  const displayLine = (start: string, end: string) => {
    return (
      <Xarrow
        start={start}
        end={end}
        startAnchor={{
          position: `${isTabletAndMore ? "right" : "bottom"}`,
          offset: { y: isTabletAndMore ? 0 : 3, x: isTabletAndMore ? 3 : 0 },
        }}
        endAnchor={{
          position: `${isTabletAndMore ? "left" : "top"}`,
          offset: { y: isTabletAndMore ? 0 : -3, x: isTabletAndMore ? -3 : 0 },
        }}
        lineColor="#DBEAFE"
        showHead={false}
        dashness={{ strokeLen: 6, nonStrokeLen: 6 }}
        strokeWidth={2.5}
        SVGcanvasProps={{
          strokeLinecap: "round",
        }}
      />
    );
  };

  return (
    <section className="referrals-intro">
      <Xwrapper>
        <ul className="referrals-intro__list">
          <li className="referrals-intro__item">
            <div className="referrals-intro__icon-wrapper" id="icon1point">
              <span className="referrals-intro__icon referrals-intro__icon--user">
                <PersonIcon />
              </span>
            </div>

            <p className="referrals-intro__item-text">
              {isAffiliate
                ? "You invite your audience"
                : "You invite your friends"}
            </p>
          </li>
          <li className="referrals-intro__item">
            <div className="referrals-intro__icon-wrapper" id="icon2point">
              <span className="referrals-intro__icon referrals-intro__icon--chain">
                <ChainIcon />
              </span>
            </div>
            <p className="referrals-intro__item-text">
              {isAffiliate
                ? "New users join in and start trading"
                : "They join in and start trading"}
            </p>
          </li>
          <li className="referrals-intro__item">
            <div className="referrals-intro__icon-wrapper" id="icon3point">
              <span className="referrals-intro__icon referrals-intro__icon--credit-card">
                <CreditCardIcon />
              </span>
            </div>
            <p className="referrals-intro__item-text">
              {`You get ${commission} of their commission`}
            </p>
          </li>
        </ul>
        {displayLine("icon1point", "icon2point")}
        {displayLine("icon2point", "icon3point")}
      </Xwrapper>
    </section>
  );
}
