export default function OkxIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 119 35"
      fill="none"
    >
      <path
        fill="#000"
        d="M34.9 0H.8a.8.8 0 0 0-.57.23.78.78 0 0 0-.23.55v33.44c0 .2.09.4.23.55A.8.8 0 0 0 .8 35h34.12a.8.8 0 0 0 .56-.23.78.78 0 0 0 .23-.55V.78c0-.2-.09-.4-.23-.55A.82.82 0 0 0 34.9 0ZM23.8 22.55c0 .2-.09.41-.24.55a.8.8 0 0 1-.56.23H12.69a.8.8 0 0 1-.56-.23.78.78 0 0 1-.23-.55v-10.1c0-.2.08-.41.23-.55a.8.8 0 0 1 .56-.23H23a.8.8 0 0 1 .56.23c.15.14.24.34.24.55v10.1ZM106.31 11.67h-10.3c-.44 0-.8.35-.8.78v10.1c0 .43.36.78.8.78h10.3c.44 0 .8-.35.8-.78v-10.1a.8.8 0 0 0-.8-.78ZM94.41 0H84.1c-.44 0-.8.35-.8.78V10.9c0 .43.36.78.8.78h10.31c.44 0 .8-.35.8-.78V.78a.78.78 0 0 0-.8-.78ZM118.2 0h-10.3c-.44 0-.8.35-.8.78V10.9c0 .43.36.78.8.78h10.3c.44 0 .8-.35.8-.78V.78a.78.78 0 0 0-.8-.78ZM94.41 23.33H84.1c-.44 0-.8.35-.8.78v10.11c0 .43.36.78.8.78h10.31c.44 0 .8-.35.8-.78v-10.1a.79.79 0 0 0-.8-.79ZM118.2 23.33h-10.3c-.44 0-.8.35-.8.78v10.11c0 .43.36.78.8.78h10.3c.44 0 .8-.35.8-.78v-10.1a.79.79 0 0 0-.8-.79ZM76.55 0H66.24c-.44 0-.8.35-.8.78V10.9c0 .43.36.78.8.78h10.3c.44 0 .8-.35.8-.78V.78a.78.78 0 0 0-.8-.78ZM76.55 23.33H66.24c-.44 0-.8.35-.8.78v10.11c0 .43.36.78.8.78h10.3c.44 0 .8-.35.8-.78v-10.1a.79.79 0 0 0-.8-.79ZM65.44 12.44c0-.2-.09-.4-.23-.55a.8.8 0 0 0-.56-.23H53.54V.78c0-.2-.09-.4-.23-.55a.8.8 0 0 0-.56-.23H42.44a.8.8 0 0 0-.56.23.78.78 0 0 0-.23.55V34.2c0 .2.08.4.23.55a.8.8 0 0 0 .56.23h10.31a.8.8 0 0 0 .56-.23.78.78 0 0 0 .24-.55V23.32h11.1a.8.8 0 0 0 .56-.22.78.78 0 0 0 .24-.56l-.01-10.1Z"
      />
    </svg>
  );
}
