import { useNavigate } from "react-router-dom";

import Button from "../button/button";
import { AppRoute, AuthStatus } from "../../const";
import { trackEvent } from "../../utils/tracking";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAuthModalOpened } from "../../store/actions";

export default function End(): JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authStatus } = useAppSelector((state) => state);

  const handleStartClick = () => {
    if (authStatus === AuthStatus.Auth) {
      navigate(AppRoute.Overview);
    } else {
      dispatch(setAuthModalOpened(true));
    }
    trackEvent("HIW", "Clicked");
  };

  return (
    <section className="landing__item landing__item--with-bg end">
      <h2 className="landing__title landing__title--blue">
        All technology is already implemented
      </h2>
      <p className="end__intro">
        The tool automatically places orders on your behalf. You don’t need to
        configure or install anything.
      </p>
      <div className="end__img-wrapper">
        <picture>
          <source
            type="image/webp"
            media="(min-width: 1080px)"
            srcSet={`${process.env.PUBLIC_URL}/img/cell/cell-desktop.webp 1x,
            ${process.env.PUBLIC_URL}/img/cell/cell-desktop@2x.webp 2x`}
            width="400"
            height="400"
          />

          <source
            type="image/webp"
            media="(min-width: 768px)"
            srcSet={`${process.env.PUBLIC_URL}/img/cell/cell-tablet.webp 1x,
            ${process.env.PUBLIC_URL}/img/cell/cell-tablet@2x.webp 2x`}
            width="350"
            height="350"
          />

          <source
            type="image/webp"
            srcSet={`${process.env.PUBLIC_URL}/img/cell/cell-mobile.webp 1x,
            ${process.env.PUBLIC_URL}/img/cell/cell-mobile@2x.webp 2x`}
            width="290"
            height="290"
          />

          <source
            media="(min-width: 1080px)"
            srcSet={`${process.env.PUBLIC_URL}/img/cell/cell-desktop.png 1x,
            ${process.env.PUBLIC_URL}/img/cell/cell-desktop@2x.png 2x`}
            width="400"
            height="400"
          />

          <source
            media="(min-width: 768px)"
            srcSet={`${process.env.PUBLIC_URL}/img/cell/cell-tablet.png 1x,
            ${process.env.PUBLIC_URL}/img/cell/cell-tablet@2x.png 2x`}
            width="350"
            height="350"
          />

          <img
            className="end__img"
            src={`${process.env.PUBLIC_URL}/img/cell/cell-mobile.png`}
            srcSet={`${process.env.PUBLIC_URL}/img/cell/cell-mobile@2x.png 2x`}
            alt="SMARD automated crypto trading bot on a trader smartphone."
            width="290"
            height="290"
          />
        </picture>
      </div>
      <div className="end__btn-wrapper">
        <Button
          pattern="white-blue"
          text="Start now"
          icon="arrow-right"
          type="button"
          handleClick={handleStartClick}
        />
      </div>
    </section>
  );
}
