export default function CloseIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.68084 0.716738C7.47727 0.513164 7.14842 0.513164 6.94485 0.716738L4.39235 3.26402L1.83985 0.711518C1.63628 0.507944 1.30743 0.507944 1.10385 0.711518C0.900278 0.915092 0.900278 1.24394 1.10385 1.44752L3.65635 4.00001L1.10385 6.55251C0.900278 6.75609 0.900278 7.08494 1.10385 7.28851C1.30743 7.49208 1.63628 7.49208 1.83985 7.28851L4.39235 4.73601L6.94485 7.28851C7.14842 7.49208 7.47727 7.49208 7.68084 7.28851C7.88442 7.08494 7.88442 6.75609 7.68084 6.55251L5.12835 4.00001L7.68084 1.44752C7.8792 1.24916 7.8792 0.915092 7.68084 0.716738Z"
        fill="currentColor"
      />
    </svg>
  );
}
