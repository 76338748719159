export default function BlueSearchIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 26 28"
    >
      <g clipPath="url(#a)">
        <path
          fill="#2563EB"
          d="M13 18.12Zm2.6 4.49-.08-.02.07.02Zm-2.2-5.08Zm2.9 5.27Zm-.54-.13-.09-.03.09.03Zm-2.5-4.78Zm2.85 4.87Zm-.18-.04Zm-.75-.28-.04-.03.04.03Zm-.15-.08Zm1.51.47h.07a4 4 0 0 1-.07 0Zm-3.34-4.77Zm.68 3.31-.02-.02.02.02Zm1.46 1.14-.05-.02.05.02Zm-2.22-4.07Zm.04-.18Zm-.08.56Zm.02-.17Zm1.79 3.62-.03-.02.03.02Zm2.04.58Zm-3.43-5.53-.04.06.04-.06Zm2.87-2.04Zm-3.29 3.59Zm3.47-3.62Zm-6.7 3.78h.9a6 6 0 0 1 .26-1.71H9.85v1.71Zm0-4h2.44a6.2 6.2 0 0 1 2.54-1.72H9.85v1.72Zm4.33 6.67c0 .02.02.03.03.04l.02.02.03.02a3.75 3.75 0 0 1-.08-.07Zm2.75-6.47Zm-1.28-4.21v2.06a6.24 6.24 0 0 1 4.06.52V6.4h-6.48V0H9.85v11.02h5.8Zm.54 4.28Zm-.53.15.05-.02-.05.02Zm-4.24 6.34-.22-.47H9.85v4.73h9.86v-1.56a6.2 6.2 0 0 1-8.3-2.7ZM16 15.35Zm-.16.04Z"
        />
        <path
          fill="#60A5FA"
          d="M4.06 21.32v-2.29h5.8v-1.71h-5.8v-2.3h5.8v-1.71h-5.8V11h5.8V0H0v26.05h9.85v-4.73h-5.8Z"
        />
        <path
          fill="#A0CBFF"
          d="M19.7 6.4 13.24 0v6.4h6.48ZM4.06 11.02v2.29h10.77c.27-.1.54-.17.82-.23v-2.06H4.05Zm9.01 8.01v.01Zm.38 1.65-.05-.1.05.1Zm-.16-.38Zm-.05-.17Zm.56 1.12-.04-.04.03.04Zm-.19-.27-.03-.06.03.06Zm-.42-1.02Zm.51 1.16Zm-.17-.29-.04-.06.04.06Zm-.18-.37-.03-.1c0 .04.02.07.03.1Zm-.26-1.1v.07a3.91 3.91 0 0 0 0-.07Zm.06.43Zm-9.09-.76v2.3h7.14a6 6 0 0 1-.44-2.29h-6.7Zm9.47-1.78-.03.07c0-.03.02-.05.03-.07Zm1.5-1.52Zm.12-.06Zm-1.03.78.02-.03-.03.03Zm.58-.51.01-.01-.01.01Zm.86-.46Zm-1.16.7.01-.02-.02.02Zm.94-.61h.02-.02Zm-1.33.99.03-.04-.03.04Zm-.24.31Zm-.21.32Zm.09-.14Zm.22-.31c0-.02.02-.03.03-.05l-.03.05Zm-9.81-1.71v2.29H11a6.2 6.2 0 0 1 1.28-2.3H4.06Zm17.98 7.44c-.44.63-1 1.18-1.64 1.62L24.36 28 26 26.38l-3.96-3.91Z"
        />
        <path
          fill="#2563EB"
          d="M20.02 16.77a3.88 3.88 0 0 0-3.09-1.54v7.62c2.13 0 3.86-1.7 3.86-3.8a3.75 3.75 0 0 0-.77-2.28Z"
        />
        <path
          fill="#60A5FA"
          d="M16.93 15.23h-.18l-.03.01-.17.01h-.04a3.84 3.84 0 0 0-.52.1l-.07.02a3.86 3.86 0 0 0-.2.06l-.06.02-.1.03a3.8 3.8 0 0 0-.4.18h-.01l-.11.07c-.12.06-.22.13-.33.2l-.01.01a3.86 3.86 0 0 0-.56.48l-.03.02-.07.08-.03.04a3.9 3.9 0 0 0-.1.13l-.04.05a3.6 3.6 0 0 0-.1.13l-.05.06-.07.12-.04.06-.05.08-.03.06-.03.07-.04.06-.02.05a3.57 3.57 0 0 0-.12.28l-.02.04a3.73 3.73 0 0 0-.1.31v.04l-.04.16-.01.04-.03.14v.04l-.02.17v.03l-.02.14v.22a3.85 3.85 0 0 0 .04.57v.06l.03.12.01.06a3.8 3.8 0 0 0 .13.45l.03.07.03.1.05.11.05.1.04.09.04.06a3.73 3.73 0 0 0 .23.38l.03.04.07.1.02.02a3.86 3.86 0 0 0 .98.88l.03.02.1.06.04.03.1.05.05.03.1.05.06.02.18.08.07.02.08.03.09.03.08.02a4.2 4.2 0 0 0 .27.07l.07.02.12.02h.06a3.92 3.92 0 0 0 .57.05v-7.62Z"
        />
        <path
          fill="#2488FF"
          d="M22.04 22.47a6.01 6.01 0 0 0-.1-6.98 6.2 6.2 0 0 0-11.18 3.54v.01a6 6 0 0 0 .66 2.74 6.19 6.19 0 0 0 10.62.69Zm-5.1.38a3.46 3.46 0 0 1-.33-.01h-.07l-.18-.03-.06-.01-.12-.02-.07-.02a3.89 3.89 0 0 1-.27-.07l-.08-.02-.09-.03c-.03 0-.05-.02-.08-.03l-.07-.02a3.97 3.97 0 0 1-.18-.08l-.05-.02a3.84 3.84 0 0 1-.1-.05l-.05-.03a3.8 3.8 0 0 1-.1-.05l-.05-.03a3.73 3.73 0 0 1-.1-.06l-.03-.02a3.82 3.82 0 0 1-.6-.46l-.03-.03-.02-.03-.03-.02-.3-.34-.02-.02a3.89 3.89 0 0 1-.07-.1l-.03-.04a3.57 3.57 0 0 1-.23-.38l-.04-.06-.04-.1-.05-.09a3.68 3.68 0 0 1-.05-.12l-.03-.1-.03-.06a3.78 3.78 0 0 1-.03-.1l-.02-.07a3.62 3.62 0 0 1-.08-.28l-.01-.06-.02-.12-.01-.06-.03-.18v-.07a4.61 4.61 0 0 1-.02-.32 3.78 3.78 0 0 1 .02-.36v-.03l.03-.17v-.04l.03-.14v-.04l.04-.16.01-.04a3.67 3.67 0 0 1 .1-.31l.02-.04a3.87 3.87 0 0 1 .12-.28l.02-.05.04-.06c0-.03.02-.05.03-.07l.03-.06.05-.08.04-.06.07-.12.05-.07a4.28 4.28 0 0 1 .1-.12c0-.02.02-.04.03-.05l.11-.13.03-.04.07-.08.03-.02a3.84 3.84 0 0 1 .56-.48l.01-.01.32-.2.12-.06.2-.1h.01l.2-.09c.04 0 .07-.02.1-.03l.05-.02a4.04 4.04 0 0 1 .21-.06l.07-.02.14-.03.06-.01a3.86 3.86 0 0 1 .32-.05h.04l.17-.02h.03a3.85 3.85 0 0 1 4.04 3.8c0 2.1-1.73 3.81-3.86 3.81Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h26v28H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
