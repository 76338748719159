type PersonActiveIconProps = {
  isActive?: boolean;
};

export default function PersonActiveIcon({
  isActive,
}: PersonActiveIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        fill={isActive ? "#32e532" : "currentColor"}
        d="M16 12.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.5 8a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-1.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.09 8.97A6.24 6.24 0 0 0 8.74 8H6.26A6.26 6.26 0 0 0 0 14.25a.75.75 0 0 0 1.5 0A4.76 4.76 0 0 1 6.26 9.5h2.48c.7 0 1.37.15 1.97.42a3.8 3.8 0 0 1 1.38-.95Z"
      />
    </svg>
  );
}
