import { Link } from "react-router-dom";
import { AppRoute } from "../../const";

export default function AuthDescription(): JSX.Element {
  return (
    <p className="auth-description">
      By accessing our website and its services, you accept our&nbsp;
      <Link className="link" to={AppRoute.Terms}>
        Terms&nbsp;of&nbsp;service
      </Link>
      ,{" "}
      <Link className="link" to={AppRoute.Disclaimers}>
        Disclaimers
      </Link>{" "}
      and&nbsp;
      <Link className="link" to={AppRoute.Policy}>
        Privacy&nbsp;Policy
      </Link>
    </p>
  );
}
