export default function BluePersonIcon(): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 4.59033C7.84532 4.59033 4 8.43565 4 13.0903C4 17.7449 7.84532 21.5903 12.5 21.5903C17.1547 21.5903 21 17.7449 21 13.0903C21 8.43565 17.1547 4.59033 12.5 4.59033Z"
        fill="#60A5FA"
      />
      <path
        d="M22 13.0903C22 17.7449 17.9285 21.5903 13 21.5903V4.59033C17.9285 4.59033 22 8.43565 22 13.0903Z"
        fill="#2563EB"
      />
      <path
        d="M12.9702 13.5903C10.5289 13.5903 8.20117 14.7533 6.5 17.2678C9.88763 21.73 16.1581 21.6919 19.5 17.1881C18.6748 16.2936 16.816 13.5903 12.9702 13.5903Z"
        fill="#A0CBFF"
      />
      <path
        d="M13 12.882C11.3457 12.882 10 11.4801 10 9.757V8.71533C10 6.99224 11.3457 5.59033 13 5.59033C14.6542 5.59033 16 6.99224 16 8.71533V9.757C16 11.4801 14.6542 12.882 13 12.882Z"
        fill="#A0CBFF"
      />
      <path
        d="M26 13.5903C26 16.5152 24.9945 19.4454 23.1512 21.7306L21.9629 20.786C23.6082 18.7294 24.4766 16.2105 24.4766 13.5903C24.4766 7.2833 19.307 2.11377 13 2.11377C6.69297 2.11377 1.52344 7.2833 1.52344 13.5903C1.52344 19.8974 6.69297 25.0669 13 25.0669C15.0109 25.0669 16.9762 24.5337 18.7129 23.5282L19.5254 24.8536C17.5602 25.9962 15.2699 26.5903 13 26.5903C5.85508 26.5903 0 20.7353 0 13.5903C0 6.44541 5.85508 0.590332 13 0.590332C20.1449 0.590332 26 6.44541 26 13.5903Z"
        fill="#60A5FA"
      />
      <path
        d="M19.1284 23.5903L20 24.8888C17.8918 26.0082 15.435 26.5903 13 26.5903V25.0978C15.1572 25.0978 17.2654 24.5754 19.1284 23.5903Z"
        fill="#2563EB"
      />
      <path
        d="M26 13.5041C26 16.4095 24.9945 19.3203 23.1512 21.5903L21.9629 20.6521C23.6082 18.6091 24.4766 16.1069 24.4766 13.5041C24.4766 7.2389 19.307 2.10366 13 2.10366V0.590332C20.1449 0.590332 26 6.40657 26 13.5041Z"
        fill="#2563EB"
      />
      <path
        d="M26 22.5903H21V17.5903H22.6667V20.9237H26V22.5903Z"
        fill="#2563EB"
      />
      <mask
        id="mask0_1104_1333"
        maskUnits="userSpaceOnUse"
        x="6"
        y="5"
        width="14"
        height="16"
      >
        <path
          d="M12.9702 13.5903C10.5289 13.5903 8.20117 14.7533 6.5 17.2678C9.88763 21.73 16.1581 21.6919 19.5 17.1881C18.6748 16.2936 16.816 13.5903 12.9702 13.5903Z"
          fill="#A0CBFF"
        />
        <path
          d="M13 12.882C11.3457 12.882 10 11.4801 10 9.757V8.71533C10 6.99224 11.3457 5.59033 13 5.59033C14.6542 5.59033 16 6.99224 16 8.71533V9.757C16 11.4801 14.6542 12.882 13 12.882Z"
          fill="#A0CBFF"
        />
      </mask>
      <g mask="url(#mask0_1104_1333)">
        <rect x="13" y="5.59033" width="8" height="15" fill="#60A5FA" />
      </g>
    </svg>
  );
}
