import HamburgerIcon from "../../icons/hamburger-icon";

type NavToggleProps = {
  isOpened: boolean;
  handleOpened: () => void;
};

export default function NavToggle({
  isOpened,
  handleOpened,
}: NavToggleProps): JSX.Element {
  return (
    <button
      className={`nav-toggle ${isOpened ? "nav-toggle--opened" : ""}`}
      type="button"
      aria-label="Open navigation"
      onClick={handleOpened}
    >
      <span className="nav-toggle__icon">
        <HamburgerIcon />
      </span>
    </button>
  );
}
