import ChainIcon from "../icons/chain-icon";
import AccountsIcon from "../icons/nav/accounts-icon";
import CardIcon from "../icons/payment/card-icon";
import WalletIcon from "../icons/payment/wallet-icon";

export const findMessageIcon = (type: string) => {
  if (type === "client-made-deposit") {
    return (
      <span className="notifications__icon notifications__icon--wallet">
        <WalletIcon />
      </span>
    );
  }

  if (
    type === "client-started-trading" ||
    type === "client-started-trading-first-time" ||
    type === "trading-stopped-by-client" ||
    type === "trading-stopped-by-keys-affected" ||
    type === "trading-stopped-by-portfolio-divergence" ||
    type === "trading-stopped-by-client-skip-charge" ||
    type === "trading-stopped-by-client-success-charge" ||
    type === "trading-stopped-by-client-failed-charge" ||
    type === "change-strategy-amount" ||
    type === "trading-stopped-by-negative-balance"
  ) {
    return (
      <span className="notifications__icon notifications__icon--accounts">
        <AccountsIcon />
      </span>
    );
  }
  if (
    type === "monthly-results-success-charge" ||
    type === "monthly-results-failed-charge" ||
    type === "monthly-results-success-charge-access-fee" ||
    type === "monthly-results-failed-charge-access-fee" ||
    type === "monthly-results-success-charge-service-fee" ||
    type === "monthly-results-failed-charge-service-fee"
  ) {
    return (
      <span className="notifications__icon notifications__icon--card">
        <CardIcon />
      </span>
    );
  }

  if (type === "referral-registered" || type === "referral-payment-accrued") {
    return (
      <span className="notifications__icon notifications__icon--chain">
        <ChainIcon />
      </span>
    );
  }
};
